import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaCheck, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { CSVLink } from "react-csv";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import { Switch } from "antd";


const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMAGE_URL;
function ResultForm() {
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const { examId, sectionId } = useParams();
  const [studentId, setStudentId] = useState([]);
  const [editedMark, setEditedMark] = useState("");
  const [mark, setMark] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [editedMarks, setEditedMarks] = useState({});
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (examId) {
      axios
        .get(`${apiUrl}/getAnswerbyExamId/${examId}`)
        .then((res) => {
          const details = res.data.data;
          setItem(res.data.data);
          setFilteredData(details || []);
          const studentIds = details.map((detail) => detail.studentId);
          setStudentId(studentIds);
          const marks = details.map((detail) => detail.writtenMark);
          setMark(marks);
  
          // Initialize editedMarks with the written marks from the backend
          const initialEditedMarks = {};
          details.forEach((detail) => {
            initialEditedMarks[detail.studentId] = detail.writtenMark || "";
          });
          setEditedMarks(initialEditedMarks);
  
          // Set the status based on the latest data
          setStatus(details.some((detail) => detail.status === 1));
  
          setTableData(details); // Set table data to trigger a refresh
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            // Handle error
          }
        });
    }
  }, [examId, status]); 

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(item);
    } else {
      const filtered = item.filter((row) =>
        row.studentName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, item, status]);

  const handleCsv = () => {
    if (item && item.length && item.map) {
      return item.map((row) => ({
        "Exam ID": row.examId,
        "Exam Name": row.examName,
        "Exam Duration": row.examDuration,
      }));
    }
    return [];
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      width: "60px",
    },

    {
      name: <h1 className="text-white pl-3 w-[250px]">Student Name</h1>,
      selector: (row) => row.studentName,
      sortable: true,
      width: "220px",
    },

    {
      name: <h1 className="text-white pl-3 w-[250px]">Student ID</h1>,
      selector: (row) => row.studentId,
      sortable: true,
      width: "220px",
    },

    {
      name: <h1 className="text-white pl-3 w-[250px]">MCQ Marks</h1>,
      selector: (row) => row.totalPoints,
      sortable: true,
      width: "215px",
    },
   
    {
      name: <h1 className="text-white pl-3 w-[250px]">Descriptive Mark</h1>,
      selector: (row) => row.writtenMark,
      sortable: true,
      width: "210px",
    },

  ];

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  return (
    <>
      <div className="flex flex-col w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
              </div>
            </div>
            <div className="flex flex-row items-center gap-6">
              <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="upload"
                    src={upload}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div>
              <div class="relative group flex items-center">
                <CSVLink
                  data={handleCsv()}
                  filename="Faculty List.csv"
                  class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
                >
                  {" "}
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </CSVLink>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>
          {/* Use the DataTable component */}
          {status ? (
            <div className="flex flex-col w-full">
              <DataTable
                fixedHeader
                fixedHeaderScrollHeight="400px"
                columns={columns}
                data={Array.isArray(filteredData) ? filteredData : []}
                selectableRows
                pagination
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
              />
            </div>
          ) : (
            <div className="text-center text-red-500 mt-4">
              Results are not published yet.
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ResultForm;
