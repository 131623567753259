import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MenuItem, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Modal, Typography } from "antd";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import Questions from "../../Forms/Questions";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const ExamForm = () => {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState([]);
  const [selectedSubjectName, setSelectedSubjectName] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState("");


  const { sectionId } = useParams();
  const [selectedFacultyName, setSelectedFacultyName] = useState([]);
  const initialFormData = {
    sectionId: "",
    examName: "",
    examId: "",
    assignFaculty: "",
    duration: "",
    subjectList: "",
    subjectId: "",
    dateOfExamination: "",
    totalMarks: "",
    passMarks: "",
    examLink: "",
    uploadFile: "",
    createExamOption: "",
    UniqueId: localStorage.getItem("Unique_id"),
    uploadedFileName: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const totalMarks = parseInt(formData.totalMarks, 10);
    const passMarks = parseInt(formData.passMarks, 10);
  
    if (isNaN(totalMarks) || isNaN(passMarks) || totalMarks < passMarks) {
      toast.warn("Pass Marks should be less than or equal to Total Marks.");
      return;
    }
    const formAppendData = new FormData();

    formAppendData.append("sectionId", sectionId);
    formAppendData.append("examName", formData.examName);
    formAppendData.append("examId", formData.examId);
    formAppendData.append("assignFaculty", selectedFacultyName);
    formAppendData.append("assignFacultyId", selectedFacultyId);
    formAppendData.append("duration", formData.duration);
    formAppendData.append("dateOfExamination", formData.dateOfExamination);
    formAppendData.append("totalMarks", formData.totalMarks);
    formAppendData.append("passMarks", formData.passMarks);
    formAppendData.append("examLink", formData.examLink);
    formAppendData.append("uploadFile", formData.uploadFile);
    formAppendData.append("subjectList", selectedSubjectName);
    formAppendData.append("subjectId", selectedSubjectId);
    formAppendData.append("createExamOption", formData.createExamOption);

    if (formData.createExamOption === "upload" && formData.uploadFile) {
      formAppendData.append("uploadFile", formData.uploadFile);
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },  
    };
    // Assuming you have an endpoint to submit the form data

    try {
      // Make a POST request to your server
      axios
        .post(`${apiUrl}/collegeExam`, formAppendData, config)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate(`/homepage/My Classroom/Exam/${sectionId}`);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
 
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      uploadFile: file,
      uploadedFileName: file ? file.name : '',
    });
  };

  const handleDoeChange = (date) => {
    setFormData({ ...formData, dateOfExamination: date.format("DD/MM/YYYY hh:mm A") });
  };

  const handleReset = (e) => {
    setFormData(initialFormData);
  };

 

  useEffect(() => {
    if (sectionId) {
      axios
        .get(`${apiUrl}/collegeassignsubjectbysectionid/${sectionId}`)
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch subjects.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Subjects not found");
          }
        });
    }
  }, [sectionId]);

  const handleSubjectChange = (e) => {
    const selectedSubjectList = e.target.value;
  
    const selectedSubject = subjects.find(
      (subject) => subject.subjectId === selectedSubjectList
    );
  
    if (selectedSubject) {
      setSelectedSubjectId(selectedSubject.subjectId);
      setSelectedFacultyName(selectedSubject.facultyName);
      setSelectedSubjectName(selectedSubject.subjectName)
      setSelectedFacultyId(selectedSubject.facultyId)
  
      console.log("Selected Subject:", selectedSubject);
      console.log("Selected Subject ID:", selectedSubject.subjectId);
      console.log("Selected Subject Name:", selectedSubject.subjectName);
      console.log("Selected Faculty Name:", selectedSubject.facultyName);
      console.log("Selected Faculty Name:", selectedSubject.facultyId);
    } else {
      console.log("Subject not found");
    }
  };
  

  const handleTimeChange = (newTime) => {
    setFormData({
      ...formData,
      duration: newTime.format("HH:mm:ss"), // Assuming newTime is a valid time object
    });
  };

  console.log("facultyId", selectedFacultyId);

  const handleFacultyChange = (e) => {
    const selectedFacultyName = e.target.value;
    const selectedFaculty = faculties.find(
      (faculty) => faculty.facultyName === selectedFacultyName
    );
  
    if (selectedFaculty) {
      setSelectedFacultyId(selectedFaculty.facultyId);
      // setSelectedFacultyName(selectedFacultyName); // Add this line to update the faculty name in the state
    }
  };
  const handleCreateButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const [getSubjectId, setGetSubjectId] = useState("");

  console.log(getSubjectId, "wferrgwvbv");

  console.log(formData.subjectList.id === undefined, "evrvwve");

  return (
    <div className="mb-4 flex justify-between items-center  top-0">
      <div className="w-full bg-white px-16 py-6  rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Section ID"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="sectionId"
                  value={sectionId}
                  InputProps={{
                    readOnly: true,
                  }}
                  // onChange={handleOnChange}
                />
                {/* )} */}

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam ID"
                  variant="outlined"
                  name="examId"
                  fullWidth
                  value={formData.examId}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Subject ID"
                  variant="outlined"
                  fullWidth
                  name="subjectId"
                  type="text"
                  required
                  value={
                    selectedSubjectId !== ""
                      ? selectedSubjectId
                      : formData.subjectId
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Assign Faculty"
                  variant="outlined"
                  fullWidth
                  name="assignFaculty"
                  onChange={handleFacultyChange}
                  type="text"
                  value={ 
                    selectedFacultyName !== ""
                      ? selectedFacultyName
                      : formData.facultyName
                  }
                  required
                ></TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Date of Examination"
                    format="DD/MM/YYYY hh:mm A"
                    variant="outlined"
                    type="date"
                    name="dateOfExamination"
                    fullWidth
              
                    onChange={handleDoeChange}

           
                  />
                </LocalizationProvider>
              </Stack>

              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam Name"
                  variant="outlined"
                  name="examName"
                  fullWidth
                  value={formData.examName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  label="Subject Name"
                  variant="outlined"
                  name="subjectList"
                  select
                  fullWidth
                  value={formData.subjectList || ""}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSubjectChange(e);
                  }}
                >
                  {subjects &&
                    subjects.map((subject) => (
                      <MenuItem key={subject._id} value={subject.subjectId}>
                        {subject.subjectName}
                      </MenuItem>
                    ))}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        sx={{
          backgroundColor: "#F5F5F5",
          color: "#105D50",
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#105D50",
          },
          "& .MuiInputBase-root": {
            height: 45,
            fontSize: "14px",
            width: 280,
          },
          "&:hover": {
            backgroundColor: "#EEEDED",
          },
        }}
        label="Duration HH:MM"
        variant="outlined"
        name="duration"
        fullWidth
        value={formData.duration}
        onChange={handleTimeChange}
        ampm={false}
        format="HH:mm:ss" // Include seconds in the format
        required
      />
    </LocalizationProvider>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Total Marks"
                  variant="outlined"
                  name="totalMarks"
                  type="number"
                  fullWidth
                  value={formData.totalMarks}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Pass Marks"
                  variant="outlined"
                  name="passMarks"
                  type="number"
                  fullWidth
                  value={formData.passMarks}
                  onChange={handleOnChange}
                  required
                />
              </Stack>
            </Stack>
            <Stack>
              <RadioGroup
                row
                name="createExamOption"
                value={formData.createExamOption}
                onChange={handleOnChange}
              >
                <FormControlLabel
                  value="create"
                  control={
                    <Radio
                      style={{
                        fontSize: "10px",
                        color: "#105D50",
                        marginLeft: "14px",
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px", color: "#105D50" }}>
                      create Exam
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="link"
                  control={
                    <Radio
                      style={{
                        fontSize: "10px",
                        color: "#105D50",
                        marginLeft: "14px",
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px", color: "#105D50" }}>
                      Exam Link
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="upload"
                  control={
                    <Radio
                      style={{
                        fontSize: "10px",
                        color: "#105D50",
                        marginLeft: "14px",
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px", color: "#105D50" }}>
                      File Upload
                    </Typography>
                  }
                />
              </RadioGroup>
              {formData.createExamOption === "create" && (
                <div className="flex flex-row justify-center">
                  <button
                    type="button"
                    className="w-28 text-[12px] font-normal  h-10 px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                    onClick={handleCreateButtonClick}
                  >
                    Create
                  </button>
                </div>
              )}

              <Modal
                title="Create Exam" // Set your modal title
                visible={isModalVisible}
                onCancel={handleCloseModal}
                width={900}
                footer={null} // Set to null if you don't want a footer
              >
                {/* Render your Questions component inside the modal */}
                <Questions
                  examId={formData.examId}
                  sectionId={sectionId}
                  subjectId={selectedSubjectId}
                  subjectName={selectedSubjectName}
                  facultyName={ selectedFacultyName}
                  totalMarks={formData.totalMarks}
                  facultyId={selectedFacultyId}
                  onQuestionSubmit={handleSubmit}
                  onCancel={handleCloseModal}
                />

               
               
              </Modal>
              {formData.createExamOption === "link" && (
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam Link"
                  variant="outlined"
                  fullWidth
                  name="examLink"
                  value={formData.examLink}
                  onChange={handleOnChange}
                  required
                />
              )}

              {formData.createExamOption === "upload" && (
                <Button
                  sx={{
                    height: 45,
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    borderColor: "#105D50",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                      borderColor: "#105D50",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  component="label"
                  name="uploadFile"
                >
                 {formData.uploadedFileName ? (
  <p>{formData.uploadedFileName}</p>
) : (
  <p>Upload File </p>
)}
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e)}
                    required
                  />
                </Button>
              )}
            </Stack>

            <Grid item xs={10}>
              <div className="flex  justify-center pt-6 pb-4  space-x-8">
                <button
                  type="button"
                  onClick={handleReset}
                  className="w-28 text-[12px] font-normal h-10 px-4 py-2   bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="w-28 text-[12px] font-normal h-10 px-4 py-2  bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="w-34 text-[12px] font-normal h-10 px-4 py-2   bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Submit & Add
                </button>
              </div>
            </Grid>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default ExamForm;
