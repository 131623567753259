import React from 'react';

const TimeTableUi = () => {
  return (
   <>

   <div className='px-8 py-4 ml-1 border border-spacing-1 font-bold text-xl border-primary text-primary w-[200px]'>
Time Table
   </div>
      <div className="rounded-md overflow-hidden border drop-shadow-md border-primary"> 
      <table className="w-full border-collapse border border-primary">
        <thead>
          <tr className="bg-primary py-6 text-white">
            <th className="py-4">Time</th>
            <th className="p-2">Monday</th>
            <th className="p-2">Tuesday</th>
            <th className="p-2">Wednesday</th>
            <th className="p-2">Thursday</th>
            <th className="p-2">Friday</th>
            <th className="p-2">Saturday</th>
            {/* Add more header columns if needed */}
          </tr>
        </thead>
        <tbody>
          <tr className="border-b p-4 border-primary"> 
          <div className='p-6'>
          </div>
          </tr>
          <tr className="border-b border-primary">
          <div className='p-6'>
          </div>
          </tr>
          <tr className="border-b border-primary">
          <div className='p-6'>
          </div>
          </tr>
          <tr className="border-b border-primary">
          <div className='p-6'>
          </div>
          </tr>
          <tr className="border-b border-primary">
          <div className='p-6'>
          </div>
          </tr>
          <tr className="border-b border-primary">
          <div className='p-6'>
          </div>
          </tr>
          <tr className="border-b rounded-lg border-primary">
          <div className='p-6'>
          </div>
          </tr>
        </tbody>
      </table>
    </div>
    </>
  );
};

export default TimeTableUi;
