import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

const ExamWarningPage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState([]);
  const [sectionId, setSectionId] = useState();
  const { examId } = useParams();
  const [exam, setExam] = useState([]);
  const [studentName, setStudentName] = useState([]);
  const [examName , setExamName] = useState([])

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleFormView = () => {
    if (exam.createExamOption === "create") {
      navigate(`/homepage/My Course/${examId}/${examId}`);
    } else if (exam.uploadFile) {
      navigate(`/homepage/My Course/${examId}/${examId}/Questions`);
    } else if (exam.examLink) {
      window.open(exam.examLink, "_blank");
    } else {
      console.warn("No matching condition found.");
    }
    createStatusForExam();
  };

  const createStatusForExam = () => {
    try {
      const studentId = localStorage.getItem("Unique_id");

      axios
        .post(`${apiUrl}/createstatus`, {
          studentId,
          studentName,
          examId,
          examName,
          status: 1,
        })
        .then((res) => {
          console.log("Status created successfully:", res.data);
          toast.success("Status created successfully");
        })

        .catch((error) => {
          console.error("Error creating status:", error);
          toast.error("Failed to create status");
        });
    } catch (error) {
      console.error("Exception:", error);
      toast.error("An error occurred");
    }
  };
  useEffect(() => {
    axios
      .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        if (res.data && res.data.data) {
          const studentsData = res.data.data;
  
          if (studentsData.length > 0) {
            setStudentData(studentsData);
            setSectionId(studentsData[0].sectionId);
            setStudentName(studentsData[0].studentName);

          } else {
            console.warn("No student data found.");
          }
        } else {
          console.warn("No data or data.data property found in the response.");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          toast.info("No Data Found");
        }
      });
  }, []);
  

  console.log("student Name", studentName);
  console.log("student", studentData);

  useEffect(() => {
    try {
      if (sectionId) {
        axios
          .get(`${apiUrl}/collegeexambyexamid/${examId}`)
          .then((res) => {
            const examData = res.data.data;

            console.log("Exam Data:", examData);

            if (examData.length > 0) {
              setExam(examData[0]);
              setExamName(examData[0].examName)
            } else {
              console.warn("No exam data found.");
            }
          })
          .catch((error) => {
            console.error("Error fetching subjects:", error);
            if (error.response) {
              console.error("Response data:", error.response.data);
              console.error("Response status:", error.response.status);
              toast.error("Failed to fetch subjects");
            }
          });
      }
    } catch (error) {
      console.error("Exception:", error);
      toast.error("An error occurred");
    }
  }, [sectionId]);
  console.log("examName", examName);
  return (
    <div className="flex flex-col items-center justify-center p-6 w-[70%] bg-gray-100 mx-auto">
      {/* Exam information */}
      <div className="mb-6 text-center">
        <h1 className="text-3xl font-bold mb-2 text-primary">{`Exam Name: ${exam.examName}`}</h1>
        <p className="text-lg">{`Exam ID: ${exam.examId}`}</p>
      </div>

      {/* Warning messages */}
      <div className="p-6 rounded-md mb-6 bg-white shadow-lg">
        <p className="text-secondary font-bold mb-4">Terms:</p>
        <ul className="list-disc pl-5">
          <li>Ensure you have a stable internet connection.</li>
          <li>Do not refresh the page during the exam.</li>
          <li>
            <span className="text-base font-semibold">Attention:</span> The exam{" "}
            {`Duration: ${exam.duration}`}
            Complete all questions within this time.
          </li>
        </ul>

        <div className="items-start mt-4 ml-2">
          <label className="flex items-start text-primary space-x-2 mb-4">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="form-checkbox h-4 w-4 text-primary"
            />
            <span className="text-sm">I accept all instructions</span>
          </label>
        </div>
      </div>

      <div className="flex items-center justify-center">
        <button
          className={`px-4 py-2 bg-primary text-white rounded-md transition-all ${
            !isChecked
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-primary-dark"
          }`}
          onClick={toggleFormView}
          disabled={!isChecked}
        >
          Start Exam
        </button>
      </div>
    </div>
  );
};

export default ExamWarningPage;

