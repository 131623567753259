import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { FaEdit, FaUserCircle } from "react-icons/fa";
import profile from "../Images/28.png";
import Calendar from "../cal/MainCalendar";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";

import { IconButton, Stack } from "@mui/material";
import { Modal, Row, Col, Typography, Divider } from 'antd';

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const SProfile = React.memo(({ sectionId }) => {
  console.log("sectId",sectionId);
  const { Text, Title, Paragraph } = Typography;
  const [showlog, setShowlog] = useState(false);
  const [showlogs, setShowlogs] = useState(false);
  const [data, setData] = useState([]);
  const [profilePic, setProfilePic] = useState([]);
  const [profileImagePath, setProfileImagePath] = useState("");
  const [form, setForm] = useState({
  uploadProfile: null,
    first_name: "",
    password: "",
    emailId: "",
    mobileNumber: "",
    landlineNumber: "",
    addressLine: "",
    area: "",
    district: "",
    state: "",
    country: "",
  });

  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [name, setName] = useState([]);
  const [isEditingName, setIsEditingName] = useState([]);
  const LMS_ROLE = JSON.parse(localStorage.getItem("lms_role"));
  const profile=localStorage.getItem("profile");

  useEffect(() => {
    // Assuming LMS_ROLE is available somewhere in your component's props or state
    const profileImagePaths = {
      2: "AdminUploads",
      3: "InstituteUploads",
      4: "CollegeInstituteAdminUploads",
      5: "CollegeBranchUploads",
      6: "CollegeBranchAdminUploads",
      7: "CollegeFacultyUploads",
      8: "CollegeStudentUploads",
    };
    setProfileImagePath(profileImagePaths[LMS_ROLE]);
  }, [LMS_ROLE]);

  const getName = () => {
    switch (LMS_ROLE) {
      case 1:
        return setName("Sathish Kumar");

      case 2:
        return axios
          .get(`${apiUrl}/admin/${localStorage.getItem("Unique_id")}`)

          .then((res) => setName(res.data.data[0].name));

      case 3:
        return axios
          .get(`${apiUrl}/institute/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setProfilePic(res.data.data[0].uploadInstituteLogo);
            setForm({
              first_name: res.data.data[0].instituteName,
              id:res.data.data[0].instituteID,
              password: res.data.data[0].password,
              emailId: res.data.data[0].emailId,
              mobileNumber: res.data.data[0].mobileNumber,
              addressLine: res.data.data[0].addressLine1,
              area: res.data.data[0].area,
              district: res.data.data[0].district,
              state: res.data.data[0].state,
              country: res.data.data[0].country,
            });
          });

      case 4:
        return axios
          .get(
            `${apiUrl}/collegeinstituteadmin/${localStorage.getItem(
              "Unique_id"
            )}`
          )
          .then((res) => {
            setData(res.data.data);
            setProfilePic(res.data.data[0].uploadProfilePic);
            setForm({
              first_name: res.data.data[0].name,
              id:res.data.data[0].instituteAdminId,
              password: res.data.data[0].password,
              emailId: res.data.data[0].emailId,
              mobileNumber: res.data.data[0].mobileNumber,
              addressLine: res.data.data[0].addressLine1,
              area: res.data.data[0].area,
              district: res.data.data[0].district,
              state: res.data.data[0].state,
              country: res.data.data[0].country,
            });
          });
      case 5:
        return axios
          .get(`${apiUrl}/collegebranch/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setProfilePic(res.data.data[0].uploadBranchLogo);
            setForm({
              first_name: res.data.data[0].branchName,
              id: res.data.data[0].branchId,
              password: res.data.data[0].password,
              emailId: res.data.data[0].emailId,
              mobileNumber: res.data.data[0].mobileNumber,
              addressLine: res.data.data[0].addressLine1,
              area: res.data.data[0].area,
              district: res.data.data[0].district,
              state: res.data.data[0].state,
              country: res.data.data[0].country,
            });
          });

          
      case 6:
        return axios
          .get(
            `${apiUrl}/collegebranchadmin/${localStorage.getItem("Unique_id")}`
          )
          .then((res) => {
            setData(res.data.data);
            setProfilePic(res.data.data[0].uploadProfilePic);
            setForm({
              first_name: res.data.data[0].name,
              id: res.data.data[0].branchAdminId,
              password: res.data.data[0].password,
              emailId: res.data.data[0].emailId,
              mobileNumber: res.data.data[0].mobileNumber,
              addressLine: res.data.data[0].addressLine1,
              area: res.data.data[0].area,
              district: res.data.data[0].district,
              state: res.data.data[0].state,
              country: res.data.data[0].country,
            });
          });

      case 7:
        return axios
          .get(`${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            
            const userData = res.data.data[0]; // Assuming the response contains user data
            setData(userData);
            setProfilePic(userData.uploadProfile);
            setForm({
              first_name: userData.facultyName,
              id: userData.facultyId,
              code: userData.employeecode,
              password: userData.password,
              dob: userData.dob,
              yoj: userData.yoj,
              qualification:userData.qualification,
              gender:userData.gender,
              emailId: userData.emailId,
              mobileNumber: userData.mobileNumber,
              addressLine: userData.addressLine1,
              area: userData.area,
              district: userData.district,
              state: userData.state,
              country: userData.country,
            });
          });

      default:
        return axios
          .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setData(res.data.data);
            setProfilePic(res.data.data[0].uploadStudentProfile);
            setForm({
              first_name: res.data.data[0].studentName,
              id: res.data.data[0].studentId,
              gender:res.data.data[0].gender,
              dob:res.data.data[0].dob,
              yoj:res.data.data[0].yoj,
              password: res.data.data[0].password,
              emailId: res.data.data[0].emailId,
              mobileNumber: res.data.data[0].mobileNumber,
              addressLine: res.data.data[0].addressLine1,
              area: res.data.data[0].area,
              district: res.data.data[0].district,
              state: res.data.data[0].state,
              country: res.data.data[0].country,
            });
          });
    }
  };

  console.log(data, "data");
  console.log("upload image ",profilePic);


  // useEffect(() => {
  //   getName();
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setProfilePic(file);
  };

  const handleEditName = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
   

    try {
      // Make an API call to update the name based on the user's role
      switch (LMS_ROLE) {
        case 1:
          // Handle Super Admin name update here
          break;
        case 2:
          await axios.put(
            `${apiUrl}/admin/${localStorage.getItem("Unique_id")}`,
            {}
          );
          break;
        case 3:
          const instituteFormData= new FormData();
          instituteFormData.append('uploadInstituteLogo', profilePic);
          instituteFormData.append('instituteName', form.first_name);
          instituteFormData.append('password', form.password);
          instituteFormData.append('emailId', form.emailId);
          instituteFormData.append('mobileNumber', form.mobileNumber);
          instituteFormData.append('addressLine1', form.addressLine);
          instituteFormData.append('area', form.area);
          instituteFormData.append('district', form.district);
          instituteFormData.append('state', form.state);
          instituteFormData.append('country', form.country);

          await axios.put(
            `${apiUrl}/institutedatatype/${localStorage.getItem("Unique_id")}`,
            instituteFormData,
            {
             headers: {
               'Content-Type': 'multipart/form-data'
             }
           },
          );
          break;
        case 4:
          const instituteAdminFormData= new FormData();
          instituteAdminFormData.append('uploadProfilePic', profilePic);
          instituteAdminFormData.append(' name', form.first_name);
          instituteAdminFormData.append('password', form.password);
          instituteAdminFormData.append('emailId', form.emailId);
          instituteAdminFormData.append('mobileNumber', form.mobileNumber);
          instituteAdminFormData.append('addressLine1', form.addressLine);
          instituteAdminFormData.append('area', form.area);
          instituteAdminFormData.append('district', form.district);
          instituteAdminFormData.append('state', form.state);
          instituteAdminFormData.append('country', form.country);
          await axios.put(
            `${apiUrl}/instituteadmindatatype/${localStorage.getItem(
              "Unique_id"
            )}`,
            instituteAdminFormData,
            {
             headers: {
               'Content-Type': 'multipart/form-data'
             }
           },
          );
          break;


        case 5:
        const branchFormData= new FormData();
        branchFormData.append('uploadBranchLogo', profilePic);
        branchFormData.append('branchName', form.first_name);
        branchFormData.append('password', form.password);
        branchFormData.append('emailId', form.emailId);
        branchFormData.append('mobileNumber', form.mobileNumber);
        branchFormData.append('addressLine1', form.addressLine);
        branchFormData.append('area', form.area);
        branchFormData.append('district', form.district);
        branchFormData.append('state', form.state);
        branchFormData.append('country', form.country);

          await axios.put(
            `${apiUrl}/branchdatatype/${localStorage.getItem("Unique_id")}`,
            branchFormData,
            {
             headers: {
               'Content-Type': 'multipart/form-data'
             }
           },
           );
          break;
        case 6:
            const branchAdminFormData = new FormData();
            branchAdminFormData.append('uploadProfilePic', profilePic);
            branchAdminFormData.append('name', form.first_name);
            branchAdminFormData.append('password', form.password);
            branchAdminFormData.append('emailId', form.emailId);
            branchAdminFormData.append('mobileNumber', form.mobileNumber);
            branchAdminFormData.append('addressLine1', form.addressLine);
            branchAdminFormData.append('area', form.area);
            branchAdminFormData.append('district', form.district);
            branchAdminFormData.append('state', form.state);
            branchAdminFormData.append('country', form.country);
            await axios.put(
            `${apiUrl}/branchadmindatatype/${localStorage.getItem(
              "Unique_id"
            )}`,
            branchAdminFormData,
           {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },
          );
          
          break;
        case 7:
          const formData = new FormData();
          formData.append('uploadProfile', profilePic);
          formData.append('facultyName', form.first_name);
          formData.append('password', form.password);
          formData.append('emailId', form.emailId);
          formData.append('mobileNumber', form.mobileNumber);
          formData.append('addressLine1', form.addressLine);
          formData.append('area', form.area);
          formData.append('district', form.district);
          formData.append('state', form.state);
          formData.append('country', form.country);
      
          await axios.put(
            `${apiUrl}/facultydatatype/${localStorage.getItem("Unique_id")}`,
           formData,
           {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          },
          );
          break;
  
        default:
          const studentFormData = new FormData();
          studentFormData.append('uploadStudentProfile', profilePic);
          studentFormData.append('studentName', form.first_name);
          studentFormData.append('password', form.password);
          studentFormData.append('emailId', form.emailId);
          studentFormData.append('mobileNumber', form.mobileNumber);
          studentFormData.append('addressLine1', form.addressLine);
          studentFormData.append('area', form.area);
          studentFormData.append('district', form.district);
          studentFormData.append('state', form.state);
          studentFormData.append('country', form.country);
      
          await axios.put(
            `${apiUrl}/studentdatatype/${localStorage.getItem("Unique_id")}`,
            studentFormData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            },
          );
      
          break;
      }
  
      // Update the local state and notify the user
      toast.success("Profile updated successfully");
      setIsEditingName(false);
      getName();
    } catch (error) {
      // Handle error
      console.error("Error updating name:", error);
      toast.error("Error updating name");
    }
  };
 


  
  return (
    <div className="flex flex-col border rounded-[10px] bg-white shadow-md w-full h-fit p-5 gap-2">
      <div className="flex flex-row justify-between w-full ">
        <h4 className="text-lg font-semibold text-primary">Profile</h4>

        <div className="flex justify-end text-primary">
          <FaEdit
            size={14}
            onClick={() => {
              setShowlog(!showlog);
              getName();
            }}
            className="w-5 h-4 cursor-pointer"
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <div className="w-full flex justify-center relative">
          <svg
            className="w-[130px] h-[130px]"
            viewBox="0 0 102 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.49476 71.7131C1.6611 65.3555 0.162274 58.484 0.0910427 51.5239C0.0198115 44.5637 1.37769 37.663 4.08064 31.2487L7.03806 32.495C4.50562 38.5046 3.23341 44.97 3.30015 51.491C3.36688 58.0121 4.77115 64.4501 7.42605 70.4066L4.49476 71.7131Z"
              fill="#D6F8F8"
            />
            <path
              d="M5.57612 28.0203C9.65794 19.9602 15.8127 13.1326 23.4076 8.23934C31.0026 3.34613 39.7636 0.563885 48.7902 0.17865L48.927 3.385C40.4699 3.74593 32.2616 6.35265 25.1458 10.9372C18.03 15.5217 12.2635 21.9186 8.43919 29.4702L5.57612 28.0203Z"
              fill="#69DED7"
            />
            <path
              d="M52.1819 0.14708C60.7086 0.352074 69.0468 2.69765 76.4299 6.96811C83.8129 11.2386 90.0037 17.2968 94.4329 24.5857L91.6903 26.2523C87.5405 19.4232 81.7403 13.7472 74.823 9.74614C67.9058 5.74509 60.0935 3.54749 52.1048 3.35542L52.1819 0.14708Z"
              fill="#12A189"
            />
            <path
              d="M96.1939 27.7293C99.8035 34.7449 101.732 42.5038 101.826 50.393C101.921 58.2821 100.18 66.0851 96.7393 73.1852L93.8512 71.7858C97.0744 65.1336 98.7061 57.8229 98.6175 50.4315C98.5288 43.04 96.7221 35.7705 93.3402 29.1976L96.1939 27.7293Z"
              fill="#105D50"
            />
          </svg>
          <img
            src={
              profilePic
                ? `${ImageUrl}/${profileImagePath}/${profile}`
                : profile
            }
            //onClick={() => setShowlog(!showlog)}
            className="w-24 h-24 rounded-full  absolute top-4 right-auto"
            alt="Profile"
            name="uploadProfile"
            style={{ objectFit: 'cover' }}
            onClick={() => setShowlogs(!showlogs)}
          /> 
        </div>
        <h4 className="text-lg font-medium text-center">{form.first_name}</h4>
      </div>
      <div className="w-full flex justify-center">
        <Calendar />
      </div>
      <Modal
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={showlog}
        onCancel={() => setShowlog(!showlog)}
        footer={null}
      >
        <div className="flex flex-col justify-center items-center gap-2">
          <div className="flex flex-col w-full h-fit px-4 sm:px-6 gap-2 border bg-zinc-50 rounded-md">
            <div className="flex flex-col justify-center items-center w-full  h-fit gap-2">
              <div className="flex flex-rows justify-center items-center w-full  h-12 mt-3">
                <h2 className="text-xl md:text-2xl  font-semibold text-primary w-full">
                  Edit Profile
                </h2>
             
              </div>
              <div className="flex flex-col justify-center items-center">
                <div className="w-full flex justify-center">
                
                  {/* <img
                    src={
                      profilePic
                        ? `${ImageUrl}/${profileImagePath}/${profilePic}`
                        : profile
                    }
                    //onClick={() => setShowlog(!showlog)}
                    className="w-[120px] h-[120px] rounded-full  right-auto"
                    alt="Profile"
                  /> */}
                </div>
                <h4 className="text-lg font-medium text-center">
                  {form.first_name}
                </h4>
              </div>

              {/* <div className="w-full h-[3px] bg-gray-300 rounded-full"></div> */}
            </div>
            <form onSubmit={handleEditName}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6"></div>
              <div className="w-full h-fit grid grid-cols-1 gap-2">
              <div className="text-center">
 
  <input
    accept="image/*"
    name="uploadProfile"
    id="image-upload"
    type="file"
    onChange={handleImageUpload}
    className="mb-8"
    style={{ display: "none", backgroundColor: "white" }}
  />
  <span className="font-medium text-gray-500 text-center ">
  
  </span>
   <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              > {profilePic && (
                <img
                src={profilePic && profilePic instanceof Blob
                  ? URL.createObjectURL(profilePic)
                  : (profilePic ? `${ImageUrl}/${profileImagePath}/${profilePic}` : null)}
                className=" w-24 h-24 rounded-full object-cover"
                style={{ objectFit: 'cover' }}
                alt="Profile"
              />
              )}</IconButton>
              </label>
</div>

               
                <div className="grid grid-cols-1 md:grid-cols-2  gap-5">
                 
          
                  <div className=" grid grid-cols-1  items-center gap-2 ">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Name{" "}
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="text"
                        required
                        name="first_name"
                        // value={name}
                        // onChange={(e) => setNewName(e.target.value)}
                        value={form.first_name}
                        onChange={handleInputChange}
                        placeholder="enter your name"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
       "
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Password{" "}
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="password"
                    
                        name="password"
                        value={form.password}
                        // onChange={handleInputChange}
                        placeholder="Password"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div className="h-28 grid grid-cols-1  items-center gap-2 ">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Email ID{" "}
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="text"
              
                        name="emailId"
                        value={form.emailId}
                        // onChange={handleInputChange}
                        placeholder="example@gmail.com"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
       "
                      />
                    </div>
                  </div>

                  <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Mobile Number
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Number"
                        required
                        name="mobileNumber"
                        value={form.mobileNumber}
                        onChange={handleInputChange}
                        placeholder="+91-1234 5678 90"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>
                </div>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  {/* <div className="h-28 grid grid-cols-1  items-center gap-2 ">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Landline Number{" "}
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Number"
                        required
                        name="landlineNumber"
                        value={form.landlineNumber}
                        onChange={handleInputChange}
                        placeholder="Enter your name"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
       "
                      />
                    </div>
                  </div> */}

                  <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          AddressLine
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Text"
                        required
                        name="addressLine"
                        value={form.addressLine}
                        onChange={handleInputChange}
                        placeholder="Enter your address"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>

                  <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Area
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Text"
                        required
                        name="area"
                        value={form.area}
                       onChange={handleInputChange}
                        placeholder="Enter your area"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>

                  <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          District
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Text"
                        required
                        name="district"
                        value={form.district}
                        onChange={handleInputChange}
                        placeholder="Enter your district"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>
                  <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          State
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Text"
                        required
                        name="state"
                        value={form.state}
                        onChange={handleInputChange}
                        placeholder="Enter your state"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>
                  <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Country
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Text"
                        required
                        name="country"
                       value={form.country}
                        onChange={handleInputChange}
                        placeholder="Enter your country"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div>
                  </div>
                  {/* <div className="h-28 grid grid-cols-1 items-center gap-2">
                    <div>
                      <label className="flex flex-row gap-1">
                        <span className="text-base font-medium text-gray-500">
                          {" "}
                          Country
                        </span>
                      </label>
                    </div>
                    <div className="grid grid-cols-1">
                      <input
                        type="Text"
                        required
                        name="country"
                       value={form.country}
                        onChange={handleInputChange}
                        placeholder="Enter your country"
                        className="p-2 border-[1.5px] border-gray-300 rounded-md
                        text-md text-gray-500 focus:border-primary focus:border-[1.5px] focus:bg-white focus:text-black focus:outline-none 
                       "
                      />
                    </div> 
                  </div>*/}
                </div> 
                <div className="h-32 flex flex-row justify-center items-center gap-6 ">
                  <button
                    className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary hover:shadow-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md "
                    type="submit"

                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Modal
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        open={showlogs}
        onCancel={() => setShowlogs(!showlogs)}
        footer={null}
      >
  {/* <FaEdit
    size={14}
    onClick={() => setShowlog(!showlog)}
    className="  w-4 h-4 text-primary cursor-pointer"
  /> */}

          <div className="py-4">
               <Row gutter={[16, 16]} className="flex flex-col sm:flex-row ">
                  <Col span={2.1}>
                  <img src={preview1} className="flex justify-start " ></img>
                  </Col>
                
                {/* <Row gutter={[16, 16]} align="left" > */}
                <div className="flex">
                    <Col span={4.5} className="z-50 flex flex-col ">
                    {profilePic && (
                <img
                src={profilePic && profilePic instanceof Blob
                  ? URL.createObjectURL(profilePic)
                  : (profilePic ? `${ImageUrl}/${profileImagePath}/${profilePic}` : null)}
                  className="rounded-full w-20 h-20  bg-white object-cover border-white border-[3px] drop-shadow-xl"
                alt="Profile"
              />
              )}
                    </Col>
                    <Col span={12} className="flex flex-col justify-center mt-2">
                      <Title level={3} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 text-white rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1 ">
                        {form.first_name}
                      </Title>
                    </Col>
                  </div>
                {/* </Row> */}
            </Row>
            <div className="sm:ml-20 my-6 space-y-2">
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">ID:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.id}</Text>
                  </Col>
                </Row>
               
               {LMS_ROLE === 7 && (
  <Row gutter={[16, 16]}>
    <Col flex="200px">
      <Text strong className="text-primary">Employee Code:</Text>
    </Col>
    <Col flex="auto">
      <Text>{form.code}</Text>
    </Col>
  </Row>
)}
 {LMS_ROLE === 7 && (
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Qualification:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.qualification}</Text>
                  </Col>
                </Row>
                )}
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Email ID:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.emailId.toLowerCase()}</Text>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Mobile Number:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.mobileNumber}</Text>
                  </Col>
                </Row>

                {(LMS_ROLE === 7 || LMS_ROLE === 8 )&& (
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Gender:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.gender}</Text>
                  </Col>
                </Row>
                    )}
                  {(LMS_ROLE === 7 || LMS_ROLE === 8 )&& (
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Date Of Birth:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.dob}</Text>
                  </Col>
                </Row>
                  )}
                      {(LMS_ROLE === 7 || LMS_ROLE === 8 )&& (
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Date Of Joining:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{form.yoj}</Text>
                  </Col>
                </Row>
                      )}

<Row gutter={[16, 16]}>
  <Col flex="200px">
    <Text strong className="text-primary">Address:</Text>
  </Col>
  <Col flex="auto">
    <Row gutter={[0, 8]}>
      <Col flex="auto">
        <Text>{form.addressLine}</Text>
      </Col>
    </Row>
    <Row gutter={[0, 8]}>
      <Col flex="auto">
        <Text>{form.area}</Text>
      </Col>
    </Row>
    <Row gutter={[0, 8]}>
      <Col flex="auto">
        <Text>{form.district}</Text>
      </Col>
    </Row>
    <Row gutter={[0, 8]}>
      <Col flex="auto">
        <Text>{form.state}</Text>
      </Col>
    </Row>
    <Row gutter={[0, 8]}>
      <Col flex="auto">
        <Text>{form.country}</Text>
      </Col>
    </Row>
  </Col>
</Row>


                </div>
             <Row className="flex justify-end -mt-20" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
              </Row>
              </div>
           
        </Modal>
    </div>
  );
});

export default SProfile;
