import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Modal, Typography } from 'antd';
import axios from "axios";

function AnnouncementEvent() {
  const { announcementId } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (announcementId) {
      axios
        .get(`${apiUrl}/collegeannouncementeventsbyuniqueId/${announcementId}`)
        .then((res) => {
          const details = res.data.data;
          setItem(details);
          setFilteredData(details || []);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            // Handle error
          }
        });
    }
  }, [announcementId, apiUrl]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(item);
    } else {
      const filtered = item.filter((row) =>
        row.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, item]);


  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[80px]">S.No</h1>,
      selector: (row, index) => index + 1,
      width: '80px',
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Name</h1>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Id</h1>,
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Mobile Number</h1>,
      selector: (row) => row.mobileNumber,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Mail ID</h1>,
      selector: (row) => row.emailId,
      sortable: true,
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="W-full h-fit bg-white flex flex-col items-center gap-3 rounded-md pt-5 px-1 drop-shadow border">
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700 focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none rounded-md"
            onChange={handleSearchChange}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
        </div>
      </div>
      <div className="flex flex-col w-full">
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
    </div>
  );
}

export default AnnouncementEvent;
