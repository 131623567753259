import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button, Stack } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate} from "react-router-dom";


const SupportForm = () => { 

  const LMS_ROLE = localStorage.getItem("lms_role");

  const apiUrl = process.env.REACT_APP_API_URL;
  const [uploadFile, setUploadFile] = useState(null);
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);

  const initialFormData = {
    emailId: "",
    pointOfContact: "",
    mobileNumber: "",
    supportType: "",
    priority: "",
    subject:"",
    description:"",
    uploadFile:null,
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === '5'
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };
 
    const [formData, setFormData] = useState(initialFormData);

    const handleOnChange = (e) => {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
    
      const formAppendData = new FormData();
      formAppendData.append("emailId", formData.emailId);
      formAppendData.append("pointOfContact", formData.pointOfContact);
      formAppendData.append("mobileNumber", formData.mobileNumber);
      formAppendData.append("supportType", formData.supportType);
      formAppendData.append("priority", formData.priority);
      formAppendData.append("subject", formData.subject);
      formAppendData.append("description", formData.description);
      formAppendData.append("uploadFile", uploadFile);
      formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
      formAppendData.append(
        "instituteUniqueId",
        LMS_ROLE === "3" || LMS_ROLE === "5"
          ? localStorage.getItem("Unique_id")
          : localStorage.getItem("institute_Unique_Id")
      );
      // Create the Axios config object with the headers
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
  
      if (uploadFile === null) {
        toast.warn("please upload the student data");
      } 
       else {
          axios
          .post(
            `${apiUrl}/collegesupportform`,
            formAppendData,
            config
          )
            .then((res) => {
              if (res.data.status === 301) {
                toast.warn(res.data.message);
              } else if (res.status === 200) {
                toast.success(res.data.message);
                navigate("/homepage/support list");
              }
            })
            .catch((error) => {
              if (error.response.data.status === 404) {
                toast.warn(error.response.data.message);
              }
            });
        }
      }
      const handleSubmitandAdd = async (event) => {
        event.preventDefault();
      
        const formAppendData = new FormData();
        formAppendData.append("emailId", formData.emailId);
        formAppendData.append("pointOfContact", formData.pointOfContact);
        formAppendData.append("mobileNumber", formData.mobileNumber);
        formAppendData.append("supportType", formData.supportType);
        formAppendData.append("priority", formData.priority);
        formAppendData.append("subject", formData.subject);
        formAppendData.append("description", formData.description);
        formAppendData.append("uploadFile", uploadFile);
        formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
        formAppendData.append(
          "instituteUniqueId",
          LMS_ROLE === "3" || LMS_ROLE === "5"
            ? localStorage.getItem("Unique_id")
            : localStorage.getItem("institute_Unique_Id")
        );
        // Create the Axios config object with the headers
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
    
        if (uploadFile === null) {
          toast.warn("please upload the student data");
        } 
         else {
            axios
            .post(
              `${apiUrl}/collegesupportform`,
              formAppendData,
              config
            )
              .then((res) => {
                if (res.data.status === 301) {
                  toast.warn(res.data.message);
                } else if (res.status === 200) {
                  toast.success(res.data.message);
                 setFormData(initialFormData);
                }
              })
              .catch((error) => {
                if (error.response.data.status === 404) {
                  toast.warn(error.response.data.message);
                }
              });
          }
        }             
   
  
    useEffect(() => {
        setFormData({ ...formData });
     });
  
    
   const handleFileUpload=(e)=> {
    setUploadFile(e.target.files[0]);
    console.log(formData.uploadStudentData);
    }
  
    const handleReset = () => {
    setFormData(initialFormData);
    };
  

  return (
    <div className="mb-4 flex justify-between items-center top-0">
      <div className="w-full bg-white px-16 py-6 rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"390px"}
                spacing={2}
              >
                 <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Point Of Contact"
                  variant="outlined"
                  name="pointOfContact"
                  type="text"
                  fullWidth
                  value={formData.pointOfContact}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  name="mobileNumber"
                  type="number"
                  fullWidth
                  value={formData.mobileNumber}
                  onChange={handleOnChange}
                  required
                />
               
                
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Support Type"
                  variant="outlined"
                  name="supportType"
                  type="text"
                  fullWidth
                  select
                  value={formData.supportType}
                  onChange={handleOnChange}
                  required
                >
                  <MenuItem value="Technical" style={{fontSize:"14px"}}>Technical</MenuItem>
                  <MenuItem value="General" style={{fontSize:"14px"}}>General</MenuItem>
                  
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Priority"
                  variant="outlined"
                  name="priority"
                  type="text"
                  fullWidth
                  select
                  value={formData.priority}
                  onChange={handleOnChange}
                  required
                >
                  <MenuItem value="Low" style={{fontSize:"14px"}}>Low</MenuItem>
                  <MenuItem value="Medium"  style={{fontSize:"14px"}}>Medium</MenuItem>
                  <MenuItem value="High"  style={{fontSize:"14px"}}>High</MenuItem>
                </TextField>
                
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height:45,
                     width:390,
                     fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
          variant="outlined"
          name="subject"
          label="Subject"
          type="text"
        
          value={formData.subject}
          onChange={handleOnChange}
          
          //defaultValue="Default Value"
        />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height:120,
                     width:390,
                     fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
          id="outlined-multiline-static"
          name="description"
          label="Description"
          type="text"
          multiline
          value={formData.description}
          onChange={handleOnChange}
          rows={4}
          //defaultValue="Default Value"
        />
         <Button
                    sx={{
                        height:45,
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        borderColor: '#105D50',
                        "&:hover": {
                        backgroundColor: "#EEEDED",
                        borderColor:"#105D50"
                        },
                    
                    }}
                    variant="outlined"
                    fullWidth
                    startIcon={<CloudUploadIcon />}
                    component="label"
                    onChange={handleFileUpload}
                    >
                    File upload
                    <input type="file" style={{ display: 'none' }} required />
                    </Button>
              </Stack>
            </Stack>

            <Grid item xs={10}>
              <div className="flex  justify-center space-x-8 pt-4 pb-4">
              {!editMode && (
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-3 bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
            )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-3 bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-3  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
            </Grid>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default SupportForm;
