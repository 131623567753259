import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal,Switch } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {  FaEdit, FaEye} from "react-icons/fa";
import preview1 from "../../Images/preview2.png";
import preview2 from "../../Images/preview1.png";
import { Row, Col ,Typography} from "antd";



function StudentTodayAttendance() {
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for selected date
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const ImageUrl = process.env.REACT_APP_IMAGE_URL;
  const { sectionId } = useParams();
  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");
  const PERMISSION_EDIT = localStorage.getItem("permission_edit");
  const PERMISSION_DELETE = localStorage.getItem("permission_delete");

  
  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Student ID</h1>,
      selector: (row) => row.studentId,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-3 w-[200px]">Student Name</h1>,
      selector: (row) => row.studentName,
      sortable: true,
    },
    {
      name: "Present/Absent",
      cell: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              checkedChildren={`Absent`}
              unCheckedChildren={`Present`}
              checked={row.leaveTaken}
              disabled={status === 1 ? false : true}
              className={
                row.leaveTaken
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
              onChange={() => handleSwitchChange(row, "leaveTaken")}
            />
          </div>
        </>
      ),
    },
    {
      name: "ML / OD",
      cell: (row) => (
        <DropdownCell
          options={["select","ML", "OD"]}
          //value={row.typeOfLeave}
          selected={row.typeOfLeave}
          onChange={handleDropdownChange}
          studentId={row.studentId}
          disabled={status === 1 ? (row.leaveTaken ? false : true) : true}
        />
      ),
    },
    {
  name: "Remark",
  selector: "remark",
  sortable: true,
  cell: (row) => (
    <input
      type="text"
      value={row.remark ||""}
      onChange={(e) => handleRemarkChange(row.studentId, e.target.value)}
      className="w-24 h-8"
      disabled={status === 1 ? (row.leaveTaken ? false : true) : true}
      />
  ),
},
{
  name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
  cell: (row) => (
    <>
      <div className="flex flex-row items-center gap-4">
        {LMS_ROLE === "3" || LMS_ROLE === "5" || LMS_ROLE === "7" ? (
          <>
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={()=>showPreviewModal(row)}/>
            {status === 0 && (
                  <FaEdit
                    className="w-5 h-5 text-blue-500 cursor-pointer"
                    onClick={() => handleEdit(row)}
                  />
                )}
          </>
        ) : (
          <>
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={()=>showPreviewModal(row)}/>
            {PERMISSION_EDIT === "true" && status === 0 && (
              // <Link to={`/homepage/My ClassRoom/today attendance/${sectionId}/${row.studentId}`}>
              //   <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer"   onClick={() => handleEdit(row)} />
              // </Link>
              <FaEdit
              className="w-5 h-5 text-blue-500 cursor-pointer"
              onClick={() => handleEdit(row)}
            />
            )}
          
          </>
        )}
      </div>
    </>
  ),
  // width:"100px"
},
  ];

  const columnsForEdit = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Student ID</h1>,
      selector: (row) => row.studentId,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-3 w-[200px]">Student Name</h1>,
      selector: (row) => row.studentName,
      sortable: true,
    },
    {
      name: "Present/Absent",
      cell: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              checkedChildren={`Absent`}
              unCheckedChildren={`Present`}
              checked={row.leaveTaken}
              className={
                row.leaveTaken
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
              onChange={() =>
                 handleSwitchChangeEdit(row.studentId, "leaveTaken")}
            />
          </div>
        </>
      ),
    },
    {
      name: "ML / OD",
      cell: (row) => (
        <DropdownCell
          options={["select","ML", "OD"]}
          //value={row.typeOfLeave}
          selected={row.typeOfLeave}
          onChange={handleDropdownChangeForEdit}
          studentId={row.studentId}
        />
      ),
    },
    {
  name: "Remark",
  selector: "remark",
  sortable: true,
  cell: (row) => (
    <input
      type="text"
      value={row.remark ||""}
      onChange={(e) => 
        handleRemarkChangeForEdit(row.studentId, e.target.value)
      }
      className="w-24 h-8"
      />
  ),
},

  ];


  const DropdownCell = ({ 
    options, 
    selected, 
    onChange, 
    studentId,
     disabled }) => {
    return (
      <select
        value={selected}
        onChange={(e) => onChange(studentId, e.target.value)}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  };

  const handleDropdownChange = (studentId, selectedOption) => {
    // Update the item state with the new selected option
    setItem((prevItem) =>
      prevItem.map((row) =>
        row.studentId === studentId
          ? { ...row, typeOfLeave: selectedOption }
          : row
      )
    );
  };

  const handleDropdownChangeForEdit = (studentId, selectedOption) => {
    setSelectedRow((prevItem) =>
      prevItem.studentId === studentId
          ? { ...prevItem, typeOfLeave: selectedOption }
          : prevItem
      
    );
  };

  const handleRemarkChange = (studentId, value) => {
    setItem((prevItem) =>
    prevItem.map((row) =>
      row.studentId === studentId ? { ...row, remark: value } : row
    )
  );
  };
  
  const handleRemarkChangeForEdit = (studentId, value) => {
    setSelectedRow((prevItem) =>
      prevItem.studentId === studentId
        ? { ...prevItem, remark: value }
        : prevItem
    );
  };
  
  const handleSwitchChange = (row, columnName) => {
    setItem((prevItem) =>
      prevItem.map((prevRow) =>
        prevRow.studentId === row.studentId
          ? { ...prevRow, leaveTaken: !prevRow.leaveTaken }
          : prevRow
      )
    );
  };
  
  
  const handleSwitchChangeEdit = (row, columnName) => {
    setSelectedRow((prevItem) =>
      prevItem.studentId === row
        ? { ...prevItem, leaveTaken: !prevItem.leaveTaken }
        : prevItem
    );
  };

  useEffect(() => {
    if (Array.isArray(item)) {
      const result = item.filter((value) => {
        // Check if facultyName is defined before trying to access its properties
        return (
          value.studentName &&
          value.studentName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [item, searchTerm]);

  const fetchData = async (date) => {
    try {
      let apiUrlEndpoint;

     
      if (date) {
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        apiUrlEndpoint = `${apiUrl}/fetchupdatedstudentAttendance/${sectionId}?date=${formattedDate}`;
        }
  
      console.log("API URL Endpoint:", apiUrlEndpoint);
  
      const response = await axios.get(apiUrlEndpoint);
  
      console.log("API Response:", response);
  
      if (response.data.data.length === 0 && date) {
        const fallbackApiUrlEndpoint = `${apiUrl}/collegestudentbysectionuniqueid/${sectionId}`;
        const fallbackResponse = await axios.get(fallbackApiUrlEndpoint);
        console.log("Fallback API URL Endpoint:", fallbackApiUrlEndpoint);
        console.log("Fallback API Response:", fallbackResponse);
  
        const updatedData = fallbackResponse.data.data;
        setItem(updatedData);
        setStatus(1);
        console.log("Fetched Data:", updatedData);
        
      } else {
        const updatedData = response.data.data;
       setItem(updatedData);
        setStatus(0);
       
      }
    } 
    catch (error) {
      console.error(error.response.data);
      if (error.response.data.status === 404) {
        // Handle the error accordingly
      }
    }
  };
  
  useEffect(() => {
    fetchData(selectedDate); // Fetch data for the initial selected date
  },  [selectedDate]);
  
  const handleDateChange = async (date) => {
    try {
      setSelectedDate(date);
        await fetchData(date);
    } catch (error) {
      console.error(error);
    }
  };
  

  const submitAttendance = async () => {
    try {
      let response;
       // Creating a new entry
       const updatedAttendance = item.map((row) => ({
        studentId: row.studentId,
        studentName: row.studentName,
        leaveTaken: row.leaveTaken ? 1 : 0,
        typeOfLeave: row.typeOfLeave === "Select" ? "": row.typeOfLeave,
        remark: row.remark || "",
        uniqueId: localStorage.getItem("Unique_id"),
        date: selectedDate.toISOString().split('T')[0],
        sectionId: sectionId,
        instituteUniqueId: row.instituteUniqueId,
      }));

      response = await axios.post(
        `${apiUrl}/collegestudentupdateattendance`,
        updatedAttendance,
        {
          // Include the status in the request
          params: { status },
        }
      );
    

    // Update the local state with the new data received from the server
    setItem(response.data);
    setStatus(0);
    console.log("Attendance updated successfully:", response.data);
    toast.success("Attendance updated successfully");
    } catch (error) {
      console.log(error, "error")
      toast.error("Error submitting attendance");
    }
  }

  const updateAttendance = async () => {
    try {
   
        const updatedRow = {
          studentId: selectedRow.studentId,
          studentName: selectedRow.studentName,
          leaveTaken:selectedRow.leaveTaken ? 1 : 0,
          typeOfLeave:selectedRow.typeOfLeave === "Select" ? "": selectedRow.typeOfLeave,
          remark: selectedRow.remark,
          uniqueId: localStorage.getItem("Unique_id"),
          instituteUniqueId: selectedRow.instituteUniqueId,
          date: selectedDate.toISOString().split('T')[0],
          sectionId: sectionId,
        };
  
       await axios.put(
          `${apiUrl}/collegestudentupdateattendance/${selectedRow.studentId}`,
          updatedRow
        );
        fetchData(selectedDate);
        setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating attendance:", error.response.data);
      toast.error("Error updating attendance");
    }
  };
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };
  const handleEdit = (row) => {
    setIsModalVisible(true);
    setSelectedRow(row);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow(null);
  };



  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Text, Title} = Typography;

  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };

  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className="W-full h-fit bg-white flex flex-col items-center gap-3 rounded-md pt-5  px-1 drop-shadow border pb-2">
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
        <div className="relative " style={{ zIndex: 2 }}>
          {/* Date picker component */}
          Date
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            className="p-3 w-[150px] text-[14px] bg-gray-100 text-gray-700
            focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          rounded-md "
          maxDate={new Date()}
          />
        </div>
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
        </div>
      </div>
      <div className="flex flex-col w-full mt-4">
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
      <div className="w-full flex justify-end px-4">
      {status === 1 ? (
  <button
    className="bg-primary text-white py-2 px-4 rounded-md"
    onClick={submitAttendance}
  >
    Submit
  </button>
) : (
  <button
  className="py-3 px-4 text-[12px] font-sm bg-gray-400 text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary  ease-in-out duration-300 flex flex-row items-center gap-1"
  // <button
  //   className="bg-gray-400 text-white py-2 px-4 rounded-md"
  disabled
>
  Submit
</button>
)}
      </div>

      <Modal
        title="Edit Attendance"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null} // Disable the default footer
        width={800}
      >
          <div className="flex flex-col w-full mt-4">
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columnsForEdit}
          data={[selectedRow]}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
      <div className="w-full flex justify-end px-4">
        <button
          className="bg-primary text-white py-2 px-4 rounded-md"
          onClick={updateAttendance}
        >
         Update Attendance
        </button>
      </div>

      </Modal>

      <div className='flex justify-end ml-14'>
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">

      <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                <Col span={2.1}>
                 <img src={preview1} className="flex justify-start" ></img>
                </Col>
               <div className="flex">
               <Col span={4.5} className="z-50 flex flex-col ">
                  {previewData.uploadStudentProfile && (
                    <img
                      src={`${ImageUrl}/CollegeStudentUploads/${previewData.uploadStudentProfile}`}
                      alt="Profile"
                      className="rounded-full w-20 h-20 border-white border-[3px] drop-shadow-xl"
                    />
                  )}
                </Col>
                <Col span={12} className="flex flex-col justify-center mt-2">
                  <Title level={1} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1 ">
                    {previewData.studentName.toUpperCase()}
                  </Title>
                </Col>
               </div>
              </Row>
      <div className="sm:ml-20 my-6 space-y-2">
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">student ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.studentId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Number Of Leaves Taken:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.leaveTaken}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Type Of Leave:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.typeOfLeave ? previewData.typeOfLeave : "nil"}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Remark:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.remark ? previewData.remark : "nil"}</Text>
        </Col>
      </Row>
      </div>
     
      <Row className="flex justify-end -mt-20" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
              </Row>
    </div>
  )}
</Modal>
 </div>
    </div>
  );
}

export default StudentTodayAttendance;
