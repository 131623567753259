import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaCheck, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../App.css";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import { CSVLink } from "react-csv";

function SupportTable() {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");
  const PERMISSION_EDIT = localStorage.getItem("permission_edit");
  const PERMISSION_DELETE = localStorage.getItem("permission_delete");

  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const history = useNavigate();
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const apiUrl = process.env.REACT_APP_API_URL;
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  // const handleToggle = async (id, value) => {
  //   const newStatus = value ? 0 : 1;
  //   try {
  //     await axios
  //       .put(`${apiUrl}/courseadditionstatus/${id}`, {
  //         status: newStatus,
  //       })
  //       .then((res) => {
  //         if (res.status === 200) {
  //           toast.success(
  //             `User ${newStatus ? "activated" : "deactivated"} successfully`
  //           );
  //           setStatus(!status);
  //         }
  //       });
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  // const { confirm } = Modal;

  // const showDeleteConfirm = (id) => {
  //   confirm({
  //     title: "Are you sure to delete this data?",
  //     icon: <ExclamationCircleFilled />,
  //     content: "",
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk() {
  //       axios.delete(`${apiUrl}/courseaddition/${id}`).then((res) => {
  //         //  console.log(res.data);
  //         if (res.status === 200) {
  //           setFilteredData((prevData) =>
  //             prevData.filter((item) => item._id !== id)
  //           );
  //           toast.success(res.data.message);
  //         } else {
  //           toast.error(res.data.message);
  //         }
  //       });
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };
  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Support Type": item.supportType,
        "Subject": item.subject,
        "Ticket Number": item.ticketNumber,
        "status":item.status
      }));
      return csvData;
    }
    return [];
  };
  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Support Type</h1>,
      selector: (row) => row.supportType,
      sortable: true,
      // width: "230px",
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Subject</h1>,
      selector: (row) => row.subject,
      sortable: true,
      // width: "250px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Ticket Number</h1>,
      selector: (row) => row.ticketNumber,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 ">Status</h1>,
      selector: (row) => row.status,
      //sortable: true,
    },
  ];

  useEffect(() => {}, []);

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.ticketNumber.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegesupportformbyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            //toast.info(error.response.data.message);
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegesupportformbyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            //toast.info(error.response.data.message);
          }
        });
    } else if (LMS_ROLE === "7") {
      axios
        .get(
          `${apiUrl}/collegesupportformbyinstituteuniqueid/${localStorage.getItem(
            "faculty_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            //toast.info(error.response.data.message);
          }
        });
    } else if (LMS_ROLE === "8") {
      axios
        .get(
          `${apiUrl}/collegesupportformbyinstituteuniqueid/${localStorage.getItem(
            "student_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            //toast.info(error.response.data.message);
          }
        });
    } else {
      axios
        .get(
          `${apiUrl}/collegesupportformbyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            //toast.info(error.response.data.message);
          }
        });
    }
  }, [status]);

  const toggleFormView = () => {
    history("/homepage/support list/add support");
  };

  

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  return (
    <>
      <div className="flex flex-col  w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
              </div>
              {LMS_ROLE === "3" ||
              LMS_ROLE === "5" ||
              LMS_ROLE === "7" ||
              LMS_ROLE === "8" ? (
                <button
                  className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
                  onClick={toggleFormView}
                >
                  <FaPlus />
                  Add Support
                </button>
              ) : (
                <>
                  {PERMISSION_ADD === "true" && (
                    <button
                      className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
                      onClick={toggleFormView}
                    >
                      <FaPlus />
                      Add Course
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-row items-center gap-6">
              {/* <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="upload"
                    src={upload}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div> */}
              <div class="relative group flex items-center">
                <CSVLink
                  data={handleCsv()}
                  filename="Support.csv"
                  class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
                >
                  {" "}
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </CSVLink>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>
          {/* Use the DataTable component */}
          <div className="flex flex-col w-full">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SupportTable;
