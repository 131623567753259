import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import { toast } from "react-toastify";

import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";

import { CSVLink } from "react-csv";
import download from "../../Images/download.png";
import upload from "../../Images/upload.png";
const apiUrl = process.env.REACT_APP_API_URL;

function StudentResult() {
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const history = useNavigate();

  const [exam, setExam] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [sectionId, setSectionId] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedExam, setSelectedExam] = useState(null);
  const [studentId, setStudentId] = useState([]);
  const [examId, setExamId] = useState([]);
  const [mark, setMark] = useState([]);
  // const [ mark, setMark ] = useState([]);
  const [id, setId] = useState([]);
  const [examName, setExamName] = useState([]);
  const [marks, setMarks] = useState([]);
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    const result =
      exam &&
      exam.filter((value) => {
        return value.examName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [exam, searchTerm]);

  useEffect(() => {
    try {
      axios
        .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
        .then((res) => {
          const studentsData = res.data.data;

          if (studentsData.length > 0) {
            setStudentData(studentsData);
            setSectionId(studentsData[0].sectionId);
            const studentId = studentsData[0].studentId;
            setStudentId(studentId);

            // Fetch exams based on sectionId
            axios
            .get(`${apiUrl}/collegeexambysectionid/${studentsData[0].sectionId}`)
            .then((examRes) => {
              const examDatas = examRes.data.data;
              // Filter exams with status 1
              const filteredExams = examDatas.filter(exam => exam.status === 1);
              setExam(filteredExams);
              if (filteredExams.length > 0) {
                const examId = filteredExams[0].examId; // Assuming you want the examId from the first exam
                setExamId(examId);
              }
            })
            .catch((error) => {
              console.error("Error fetching subjects:", error);
              if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
                toast.error("Failed to fetch subjects");
              }
            });
          
          } else {
            console.warn("No student data found.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("No Data Found");
          }
        });
    } catch (error) {
      console.error("Exception:", error);
      toast.error("An error occurred");
    }
  }, []);

  const getExam = (examId) => {
    axios
      .get(
        `${apiUrl}/getResultbyStudentidExamid/${localStorage.getItem(
          "Unique_id"
        )}/${examId}`
      )
      .then((res) => {
        const details = res.data.data[0];
        
        // Access the first element of the array
        if (details) {
          setItem(details);
          setMark(details.totalPoints);
          setMarks(details.writtenMark);
          setId(details.examId);
          setExamName(details.examName);
        }else {
          
          console.error('No data found for the specified examId');
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          // Handle error
        }
      });
  };

  const closeModal = () => {
    setModalOpen(false);
    setItem([""]);
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      sortable: true,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject ID</h1>,
      selector: (row) => row.subjectId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject Name</h1>,
      selector: (row) => row.subjectList,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam ID</h1>,
      selector: (row) => row.examId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam Name</h1>,
      selector: (row) => row.examName,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Duration</h1>,
      selector: (row) => row.duration,
      sortable: true,
      // width: "150px",
    },

    {
      name: <h1 className="text-white pl-3 w-[100px]">Result</h1>,
      cell: (row) => (
        <>
          <button
            onClick={() => {
              getExam(row.examId);
              setModalOpen(true);
            }}
            className={`py-2 px-4 rounded-md text-white bg-primary hover:bg-secondary hover:text-primary items-center gap-1`}
          >
            Result
          </button>
        </>
      ),
      // width: "150px",
    },
  ];

  const handleCsv = () => {
    if (filteredData && filteredData.length > 0) {
      const csvData = filteredData.map((item) => ({
        "Exam ID": item.examId,
        "Exam Name": item.examName,
        Semester: item.semesterNumber,
        "Date Of Exam": item.dateOfExamination,
        "Subject Name": item.subjectName,
        "Subject Id": item.subjectId,
      }));
      return csvData;
    }
    return [];
  };
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };
  console.log("result", item.totalPoints);
  return (
    <div className="flex flex-col  w-full ">
      <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
        <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
          <div className="flex flex-row items-center gap-4">
            <div className="relative">
              <input
                placeholder="Search"
                type="text"
                required
                className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />
              <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
            </div>
          </div>
          {/* Use the DataTable component */}
          <div className="flex flex-row items-center gap-6">
            {/* <div class="relative group flex items-center">
              <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                {" "}
                <img
                  alt="upload"
                  src={upload}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </span>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Upload
              </div>
            </div> */}
            <div class="relative group flex items-center">
              <CSVLink
                data={handleCsv()}
                filename="Faculty List.csv"
                class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
              >
                {" "}
                <img
                  alt="download"
                  src={download}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </CSVLink>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Download
              </div>
            </div>
          </div>
        </div>
        {/* Use the DataTable component */}
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          onChangePage={handlePageChange}
        />
{isModalOpen && (
  <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-white bg-opacity-80">
    <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center rounded">
      <div className="bg-white border-[0.5px] border-primary shadow-md rounded p-8 w-[450px]">
        <h2 className="text-2xl font-bold mb-4 text-primary flex justify-center">
          Result
        </h2>

        {item && item.status === 1 ? (
          <div className="flex flex-col items-center">
            <div className="mt-4 align-items-center">
              <p className="text-base font-semibold">
                Exam Name: <span className="font-medium text-primary">{examName}</span>
              </p>
            </div>
            <div className="mb-4 mt-2">
              <p className="text-base font-normal">
                Exam ID: <span className="font-normal text-primary">{id}</span>
              </p>
            </div>

            <div className="mb-4 border border-gray-300 rounded-md p-2">
              <p className="text-[18px] font-normal flex justify-center">
                MCQ Marks :{' '}
                <span className="font-semibold text-primary">{mark}</span>
              </p>
            </div>

            <div className="mb-4 border border-gray-300 rounded-md p-2">
              <p className="text-[18px] flex justify-center font-normal">
                Descriptive Marks :{' '}
                <span className="font-semibold text-primary">{marks}</span>
              </p>
            </div>
          </div>
        ) : item && item.status === 0 ? (
          <p className="text-lg font-bold mb-4 text-center text-red-500">
            Result not published yet.
          </p>
        ) : (
          <p className="text-lg font-bold mb-4 text-center text-red-500">
            You haven't attended the exam.
          </p>
        )}

        <div className="flex justify-center">
          <button
            className="bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}

      </div>
    </div>
  );
}

export default StudentResult;
