import React from 'react';
import { FaCircleUser } from 'react-icons/fa6';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ProgressBar = () => {
  const presentPercentage = 75;

  return (

    <div className='flex justify-center'>
    <div className='w-[200px] h-auto flex flex-col border border-primary rounded-lg drop-shadow-lg p-6'>
      <div>
        <h5 className='font-semibold text-base ml-2'>Statistics</h5>
        <div className='mt-4 text-gray-500 font-normal text-sm ml-2'>jan 2023 - mar 2024</div>
      </div>
    <div className='flex flex-row mt-4 '>

    <div className='flex flex-col ml-2'>
      <div className='flex flex-row items-center'>
        <div>
          <FaCircleUser className='w-8 h-8 text-blue-600 '  />
        </div>
        <div>
          <p className='text-gray-600 ml-2 text-sm'>Present</p>
          <p className='text-gray-800 ml-2 text-sm font-semibold'>{presentPercentage}%</p>
        </div>
      </div>

      <div className='flex flex-row items-center mt-4'>
        <div>
          <FaCircleUser className='w-8 h-8 text-red-500' />
        </div>
        <div>
          <p className='text-gray-600 ml-2 text-sm'>Absent</p>
          <p className='text-gray-800 ml-2 text-sm font-semibold'>30%</p>
        </div>
      </div>

      <div className='flex flex-row items-center mt-4'>
        <div>
          <FaCircleUser className='w-8 h-8 text-orange-400' />
        </div>
        <div>
          <p className='text-gray-600 ml-2 text-sm'>On Duty</p>
          <p className='text-gray-800 ml-2 text-sm font-semibold'>5%</p>
        </div>
      </div>
    </div>
<div className='ml-16 mr-4 mt-4'>
    <div style={{ width: 100, height: 100 }}>
    <CircularProgressbar
      value={presentPercentage}
      text={`${presentPercentage}% Attendance`}
      styles={{
        root: { width: '100%' },
        path: { stroke: '#105d50' },
        text: {
          fill: '#105d50',
          fontSize: '10px',
          fontWeight: 'bold',
        },
      }}
    />
    </div>
    </div>

    {/* <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={{
                        root: { width: "100%", height: "100%" },
                        path: { stroke: "#105d50" },
                        text: {
                          fill: "#105d50",
                          fontSize: "10px",
                          fontWeight: "bold",
                        },
                      }}
                    /> */}
  </div>
  </div></div>
);
};

export default ProgressBar;
