import React from "react";
import FacultyHomePage from "../Home/FacultyHomePage";
import StudentHomePage from "../Home/StudentHomePage";
import InstituteHomePage from "../Home/InstituteHomePage";

const Home = () => {
  const LMS_ROLE = JSON.parse(localStorage.getItem("lms_role"));

  console.log(LMS_ROLE, "lmsrole");

  const getRole = () => {
    switch (LMS_ROLE) {
      case 3:
        return <InstituteHomePage />;

      case 4:
        return <InstituteHomePage />;
      case 5:
        return <InstituteHomePage />;

      case 6:
        return <InstituteHomePage />;

      case 7:
        return <FacultyHomePage/>;

      default:
        return <StudentHomePage />;
    }
  };

  return <>{getRole()}</>;
};

export default Home;
