import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import attendance from "../../Images/checking-attendance.png";
import exams from "../../Images/exam.png";
import result from "../../Images/result.png";
import SubjectsComponent from "./StudentSubject";
import AttendanceComponent from "./StudentsAttendance";
import ExamComponent from "./Exam";
import axios from "axios";

const MyCourse = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isToggle, setIsToggle] = useState(false);

  const cardData = [
    { title: "Subjects", link: "/homepage/My Course/Subjects", icon: result },
    {
      title: "Attendance",
      link: "/homepage/My Course/Students Attendance",
      icon: attendance,
    },
    { title: "Exam", link: `/homepage/My Course/Exam`, icon: exams },
  ];

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  const handleCardClick = (component) => {
    setSelectedComponent(component);
    handleToggle();
  };



  return (
    <div className="bg-white h-auto rounded-xl border drop-shadow ">
      <div className="flex flex-col justify-between mx-auto max-w-screen-xl">
        {/* Center the card grid horizontally and vertically */}
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-16">
            {cardData.map((card, index) => (
              <div
                key={index}
                onClick={() => handleCardClick(card.title)}
                className="hover:bg-gray-100 bg-white border rounded-2xl h-[60px] w-[200px] mt-8 transition-colors duration-300 flex items-center justify-center shadow-md cursor-pointer"
              >
                <img
                  src={card.icon}
                  alt={card.title}
                  className="w-5 h-5 mr-2"
                />
                <p className="font-semibold text-center text-md">
                  {card.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-8">
        {selectedComponent === "Subjects" && <SubjectsComponent />}
        {selectedComponent === "Attendance" && <AttendanceComponent />}
        {selectedComponent === "Exam" && <ExamComponent />}
      </div>
    </div>
  );
};

export default MyCourse;
