import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

const QuestionView = () => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [studentData, setStudentData] = useState({});
  const [sectionId, setSectionId] = useState(null);
  const [exam, setExam] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState([]);
  const [studentName, setStudentName] = useState("");
  const [studentId, setStudentId] = useState("");
  const { examId } = useParams();
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showTimeSubmitPopup, setShowTimeSubmitPopup] = useState(false);
  const [ examName,setExamName] = useState([])
  const [formData, setFormData] = useState({
    questions: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        const studentsData = res.data.data;

        if (studentsData && studentsData.length > 0) {
          const student = studentsData[0];
          setStudentData(student);
          setSectionId(student.sectionId);
          setStudentId(student.studentId);
          setStudentName(student.studentName);
        } else {
          console.warn("No student data found.");
        }
      })
      .catch((error) => {
        console.error(
          "Error fetching student data:",
          error.response?.data || error.message
        );
      });
  }, []);

  useEffect(() => {
    try {
      if (sectionId) {
        axios
          .get(`${apiUrl}/collegeexambyexamid/${examId}`)
          .then((res) => {
            const examData = res.data.data;
            if (examData && examData.length > 0) {
              const firstExam = examData[0];
              setExam(firstExam.examId);
              setExamName(firstExam.examName);
              setTimeRemaining(firstExam.duration);
              startCountdown(firstExam.duration);
              document.addEventListener("fullscreenchange", handleFullscreenChange);

              // Request full-screen in response to a user action
              document.documentElement.requestFullscreen();
            } else {
              console.warn("No exam data found.");
            }
          })
          .catch((error) => {
            console.error("Error fetching exam data:", error);
          });
      }
    } catch (error) {
      console.error("Exception:", error);
    }
  }, [sectionId]);

  const handleFullscreenChange = () => {
    // Check if not in full-screen mode, and show the time submit popup
    if (!document.fullscreenElement) {
      setShowTimeSubmitPopup(true);
    }
  };

  useEffect(() => {
    try {
      if (examId) {
        axios
          .get(`${apiUrl}/getQuestionsbyexamid/${examId}?questionType=multiple-choice`)
          .then((res) => {
            const mcqQuestions = res.data.data.filter(
              (question) => question.questionType === "multiple-choice"
            );

            const updatedQuestions = mcqQuestions.map((question) => ({
              ...question,
              answerText: question.answerText,
              points: question.points,
            }));

            setFormData({ questions: updatedQuestions });
          })
          .catch((error) => {
            console.error("Error fetching MCQ questions:", error);
          });
      }
    } catch (error) {
      console.error("Exception:", error);
    }
  }, [examId]);

  const handleChange = (e, questionId) => {
    const { value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      questions: prevFormData.questions.map((question) =>
        question._id === questionId
          ? {
              ...question,
              selectedOption: value,
            }
          : question
      ),
    }));
  };

  const handleConfirmSubmit = async () => {
    const answersData = formData.questions.map((question) => ({
      examId: examId,
      examName: examName,
      studentId: studentId,
      studentName: studentName,
      questionType: question.questionType,
      questionText: question.questionText,
      selectedOption: question.selectedOption,
      answerText: question.answerText,
      points: question.points,
    }));

    try {
      const response = await axios.post(
        `${apiUrl}/questionAnswered/${examId}`,
        { questions: answersData }
      );

      if (response.data.error) {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        navigate(`/homepage/My Course`);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      toast.error("An error occurred while saving the exam.");
    }
  };

  const handleNextOrSubmit = async (e) => {
    e.preventDefault();

    if (selectedQuestionIndex < formData.questions.length - 1) {
      setSelectedQuestionIndex((prevIndex) => prevIndex + 1);
    } else {
      setShowSubmitPopup(true); // Show confirmation popup
    }
  };

  const renderMultipleChoiceQuestion = (question, index) => (
    <div
      key={question._id}
      className={`mt-6 ${selectedQuestionIndex === index ? "" : "hidden"}`}
    >
      <label
        htmlFor={question._id}
        className="text-gray-700 text-sm font-bold mb-2 flex items-center"
      >
        Question {index + 1}
      </label>
      {question.questionType === "multiple-choice" ? (
        <div className="mt-6">
          <strong className="text-base">{question.questionText}</strong>
          <div className="flex flex-col mt-6">
            {question.options?.map((option, optionIndex) => (
              <label key={optionIndex} className="mb-2 cursor-pointer">
                <input
                  type="radio"
                  name={question._id}
                  value={option}
                  onChange={(e) => handleChange(e, question._id)}
                  className="mr-2 leading-tight"
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );

  const mcqCount =
    formData.questions?.filter(
      (question) => question.questionType === "multiple-choice"
    ).length || 0;

  const isTimeEndingSoon = timeRemaining < 60;

  const startCountdown = (duration) => {
    const startTime = new Date(); // Fetch current time

    const [hours, minutes, seconds] = duration.split(":").map(Number);

    let totalSeconds = hours * 3600 + minutes * 60 + seconds;

    const intervalId = setInterval(() => {
        const remainingMinutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        setTimeRemaining(
            `${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
        );

        totalSeconds--;

        if (totalSeconds < 0) {
            clearInterval(intervalId);
            setShowTimeSubmitPopup(true);
        }
    }, 1000);
};
  return (
    <div className="container mx-auto mt-10 w-100">
      <div
        className={`flex justify-between items-center mt-4 ${
          isTimeEndingSoon ? "text-red-500" : ""
        }`}
      >
        <p className="mr-4 text-secondary font-bold">
          MCQ Questions: {mcqCount}
        </p>
        <p className="mr-4 font-bold">Time Remaining: {timeRemaining}</p>
      </div>

      <form
        onSubmit={handleNextOrSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        {formData.questions?.map((question, index) => (
          <React.Fragment key={question._id}>
            {question.questionType === "multiple-choice" &&
              renderMultipleChoiceQuestion(question, index)}
          </React.Fragment>
        ))}

        <div className="flex-1 p-4 mt-4">
          <div className="mb-4 flex justify-between">
            <button
              type="button"
              onClick={() =>
                setSelectedQuestionIndex(Math.max(selectedQuestionIndex - 1, 0))
              }
              className={`bg-primary hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                selectedQuestionIndex > 0 ? "" : "hidden"
              }`}
            >
              Previous
            </button>
            <button
              type="submit"
              className={`bg-primary hover:bg-yellow-400 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                selectedQuestionIndex === formData.questions.length - 1
                  ? "Proceed"
                  : ""
              }`}
            >
              {selectedQuestionIndex === formData.questions.length - 1
                ? "Proceed"
                : "Next"}
            </button>
          </div>
        </div>
      </form>

      {showSubmitPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-gray-200 border-2 border-primary shadow-md rounded p-20">
            <p className="text-center font-bold mt-8">Submit all questions?</p>
            <div className="flex justify-center items-center mt-4">
              <button
                className="bg-primary text-white px-4 py-2 rounded mr-4 hover:bg-primary-dark"
                onClick={handleConfirmSubmit}
              >
                Yes
              </button>
              <button
                className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
                onClick={() => setShowSubmitPopup(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showTimeSubmitPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-gray-200 border-2 border-primary shadow-md rounded p-20">
            <p className="text-center font-bold text-red-500 mb-4">
              Time is Over!
            </p>
            <p className="text-center font-bold mt-8">Submit all questions?</p>
            <div className="flex justify-center items-center mt-4">
              <button
                className="bg-primary text-white px-4 py-2 rounded mr-4 hover:bg-primary-dark"
                onClick={handleConfirmSubmit}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionView;
