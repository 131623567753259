import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Attendance from "../../Images/checking-attendance.png";
import Students from "../../Images/education.png";
import Exam from "../../Images/exam.png";
import Result from "../../Images/checklist.png";
import { FaPlus, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Select } from "antd";


const apiUrl = process.env.REACT_APP_API_URL;

const Classroom = ({ handleToggle }) => {

  const [subjects, setSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedSection, setSelectedSection] = useState();
  const [sectionData, setSectionData] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState();
  const [addedSubjects, setAddedSubjects] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [selectedCourse, setSelectedCourse] = useState();
  const [selectedSemester, setSelectedSemester] = useState();
  const [courseData, setCourseData] = useState([]);
  const [semesterData, setSemesterData] = useState([]);


  const apiUrl = process.env.REACT_APP_API_URL;

  const cardData = [
    {
      title: "Students",
      icon: Students,
      path: `/homepage/Classroom/Students/${selectedSectionId}`,
    },
    // {
    //   title: "Attendance",
    //   icon: Attendance,
    //   path: `/homepage/Classroom/Attendance/${selectedSectionId}`,
    // },
    {
      title: "Exam",
      icon: Exam,
      path: `/homepage/Classroom/Exam/${selectedSectionId}`,
    },
    {
      title: "Result",
      icon: Result,
      path: `/homepage/Classroom/Result/${selectedSectionId}`,
    },
  ];

  useEffect(() => {
    if (subjects) {
      const filtered = subjects.filter(
        (subject) =>
          subject &&
          subject.subjectName &&
          subject.subjectName.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredSubjects(filtered);
    }
  }, [searchText, subjects]);


  const [error, setError] = useState("");

  const fetchSubjectCards = async (sectionId) => {
    try {
      if (sectionId !== null) {
        const response = await axios.get(`${apiUrl}/collegeassignsubjectbysectionidandfacultyid/${sectionId}/${localStorage.getItem("Unique_id")}`);
        const subjectCardsData = response.data.data;
        setAddedSubjects(subjectCardsData);
      }
    } catch (error) {
      console.error('Error fetching subject cards:', error);
      setError('Error fetching subject cards. Please try again.');
    }
  };

  useEffect(() => {
    if(selectedSectionId){
    fetchSubjectCards(selectedSectionId);
  }}, [selectedSectionId]);

  console.log("subject", selectedSectionId);

  

  const handleCourseChange = async (event, newValue) => {
    const selectedCourseId = courseData[newValue]?.courseId;
    console.log("newValue:", newValue);
    if (selectedCourseId) {
      setSelectedCourse(selectedCourseId);
      console.log("sel", selectedCourse);
      // Fetch semesters based on the selected course
      const semestersResponse = await axios.get(
        `${apiUrl}/collegesemesterbycourseIdandfacultyId/${localStorage.getItem("Unique_id")}/${selectedCourseId}`
      );
      const semestersData = semestersResponse.data.data;
      setSemesterData(semestersData);
      console.log("selsem", semesterData);
      // Reset selected semester and section
      // setSelectedSemester(null);
      // setSelectedSection(null);
    }
    else {

    }
  };

  const handleSemesterChange = async (event, newValue) => {
    const selectedSemesterNumber = semesterData[newValue].semesterNumber;
    setSelectedSemester(selectedSemesterNumber);

    // Fetch sections based on the selected course and semester
    const sectionsResponse = await axios.get(
      `${apiUrl}/collegesectionbyfacultyIdCourseIdSemesterNumber/${localStorage.getItem("Unique_id")}/${selectedCourse}/${selectedSemesterNumber}`
    );
    const sectionsData = sectionsResponse.data.data;
    setSectionData(sectionsData);

    // Reset selected section
    setSelectedSection(null);
  };

  const handleSectionChange = (event, newValue) => {
    const selectedSectionId = sectionData[newValue]?.sectionId;
    console.log('Section clicked:', selectedSectionId);

    if (selectedSectionId) {
      setSelectedSectionId(selectedSectionId);
      //fetchSubjectCards(selectedSectionId);
    } else {
      console.error('Selected section ID is undefined or null.');
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch courses
        const coursesResponse = await axios.get(
          `${apiUrl}/collegecoursebyfacultyId/${localStorage.getItem("Unique_id")}`
        );
        const coursesData = coursesResponse.data.data.filter((course, index, self) =>
        index === self.findIndex((c) => c.courseId === course.courseId)
      ); // Filter out duplicate courses
        setCourseData(coursesData);

        // Fetch the default semester based on the first course (you can adjust this logic)
        // if (coursesData.length > 0) {
        //   const initialCourseId = coursesData[0].courseId;
        //   setSelectedCourse(initialCourseId);
        // }
      } catch (error) {
        console.error("An error occurred:", error.response ? error.response.data : error.message);
        if (error.response && error.response.status === 404) {
          console.log("Resource not found");
        }
        // Handle other errors as needed
      }
    };

    fetchData(); // Call the async function
  }, []); // Only run on mountz




  return (
    <div className="bg-white h-auto rounded-md border drop-shadow">

      <div className="flex items-center mt-2 ml-2">


        <Tabs
          value={selectedCourse}
          onChange={handleCourseChange}
          // allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          indicatorColor="none"
          // TabIndicatorProps={{
          //   style: { backgroundColor: "#105D50", padding: "2px" },
          // }}
          sx={{
            "& button": {
              borderRadius: 2,
              height: "auto", // Adjust the height as needed
              padding: "4px",  // Adjust padding if needed

            }
          }}

        >
          {courseData
            .map((course) => (
              <Tab
                key={course.courseId}
                label={course.courseName}
                style={{
                  // borderLeft: "1px groove #105D50",
                  // borderBottom: "1px groove #105D50",
                  // borderRight: "4px groove #105D50",
                  // borderTop: "2px groove #105D50",
                  padding: "2px",
                  backgroundColor:
                    selectedCourse === course.courseId ? "#eeeeee" : "transparent",
                  color: selectedCourse === course.courseId ? "#105D50" : "black",
                }}
              />

            ))}
        </Tabs>


      </div>

      <div className="flex items-center mt-4 ml-2">
        {/* Semesters Tab */}
        <Tabs
          value={selectedSemester}
          onChange={handleSemesterChange}
          indicatorColor="none"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          // TabIndicatorProps={{
          //   style: { backgroundColor: "#105D50", padding: "2px" },
          // }}
          sx={{
            "& button": {
              borderRadius: 2,
              height: "auto", // Adjust the height as needed
              padding: "4px", // Adjust padding if needed

            },

          }}
        >
       {semesterData
    .filter((semester, index, self) =>
      index === self.findIndex((s) => s.semesterNumber === semester.semesterNumber)
    )
    .map((semester) => (
              <Tab key={semester.semesterNumber}
                label={semester.semesterNumber}
                //className={selectedSemester === semester.semesterNumber ? "Tab selected" : ""}
                style={{
                  // borderLeft: "1px groove #105D50",
                  // borderBottom: "1px groove #105D50",
                  // borderRight: "4px groove #105D50",
                  // borderTop: "2px groove #105D50",
                  backgroundColor:
                    selectedSemester === semester.semesterNumber ? "#eeeeee" : "transparent",
                  color: selectedSemester === semester.semesterNumber ? "#105D50" : "black",
                  gap: "2px",
                }}
              />
            ))}
        </Tabs>
      </div>

      <div className="flex items-center mt-5  ml-2">
        {/* Sections Tab */}
        <Tabs
          value={selectedSection}
          onChange={handleSectionChange}
          indicatorColor="none"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          // TabIndicatorProps={{
          //   style: { backgroundColor: "#105D50", padding: "2px" },
          // }}
          sx={{
            "& button": {
              borderRadius: 2,
              height: "auto", // Adjust the height as needed
              padding: "0", // Adjust padding if needed
              margin: "0"
            },
          }}
        >
          {sectionData

            .map((section) => (
              <Tab key={section.sectionId}
                label={section.sectionName}
                style={{
                  // borderLeft: "1px groove #105D50",
                  // borderBottom: "1px groove #105D50",
                  // borderRight: "4px groove #105D50",
                  backgroundColor:
                    selectedSection === section.sectionId ? "#eeeeee" : "transparent",
                  color: selectedSection === section.sectionId ? "#105D50" : "black",
                  gap: "2px",
                }}
              />
            ))}
        </Tabs>
      </div>

      <div className="flex flex-col justify-between mx-auto max-w-screen-xl p-3">
        <div className="flex justify-center items-center ">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6">
            {cardData.map((card, index) => (
              <Link
                key={index}
                to={card.path}
                onClick={handleToggle}
                className="hover:bg-gray-100 bg-white border rounded-2xl h-[60px] w-[200px] mt-8 transition-colors duration-300 flex items-center justify-center shadow-md"
              >
                <img
                  src={card.icon}
                  alt={card.title}
                  className="w-5 h-5 mr-2"
                />
                <p className="font-semibold text-center text-md">
                  {card.title}
                </p>
              </Link>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between px-4 py-2 rounded-lg mt-10">
          <h2 className="text-xl font-semibold mt-8">Subjects</h2>
          <div className="relative ml-[450px] ">
            <input
              placeholder="Search"
              type="text"
              required
              className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
                focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
              rounded-md "
              onChange={(event) => setSearchText(event.target.value)}
              value={searchText}
            />
            <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
          </div>

        </div>
        <hr className="border-t bg-gray-800 h-0.5 mx-4 shadow-md" />

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-4 mt-8">
          {addedSubjects.map((subject, index) => (

            <div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden transition duration-300 transform hover:scale-105 relative"
            >
              <Link
                to={`/homepage/Classroom/${encodeURIComponent(subject.subjectId)}/${selectedSectionId}`}
              >
                <div className="bg-gray-300 text-white text-8xl flex items-center justify-center h-32">
                  {subject.subjectName[0]}
                </div>
                <div className="p-6 mb-4">
                  <h2 className="text-xl font-semibold text-gray-800 mb-2">
                    {subject.subjectName}
                  </h2>

                  <p className="text-sm text-gray-600">
                    Faculty Name: {subject.facultyName}
                  </p>

                </div>
              </Link>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default Classroom;