import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function ForgotPassword() {
  const apiUrl = process.env.REACT_APP_API_URL;

  const initialState = {
    email: "",
    password: "",
  };
  const [visible, setVisible] = useState(false);

  const [form, setForm] = useState(initialState);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

 
  const handleEmailSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/forgotpassword`, {
        email: form.email,
      });
      if (response.data.message === "success") {
        toast.success("Password recovery email sent successfully");
        setEmailSubmitted(true);
      } else {
        toast.warn(response.data.message);
      } 
    } catch (error) {
      toast.error("An error occurred while sending the password recovery email");
    }
  };

  
  const handlePasswordSubmit = async () => {
    try {
      const response = await axios.post(`${apiUrl}/resetpassword`, {
        email: form.email,
        password: form.password,
      });
      if (response.data.message === "success") {
        toast.success("Password has been reset successfully");
        
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred while resetting the password");
    }
  };

  return (
    <div className="flex flex-col justify-center items-center">
      <form className="w-[400px] mx-auto h-fit px-8 pt-8 pb-4 rounded-2xl">
      
        {!emailSubmitted && (
          <div className="flex flex-col text-gray-400 py-2 relative">
            <div className="absolute flex text-color-red items-center" />
            <input
              className="rounded-lg bg-white px-11 py-3 focus:border-blue-50 focus:bg-gray-50 focus:outline-none"
              type="email"
              placeholder="Email Address"
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value },
              setForm(initialState))}
            />
          </div>
        )}

       
        {emailSubmitted && (
          <div className="w-full flex flex-col text-gray-400 py-2 relative">
            <div className="absolute flex text-color-red items-center" />
            <input
              className="rounded-lg bg-white px-11 py-3 focus:border-blue-50 focus:bg-gray-50 focus:outline-none"
              type={visible ? "text" : "password"}
              placeholder="New Password"
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
            />
          </div>
        )}
      </form>

     
      <div className="w-full flex flex-row justify-center">
        {!emailSubmitted && (
          <button
            className="w-[150px] h-10 mx-2 bg-[rgb(21,164,157)] mt-2 hover:bg-teal-500 shadow-lg text-md text-white font-medium rounded"
            onClick={handleEmailSubmit}
          >
            Submit Email
          </button>
        )}

        {emailSubmitted && (
          <button
            className="w-[150px] h-10 mx-2 bg-[rgb(21,164,157)] mt-2 hover:bg-teal-500 shadow-lg text-md text-white font-medium rounded"
            onClick={handlePasswordSubmit}
          >
            Submit Password
          </button>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
