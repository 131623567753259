import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { AiOutlineSearch } from 'react-icons/ai';
import Switch from "@mui/material/Switch";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaEye } from "react-icons/fa";
import { DatePicker as AntDatePicker } from 'antd';
import preview1 from "../../Images/preview1.png";
import preview2 from "../../Images/preview2.png";
import { Modal,Row, Col ,Typography} from "antd";


const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

function StudentAttendance() {
  const [branches, setBranches] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Define anchorEl state
  const [openMenu, setOpenMenu] = useState(null); // Define openMenu state
  const history = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const { RangePicker } = AntDatePicker;

  const { sectionId } = useParams();

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: "Student Name",
      selector: "studentName",
      sortable: true,
      width: "200px",
    },

    {
      name: "Student ID",
      selector: "studentId",
      sortable: true,
      width: "200px",
    },

    // {
    //   name: "Mobile Number",
    //   selector: "mobileNumber",
    //   sortable: true,
    //   width: "200px",
    // },

    {
      name: "Total Working Days",
      selector: "totalWorkingDays",
      sortable: true,
      width: "200px",
    },

    {
      name: "Leave Taken",
      selector: "leaveTaken",
      sortable: true,
      width: "200px",
    },
    {
      name: "No.of OD's",
      selector: "odCount",
      sortable: true,
      width: "200px",
     // cell: (row) => (row.odPercentage ? `${row.odPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name: "No. of Presents",
      selector: "presentCount",
      sortable: true,
      width: "200px",
      //cell: (row) => (row.presentPercentage ? `${row.presentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name: "No. od Absents",
      selector: "absentCount",
      sortable: true,
      width: "200px",
      //cell: (row) => (row.absentPercentage ? `${row.absentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name: "Percentage",
      selector: "percentageAttendance",
      sortable: true,
      width: "200px",
      cell: (row) => (row.percentageAttendance ? `${Math.round(row.percentageAttendance)}%` : "0"),
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={()=>showPreviewModal(row)}/>
          </div>
        </>
      ),
      // width:"100px"
    },
  ];

  useEffect(() => {
    if (Array.isArray(item)) {
      const result = item?.filter((value) => {
        // Check if facultyName is defined before trying to access its properties
        return (
          value?.studentName &&
          value.studentName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }) ?? [];
      setFilteredData(result);
    }
  }, [item, searchTerm]);

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px"
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",

      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width:"150px",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: '1px solid #ddd',
      },
    }
  };
  const toggleFormView = () => {
    history(`/homepage/My Classroom/today attendance/${sectionId}`);

  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onStartDateChange = (date, dateString) => {
    setStartDate(date);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(date);
  };


  useEffect(() => {
    const fetchStudentAttendanceData = async () => {
      try {
        const endpoint = `${apiUrl}/collegestudentbysectionuniqueid/${sectionId}`;
        const response = await axios.get(endpoint);

        if (response && response.data) {
          const fetchStudentAttendanceData = response.data.data;
          setFilteredData(fetchStudentAttendanceData);
        } else {
          console.error("Invalid response format:", response);
        }
      } catch (error) {
        console.log(error.response?.data);
        if (error.response?.data?.status === 404) {
          // Handle the error accordingly
        }
      }
    };

    fetchStudentAttendanceData(); // Fetch data on component mount
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      // Fetch faculty attendance data
      const fetchStudentAttendanceData = async () => {
        try {
          if (!startDate || !endDate) {

            return;
          }

          // Format the dates to match your backend expectations
          const formattedStartDate = startDate.toISOString();
          const formattedEndDate = endDate.toISOString();

          const endpoint = `${apiUrl}/studentUpdatedAttendance/${sectionId}/${formattedStartDate}/${formattedEndDate}`;
          const response = await axios.get(endpoint);

          if (response && response.data) {
            const studentAttendanceData = response.data.data;
            setFilteredData(studentAttendanceData);
          } else {
            console.error("Invalid response format:", response);

          }

        } catch (error) {
          console.log(error.response?.data);
          if (error.response?.data?.status === 404) {
            console.error("Response data:", error.response.data);
          }
          throw error;
        }
      };

      // Call the fetch function only when both start date and end date are selected
      if (startDate && endDate) {
        fetchStudentAttendanceData();
      }
    }
  }, [startDate, endDate]);



  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Text, Title} = Typography;

  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };

  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  return (
    <div className="W-full h-fit bg-white flex flex-col items-center gap-3 rounded-md pt-5  px-1 drop-shadow border">
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
        </div>

        <button
          className="flex flex-row py-3 px-4 text-[14px] bg-primary  text-white rounded-lg hover:bg-custom-orange  hover:text-green items-center gap-1 "
          onClick={toggleFormView}
        >

          Todays Attendance
        </button>
      </div>
      <div className="flex flex-row gap-4 mt-4 mb-4 justify-start" style={{ zIndex: 2 }}>
        <label className="label flex flex-row items-center gap-2">Date</label>
        <RangePicker
          value={[startDate, endDate]}
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              onStartDateChange(dates[0]);
              onEndDateChange(dates[1]);
            }
          }}
          placeholder={['Start Date', 'End Date']}
          format="DD/MM/YYYY"
          className="custom-range-picker"
        />
      </div>

      {/* Use the DataTable component */}
      <div className="flex flex-col w-full">
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        // Apply custom styles here
        />
      </div>
      <div className='flex justify-end ml-14'>
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">

      <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                <Col span={2.1}>
                 <img src={preview2} className="flex justify-start" ></img>
                </Col>
               <div className="flex">
               <Col span={4.5} className="z-50 flex flex-col ">
                  {previewData.uploadStudentProfile && (
                    <img
                      src={`${ImageUrl}/CollegeStudentUploads/${previewData.uploadStudentProfile}`}
                      alt="Profile"
                      className="rounded-full w-20 h-20 border-white border-[3px] drop-shadow-xl"
                    />
                  )}
                </Col>
                <Col span={12} className="flex flex-col justify-center mt-2">
                  <Title level={1} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1 ">
                    {previewData.studentName.toUpperCase()}
                  </Title>
                </Col>
               </div>
              </Row>
      <div className="sm:ml-20 my-6 space-y-2">
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">student ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.studentId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Total Working Days:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.totalWorkingDays}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No.of OD's:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.odCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No. of Presents:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.presentCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No. of Absents:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.absentCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Percentage:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.percentageAttendance}%</Text>
        </Col>
      </Row>
      </div>
     
      <Row className="flex justify-end -mt-20" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview1}></img>
                </Col>
              </Row>
    </div>
  )}
</Modal>
 </div>
    </div>
  );
}

export default StudentAttendance;