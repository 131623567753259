import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Switch } from "antd";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import TextField from "@mui/material/TextField";

import { CSVLink } from "react-csv";
import download from "../Images/download.png";
import upload from "../Images/upload.png";
import MenuItem from '@mui/material/MenuItem';
import { Box, IconButton, Stack } from "@mui/material";
const apiUrl = process.env.REACT_APP_API_URL;

function Result({ exams }) {
  const LMS_ROLE = localStorage.getItem("lms_role");


  const [semesters, setSemesters] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  const [isExam, setIsExam] = useState(false);
  const [courses, setCourses] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const history = useNavigate();
  const { sectionId } = useParams();
  const [exam, setExam] = useState([]);
  // const [examId, setExamId] = useState([])
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const[academicYear,setAcademicYear]=useState();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [formData, setFormData] = useState({
    semesterNumber: "",
    sectionId: "",
    courseId: "",
  });
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [selectedSemesterNumber, setSelectedSemesterNumber] = useState("");

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
           console.log("Results not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    }
  }, []);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    // Find the course object in the courses array that matches the selected course name
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse.courseId);
      setSelectedSemesterNumber("");
    }
  };

  // useEffect(() => {
  //   // Fetch semesters from the database based on the selected course ID
  //   if (selectedCourseId) {
  //     axios
  //       .get(`${apiUrl}/semesterbycourseid/${selectedCourseId}`)
  //       .then((response) => {
  //         if (response.status === 200 && Array.isArray(response.data.data)) {
  //           // Assuming semesters are in response.data.data
  //           const semestersData = response.data.data;

  //           // Assuming the semesters are directly available in semestersData
  //           const courseSemesters = Array.isArray(semestersData)
  //             ? semestersData.map((course) => course.courseSemesters).flat()
  //             : [];

  //           console.log(courseSemesters, "yffufheu  ")
  //           // Set semesters state with the semesters for the selected course
  //           setSemesters(courseSemesters);
  //           console.log(selectedCourseId);
  //         } else {
  //           console.log("Failed to fetch semesters.");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error.response.data);
  //         if (error.response.data.status === 404) {
  //           console.log("Semesters not found");
  //         }
  //       });
  //   }
  // }, [selectedCourseId]); 

  // useEffect(() => {
  //   // Fetch sections from the database based on the selected course ID and semester number
  //   if (selectedCourseId && formData.semesterNumber) {
  //     axios
  //       .get(
  //         `${apiUrl}/collegesectionbycourseidandsemester/${selectedCourseId}/${encodeURIComponent(
  //           formData.semesterNumber
  //         )}`
  //       )
  //       .then((response) => {
  //         if (response.status === 200 && Array.isArray(response.data.data)) {
  //           const fetchedSectionsData = response.data.data;

  //           // Set sections data state with the fetched section data
  //           setSectionsData(fetchedSectionsData);

  //           // Set sections state with the section names
  //           const sectionNames = fetchedSectionsData.map(
  //             (section) => section.sectionName
  //           );
  //           setSections(sectionNames);
  //         } else {
  //           console.log("Failed to fetch sections.");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error.response.data);
  //         if (error.response.data.status === 404) {
  //           console.log("Sections not found");
  //         }
  //       });
  //   }
  // }, [selectedCourseId, formData.semesterNumber, setSections]);


  const handleSectionChange = (e) => {
    const selectedSectionName = e.target.value;

    const selectedSection = sectionsData.find(
      (section) => section.sectionName === selectedSectionName
    );

    if (selectedSection) {
      console.log("Selected Section ID:", selectedSection.sectionId);

      setFormData((prevFormData) => ({
        ...prevFormData,
        sectionId: selectedSection.sectionId,
      }));
      setSelectedSectionId(selectedSection.sectionId);
    }
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/collegeexam/${id}`).then((res) => {
          //  console.log(res.data);
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
        //axios.delete(`${apiUrl}/collegefacultylogin/${facultyId}`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleIconClick = (examId) => {
    history(`/homepage/Result/${selectedSectionId}/${examId}`);
    console.log(examId, "examIds");
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      sortable: true,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Exam ID</h1>,
      selector: (row) => row.examId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Exam Name</h1>,
      selector: (row) => row.examName,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Subject ID</h1>,
      selector: (row) => row.subjectId,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Subject Name</h1>,
      selector: (row) => row.subjectList,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[60px]">Duration</h1>,
      selector: (row) => row.duration,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <>
              <button
                onClick={() => handleIconClick(row.examId)}
                className="px-4 py-2 text-white  rounded-md bg-primary hover:br-secondary "
              >View Result
</button>

            </>
          </div>
        </>
      ),
      width: "150px",
    },
  ];

  // Define your custom date format function here
  const formatDate = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const result =
      item &&
      searchTerm &&
      item.filter((value) => {
        return value.examName.toLowerCase().includes(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);
  useEffect(() => {
    if (selectedSectionId) {
      axios
        .get(`${apiUrl}/getexambysectionid/${selectedSectionId}`)
        .then((res) => {
          const examData = res.data && res.data.data ? res.data.data : [];
          // Filter exams with status 1
          const filteredExams = examData.filter(exam => exam.status === 1);
          setFilteredData(filteredExams);
          if (filteredExams.length > 0) {
            setExam(filteredExams[0].examId);
          }
          console.log(examData);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            // Handle error if needed
          }
        });
    }
  }, [selectedSectionId]);

  console.log("Exam Id", exam);
  const handleCsv = () => {
    if (filteredData && filteredData.length > 0) {
      return filteredData.map((item) => ({
        "Exam ID": item.examId,
        "Exam Name": item.examName,
        Semester: item.semesterNumber,
        "Date Of Exam": item.dateOfExamination,
        "Subject Name": item.subjectName,
        "Subject Id": item.subjectId,
      }));
    }
    return [];
  };
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };
  const handleAcademicYearChange = (e) => {
    const selectedAcademicYear = e.target.value;

    // setFormData((prevData) => ({
    //   ...prevData,
    //   academicYear: selectedAcademicYear,
    // }));

    // check if both courseId and selectedAcademicYear are available
    if (selectedCourseId !== null && selectedAcademicYear !== "") {
      const encodedAcademicYear = encodeURIComponent(selectedAcademicYear);
      axios
        .get(`${apiUrl}/semesterbycourseidandacademicyear/${selectedCourseId}/${encodedAcademicYear}`)
        .then((res) => {
          setItem(res.data.data);
          if (res.data.data.length > 0) {
            // Use a Set to remove duplicates and then convert it back to an array
            const uniqueSemesters = [...new Set(res.data.data.map((semester) => semester.semesterNumber))];
            setSemesters(uniqueSemesters);
          }

          console.log("API Response:", res.data);
          console.log("data", item);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    }
  }, []);
  const handleSemesterChange = (e) => {
    const selectedSemesterNumber = e.target.value;
  
    // Update the semester number in the form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      semesterNumber: selectedSemesterNumber,
    }));
  
    // Fetch sections based on the selected course ID, academic year, and semester number
    if (selectedCourseId && formData.academicYear && selectedSemesterNumber) {
      axios
        .get(
          `${apiUrl}/sectionbycourseandsemesterandacademicyear/${selectedCourseId}/${encodeURIComponent(
            selectedSemesterNumber
          )}/${formData.academicYear}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            const fetchedSectionsData = response.data.data;
  
            // Set sections data state with the fetched section data
            setSectionsData(fetchedSectionsData);
  
            // Set sections state with the section names
            const sectionNames = fetchedSectionsData.map(
              (section) => section.sectionName
            );
            setSections(sectionNames);
          } else {
            console.log("Failed to fetch sections.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Sections not found");
          }
        });
    }
  
    console.log("Selected Semester Number:", selectedSemesterNumber);
  };
  
  return (
    <div className="W-full h-fit bg-white flex flex-col  gap-3 rounded-md pt-5  px-1 drop-shadow border">
          {!isExam ? (
        <div className="w-full h-fit flex flex-col py-12">
          <form
            // onSubmit={handleSubmit}
            className="flex flex-row justify-center items-center gap-4"
          >
            <Stack
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  // Reduce label font size
                  fontSize: "13px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50", // Set the default outline color to black
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                    // Change the border color on hover
                  },
  
                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px", // Set the outline color to yellow on focus
                  },
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width={"820px"}
                spacing={2}
              >
               
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                      
                    },

                    "& .MuiInputBase-root": {
                      height: 50,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Course Name"
                  variant="outlined"
                  name="courseName"
                  fullWidth
                  select
                  value={formData.courseName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleCourseChange(e); // Call the course change handler
                  }}
                  required
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                      
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formData.academicYear}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleAcademicYearChange(e); 
                  }}
                  // onChange={handleOnChange}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 50,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    // width: "120%"
                  }}

                  label="Semester Number"
                  variant="outlined"
                  fullWidth
                  select
                  name="semesterNumber"
                  type="text"
                  value={formData.semesterNumber}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSemesterChange(e);
                  }}
                  required
                >
                  {semesters.map((semester, index) => (
                    <MenuItem key={index} value={semester}>
                      {semester}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 50,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%"
                  }}
                  select
                  label="Section Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="sectionName"
                  value={formData.sectionName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSectionChange(e);
                  }}
                >
                   {sectionsData.map((section) => (
    <MenuItem key={section.sectionId} value={section.sectionName}>
      {`${section.sectionName} `}
    </MenuItem>
  ))}
                </TextField>

         </Stack>

              </Stack>
        
          </form>
        </div>

      ) : null}
     
      <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
        <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
          <div className="flex flex-row items-center gap-4">
            <div className="relative">
              <input
                placeholder="Search"
                type="text"
                required
                className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />
              <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
            </div>
        
          </div>
          {/* Use the DataTable component */}
          <div className="flex flex-row items-center gap-6">
            {/* <div class="relative group flex items-center">
              <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                {" "}
                <img
                  alt="upload"
                  src={upload}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </span>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Upload
              </div>
            </div> */}
            <div class="relative group flex items-center">
              <CSVLink
                data={handleCsv()}
                filename="Results.csv"
                class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
              >
                {" "}
                <img
                  alt="download"
                  src={download}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </CSVLink>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Download        
              </div>
            </div>
          </div>
        </div>
        {/* Use the DataTable component */}
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          onChangePage={handlePageChange}
        />
      </div>
    </div>
  );
}

export default Result;