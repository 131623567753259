import { Stack, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { Modal, Button } from 'antd';

function ShiftSemester() {
    const LMS_ROLE = localStorage.getItem("lms_role");
    const { sectionId } = useParams();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [item, setItem] = useState();
    const[academicYear,setAcademicYear]=useState();
    const[courseId,setCourseId]=useState();
    const [semesters, setSemesters] = useState([]);
    const[sections,setSections]=useState([]);
    const[sectionsData,setSectionsData]=useState([]);

    const [formData, setFormData] = useState({
        currentSemester: "",
        currentSectionName: "",
        currentSectionId: "",
        currentAcademicYear: "",
        academicYear: "",
        semesterNumber: "",
        sectionName: "",
        sectionId: "",
    });
    const handleSubmit = () => {
      axios
        .post(`${apiUrl}/shiftStudents`, {
          currentSectionId: formData.currentSectionId,
          academicYear: formData.academicYear,
          semesterNumber: formData.semesterNumber,
          sectionId: formData.sectionId,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            handleCloseModal();
        } else if (res.status === 400) {
            toast.warn(res.data.message);
            // Handle existing students information if needed
            console.log(res.data.existingStudents);
        }
        })
        .catch((err) => {
          console.error(err);
          toast.error('Failed to shift students');
        });
    };
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (sectionId !== null) {
            axios
                .get(`${apiUrl}/collegesectionbysectionid/${sectionId}`)
                .then((res) => {
                    setItem(res.data.data);
                    console.log("API Response:", res.data);
                    console.log("data", item);
                    setCourseId( res.data.data[0].courseId);
                    setFormData({
                        currentSemester: res.data.data[0].semesterNumber,
                        currentSectionName: res.data.data[0].sectionName,
                        currentSectionId: res.data.data[0].sectionId,
                        currentAcademicYear: res.data.data[0].academicYear,
                    });
                })

                .catch((err) => console.log(err));
        }
    }, [sectionId]);
    console.log(sectionId);

    
    const handleAcademicYearChange = (e) => {
        const selectedAcademicYear = e.target.value;
    
        setFormData((prevData) => ({
          ...prevData,
          academicYear: selectedAcademicYear,
        }));
    
        // check if both courseId and selectedAcademicYear are available
        if (courseId !== null && selectedAcademicYear !== "") {
          const encodedAcademicYear = encodeURIComponent(selectedAcademicYear);
          axios
            .get(`${apiUrl}/semesterbycourseidandacademicyear/${courseId}/${encodedAcademicYear}`)
            .then((res) => {
              setItem(res.data.data);
              if (res.data.data.length > 0) {
                const semesterNumbers = res.data.data.map((semester) => semester.semesterNumber);
                setSemesters(semesterNumbers);
              }
    
              console.log("API Response:", res.data);
              console.log("data", item);
            })
            .catch((err) => console.log(err));
        }
      };
    
      useEffect(() => {
        if (LMS_ROLE === "3" || LMS_ROLE === "5" || LMS_ROLE === "7") {
          axios
            .get(
              `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
                "institute_id"
              )}`
            )
            .then((response) => {
              if (response.status === 200 && Array.isArray(response.data.data)) {
                setAcademicYear(response.data.data);
                console.log("data",academicYear);
              } else {
                console.log("Failed to fetch courses.");
              }
            })
            .catch((error) => {
              console.log(error.response.data);
              if (error.response.data.status === 404) {
                toast.info("Course name not found");
              }
            });
        } else if (LMS_ROLE === "4") {
          axios
            .get(
              `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
                "institute_Unique_Id"
              )}`
            )
            .then((response) => {
              if (response.status === 200 && Array.isArray(response.data.data)) {
                setAcademicYear(response.data.data);
              } else {
                console.log("Failed to fetch courses.");
              }
            })
            .catch((error) => {
              console.log(error.response.data);
              if (error.response.data.status === 404) {
                toast.info("Course name not found");
              }
            });
        } else if (LMS_ROLE === "6") {
          axios
            .get(
              `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
                "branch_Unique_Id"
              )}`
            )
            .then((response) => {
              if (response.status === 200 && Array.isArray(response.data.data)) {
                setAcademicYear(response.data.data);
              } else {
                console.log("Failed to fetch courses.");
              }
            })
            .catch((error) => {
              console.log(error.response.data);
              if (error.response.data.status === 404) {
                toast.info("Course name not found");
              }
            });
        }
      }, []);

      
      useEffect(() => {
        if (courseId && formData.semesterNumber && academicYear) {
          axios
            .get(
              `${apiUrl}/sectionbycourseandsemesterandacademicyear/${courseId}/${encodeURIComponent(
                formData.semesterNumber
              )}/${formData.academicYear}`
            )
            .then((response) => {
              if (response.status === 200 && Array.isArray(response.data.data)) {
                const fetchedSectionsData = response.data.data;
    
                // Set sections data state with the fetched section data
                setSectionsData(fetchedSectionsData);
    
                // Set sections state with the section names
                const sectionNames = fetchedSectionsData.map(
                  (section) => section.sectionName
                );
                setSections(sectionNames);
    
              
              } else {
                console.log("Failed to fetch sections.");
              }
            })
            .catch((error) => {
              console.log(error.response.data);
              if (error.response.data.status === 404) {
                toast.info("Sections not found");
              }
            });
        }
      }, [courseId, formData.semesterNumber, academicYear]);

      const handleSectionChange = (e) => {
        const selectedSectionName = e.target.value;
        const selectedSection = sectionsData.find((section) => section.sectionName === selectedSectionName);
        
        if (selectedSection) {
            setFormData((prevData) => ({
                ...prevData,
                sectionId: selectedSection.sectionId,
            }));
        }
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleShift = () => {
      setIsModalVisible(true);
    };
  
    const handleCloseModal = () => {
      setIsModalVisible(false);
    };
    return (

        <div className="mb-4 flex justify-between items-center ">
            <div className="w-full h-fit bg-white px-16 py-12 rounded-lg drop-shadow border ">
                {/* Add Branch Form */}

                <form onSubmit={handleSubmit}>
                    <Stack
                        sx={{
                            margin: "auto",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            "& > :not(style)": {},
                            "& .MuiInputLabel-root": {
                                // Reduce label font size
                                fontSize: "13px",
                                color: "#105D50",
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    border: "1.3px solid #105D50", // Set the default outline color to black
                                },
                                "&:hover fieldset": {
                                    border: "1.3px solid #105D50",
                                    // Change the border color on hover
                                },

                                "&.Mui-focused fieldset": {
                                    border: "1.34px solid #105D50",
                                    fontSize: "13px", // Set the outline color to yellow on focus
                                },
                            },
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ marginBottom: "20px", width: "100%" }}
                            spacing={2}
                        >
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                width={"280px"}
                                spacing={2}
                            >
                                <TextField
                                    sx={{
                                        backgroundColor: "#F5F5F5",
                                        color: "#105D50",
                                        "& .MuiFormLabel-root.Mui-focused": {
                                            color: "#105D50",
                                        },

                                        "& .MuiInputBase-root": {
                                            height: 45,
                                            fontSize: "14px",
                                        },
                                        "&:hover": {
                                            // Apply styles when hovering over the TextField
                                            backgroundColor: "#EEEDED", // Change background color on hover
                                        },
                                    }}
                                    label="Current Academic Year"
                                    variant="outlined"
                                    name="currentAcademicYear"
                                    type="text"
                                    fullWidth
                                    value={formData.currentAcademicYear}
                                    onChange={handleOnChange}
                                    required
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />

                                <TextField
                                    sx={{
                                        backgroundColor: "#F5F5F5",
                                        color: "#105D50",
                                        "& .MuiFormLabel-root.Mui-focused": {
                                            color: "#105D50",
                                        },

                                        "& .MuiInputBase-root": {
                                            height: 45,
                                            fontSize: "14px",
                                        },
                                        "&:hover": {
                                            // Apply styles when hovering over the TextField
                                            backgroundColor: "#EEEDED", // Change background color on hover
                                        },
                                    }}
                                    label="Current Section Name"
                                    variant="outlined"
                                    name="currentSectionName"
                                    type="text"
                                    fullWidth
                                    value={formData.currentSectionName}
                                    onChange={handleOnChange}
                                    required
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                 <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formData.academicYear}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleAcademicYearChange(e); // Call the course change handler
                  }}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#105D50",
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#105D50",
        },
        "& .MuiInputBase-root": {
          height: 45,
          fontSize: "14px",
        },
        "&:hover": {
          backgroundColor: "#EEEDED",
        },
      }}
      label="Section Name"
      variant="outlined"
      name="sectionName"
      type="text"
      fullWidth
      select
      value={formData.sectionName}
      onChange={(e) => {
        handleOnChange(e);
        handleSectionChange(e); // Call the section change handler
    }}
      required
    >
      {sections.map((section, index) => (
        <MenuItem key={index} value={section}>
          {section}
        </MenuItem>
      ))}
    </TextField>
                            </Stack>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                width={"280px"}
                                spacing={2}
                            >
                                <TextField
                                    sx={{
                                        backgroundColor: "#F5F5F5",
                                        color: "#105D50",
                                        "& .MuiFormLabel-root.Mui-focused": {
                                            color: "#105D50",
                                        },

                                        "& .MuiInputBase-root": {
                                            height: 45,
                                            fontSize: "14px",
                                        },
                                        "&:hover": {
                                            // Apply styles when hovering over the TextField
                                            backgroundColor: "#EEEDED", // Change background color on hover
                                        },
                                    }}
                                    label="Current Semester"
                                    variant="outlined"
                                    fullWidth
                                    name="CurrentSemester"
                                    type="text"
                                    value={formData.currentSemester}
                                    onChange={handleOnChange}
                                    required
                                    InputProps={{
                                    readOnly: true,
                                    }}
                                />
                                <TextField
                                    sx={{
                                        backgroundColor: "#F5F5F5",
                                        color: "#105D50",
                                        "& .MuiFormLabel-root.Mui-focused": {
                                            color: "#105D50",
                                        },

                                        "& .MuiInputBase-root": {
                                            height: 45,
                                            fontSize: "14px",
                                        },
                                        "&:hover": {
                                            // Apply styles when hovering over the TextField
                                            backgroundColor: "#EEEDED", // Change background color on hover
                                        },
                                    }}
                                    label="Current Section ID"
                                    variant="outlined"
                                    fullWidth
                                    name="currentSectionId"
                                    type="text"
                                    value={formData.currentSectionId}
                                    onChange={handleOnChange}
                                    required
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                    <TextField
                        sx={{
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        "& .MuiFormLabel-root.Mui-focused": {
                            color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                            height: 45,
                            fontSize: "14px",
                        },
                        "&:hover": {
                            backgroundColor: "#EEEDED",
                        },
                        }}
                        label="Semester Number"
                        variant="outlined"
                        fullWidth
                        select
                        name="semesterNumber"
                        type="text"
                        value={formData.semester}
                        onChange={handleOnChange}
                        required
                    >
                      {semesters.map((semester, index) => (
        <MenuItem key={index} value={semester}>
          {semester}
        </MenuItem>
      ))}
                  </TextField>
                                <TextField
                                    sx={{
                                        backgroundColor: "#F5F5F5",
                                        color: "#105D50",
                                        "& .MuiFormLabel-root.Mui-focused": {
                                            color: "#105D50",
                                        },

                                        "& .MuiInputBase-root": {
                                            height: 45,
                                            fontSize: "14px",
                                        },
                                        "&:hover": {
                                            // Apply styles when hovering over the TextField
                                            backgroundColor: "#EEEDED", // Change background color on hover
                                        },
                                    }}
                                    label="Section ID"
                                    variant="outlined"
                                    fullWidth
                                    name="sectionId"
                                    type="text"
                                    value={formData.sectionId}
                                    onChange={handleOnChange}
                                    required
                                    InputProps={{
                                      readOnly: true,
                                  }}
                                />
                            </Stack>

                        </Stack>
                    </Stack>
                    <div className="flex justify-center items-center space-x-8 pt-8 pb-8">
                        <button
                            type="button"
                            // onClick={handleSubmit}
                            onClick={handleShift}
                            className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                        >
                            Shift Students
                        </button>
                    </div>
                </form>
                
                <Modal
    title="Shift Students to Next Semester"
    visible={isModalVisible}
    onCancel={handleCloseModal}
    footer={null}
    width={500}
    bodyStyle={{ padding: '10px' }}
>
    <p className="text-base text-gray-700 mb-4">
        Are you sure you want to shift students to the next semester?
    </p>
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        
        <button
            type="primary"
            onClick={handleSubmit}
            className="text-[12px] font-normal p-2  bg-primary text-white rounded-md hover:bg-secondary duration-300"
        >
            Confirm Shift
        </button>
    </div>
</Modal>
            </div>
        </div>

    )
}
export default ShiftSemester;