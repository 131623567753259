import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaCheck, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Modal, Row, Col, Typography, Divider } from 'antd';
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../App.css";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import { CSVLink } from "react-csv";
import FileUploadModal from "../SubComponents/fileuploadform";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";


function Course() {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");
  const PERMISSION_EDIT = localStorage.getItem("permission_edit");
  const PERMISSION_DELETE = localStorage.getItem("permission_delete");
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const history = useNavigate();
  const { Text, Title, Paragraph } = Typography;

  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const apiUrl = process.env.REACT_APP_API_URL;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const handleToggle = async (id, value) => {
    const newStatus = value ? 0 : 1;
    try {
      await axios
        .put(`${apiUrl}/collegecourseadditionstatus/${id}`, {
          status: newStatus,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              `Course ${newStatus ? "activated" : "deactivated"} successfully`
            );
            setStatus(!status);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/collegecourseaddition/${id}`).then((res) => {
          //  console.log(res.data);
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Course ID": item.courseId,
        "Course Name": item.courseName,
        "Semesters": item.courseDuration,
      }));
      return csvData;
    }
    return [];
  };
  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-4 w-[200px]">Course ID</h1>,
      selector: (row) => row.courseId,
      sortable: true,
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-4 w-[230px]">Course Name</h1>,
      selector: (row) => row.courseName,
      sortable: true,
      width: "250px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Semesters</h1>,
      selector: (row) => row.courseDuration,
      sortable: true,
      // width: "150px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Status</h1>,
      selector: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              // checkedChildren={`ON`}
              // unCheckedChildren={`OFF`}
              checked={row.status === 1}
              onChange={() => handleToggle(row._id, row.status === 1)}
              className={
                row.status === 1
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
            />
          </div>
        </>
      ),
      // width:"150px"
    },
    {
      name: <h1 className="text-white pl-4 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            {LMS_ROLE === "3" || LMS_ROLE === "5" ? (
              <>
                <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)} />
                <Link to={`/homepage/course/${row.courseId}`}>
                  <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                </Link>
                <MdDelete
                  className="w-6 h-6 text-red-500 cursor-pointer"
                  onClick={() => showDeleteConfirm(row._id)}
                />
              </>
            ) : (
              <>
                <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)} />
                {PERMISSION_EDIT === "true" && (
                  <Link to={`/homepage/course/${row.courseId}`}>
                    <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                  </Link>
                )}
                {PERMISSION_DELETE === "true" && (
                  <MdDelete
                    className="w-6 h-6 text-red-500 cursor-pointer"
                    onClick={() => showDeleteConfirm(row._id)}
                  />
                )}
              </>
            )}
          </div>
        </>
      ),
      //  width:"150px"
    },
  ];

  useEffect(() => { }, []);

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.courseName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            //toast.info(error.response.data.message);
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            // toast.info(error.response.data.message);
          }
        });
    } else {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info(error.response.data.message);
          }
        });
    }
  }, [status]);

  const toggleFormView = () => {
    history("/homepage/course/add course");
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {

        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",


      },
    },
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };




  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <div className="flex flex-col  w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
              </div>
              {LMS_ROLE === "3" || LMS_ROLE === "5" ? (
                <button
                  className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
                  onClick={toggleFormView}
                >
                  <FaPlus />
                  Add Course
                </button>
              ) : (
                <>
                  {PERMISSION_ADD === "true" && (
                    <button
                      className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
                      onClick={toggleFormView}
                    >
                      <FaPlus />
                      Add Course
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-row items-center gap-6">
              <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {""}
                  <img
                    alt="upload"
                    src={upload}
                    onClick={openModal}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div>
              <div class="relative group flex items-center">
                <CSVLink
                  data={handleCsv()}
                  filename="Course List.csv"
                  class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
                >
                  {" "}
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </CSVLink>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>
          {/* Use the DataTable component */}
          <div className="flex flex-col w-full">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <FileUploadModal isOpen={isModalOpen} onClose={closeModal} menuType="courses" />
      </div>
      <div className='flex justify-end ml-14'>
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">
        
        <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                  <Col span={2.1}>
                  <img src={preview1} className="flex justify-start" ></img>
                  </Col>
                
    
                    <Col span={6} className="flex flex-col justify-center ">
                    <div className="sm:w-96 w-60 pt-4 pb-1 px-2 bg-primary rounded-full flex flex-col justify-center items-center">
                    <Title level={3} style={{fontSize,color:"white"}} className="capitalize">
                        {previewData.courseName.toUpperCase()}
                    </Title>
                    </div>
                    </Col>                                
         </Row>

      <div className="sm:ml-20 my-6 space-y-2">
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Course ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Course Duration :</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseDuration}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Course Code:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseCode}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Course Keywords:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseKeywords}</Text>
        </Col>
      </Row>

    

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Description:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseDescription}</Text>
        </Col>
      </Row>
      </div>
      <Row className="flex justify-end -mt-10" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
      </Row>
    </div>
  )}
</Modal>
    </div>
    </>
  );
}

export default Course;
