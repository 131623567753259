import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";

const SectionForm = () => {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [subjects, setSubjects] = useState([]); // Replace this with your actual subject data
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams();
  const [semesters, setSemesters] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState("");
  const [selectedSemesterNumber, setSelectedSemesterNumber] = useState("");
 const[academicYear,setAcademicYear]=useState();
  // const [selectSubject, setSelectSubject] = useState({
  //   subject_id:'',
  //   subject_Name:''
  // })

  const apiUrl = process.env.REACT_APP_API_URL;

  const initialFormData = {
    courseName: "",
    courseId: "",
    sectionName: "",
    sectionId: "",
    semesterNumber: "",
    mentorFacultyName: "",
    mentorFacultyId: "",
    subjectList: "",
    subjectId: "",
    academicYear:"",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };
  const [formData, setFormData] = useState(initialFormData);

  console.log(formData)

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formSendData = {
      courseName: formData.courseName,
      courseId: selectedCourseId,
      sectionName: formData.sectionName,
      sectionId: formData.sectionId,
      academicYear:formData.academicYear,
      semesterNumber: formData.semesterNumber,
      mentorFacultyName: formData.mentorFacultyName,
      mentorFacultyId: selectedFacultyId,
      subjectList: selectSubject,
      subjectId: selectedSubjectIds,
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
  
    };

    if (editMode) {
      axios.put(`${apiUrl}/collegesection/${id}`, formSendData).then((res) => {
        if (res.data.status === 301) {
          toast.warn(res.data.message);
        } else if (res.status === 200) {
          toast.success(res.data.message);
          navigate("/homepage/section");
        } else {
          console.log(res.data.message);
        }
      });
    } else {
      axios.post(`${apiUrl}/collegesection`, formSendData).then((res) => {
        if (res.data.status === 301) {
          toast.warn(res.data.message);
        } else if (res.status === 200) {
          toast.success(res.data.message);
          navigate("/homepage/section");
        } else {
          console.log(res.data.message);
        }
      });
    }
  };

  console.log("FORMDATA", formData.semesterNumber);
 
  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    const formSendData = {
      courseName: formData.courseName,
      courseId: selectedCourseId,
      sectionName: formData.sectionName,
      sectionId: formData.sectionId,
      semesterNumber: formData.semesterNumber,
      academicYear:formData.academicYear,
      mentorFacultyName: formData.mentorFacultyName,
      mentorFacultyId: selectedFacultyId,
      subjectList: selectSubject,
      subjectId: selectedSubjectIds,
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
  
    };

    if (editMode) {
      axios.put(`${apiUrl}/collegesection/${id}`, formSendData).then((res) => {
        if (res.data.status === 301) {
          toast.warn(res.data.message);
        } else if (res.status === 200) {
          toast.success(res.data.message);
         setFormData(initialFormData);
         setSelectedCourseId("");
         setSelectedFacultyId("");
         setGetSubject({
           ...getSubject,
           sname: [], // Reset the subject list to an empty array
         });
        } else {
          console.log(res.data.message);
        }
      });
    } else {
      axios.post(`${apiUrl}/collegesection`, formSendData).then((res) => {
        if (res.data.status === 301) {
          toast.warn(res.data.message);
        } else if (res.status === 200) {
          toast.success(res.data.message);
          setFormData(initialFormData);
          setSelectedCourseId("");
          setSelectedFacultyId("");
          setGetSubject({
            ...getSubject,
            sname: [], // Reset the subject list to an empty array
          });
        } else {
          console.log(res.data.message);
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({
        ...formData,
        semesterNumber: "", // Ensure to reset semesterNumber in non-edit mode
        subjectList: [],   // Ensure to reset subjectList in non-edit mode
      });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/collegesection/${id}`);
  
    console.log(formData.data.data);
  
    if (formData.status === 200) {
      const fetchedData = formData.data.data[0];
  
      setFormData({
        ...fetchedData,
        semesterNumber: fetchedData.semesterNumber.toString(), // Ensure to convert to string if necessary
        subjectList: fetchedData.subjectList || [],   // Ensure to set subjectList as an array
      });

      fetchedData.subjectList.forEach(element => {

        setSelectSubject((prev) => [
          ...prev,
          { name: element.name, id: element.id },
        ]);

      });

      // Set selectedSemesterNumber based on fetchedData.semesterNumber if needed
    setSelectedSemesterNumber(fetchedData.semesterNumber.toString());
    // Set selectedFacultyId based on fetchedData.mentorFacultyId if needed
    setSelectedFacultyId(fetchedData.mentorFacultyId || ''); // Handle empty string
    setSelectedCourseId(fetchedData.courseId || '');   // Assuming this is the state setter for selectedCourseId
    } else {
      console.log("Something went wrong !!");
    }
  };
  const handleReset = (e) => {
    setFormData(initialFormData);
    setSelectedCourseId("");
    setSelectedFacultyId("");
    setGetSubject({
      ...getSubject,
      sname: [], // Reset the subject list to an empty array
    });
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    // Find the course object in the courses array that matches the selected course name
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse.courseId);
      setSelectedSemesterNumber("");
    }
  };
  useEffect(() => {
    // Fetch semesters from the database based on the selected course ID
    if (selectedCourseId) {
      axios
        .get(`${apiUrl}/semesterbycourseid/${selectedCourseId}`)
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Assuming semesters are in response.data.data
            const semestersData = response.data.data;

            // Assuming the semesters are directly available in semestersData
            const courseSemesters = Array.isArray(semestersData)
              ? semestersData.map((course) => course.courseSemesters).flat()
              : [];

            // Set semesters state with the semesters for the selected course
            setSemesters(courseSemesters);
            console.log(selectedCourseId);
          } else {
            console.log("Failed to fetch semesters.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Semesters not found");
          }
        });
    }
  }, [selectedCourseId]);

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const handleFacultyChange = (e) => {
    const selectedFacultyName = e.target.value;
    console.log('Selected Faculty Name:', selectedFacultyName);
    
    const selectedFaculty = faculties.find(
      (faculty) => faculty.facultyName === selectedFacultyName
    );
  
    if (selectedFaculty) {
      setSelectedFacultyId(selectedFaculty.facultyId);
      console.log('Selected Faculty ID:', selectedFaculty.facultyId);
    }
  };
  
  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyinstitutquniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyinstitutquniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyinstitutquniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const [selectSubject, setSelectSubject] = useState([]);
  const [getSubject, setGetSubject] = useState({
    sname: []
  });

  // console.log(selectSubject, "dbvjdd");

  const handleCheckboxChange = (e, subject) => {
    console.log("trigger", subject, e.target.checked);

    const selectedSubjectName = subject.subjectName;
    const selectedSubjectId = subject.subjectId;

    if (e.target.checked) {
     
      setSelectSubject((prev) =>[
        ...prev,
        { name: selectedSubjectName , id : selectedSubjectId}
      ])
    } else {
      setSelectSubject((prev) =>
        prev.filter(
          (item) =>
             item.id !== selectedSubjectId
        )
      );
    }
  };


  const handleSubjectChange = (e) => {
    const selectedSubjectNames = e.target.value || [];
  
    // Filter the subjects based on selected Subject Names
    const selectedSubjects = subjects.filter((subject) =>
      selectedSubjectNames.includes(subject.subjectName)
    );
  
    // Map the selected subjects to their IDs
    const selectedSubjectIds = selectedSubjects.map((subject) => subject.subjectId);
  
    // Join the IDs as a comma-separated string
    const subjectIdsString = selectedSubjectIds.join(", ");
  
    // Update the state with the selected Subject IDs
    setSelectedSubjectIds(subjectIdsString);
  };

  // const handleAcademicYearChange = (e) => {
  //   const selectedAcademicYear = e.target.value;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     academicYear: selectedAcademicYear,
  //   }));
  // };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);


  // console.log(selectSubject.map(s => s.id.toLowerCase() ), subjects.map(s=>s.subjectId.toLowerCase()), "select")

  return (
    <div className="mb-4 flex justify-between items-center ">
      <div className="w-full h-fit bg-white px-16 py-12 rounded-lg drop-shadow border ">
        {/* Add Branch Form */}

        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Course Name"
                  variant="outlined"
                  name="courseName"
                  fullWidth
                  select
                  value={formData.courseName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleCourseChange(e); // Call the course change handler
                  }}
                  required
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formData.academicYear}
                  // onChange={(e) => {
                  //   handleOnChange(e);
                  //   handleAcademicYearChange(e); // Call the course change handler
                  // }}
                  onChange={handleOnChange}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Section Name"
                  variant="outlined"
                  fullWidth
                  name="sectionName"
                  type="text"
                  value={formData.sectionName}
                  onChange={handleOnChange}
                  required
                />
                
                {/* {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Semester Number"
                    variant="outlined"
                    fullWidth
                    select
                    name="semesterNumber"
                    type="text"
                    value={selectedSemesterNumber !== "" ? selectedSemesterNumber : formData.semesterNumber}
                    // value={
                    //   selectedSemesterNumber !== ""
                    //     ? selectedSemesterNumber
                    //     : formData.semesterNumber
                    // }
                    
                  >
                    {/* {semesters.map((semester, index) => (
                      <MenuItem key={index} value={semester}>
                        {semester}
                      </MenuItem>
                    ))} 
                  </TextField>
                ) : ( */}
                 
                {/*  )} */}
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Mentor Faculty Name"
                  variant="outlined"
                  fullWidth
                  select
                  name="mentorFacultyName"
                  type="text"
                  value={formData.mentorFacultyName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleFacultyChange(e); // Call the course change handler
                  }}
                  required
                >
                  {faculties.map((faculty) => (
                    <MenuItem key={faculty._id} value={faculty.facultyName}>
                      {faculty.facultyName}
                    </MenuItem>
                  ))}
                </TextField>
                
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Course ID"
                    variant="outlined"
                    fullWidth
                    name="courseId"
                    value={
                      selectedCourseId !== ""
                        ? selectedCourseId
                        : formData.courseId
                    }
                    // onChange={handleOnChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Course ID"
                    variant="outlined"
                    fullWidth
                    name="courseId"
                    value={selectedCourseId}
                    required
                  />
                )}
                 <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Semester Number"
                    variant="outlined"
                    fullWidth
                    select
                    name="semesterNumber"
                    type="text"
                    value={formData.semesterNumber}
                    onChange={handleOnChange}
                    required
                  >
                    {semesters.map((semester, index) => (
                      <MenuItem key={index} value={semester}>
                        {semester}
                      </MenuItem>
                    ))}
                  </TextField>

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Section ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionId"
                    value={formData.sectionId}
                    onChange={handleOnChange}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Section ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionId"
                    value={formData.sectionId}
                    onChange={handleOnChange}
                  />
                )}
               {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Mentor Faculty ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="mentorFacultyId"
                    value={selectedFacultyId !== "" ? selectedFacultyId : formData.mentorFacultyId}

                    onChange={handleOnChange}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Mentor Faculty ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="mentorFacultyId"
                    value={selectedFacultyId}
                    onChange={handleOnChange}
                  />
                )}
</Stack>
</Stack>
<Stack
              direction="row"
              justifyContent="center"
              //alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"580px"}
                spacing={2}
              >
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Subject List"
                    variant="outlined"
                    fullWidth
                    name="subjectList"
                    type="text"
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: "160px",
                          },
                        },
                      },
                    }}
                    value={selectSubject.map(s => s.name) || []}
                    // onChange={(e) =>
                    //   setGetSubject({ ...getSubject, sname: e.target.value })
                    // }
                    required
                  >
                    {subjects.map((subject) => (
                     
                      <MenuItem key={subject._id} value={subject.subjectName}>
                        <Checkbox
                          checked={selectSubject.some(s => s.id === subject.subjectId)}
                          onChange={(e) => handleCheckboxChange(e, subject)}
                          style={{
                            color: "#105D50",
                            width: 36,
                            height: 36,
                          }}
                        />
                        {subject.subjectName}
                      </MenuItem> 
                      
                      
                    ))}
                  </TextField>
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Subject List"
                    variant="outlined"
                    fullWidth
                    name="subjectList"
                    type="text"
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: "160px",
                          },
                        },
                      },
                    }}
                    value={selectSubject.map(s => s.name) || []}
                    // onChange={(e) => {
                    //   handleOnChange(e);
                    //   handleSubjectChange(e);
                    // }}

                    // onChange={(e) =>
                    //   setGetSubject({ ...getSubject, sname: e.target.value })
                    // }
                    required
                  >
                    {subjects.map((subject) => (
                      subject.status === 1 ?
                      <MenuItem key={subject._id} value={subject.subjectName}>
                        
                         <Checkbox
                          checked={selectSubject.some(s => s.id === subject.subjectId)}
                          onChange={(e) => handleCheckboxChange(e, subject)}
                          style={{
                            color: "#105D50",
                            width: 36,
                            height: 36,
                          }}
                        />
                        {subject.subjectName}
                      </MenuItem> : null
                    ))}
                  </TextField>
                )}
              </Stack>
          
</Stack>
            <div className="flex justify-center items-center space-x-8 pt-8 pb-8">
              {!editMode && (
                <button
                  type="button"
                  onClick={handleReset}
                  className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Reset
                </button>
              )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default SectionForm;