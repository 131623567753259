import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import download from "../Images/download.png";
import { CSVLink } from "react-csv";
import { Button } from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL;

function MeetTable() {
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const { sectionId } = useParams();
  const { confirm } = Modal;

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/deletemeet/${id}`).then((res) => {
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  const showViewModal = (meeting) => {
    setSelectedMeeting(meeting);
    setViewModalVisible(true);
  };

  const hideViewModal = () => {
    setViewModalVisible(false);
  };

  const viewModalColumns = [
    {
      name: "Student ID",
      selector: "studentId",
    },
    {
      name: "Student Name",
      selector: "studentName",
    },
  ];

  useEffect(() => {
    axios
      .get(`${apiUrl}/meetdata/${sectionId}`)
      .then((res) => {
        console.log("Fetched data:", res.data);
        setItem(res.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 204) {
          // Handle the case where no data is found
        }
      });
  }, [sectionId]);
  
  

  useEffect(() => {
    if (item) {
      const result = item.filter((value) => {
        return (
          value.facultyId &&
          value.facultyId.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [item, searchTerm]);
  

  //   const handleCsv = () => {
  //     if (item !== undefined) {
  //       const csvData = filteredData.map((item) => ({
  //         "Batch ID": item.batchId,
  //         "Batch Name": item.batchName,
  //         "Batch Timing": item.batchTimings,
  //         "Faculty Name": item.facultyName,
  //         "Faculty ID": item.facultyId,
  //         "No of Students": item.noOfStudents,
  //       }));
  //       return csvData;
  //     }
  //     return [];
  //   };


  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Faculty Name</h1>,
      selector: (row) => row.facultyName,
      sortable: true,
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Meeting Type</h1>,
      selector: (row) => row.meetingType,
      sortable: true,
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-3 w-[220px]">Schedule Time</h1>,
      selector: (row) => row.scheduleTime,
      sortable: true,
      width: "220px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Meet Link</h1>,
      selector: (row) => row.meetLink,
      sortable: true,
      width: "150px",
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <Button type="primary" onClick={() => showViewModal(row)}>
              View
            </Button>
            <MdDelete
              className="w-6 h-6 text-red-500 cursor-pointer"
              onClick={() => showDeleteConfirm(row._id)}
            />
          </div>
        </>
      ),
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
              </div>
            </div>
            <div className="flex flex-row items-center gap-6">
              {/* Additional actions or buttons */}
            </div>
          </div>
  
          <div className="flex flex-col w-full">
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="400px"
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
            />
          </div>
        </div>
      </div>
  
      <Modal
        title={`Students for ${
          selectedMeeting && selectedMeeting.facultyName
        }'s Meeting`}
        visible={viewModalVisible}
        onCancel={hideViewModal}
        footer={null}
      >
        {selectedMeeting && (
          <DataTable
            columns={viewModalColumns}
            data={selectedMeeting.students}
            customStyles={customStyles}
            noHeader
          />
        )}
      </Modal>
    </>
  );
  
}

export default MeetTable;
