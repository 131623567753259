import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MenuItem, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Modal, Typography } from "antd";
import { Link } from "react-router-dom";
import { faPlus, faRandom, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";
import { QuestionMarkSharp } from "@mui/icons-material";
import Questions from "./Questions";
import { startOfDay } from "date-fns";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const ExamForm = () => {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const [courses, setCourses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState([]);
  const [selectedSubjectName, setSelectedSubjectName] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [sections, setSections] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [sectionsData, setSectionsData] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState("");
  const[academicYear,setAcademicYear]=useState();
  const [item, setItem] = useState();
  const initialFormData = {
    courseName: "",
    courseId: "",
    sectionName: "",
    sectionId: "",
    examName: "",
    examId: "",
    assignFaculty: "",
    duration: "",
    subjectList: "",
    subjectId: "",
    dateOfExamination: "",
    totalMarks: "",
    passMarks: "",
    examLink: "",
    uploadFile: "",
    createExamOption: "",
    semesterNumber: "",
    uploadedFileName: "",
    academicYear:"",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [semesters, setSemesters] = useState([]);
  const [selectedFacultyName, setSelectedFacultyName] = useState([]);
  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  console.log("facultyId", selectedFacultyId);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const totalMarks = parseInt(formData.totalMarks, 10);
    const passMarks = parseInt(formData.passMarks, 10);

    if (isNaN(totalMarks) || isNaN(passMarks) || totalMarks < passMarks) {
      toast.warn("Pass Marks should be less than or equal to Total Marks.");
      return;
    }
    const formAppendData = new FormData();

    formAppendData.append("courseName", formData.courseName);
    formAppendData.append("courseId", selectedCourseId);
    formAppendData.append("sectionName", formData.sectionName);
    formAppendData.append("sectionId", selectedSectionId);
    formAppendData.append("examName", formData.examName);
    formAppendData.append("semesterNumber", formData.semesterNumber);
    formAppendData.append("examId", formData.examId);
    formAppendData.append("assignFaculty", formData.assignFaculty);
    formAppendData.append("assignFacultyId", selectedFacultyId);
    formAppendData.append("duration", formData.duration);
    formAppendData.append("dateOfExamination", formData.dateOfExamination);
    formAppendData.append("totalMarks", formData.totalMarks);
    formAppendData.append("passMarks", formData.passMarks);
    formAppendData.append("examLink", formData.examLink);
    formAppendData.append("academicYear", formData.academicYear);
    formAppendData.append("uploadFile", formData.uploadFile);
    formAppendData.append(
      "subjectList",
      formData.subjectList.name === undefined ? "" : formData.subjectList.name
    );
    formAppendData.append("createExamOption", formData.createExamOption);
    formAppendData.append(
      "subjectId",
      formData.subjectList.id === undefined ? "" : formData.subjectList.id
    );
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : LMS_ROLE === "6"
        ? localStorage.getItem("branch_Unique_Id")
        : localStorage.getItem("institute_Unique_Id")
    );

    console.log("Selected Faculty ID in handleSubmit:", selectedFacultyId);

    if (formData.createExamOption === "upload" && formData.uploadFile) {
      formAppendData.append("uploadFile", formData.uploadFile);
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      axios
        .post(`${apiUrl}/collegeExam`, formAppendData, config)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/exam");
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
    // }
    // navigate("/homepage/branch");
  };

  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    const formAppendData = new FormData();

    formAppendData.append("courseName", formData.courseName);
    formAppendData.append("courseId", selectedCourseId);
    formAppendData.append("sectionName", formData.sectionName);
    formAppendData.append("sectionId", selectedSectionId);
    formAppendData.append("examName", formData.examName);
    formAppendData.append("semesterNumber", formData.semesterNumber);
    formAppendData.append("examId", formData.examId);
    formAppendData.append("assignFaculty", formData.assignFaculty);
    formAppendData.append("assignFacultyId", selectedFacultyId);
    formAppendData.append("duration", formData.duration);
    formAppendData.append("academicYear", formData.academicYear);
    formAppendData.append("dateOfExamination", formData.dateOfExamination);
    formAppendData.append("totalMarks", formData.totalMarks);
    formAppendData.append("passMarks", formData.passMarks);
    formAppendData.append("examLink", formData.examLink);
    formAppendData.append("uploadFile", formData.uploadFile);
    formAppendData.append(
      "subjectList",
      formData.subjectList.name === undefined ? "" : formData.subjectList.name
    );
    formAppendData.append("createExamOption", formData.createExamOption);
    formAppendData.append(
      "subjectId",
      formData.subjectList.id === undefined ? "" : formData.subjectList.id
    );
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : LMS_ROLE === "6"
        ? localStorage.getItem("branch_Unique_Id")
        : localStorage.getItem("institute_Unique_Id")
    );

    console.log("Selected Faculty ID in handleSubmit:", selectedFacultyId);

    if (formData.createExamOption === "upload" && formData.uploadFile) {
      formAppendData.append("uploadFile", formData.uploadFile);
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      axios
        .post(`${apiUrl}/collegeExam`, formAppendData, config)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            setFormData(initialFormData);
            setSelectedCourseId("");
            setSelectedSectionId("");
            setSemesters("");
            setFormData((prevData) => ({
              ...prevData,
              duration: null,
            }));
            // setFormData((prevData) => ({
            //   ...prevData,
            //   semesterNumber: "", // Assuming semesterNumber is a string
            // }));
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
    // }
    // navigate("/homepage/branch");
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      uploadFile: file,
      uploadedFileName: file ? file.name : "",
    });
  };

  const handleDoeChange = (date) => {
    setFormData({ ...formData, dateOfExamination: date.format("DD/MM/YYYY hh:mm A") });
  };

  const handleReset = (e) => {
    setFormData(initialFormData);
    setSelectedCourseId("");
    setSelectedSectionId("");
    // setSemesters("");
    setFormData((prevData) => ({
      ...prevData,
      duration: null,
    }));
    // setFormData((prevData) => ({
    //   ...prevData,
    //   semesterNumber: "", // Assuming semesterNumber is a string
    // }));
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    // Find the course object in the courses array that matches the selected course name
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse.courseId);
    }
  };
 
  const handleFacultyChange = (e) => {
    const selectedFacultyName = e.target.value;
    // Find the faculty object in the faculties array that matches the selected faculty name
    const selectedFaculty = faculties.find((faculty) =>
      faculty.facultyName.some(
        (facultyName) => facultyName.name === selectedFacultyName
      )
    );

    if (selectedFaculty) {
      // Find the corresponding facultyId for the selected facultyName
      const selectedFacultyIdObj = selectedFaculty.facultyName.find(
        (facultyNameObj) => facultyNameObj.name === selectedFacultyName
      );

      if (selectedFacultyIdObj) {
        const selectedFacultyId = selectedFacultyIdObj.id;

        console.log("Selected Faculty ID:", selectedFacultyId);

        setFormData((prevFormData) => ({
          ...prevFormData,
          facultyId: selectedFacultyId,
        }));
        const formAppendData = new FormData();
        setSelectedFacultyId(selectedFacultyId);

        // Assuming selectedFacultyId is a single value, not an array
        formAppendData.set("assignFacultyId", selectedFacultyId);
      } else {
        console.error(
          "Selected facultyId not found for the selected facultyName"
        );
      }
    }
  };

  useEffect(() => {
    if (selectedSectionId) {
      axios
        .get(`${apiUrl}/collegesubjectbysectionid/${selectedSectionId}`)
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch subjects.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Subjects not found");
          }
        });
    }
  }, [selectedSectionId]);

  console.log("subjectId", selectedSubjectId);

  useEffect(() => {
    if (selectedSubjectId) {
      axios
        .get(`${apiUrl}/collegefacultybysubjectid/${selectedSubjectId.id}`)
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch subjects.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Subjects not found");
          }
        });
    }
  }, [selectedSubjectId]);

  const handleSubjectChange = (e) => {
    const selectedSubjectList = e.target.value;

    const selectedSubject = subjects.find((subject) =>
      subject.subjectList.some(
        (subjectItem) => subjectItem === selectedSubjectList
      )
    );

    if (selectedSubject) {
      setSelectedSubjectId(selectedSubjectList);
      setSelectedFacultyName(selectedSubject.facultyName);
      // setFormData((prevData) => ({
      //   ...prevData,
      //   subjectId: selectedSubject.subjectId,
      // }));

      console.log("Selected Subject:", selectedSubject);
      console.log("Selected Subject ID:", selectedSubject.subjectList[0].id);
      console.log("Selected Faculty Name:", selectedSubject.facultyNameList);
    } else {
      console.log("Subject not found");
    }
  };

  const handleTimeChange = (newTime) => {
    setFormData({
      ...formData,
      duration: newTime.format("HH:mm:ss"), // Assuming newTime is a valid time object
    });
  };

  const handleCreateButtonClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const [getSubjectId, setGetSubjectId] = useState("");

  console.log(getSubjectId, "wferrgwvbv");

  console.log(formData.subjectList.id === undefined, "evrvwve");

  const handleSemesterChange = (e) => {
    const selectedSemesterNumber = e.target.value;
  
    // Update the semester number in the form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      semesterNumber: selectedSemesterNumber,
    }));
  
    // Fetch sections based on the selected course ID, academic year, and semester number
    if (selectedCourseId && formData.academicYear && selectedSemesterNumber) {
      axios
        .get(
          `${apiUrl}/sectionbycourseandsemesterandacademicyear/${selectedCourseId}/${encodeURIComponent(
            selectedSemesterNumber
          )}/${formData.academicYear}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            const fetchedSectionsData = response.data.data;
  
            // Set sections data state with the fetched section data
            setSectionsData(fetchedSectionsData);
  
            // Set sections state with the section names
            const sectionNames = fetchedSectionsData.map(
              (section) => section.sectionName
            );
            setSections(sectionNames);
          } else {
            console.log("Failed to fetch sections.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Sections not found");
          }
        });
    }
  
    console.log("Selected Semester Number:", selectedSemesterNumber);
  };
  
  
    const handleSectionChange = (e) => {
      const selectedSectionName = e.target.value;
  
      const selectedSection = sectionsData.find(
        (section) => section.sectionName === selectedSectionName
      );
  
      if (selectedSection) {
        console.log("Selected Section ID:", selectedSection.sectionId);
  
        setFormData((prevFormData) => ({
          ...prevFormData,
          sectionId: selectedSection.sectionId,
        }));
        setSelectedSectionId(selectedSection.sectionId);
      }
    };
  
    useEffect(() => {
      if (selectedSectionId) {
        axios
          .get(`${apiUrl}/collegesubjectbysectionid/${selectedSectionId}`)
          .then((response) => {
            if (response.status === 200 && Array.isArray(response.data.data)) {
              setSubjects(response.data.data);
            } else {
              console.log("Failed to fetch sections.");
            }
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response.data.status === 404) {
              console.log("Section not found");
            }
          });
      }
    }, [selectedSectionId]);

    const handleAcademicYearChange = (e) => {
      const selectedAcademicYear = e.target.value;
  
      // setFormData((prevData) => ({
      //   ...prevData,
      //   academicYear: selectedAcademicYear,
      // }));
  
      // check if both courseId and selectedAcademicYear are available
      if (selectedCourseId !== null && selectedAcademicYear !== "") {
        const encodedAcademicYear = encodeURIComponent(selectedAcademicYear);
        axios
          .get(`${apiUrl}/semesterbycourseidandacademicyear/${selectedCourseId}/${encodedAcademicYear}`)
          .then((res) => {
            setItem(res.data.data);
            if (res.data.data.length > 0) {
              // Use a Set to remove duplicates and then convert it back to an array
              const uniqueSemesters = [...new Set(res.data.data.map((semester) => semester.semesterNumber))];
              setSemesters(uniqueSemesters);
            }
  
            console.log("API Response:", res.data);
            console.log("data", item);
          })
          .catch((err) => console.log(err));
      }
    };
  
    useEffect(() => {
      if (LMS_ROLE === "3" || LMS_ROLE === "5") {
        axios
          .get(
            `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
              "Unique_id"
            )}`
          )
          .then((response) => {
            if (response.status === 200 && Array.isArray(response.data.data)) {
              // Filter academic years with status equal to 1
              const filteredAcademicYears = response.data.data.filter(
                (year) => year.status === 1
              );
              setAcademicYear(filteredAcademicYears);
            } else {
              console.log("Failed to fetch courses.");
            }
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response.data.status === 404) {
              toast.info("Course name not found");
            }
          });
      } else if (LMS_ROLE === "4") {
        axios
          .get(
            `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          )
          .then((response) => {
            if (response.status === 200 && Array.isArray(response.data.data)) {
              // Filter academic years with status equal to 1
              const filteredAcademicYears = response.data.data.filter(
                (year) => year.status === 1
              );
              setAcademicYear(filteredAcademicYears);
            } else {
              console.log("Failed to fetch courses.");
            }
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response.data.status === 404) {
              toast.info("Course name not found");
            }
          });
      } else if (LMS_ROLE === "6") {
        axios
          .get(
            `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
              "branch_Unique_Id"
            )}`
          )
          .then((response) => {
            if (response.status === 200 && Array.isArray(response.data.data)) {
              // Filter academic years with status equal to 1
              const filteredAcademicYears = response.data.data.filter(
                (year) => year.status === 1
              );
              setAcademicYear(filteredAcademicYears);
            } else {
              console.log("Failed to fetch courses.");
            }
          })
          .catch((error) => {
            console.log(error.response.data);
            if (error.response.data.status === 404) {
              toast.info("Course name not found");
            }
          });
      }
    }, []);
  
    
  return (
    <div className="mb-4 flex justify-between items-center  top-0">
      <div className="w-full bg-white px-16 py-6  rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Course Name"
                  variant="outlined"
                  name="courseName"
                  fullWidth
                  select
                  value={formData.courseName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleCourseChange(e); // Call the course change handler
                  }}
                  required
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </TextField>
                

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formData.academicYear}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleAcademicYearChange(e); 
                  }}
                  // onChange={handleOnChange}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  select
                  label="Section Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="sectionName"
                  value={formData.sectionName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSectionChange(e);
                  }}
                >
                  {sections.flat().map((sectionName, id) => (
                    <MenuItem key={id} value={sectionName}>
                      {sectionName}
                    </MenuItem>
                  ))}
                </TextField>

                {/* <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Section ID"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="sectionId"
                  value={selectedSectionId}
                  InputProps={{
                    readOnly: true,
                  }}
                  // onChange={handleOnChange}
                />
                )} */}

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam ID"
                  variant="outlined"
                  name="examId"
                  fullWidth
                  value={formData.examId}
                  onChange={handleOnChange}
                  required
                />

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Subject ID"
                    variant="outlined"
                    fullWidth
                    name="subjectId"
                    type="text"
                    value={
                      selectedSubjectId !== ""
                        ? selectedSubjectId
                        : formData.subjectId
                    }
                    //onChange={handleSubjectChange}
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Subject ID"
                    variant="outlined"
                    fullWidth
                    name="subjectId"
                    type="text"
                    value={
                      formData.subjectList.id === undefined
                        ? ""
                        : formData.subjectList.id
                    }
                    //onChange={handleSubjectChange}
                    required
                  />
                )}
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  select
                  label="Assign Faculty"
                  variant="outlined"
                  fullWidth
                  name="assignFaculty"
                  value={formData.assignFaculty}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleFacultyChange(e); // Call the course change handler;
                  }}
                  required
                >
                  {faculties.map((faculty) =>
                    faculty.facultyName.map((facultyNameObj) => (
                      <MenuItem
                        key={facultyNameObj.name}
                        value={facultyNameObj.name}
                      >
                        {facultyNameObj.name}
                      </MenuItem>
                    ))
                  )}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Date of Examination"
                    format="DD/MM/YYYY hh:mm A"
                    variant="outlined"
                    type="date"
                    name="dateOfExamination"
                    fullWidth
              
                    onChange={handleDoeChange}

           
                  />
                </LocalizationProvider>
              </Stack>

              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Course ID"
                    variant="outlined"
                    fullWidth
                    name="courseId"
                    value={
                      selectedCourseId !== ""
                        ? selectedCourseId
                        : formData.courseId
                    }
                    // onChange={handleOnChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Course ID"
                    variant="outlined"
                    fullWidth
                    name="courseId"
                    value={selectedCourseId}
                    required
                  />
                )}

                {/* {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Section ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionId"
                    value={
                      selectedSectionId !== ""
                        ? selectedSectionId
                        : formData.sectionId
                    }
                    // onChange={handleOnChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : ( */}
                 <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  label="Semester Number"
                  variant="outlined"
                  fullWidth
                  select
                  name="semesterNumber"
                  type="text"
                  value={formData.semesterNumber}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSemesterChange(e);
                  }}
                  required
                >
                  {semesters.map((semester, index) => (
                    <MenuItem key={index} value={semester}>
                      {semester}
                    </MenuItem>
                  ))}
                </TextField>

                
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam Name"
                  variant="outlined"
                  name="examName"
                  fullWidth
                  value={formData.examName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Subject Name"
                  variant="outlined"
                  name="subjectList"
                  select
                  fullWidth
                  value={formData.subjectList || ""}
                  onChange={(e) => {
                    handleOnChange(e);
                    // if (e && e.target && e.target.value !== undefined) {
                    handleSubjectChange(e);
                    // }
                  }}
                  required
                >
                  {subjects.map((subject) =>
                    subject.subjectList.map((subjectItem) => (
                      <MenuItem key={subjectItem} value={subjectItem}>
                        {subjectItem.name}
                      </MenuItem>
                    ))
                  )}
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        sx={{
          backgroundColor: "#F5F5F5",
          color: "#105D50",
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#105D50",
          },
          "& .MuiInputBase-root": {
            height: 45,
            fontSize: "14px",
            width: 280,
          },
          "&:hover": {
            backgroundColor: "#EEEDED",
          },
        }}
        label="Duration HH:MM"
        variant="outlined"
        name="duration"
        fullWidth
        value={formData.duration}
        onChange={handleTimeChange}
        ampm={false}
        format="HH:mm:ss" // Include seconds in the format
        required
      />
    </LocalizationProvider>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Total Marks"
                  variant="outlined"
                  name="totalMarks"
                  type="number"
                  fullWidth
                  value={formData.totalMarks}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Pass Marks"
                  variant="outlined"
                  name="passMarks"
                  type="number"
                  fullWidth
                  value={formData.passMarks}
                  onChange={handleOnChange}
                  required
                />

                {/* <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                      
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize:"14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam Link"
                  variant="outlined"
                  fullWidth
                  //value={formData.description}
                  required
                /> */}
              </Stack>
            </Stack>
            <Stack>
              <RadioGroup
                row
                name="createExamOption"
                value={formData.createExamOption}
                onChange={handleOnChange}
              >
                <FormControlLabel
                  value="create"
                  control={
                    <Radio
                      style={{
                        fontSize: "10px",
                        color: "#105D50",
                        marginLeft: "14px",
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px", color: "#105D50" }}>
                      Create Exam
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="link"
                  control={
                    <Radio
                      style={{
                        fontSize: "10px",
                        color: "#105D50",
                        marginLeft: "14px",
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px", color: "#105D50" }}>
                      Exam Link
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="upload"
                  control={
                    <Radio
                      style={{
                        fontSize: "10px",
                        color: "#105D50",
                        marginLeft: "14px",
                      }}
                    />
                  }
                  label={
                    <Typography style={{ fontSize: "14px", color: "#105D50" }}>
                      File Upload
                    </Typography>
                  }
                />
              </RadioGroup>
              {formData.createExamOption === "create" && (
                <div className="flex flex-row justify-center ml-1 mt-2">
                  <button
                    type="button"
                    className="w-28 text-[12px] font-normal   h-10 px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                    onClick={handleCreateButtonClick}
                  >
                    Create
                  </button>
                </div>
              )}

              <Modal
                title="Create Exam" // Set your modal title
                visible={isModalVisible}
                onCancel={handleCloseModal}
                width={900}
                footer={null} // Set to null if you don't want a footer
              >
                <Questions
                  examId={formData.examId}
                  sectionId={formData.sectionId}
                  subjectId={formData.subjectList.id}
                  subjectName={formData.subjectList.name}
                  facultyName={formData.assignFaculty}
                  totalMarks={formData.totalMarks}
                  facultyId={selectedFacultyId}
                  onQuestionSubmit={handleSubmit}
                  onCancel={handleCloseModal}
                />
              </Modal>
              {formData.createExamOption === "link" && (
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Exam Link"
                  variant="outlined"
                  fullWidth
                  name="examLink"
                  value={formData.examLink}
                  onChange={handleOnChange}
                  required
                />
              )}

              {formData.createExamOption === "upload" && (
                <Button
                  sx={{
                    height: 45,
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    borderColor: "#105D50",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                      borderColor: "#105D50",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  component="label"
                  name="uploadFile"
                >
                  {formData.uploadedFileName ? (
                    <p>{formData.uploadedFileName}</p>
                  ) : (
                    <p>Upload File </p>
                  )}

                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e)}
                    required
                  />
                </Button>
              )}
            </Stack>

            <Grid item xs={10}>
              <div className="flex  justify-center pt-6 pb-4  space-x-8">
                <button
                  type="button"
                  onClick={handleReset}
                  className="w-28 text-[12px] font-normal h-10 px-4 py-2   bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="w-28 text-[12px] font-normal h-10 px-4 py-2  bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className="w-34 text-[12px] font-normal h-10 px-4 py-2   bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Submit & Add
                </button>
              </div>
            </Grid>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default ExamForm;
