import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { AiOutlineSearch } from 'react-icons/ai';
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Modal, Row, Col, Typography, Divider,message } from 'antd';
import axios from "axios";
import { MdDelete } from "react-icons/md";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";
 

function Announcement() {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");
  const PERMISSION_EDIT = localStorage.getItem("permission_edit");
  const PERMISSION_DELETE = localStorage.getItem("permission_delete");
  const history = useNavigate();
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Text, Title, Paragraph } = Typography;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [announcementId, setAnnouncementId] = useState([])

  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { confirm } = Modal;

  const showDeleteConfirm = (id) => {
    Modal.confirm({
      title: 'Are you sure to delete this data?',
      icon: null,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteAnnouncement(id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteAnnouncement = (id) => {
    axios.delete(`${apiUrl}/collegeannouncement/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setFilteredData((prevData) =>
            prevData.filter((item) => item._id !== id)
          );
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
      })
      .catch((error) => {
        console.error('Error deleting announcement:', error);
        message.error('An error occurred while deleting the announcement.');
      });
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[80px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      //sortable: true,
      width: '80px',
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Announcement Title</h1>,
      selector: (row) => row.announcementTitle,
      sortable: true,

    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Announcement Date</h1>,
      selector: (row) => row.announcementDate,
      sortable: true,

    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Expiration  Date</h1>,
      selector: (row) => row.expirationDate,
      sortable: true,

    },
    {
      name: <h1 className="text-white pl-4 w-[250px]">Announcement Subject</h1>,
      selector: (row) => row.announcementSubject,
      sortable: true,

    },
    {
      name: <h1 className="text-white pl-4 w-[250px]">Announcement Category</h1>,
      selector: (row) => row.category,
      sortable: true,

    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Contact Number</h1>,
      selector: (row) => row.contactNumber,
      sortable: true,

    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Mail ID</h1>,
      selector: (row) => row.contactEmailId,
      sortable: true,

    },


    {
      name: <h1 className="text-white pl-4 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            {LMS_ROLE === "3" || LMS_ROLE === "5" ? (
              <>
                {" "}
                <FaEye
  className="w-6 h-6 text-gray-600 cursor-pointer"
  onClick={() => showPreviewModal(row)}
  data-category={row.category}
/>
                {/* <Link to={`/homepage/announcement/${row._id}`}>
                  <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                </Link> */}
                <MdDelete
                  className="w-6 h-6 text-red-500 cursor-pointer"
                  onClick={() => showDeleteConfirm(row._id)}
                />
              </>
            ) : (
              <>
                {" "}
                <FaEye
  className="w-6 h-6 text-gray-600 cursor-pointer"
  onClick={() => showPreviewModal(row)}
  data-category={row.category} // Add custom attribute for category
/>

                {PERMISSION_EDIT === "true" && (
                  <Link to={`/homepage/announcement/${row._id}`}>
                    <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                  </Link>
                )}
                {PERMISSION_DELETE === "true" && (
                  <MdDelete
                    className="w-6 h-6 text-red-500 cursor-pointer"
                    onClick={() => showDeleteConfirm(row._id)}
                  />
                )}
              </>
            )}
          </div>
        </>
      ),
      // width:"100px"
    },

  ];



  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.announcementSubject.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegeannouncementbyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info(error.response.data.message);
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegeannouncementbyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info(error.response.data.message);
          }
        });
    } else {
      axios
        .get(
          `${apiUrl}/collegeannouncementbyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info(error.response.data.message);
          }
        });
    }
  }, []);


  const toggleFormView = () => {
    history('/homepage/announcement/add announcement');
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',      
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const showPreviewModal = (data) => {
    const category = data.category.toLowerCase(); // Ensure category comparison is case-insensitive
    switch (category) {
      case 'event':
        const announcementId = data.announcementUniqueId; // Assuming _id is the unique identifier
        history(`/homepage/Announcement/${announcementId}`);
        break;
      case 'information':
      case 'circular':
        setPreviewData(data);
        setIsPreviewModalVisible(true);
        break;
      default:
        console.log("Category not supported for preview.");
    }
  };

  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };


  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <div className="W-full h-fit bg-white flex flex-col items-center gap-3 rounded-md pt-5  px-1 drop-shadow border">
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
        </div>
        <button
          className="flex flex-row py-3 px-4 text-[12px]  bg-primary text-white rounded-lg hover:bg-secondary  hover:text-green items-center gap-1 "
          onClick={toggleFormView}
        >
          <FaPlus />
          Add Announcement
        </button>
      </div>
      {/* Use the DataTable component */}
      <div className="flex flex-col w-full">
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          onChangePage={handlePageChange}
          />
      </div>
  
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">
       
      <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                  <Col span={2.1}>
                  <img src={preview1} className="flex justify-start" ></img>
                  </Col>
                
    
                    <Col span={6} className="flex flex-col justify-center ">
                    <div className="sm:w-96 w-60 pt-4 pb-1 px-2 bg-primary rounded-full flex flex-col justify-center items-center">
                    <Title level={3} style={{fontSize,color:"white"}} className="capitalize">
                       Announcements
                    </Title>
                    </div>
                    </Col>                                
         </Row>
      <div className="sm:ml-20 my-6 space-y-2">
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Date:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.announcementDate}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Subject</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.announcementSubject}</Text>
        </Col>
      </Row>

      {/* <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Priority:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.priority}</Text>
        </Col>
      </Row> */}
      </div>

      
      <Row className="flex justify-end -mt-10" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
      </Row>
    </div>
      )}
    </Modal>
    </div>
    
  );
}

export default Announcement;
