import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";
import { Modal, Row, Col, Typography } from 'antd';
import axios from "axios";
import { FaEye } from "react-icons/fa";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from '@mui/material/MenuItem';
import TextField from "@mui/material/TextField";
import { DatePicker as AntDatePicker } from 'antd';
import { Box, IconButton, Stack } from "@mui/material";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";
import DatePicker from "react-datepicker";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

function StudentsAttendance({ students }) {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const history = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isStudent, setIsStudent] = useState(false);
  const [item, setItem] = useState([]);
  const [courses, setCourses] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  const [selectedSemesterNumber, setSelectedSemesterNumber] = useState("");
  const [getSectionName, setGetSectionName] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [academicYear,setAcademicYear]=useState();
    const [formData, setFormData] = useState({
    semesterNumber: "",
    sectionId: "",
    courseId: "",
  });
  const { RangePicker } = AntDatePicker;
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Text, Title} = Typography;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onStartDateChange = (date, dateString) => {
    setStartDate(date);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(date);
  };


  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [formAcademicData, setFormAcademicData] = useState({
   academicYear:"",
  });

  const handleAcademicChange = (e) => {
    setFormAcademicData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  //get academic year
    useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
           console.log("Course name not found");
          }
        });
    }
  }, []);

  

//get course
  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Course name not found");
          }
        });
    }
  }, []);


  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    // Find the course object in the courses array that matches the selected course name
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse.courseId);
      setSelectedSemesterNumber("");
      
    }
  };

  const handleSemesterChange = (e) => {
    const selectedSemesterNumber = e.target.value;
  
    // Update the semester number in the form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      semesterNumber: selectedSemesterNumber,
    }));
  
    // Fetch sections based on the selected course ID, academic year, and semester number
    if (selectedCourseId && formData.academicYear && selectedSemesterNumber) {
      axios
        .get(
          `${apiUrl}/sectionbycourseandsemesterandacademicyear/${selectedCourseId}/${encodeURIComponent(
            selectedSemesterNumber
          )}/${formData.academicYear}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            const fetchedSectionsData = response.data.data;
  
            // Set sections data state with the fetched section data
            setSectionsData(fetchedSectionsData);
  
            // Set sections state with the section names
            const sectionNames = fetchedSectionsData.map(
              (section) => section.sectionName
            );
            setSections(sectionNames);
          } else {
            console.log("Failed to fetch sections.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Sections not found");
          }
        });
    }
  
    console.log("Selected Semester Number:", selectedSemesterNumber);
  };
  const handleAcademicYearChange = (e) => {
    const selectedAcademicYear = e.target.value;

    // setFormData((prevData) => ({
    //   ...prevData,
    //   academicYear: selectedAcademicYear,
    // }));

    // check if both courseId and selectedAcademicYear are available
    if (selectedCourseId !== null && selectedAcademicYear !== "") {
      const encodedAcademicYear = encodeURIComponent(selectedAcademicYear);
      axios
        .get(`${apiUrl}/semesterbycourseidandacademicyear/${selectedCourseId}/${encodedAcademicYear}`)
        .then((res) => {
          setItem(res.data.data);
          if (res.data.data.length > 0) {
            // Use a Set to remove duplicates and then convert it back to an array
            const uniqueSemesters = [...new Set(res.data.data.map((semester) => semester.semesterNumber))];
            setSemesters(uniqueSemesters);
          }

          console.log("API Response:", res.data);
          console.log("data", item);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSectionChange = (e) => {
    const selectedSectionName = e.target.value;

    const selectedSection = sectionsData.find(
      (section) => section.sectionName === selectedSectionName
    );

    if (selectedSection) {
      console.log("Selected Section ID:", selectedSection.sectionId);

      setFormData((prevFormData) => ({
        ...prevFormData,
        sectionId: selectedSection.sectionId,
      }));
      setSelectedSectionId(selectedSection.sectionId);
    }
  };



  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[150px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Student Name</h1>,
      selector: (row) => row.studentName,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Student ID</h1>,
      selector: (row) => row.studentId,
      sortable: true,
      // width: "200px",
    },

    // {
    //   name: "Mobile Number",
    //   selector: "mobileNumber",
    //   sortable: true,
    //   width: "200px",
    // },

    {
      name: <h1 className="text-white pl-3 w-[250px]">Total Working Days</h1>,
      selector: (row) => row.totalWorkingDays,
      sortable: true,
      // width: "200px",
    },

    {
      name:<h1 className="text-white pl-4 w-[150px]">No.of Leaves</h1>,
      selector: (row) => row.leaveTaken,
      sortable: true,
      width: "200px",
    },
    {
      name:<h1 className="text-white pl-4 w-[150px]">No.of OD's</h1>,
      selector: (row) => row.odCount,
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.odCount ? `${row.odCount}` : "0%"),
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">No.of Presents</h1>,
      selector:(row) => row.presentCount,
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.presentPercentage ? `${row.presentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name:<h1 className="text-white pl-4 w-[150px]">No.of Absents</h1>,
      selector: (row) => row.absentCount,
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.absentPercentage ? `${row.absentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name:<h1 className="text-white pl-4 w-[250px]">Attendance Percentage</h1>,
      selector: (row) => (row.percentageAttendance ? `${Math.round(row.percentageAttendance)}%` : "0"),
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.percentageAttendance ? `${row.percentageAttendance}%` : "0"),
    },

    {
      name: <h1 className="text-white pl-4 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)}/>
          </div>
        </>
      ),
      // width:"100px"
    },
  ];

  const facultycolumns = [
    {
      name:<h1 className="text-white pl-3 w-[150px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name:  <h1 className="text-white pl-4 w-[150px]">Faculty Name</h1>,
      selector: (row) => row.facultyName,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">Faculty ID</h1>,
      selector: (row) => row.facultyId,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[250px]">Total Working Days</h1>,
      selector: "totalWorkingDays",
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-4 w-[150px]">No.of Leaves</h1>,
      selector: "leaveTaken",
      sortable: true,
      // width: "200px",
    },
    {
      name:  <h1 className="text-white pl-4 w-[150px]">No.of OD's</h1>,
      selector: "odCount",
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.odCount ? `${row.odCount}` : "0%"),
    },
    {
      name:  <h1 className="text-white pl-4 w-[150px]">No.of Presents</h1>,
      selector: "presentCount",
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.presentPercentage ? `${row.presentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">No.of Absents</h1>,
      selector: "absentCount",
      sortable: true,
      // width: "200px",
     // cell: (row) => (row.absentPercentage ? `${row.absentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name:  <h1 className="text-white pl-4 w-[250px]">Attendance Percentage</h1>,
      selector: "percentageAttendance",
      sortable: true,
      // width: "200px",
      cell: (row) => (row.percentageAttendance ? `${Math.round(row.percentageAttendance)}%` : "0"),
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)}/>
          </div>
        </>
      ),
      // width:"100px"
    },
  ];
  
  useEffect(() => {
    if (Array.isArray(item)) {
      const result = item?.filter((value) => {
        // Check if facultyName is defined before trying to access its properties
        return (
          value?.facultyName &&
          value.facultyName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }) ?? [];
      setFilteredData(result);
    }
  }, [item, searchTerm]);

  const handleToggle = () => {
    setIsStudent((prevIsStudent) => !prevIsStudent);
  };

  useEffect(() => {
    // Fetch student attendance data
    const fetchStudentAttendanceData = async () => {
      try {
        const endpoint = `${apiUrl}/collegestudentattendance/${localStorage.getItem("Unique_id")}`;
        const response = await axios.get(endpoint);

        if (response && response.data) {
          const studentAttendanceData = response.data;

          // Assuming studentAttendanceData is an array of objects with appropriate properties
          const mappedStudentData = Array.isArray(studentAttendanceData)
            ? studentAttendanceData.map((item) => ({
              studentName: item.studentName,
              studentId: item.studentId,
              mobileNumber: item.mobileNumber,
              workingDays: item.workingDays,
              leaveTaken: item.leaveTaken,
            }))
            : [];

          // Update the local state with the student attendance data
          setData(mappedStudentData);
        } else {
          console.error("Invalid response format:", response);
          // Handle the error accordingly
        }
      } catch (error) {
        console.log(error.response?.data);
        if (error.response?.data?.status === 404) {
          console.log(error.response?.data);
        }
        throw error;
      }
    };

    // Fetch faculty attendance data
    const fetchFacultyAttendanceData = async () => {
      try {
        if (LMS_ROLE == "3" || LMS_ROLE == "5"){
        const endpoint = `${apiUrl}/facultiesattendancebyuniqueid/${localStorage.getItem("Unique_id")}`;
        const response = await axios.get(endpoint);


        if (response && response.data) {
          const facultyAttendanceData = response.data.data;
          setFilteredData(facultyAttendanceData);
        } else {
          console.error("Invalid response format:", response);
          // Handle the error accordingly
        }
      }
      else if(LMS_ROLE == "4" ){
        const endpoint = `${apiUrl}/facultiesattendancebyinstituteuniqueid/${localStorage.getItem("institute_Unique_Id")}`;
        const response = await axios.get(endpoint);


        if (response && response.data) {
          const facultyAttendanceData = response.data.data;
          setFilteredData(facultyAttendanceData);
        } else {
          console.error("Invalid response format:", response);
          // Handle the error accordingly
        }
      }
      else
      {
        const endpoint = `${apiUrl}/facultiesattendancebyinstituteuniqueid/${localStorage.getItem("branch_Unique_Id")}`;
        const response = await axios.get(endpoint);


        if (response && response.data) {
          const facultyAttendanceData = response.data.data;
          setFilteredData(facultyAttendanceData);
        } else {
          console.error("Invalid response format:", response);
          // Handle the error accordingly
        }
      
      }
      } catch (error) {
        console.log(error.response?.data);
        if (error.response?.data?.status === 404) {
          // Handle the error accordingly
        }
        throw error;
      }
    };

    // Call the appropriate fetch function based on the toggle state
    if (isStudent) {
      fetchStudentAttendanceData();
    } else {
      fetchFacultyAttendanceData();
    }
  }, [isStudent]);

  console.log(isStudent);
  console.log("Data for student:", data);
  console.log("Data for faculty:", filteredData);
  
  const handleMenuOpen = (event, branchId) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(branchId);
  };

  const toggleFormView = () => {
    history("/homepage/attendance/today attendace");
    setIsStudent((prevIsStudent) => !prevIsStudent);
  };

  useEffect(() => {
    // Fetch faculty attendance data
    const fetchFacultyAttendanceData = async () => {
      try {
        if (!startDate || !endDate || !formAcademicData.academicYear) 
        {
          return;
        }

        // Format the dates to match your backend expectations
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        const endpoint = `${apiUrl}/facultyUpdatedAttendance/${localStorage.getItem("Unique_id")}/${formattedStartDate}/${formattedEndDate}/${formAcademicData.academicYear}`;
        const response = await axios.get(endpoint);

        if (response && response.data) {
          const facultyAttendanceData = response.data.data;
          setFilteredData(facultyAttendanceData);
        } else {
          console.error("Invalid response format:", response);

        }
      } catch (error) {
        console.log(error.response?.data);
        if (error.response?.data?.status === 404) {
          console.error("Response data:", error.response.data);
        }
        throw error;
      }
    };

    // Call the fetch function only when both start date and end date are selected
    if (startDate && endDate && formAcademicData.academicYear) {
      fetchFacultyAttendanceData();
    }
  }, [startDate, endDate, formAcademicData.academicYear]);


  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const switchStyle = {
    borderRadius: 2,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#105D50",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#105D50",
    },
  };
  useEffect(() => {
    const fetchStudentAttendanceData = async () => {
      try {
        if (LMS_ROLE == "3" || LMS_ROLE == "5")
        {
        const endpoint = `${apiUrl}/collegestudentbyinstituteuniqueid/${localStorage.getItem("Unique_id")}`;
        const response = await axios.get(endpoint);
      
        if (response && response.data) {
          const fetchStudentAttendanceData = response.data.data;
          setData(fetchStudentAttendanceData);
        } else {
          console.error("Invalid response format:", response);
        }}
        else if(LMS_ROLE == "4")
        {
          const endpoint = `${apiUrl}/collegestudentbyinstituteuniqueid/${localStorage.getItem("institute_Unique_Id")}`;
          const response = await axios.get(endpoint);
        
          if (response && response.data) {
            const fetchStudentAttendanceData = response.data.data;
            setData(fetchStudentAttendanceData);
          } else {
            console.error("Invalid response format:", response);
          }}
          else
          {
            const endpoint = `${apiUrl}/collegestudentbyinstituteuniqueid/${localStorage.getItem("branch_Unique_Id")}`;
            const response = await axios.get(endpoint);
          
            if (response && response.data) {
              const fetchStudentAttendanceData = response.data.data;
              setData(fetchStudentAttendanceData);
            } else {
              console.error("Invalid response format:", response);
            }}
      } catch (error) {
        console.log(error.response?.data);
        if (error.response?.data?.status === 404) {
          // Handle the error accordingly
        }
      }
    };
    if (fetchStudentAttendanceData.length === 0) {
      //toast.info('No attendance data found.');
      console.log("no data found.")
    }
    fetchStudentAttendanceData(); // Fetch data on component mount
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      // Fetch faculty attendance data
      const fetchStudentAttendanceData = async () => {
        try {
          if (!startDate || !endDate) {

            return;
          }

          // Format the dates to match your backend expectations
          const formattedStartDate = startDate.toISOString();
          const formattedEndDate = endDate.toISOString();

          const endpoint = `${apiUrl}/studentUpdatedAttendance/${selectedSectionId}/${localStorage.getItem("Unique_id")}/${formattedStartDate}/${formattedEndDate}/${academicYear}`;
          const response = await axios.get(endpoint);

          if (response && response.data) {
            const studentAttendanceData = response.data.data;
            setData(studentAttendanceData);
          } else {
            console.error("Invalid response format:", response);

          }

        } catch (error) {
          console.log(error.response?.data);
          if (error.response?.data?.status === 404) {
            console.error("Response data:", error.response.data);
          }
          throw error;
        }
      };

      // Call the fetch function only when both start date and end date are selected
      if (startDate && endDate) {
        fetchStudentAttendanceData();
      }
    }
  }, [startDate, endDate]);

  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };



  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [startAcademicDate, setStartAcademicDate] = useState(new Date("2014/02/08"));
  const [endAcademicDate, setEndAcademicDate] = useState(null);

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartAcademicDate(newStartDate);
    setEndAcademicDate(newEndDate);
  };

  return (
    <div>
     
    <div className="W-full h-fit bg-white flex flex-col  gap-3 rounded-md pt-5  px-1 drop-shadow border">
    
      {!isStudent ? (
        <div className="w-full h-fit flex flex-col py-12">
          <form
            // onSubmit={handleSubmit}
            className="flex flex-row justify-center items-center gap-4"
          >
            <Stack
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  // Reduce label font size
                  fontSize: "13px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50", // Set the default outline color to black
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                    // Change the border color on hover
                  },

                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px", // Set the outline color to yellow on focus
                  },
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width={"820px"}
                spacing={2}
              >
                {/* <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              > */}
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 40,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Course Name"
                  variant="outlined"
                  name="courseName"
                  fullWidth
                  select
                  value={formData.courseName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleCourseChange(e); // Call the course change handler
                  }}
                  required
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 40,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formData.academicYear}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleAcademicYearChange(e); // Call the course change handler
                  }}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 40,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    // width: "120%"
                  }}

                  label="Semester Number"
                  variant="outlined"
                  fullWidth
                  select
                  name="semesterNumber"
                  type="text"
                  value={formData.semesterNumber}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSemesterChange(e); // Call the course change handler
                  }}
                  required
                >
                  {semesters.map((semester, index) => (
                    <MenuItem key={index} value={semester}>
                      {semester}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 40,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%"
                  }}
                  select
                  label="Section Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="sectionName"
                  value={formData.sectionName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleSectionChange(e);
                  }}
                >
                  {sections.flat().map((sectionName, id) => (
                    <MenuItem key={id} value={sectionName}>
                      {sectionName}
                    </MenuItem>
                  ))}
  
                </TextField>

                {/* </Stack> */}
                {/* <button
              type="submit"
              className="py-1 px-5 text-[14px] font-sm rounded-md bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300"
            >
              Submit
            </button> */}
                {/* <label className="label flex flex-row items-center">Date</label> */}
                <RangePicker
                  value={[startDate, endDate]}
                  onChange={(dates) => {
                    if (dates && dates.length === 2) {
                      onStartDateChange(dates[0]);
                      onEndDateChange(dates[1]);
                    }
                  }}
                  allowClear={true}
                  placeholder={['Start Date', 'End Date']}
                  format="DD/MM/YYYY"
                  className="custom-range-picker"
                  style={{ border: "1.2px solid #105d50", height: "40px", width: "880px", color: "#105D50" }}
                />
              </Stack>
            </Stack>
          </form>
        </div>

      ) : null}
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
      <div className="w-fit flex items-end">
          <label className=" cursor-pointer flex flex-row items-center gap-2">
            <span className="">Student</span>
            <input
              type="checkbox"
              className="toggle border-[#105D50] bg-primary w-15 h-5"
              onClick={handleToggle}
            />
            <span className="">Faculty</span>
          </label>
        </div>
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
            rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
        </div>
        {isStudent ? (
          <>
            <div className="flex flex-row gap-4">
            <button
               className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
                onClick={toggleFormView}
              >
                Todays Attendance
              </button>


            </div>
          </>
        ) : null}
      </div>
      {/* Use the DataTable component */}
      <div className="flex flex-col w-full">
        {!isStudent ? (
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="250px"
            columns={columns}
            data={data}
            selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover
            pointerOnHover

          />
        ) : (
          <>
            <div className="flex flex-row gap-4 mt-4 mb-4 justify-start" style={{ zIndex: 2 }}>
            <div className="flex items-center relative gap-2" style={{ zIndex: 2 }}>
        {/* Academic Year
      <DatePicker
      selected={startAcademicDate}
      onChange={handleChange}
      selectsRange
      startDate={startAcademicDate}
      endDate={endAcademicDate}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      className="p-3 w-[150px] text-[14px] bg-gray-100 text-gray-700
      focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
    rounded-md "

    /> */}
    <Stack
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  // Reduce label font size
                  fontSize: "13px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50", // Set the default outline color to black
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                    // Change the border color on hover
                  },

                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px", // Set the outline color to yellow on focus
                  },
                },
              }}
            >
    <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width={"250px"}
                spacing={2}
              >
    <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 40,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formAcademicData.academicYear}
                  onChange={(e) => {
                    handleAcademicChange(e);
                    handleAcademicYearChange(e); // Call the course change handler
                  }}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                </Stack>
                </Stack>
    </div>
              <label className="label flex flex-row items-center gap-2">Date</label>
              <RangePicker
                value={[startDate, endDate]}
                onChange={(dates,dateString) => {
                  if (dates && dates.length === 2) {
                    onStartDateChange(dates[0]);
                    onEndDateChange(dates[1]);
                  }
                }}
                placeholder={['Start Date', 'End Date']}
                format="DD/MM/YYYY"
                className="custom-range-picker"
              />
            </div>
            <DataTable
              fixedHeader
              fixedHeaderScrollHeight="250px"
              columns={facultycolumns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover

            />
          </>
        )}

      </div>
      {!isStudent ? ( 
      <>
      <div className='flex justify-end ml-14'>
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">

      <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                <Col span={2.1}>
                 <img src={preview1} className="flex justify-start" ></img>
                </Col>
               <div className="flex">
               <Col span={4.5} className="z-50 flex flex-col ">
                  {previewData.uploadStudentProfile && (
                    <img
                      src={`${ImageUrl}/CollegeStudentUploads/${previewData.uploadStudentProfile}`}
                      alt="Profile"
                      className="rounded-full w-20 h-20 border-white border-[3px] drop-shadow-xl"
                    />
                  )}
                </Col>
                <Col span={12} className="flex flex-col justify-center mt-2">
                  <Title level={1} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1 ">
                    {previewData.studentName.toUpperCase()}
                  </Title>
                </Col>
               </div>
              </Row>
      <div className="sm:ml-20 my-6 space-y-2">
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">student ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.studentId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Total Working Days:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.totalWorkingDays}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No.of OD's:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.odCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No. of Presents:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.presentCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No. of Absents:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.absentCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Percentage:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.percentageAttendance}%</Text>
        </Col>
      </Row>
      </div>
     
      <Row className="flex justify-end -mt-20" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
              </Row>
    </div>
  )}
</Modal>
 </div>
</>):(<> <div className='flex justify-end ml-14'>
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">
       

      <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                <Col span={2.1}>
                 <img src={preview1} className="flex justify-start" ></img>
                </Col>
               <div className="flex">
               <Col span={4.5} className="z-50 flex flex-col ">
               {previewData.uploadProfile && (
                              <img
                              src={`${ImageUrl}/CollegeFacultyUploads/${previewData.uploadProfile}`}
                              alt="Profile"
                              className="rounded-full w-20 h-20 bg-white border-primary border-[3px] drop-shadow-xl"
                              />
                          )}
                </Col>
                <Col span={12} className="flex flex-col justify-center mt-2">
                  <Title level={1} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1">
                  {previewData.facultyName.toUpperCase()}
                  </Title>
                </Col>
               </div>
              </Row>

     <div className="sm:ml-20 my-6 space-y-2">
     <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Faculty ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.facultyId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Total Working Days</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.totalWorkingDays}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No.of OD's:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.odCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No. of Presents:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.presentCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">No. of Absents:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.absentCount}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Percentage:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.percentageAttendance}%</Text>
        </Col>
      </Row>  
     </div>
     <Row className="flex justify-end -mt-20" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
              </Row>
    </div>
  )}
</Modal>
</div>
</>)}
    </div></div>
  );
}

export default StudentsAttendance;
