import React, { useEffect, useState } from "react";
import profileImage from "../Images/Profile.png";
import { useParams } from "react-router-dom";
import { Select } from "antd";
const LMS_ROLE = localStorage.getItem("lms_role");
const uniqueId = localStorage.getItem("batchId");

const FileManagement = () => {
  // const [announcements, setAnnouncements] = useState([]);
  // const id = useParams();
  // const apiUrl = process.env.REACT_APP_API_URL;
  // useEffect(() => {
  //   // Fetch announcements from the server and update the announcements state
  //   // Example using fetch:
  //   fetch(`${apiUrl}/announcementbybatchid/${id}`) // Replace with the actual API endpoint
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (Array.isArray(data.data)) {
  //         setAnnouncements(data.data);
  //       }
  //     })
  //     .catch((error) => console.error(error));
  // }, [id]);
  // console.log(`${apiUrl}/announcementbybatchid/${id}`);

  // {announcements.map((announcement, index) => (
  //   <div key={index} className="announcement-box bg-[#b7ceca] border border-gray-300 p-4 mb-4 rounded-lg relative">
  //     <div className="announcement-content text-gray-700 mb-4 font-normal text-[14px]">
  //       {announcement.description} {/* Use the appropriate field for the announcement content */}
  //     </div>
  //     <hr />
  //     <div className="relative flex items-center rounded-md top-3">
  //       <img
  //         src={profileImage}
  //         alt="Profile"
  //         className="w-10 h-10 rounded-full cursor-pointer"
  //       />
  //       <span className="user-name font-normal text-[12px] ml-2">{announcement.userName}</span>
  //     </div>
  //   </div>
  // ))}
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">File Mangement</h1>
   
    </div>
  );
};

export default FileManagement;
