import "./App.css";
import { Form, Route, Routes, Switch } from "react-router-dom";
import Login from "./components/Login";
import Homepage from "./components/Homepage";
import Home from "./Menus/Home";
import ELibraryForm from "./Forms/ELibraryForm";
import Branch from "./Menus/Branch";
import Announcement from "./Menus/Announcement";
import Course from "./Menus/Course";
import Faculty from "./Menus/Faculty";
import Student from "./Menus/Student";
import Result from "./Menus/Result";
import StudentsAttendance from "./Menus/Attendance";
import InstituteAdmin from "./Menus/InstituteAdmin";
import SupportForm from "./Menus/SupportForm";
import BranchForm from "./Forms/BranchForm";
import FacultyForm from "./Forms/FacultyForm";

import StudentForm from "./Forms/StudentForm";
import CourseForm from "./Forms/CourseForm";
import ResultForm from "./Forms/ResultForm";
import AnnouncementForm from "./Forms/AnnouncementForm";
import ExamForm from "./Forms/ExamForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from "./SubComponents/PrivateRoutes";
import InstituteAdminForm from "./Forms/InstituteAdminForm";
import TodayAttendance from "./Menus/TodayAttendance";
import BranchAdminForm from "./Forms/BranchAdminForm";
import BranchAdmin from "./Menus/BranchAdmin";


import FacultyStudents from "./Menus/MyBatchComponents/Students";


import FacultyExamForm from "./Menus/MyBatchComponents/ExamForm";
import FacultyResult from "./Menus/MyBatchComponents/Result";

import FileManagement from "./Menus/FileManagement";

import SubjectContent from "./Menus/StudentComponents/Subjects";

import E_Book from "./Menus/StudentComponents/E_Book";

import StudentResult from "./Menus/StudentComponents/Result";
import SupportTable from "./Menus/SupportTable";
import ELibrary from "./Menus/ELibrary";

import SubjectForm from "./Forms/SubjectForm";
import Subject from "./Menus/Subject";
import Section from "./Menus/Section";
import SectionForm from "./Forms/SectionForm";

import MyClassroom from "./Menus/MyBatchComponents/MyClassroom";
import FacultyExam from "./Menus/MyBatchComponents/Exam";

import Exams from "./Menus/Exam";
import MyCourse from "./Menus/StudentComponents/MyCourse";
import Question from "./Menus/StudentComponents/Question";
import Classroom from "./Menus/MyBatchComponents/classroom";

import QuestionView from "./Menus/StudentComponents/QuestionsView";
import ExamWarningPage from "./Menus/StudentComponents/ExamWarning";
import SectionTopic from "./Menus/MyBatchComponents/AddSubject";
import CollegeContent from "./Menus/MyBatchComponents/Content";
import StudentTodayAttendance from "./Menus/MyBatchComponents/StudentTodayAttenance";
import AnsweredCorrection from "./Menus/MyBatchComponents/AnswerCorrection";
import CollegeStudentContent from "./Menus/StudentComponents/Subjects";
import StudentAttendance from "./Menus/MyBatchComponents/StudentAttendance";
import FacultyMyclassExam from "./Menus/MyBatchComponents/MyclassExam";
import Descriptive from "./Menus/StudentComponents/Descriptive";
import ExamView from "./Menus/MyBatchComponents/ExamView";
import FacultyMyResult from "./Menus/MyBatchComponents/FacultyMyResult";
import FacultyMyResultView from "./Menus/MyBatchComponents/FacultyMyResultView";
import Timetable from "./components/Timetable";
import AnnouncementUI from "./Home/AnnouncementUI";
import TimeTableUi from "./Home/TimeTableUi";
import Profile from "./Home/Profile";
import CircleProgressBar from "./Home/ProgressBar";
import StudentHomePage from "./Home/StudentHomePage";
import FacultyHomePage from "./Home/FacultyHomePage";
import InstituteHomepage from "./Home/InstituteHomePage";
import SuperAdminHomePage from "./Home/SuperAdminHomePage";
import Timetableall from "./Home/Timetableforall";
import FacultyAnnouncementForm from "./Menus/MyBatchComponents/AnnouncementForm";
import FacultyAnnouncement from "./Menus/MyBatchComponents/Announcement";
import AnnouncementEvent from "./Menus/AnnouncementEvent";
import FacultyAnnouncementEvent from "./Menus/MyBatchComponents/FacultyAnnouncementEvents";
import ChatList1 from "./Chat/ChatList1";
import CreateMeet from "./L_Meet/CreateMeet";
import AcademicYear from "./Forms/AcademicYear";
import AcademicTable from "./Menus/Academictable";
import MeetTable from "./L_Meet/MeetTable";
import ShiftSemester from "./Menus/MyBatchComponents/ShiftSemester";
import Video from "./L_Meet/Meet";
import Virtual from "./Forms/Virtual";
import ForgotPassword from "./SubComponents/forgotpassword";


function App() {
  return (
    <div className="h-full w-full bg-white">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotPassword" element={<ForgotPassword/>}/>
        <Route element={<PrivateRoutes />}>
          <Route path="/homepage" element={<Homepage />}>
            {/* superadmin and admin menus */}
            <Route path="/homepage/home" element={<Home />} />

            <Route path="/homepage/e-library" element={<ELibrary />} />
            <Route
              path="/homepage/e-Library/add resource"
              element={<ELibraryForm />}
            />
           <Route path="/homepage/e-Library/:id" element={<ELibraryForm />} />

            {/* institute route */}
            <Route path="/homepage/branch" element={<Branch />} />
            <Route
              path="/homepage/branch/add branch"
              element={<BranchForm />}
            />
            <Route path="/homepage/branch/:id" element={<BranchForm />} />
            <Route path="/homepage/faculty" element={<Faculty />} />
            <Route
              path="/homepage/faculty/add faculty"
              element={<FacultyForm />}
            />
            <Route path="/homepage/faculty/:id" element={<FacultyForm />} />
            <Route path="/homepage/student" element={<Student />} />
            <Route
              path="/homepage/student/add student"
              element={<StudentForm />}
            />
            <Route path="/homepage/student/:id" element={<StudentForm />} />
            <Route path="/homepage/course" element={<Course />} />
            <Route
              path="/homepage/course/add course"
              element={<CourseForm />}
            />
            <Route path="/homepage/course/:id" element={<CourseForm />} />
            <Route
              path="/homepage/academic year/add academicdetails"
              element={<AcademicYear />}
            />
            <Route
              path="/homepage/academic year/add academicdetails/:id"
              element={<AcademicYear />}
            />
            <Route path="/homepage/academic year" element={<AcademicTable />} />
            <Route path="/homepage/subject" element={<Subject />} />
            <Route
              path="/homepage/subject/add subject"
              element={<SubjectForm />}
            />
            <Route path="/homepage/subject/:id" element={<SubjectForm />} />
            <Route path="/homepage/section" element={<Section />} />
            <Route
              path="/homepage/section/add section"
              element={<SectionForm />}
            />
            <Route path="/homepage/section/:id" element={<SectionForm />} />
            <Route path="/homepage/exam" element={<Exams />} />
            <Route path="/homepage/exam/add exam" element={<ExamForm />} />
            <Route path="/homepage/result" element={<Result />} />
            <Route
              path="/homepage/attendance"
              element={<StudentsAttendance />}
            />
            <Route path="/homepage/announcement" element={<Announcement />} />
            <Route path="/homepage/chat" element={<ChatList1 />} />
            <Route
              path="/homepage/announcement/add announcement"
              element={<AnnouncementForm />}
            />
            <Route
              path="/homepage/Announcement/:announcementId"
              element={<AnnouncementEvent />}
            />
            <Route
              path="/homepage/announcement/:id"
              element={<AnnouncementForm />}
            />
            <Route
              path="/homepage/institute admin"
              element={<InstituteAdmin />}
            />
            <Route
              path="/homepage/institute admin/add instituteadmin"
              element={<InstituteAdminForm />}
            />
            <Route
              path="/homepage/institute admin/:id"
              element={<InstituteAdminForm />}
            />
            <Route
              path="/homepage/attendance/today attendace"
              element={<TodayAttendance />}
            />
            {/* <Route path="/homepage/attendance/today attendace/" element={<TodayAttendance />} /> */}
            <Route
              path="/homepage/Result/:selectedSectionId/:examId"
              element={<ResultForm />}
            />

            {/* <Route
              path="/homepage/announcement"
              element={<Announcement />}
            /> */}
            <Route path="/homepage/support list" element={<SupportTable />} />
            <Route
              path="/homepage/support list/add support"
              element={<SupportForm />}
            />

            <Route path="/homepage/virtual reality" element={<Virtual />} />

            {/* Branch route */}
            <Route path="/homepage/branch admin" element={<BranchAdmin />} />
            <Route
              path="/homepage/branch admin/add branchadmin"
              element={<BranchAdminForm />}
            />
            <Route
              path="/homepage/branch admin/:id"
              element={<BranchAdminForm />}
            />

            {/* faculty route */}
            <Route path="/homepage/My Classroom" element={<MyClassroom />} />
            <Route
              path="/homepage/My Classroom/Students/:sectionId"
              element={<FacultyStudents />}
            />
            <Route
              path="/homepage/My Classroom/Students/:sectionId/LMS Chat"
              element={<ChatList1 />}
            />

            <Route
              path="/homepage/My Classroom/Students/:sectionId/LMS Meet/Meet Table"
              element={<MeetTable />}
            />

            <Route
              path="/homepage/My Classroom/attendance/:sectionId"
              element={<StudentAttendance />}
            />
            <Route
              path="/homepage/My Classroom/today attendance/:sectionId"
              element={<StudentTodayAttendance />}
            />
            <Route
              path="/homepage/My Classroom/today attendance/:sectionId/:id"
              element={<StudentTodayAttendance />}
            />
            <Route
              path="/homepage/My Classroom/Exam/:sectionId"
              element={<FacultyMyclassExam />}
            />
            <Route
              path="/homepage/My Classroom/Exam/:sectionId/Exam Form/:sectionId"
              element={<FacultyExamForm />}
            />
            <Route
              path="/homepage/My Classroom/:sectionId"
              element={<FacultyMyResult />}
            />
            <Route
              path="/homepage/My Classroom/:sectionId/:examId"
              element={<FacultyMyResultView />}
            />
            {/* <Route path="/homepage/Questionview" element={<QuestionView />} /> */}
            <Route
              path="/homepage/My Classroom/Result"
              element={<FacultyResult />}
            />
            {/* <Route path="/homepage/My Classroom/Result/Result Form" element={<FacultyResultForm />} /> */}
            <Route path="/homepage/Classroom" element={<Classroom />} />
            <Route
              path="/homepage/Classroom/Students/:sectionId"
              element={<FacultyStudents />}
            />
            <Route
              path="/homepage/Classroom/:subjectName/:selectedSectionId"
              element={<SectionTopic />}
            />
            <Route
              path="/homepage/Classroom/:subjectName/:selectedSectionId/:sectionId/:topicId/content"
              element={<CollegeContent />}
            />
            <Route
              path="/homepage/Classroom/Exam/:sectionId"
              element={<FacultyExam />}
            />
            <Route
              path="/homepage/Classroom/Exam/:sectionId/Exam Form/:sectionId"
              element={<FacultyExamForm />}
            />
            <Route
              path="/homepage/Classroom/Result/:sectionId/:examId"
              element={<AnsweredCorrection />}
            />
            <Route
              path="/homepage/Classroom/ExamView/:sectionId/:examId"
              element={<ExamView />}
            />

            <Route
              path="/homepage/Classroom/Exam/:sectionId/Exam Form/:sectionId"
              element={<FacultyExamForm />}
            />

            <Route
              path="/homepage/Classroom/Result/:sectionId"
              element={<FacultyResult />}
            />
            {/*<Route path="/homepage/ClassRoom/Result/Result Form" element={<FacultyResultForm />} /> */}
            <Route
              path="/homepage/File Management"
              element={<FileManagement />}
            />

            <Route
              path="/homepage/Faculty Announcement/Add Announcement"
              element={<FacultyAnnouncementForm />}
            />

            <Route
              path="/homepage/Faculty Announcement"
              element={<FacultyAnnouncement />}
            />

            <Route
              path="/homepage/Faculty Announcement/:announcementId"
              element={<FacultyAnnouncementEvent />}
            />

            {/* Student route */}
            <Route
              path="/homepage/My Course/:examId/:examId"
              element={<QuestionView />}
            />
            <Route
              path="/homepage/My Course/:examId/:examId/:examId"
              element={<Question />}
            />
            <Route
              path="/homepage/My Course/:examId"
              element={<ExamWarningPage />}
            />
            <Route path="/homepage/My Course" element={<MyCourse />} />

            <Route path="/homepage/Results" element={<StudentResult />} />


            <Route path="/homepage/LMS Meet" element={<CreateMeet />} />

            <Route path="/homepage/LMS Meet/:url" element={<Video />} />

            <Route
              path="/homepage/My Course/Subject/:subjectName/:sectionId"
              element={<CollegeStudentContent />}
            />
            <Route
              path="/homepage/My Course/:subjectName"
              element={<SubjectContent />}
            />
            <Route
              path="/homepage/My Course/Students Attendance"
              element={<StudentsAttendance />}
            />
            <Route
              path="/homepage/My Course/:examId/:examId/Questions"
              element={<Descriptive />}
            />

            <Route path="/homepage/E Library" element={<E_Book />} />
            {/* <Route path="/homepage/videocall" element={<GroupVideoCall />} /> */}
            <Route path="/homepage/Timetable" element={<Timetable />} />
            <Route path="/homepage/profile" element={<Profile />} />
            <Route
              path="/homepage/AnnouncementUi"
              element={<AnnouncementUI />}
            />
            <Route path="/homepage/timetableUi" element={<TimeTableUi />} />
            <Route
              path="/homepage/circleProgressBar"
              element={<CircleProgressBar />}
            />
            <Route
              path="/homepage/StudentHomepage"
              element={<StudentHomePage />}
            />
            <Route
              path="/homepage/FacultyHomepage"
              element={<FacultyHomePage />}
            />
            <Route
              path="/homepage/InstituteHomepage"
              element={<InstituteHomepage />}
            />
            <Route path="/homepage/timetableall" element={<Timetableall />} />
            <Route
              path="/homepage/SuperAdminHomepage"
              element={<SuperAdminHomePage />}
            />
            {/* Chat Routes */}

            <Route
              path="/homepage/My Classroom/Students/shift semester/:sectionId"
              element={<ShiftSemester />}
            />
          </Route>
       
        </Route>
      </Routes>

      {/* </ContextProvider> */}
      <ToastContainer />
    </div>
  );
}

export default App;
