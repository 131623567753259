import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Switch } from "antd";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

const apiUrl = process.env.REACT_APP_API_URL;

function FacultyExam({ exams }) {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");

  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const history = useNavigate();
  const { sectionId } = useParams();
  const [exam, setExam] = useState([]);
  // const [examId, setExamId] = useState([])
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleToggle = async (id, value) => {
    const newStatus = value ? 0 : 1;
    try {
      await axios
        .put(`${apiUrl}/collegeexamstatus/${id}`, {
          status: newStatus,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              `Exam ${newStatus ? "activated" : "deactivated"} successfully`
            );
            setStatus(!status);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/collegeexam/${id}`).then((res) => {
          //  console.log(res.data);
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
        //axios.delete(`${apiUrl}/collegefacultylogin/${facultyId}`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleIconClick = (examId) => {
    history(`/homepage/Classroom/ExamView/${sectionId}/${examId}`);
    console.log(examId, "examIds");
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam ID</h1>,
      selector: (row) => row.examId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam Name</h1>,
      selector: (row) => row.examName,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject ID</h1>,
      selector: (row) => row.subjectId,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject Name</h1>,
      selector: (row) => row.subjectList,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Duration</h1>,
      selector: (row) => row.duration,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <>
              <FaEye
                onClick={() => handleIconClick(row.examId)}
                className="w-6 h-6 text-gray-600 cursor-pointer"
              />
              {/* <Link to={`/homepage/Classroom/Exam/${row.examId}`}>
                  <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                </Link> */}
              <MdDelete
                className="w-6 h-6 text-red-500 cursor-pointer"
                onClick={() => showDeleteConfirm(row._id, row.examId)}
              />
            </>
          </div>
        </>
      ),
    },
    {
      name: <h1 className="text-white pl-4 w-[100px]">Status</h1>,
      selector: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              checked={row.status === 1}
              onChange={() => handleToggle(row.examId, row.status === 1)}
              className={
                row.status === 1
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
            />
          </div>
        </>
      ),
      // width:"150px"
    },
  ];

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.examName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/getexambysectionid/${sectionId}`)
      .then((res) => {
        const exam = res.data.data;
        setItem(exam);
        setExam(exam[0].examId);

        console.log(res.data.data);
        // console.log(setExamId)
        console.log(setExam);
      })
      .catch((error) => {
        console.log(error);
       
      });
  }, [status]);

  console.log("Exam Id", exam);

  const toggleFormView = () => {
    history(`/homepage/Classroom/Exam/${sectionId}/Exam Form/${sectionId}`);
  };

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Exam ID": item.examId,
        "Exam Name": item.examName,
        Semester: item.semesterNumber,
        "Date Of Exam": item.dateOfExamination,
        "Subject Name": item.subjectName,
        "Subject Id": item.subjectId,
      }));
      return csvData;
    }
    return [];
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  return (
    <div className="flex flex-col  w-full ">
      <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
        <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
          <div className="flex flex-row items-center gap-4">
            <div className="relative">
              <input
                placeholder="Search"
                type="text"
                required
                className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />
              <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
            </div>
            {LMS_ROLE === "3" || LMS_ROLE === "5" || LMS_ROLE === "7" ? (
              <button
                className="flex flex-row py-2 px-4 text-[14px] bg-primary text-white rounded-lg hover:bg-secondary  hover:text-green items-center gap-1 "
                onClick={toggleFormView}
              >
                <FaPlus />
                Add Exams
              </button>
            ) : (
              <>
                {PERMISSION_ADD === "true" && (
                  <button
                    className="py-2 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
                    onClick={toggleFormView}
                  >
                    <FaPlus />
                    Add Exams
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        {/* Use the DataTable component */}
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
          onChangePage={handlePageChange}
        />
      </div>
    </div>
  );
}

export default FacultyExam;
