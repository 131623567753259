import React, { Fragment, useEffect, useState } from "react";
import {
  FaBars,
  FaBook,
  FaBookReader,
  FaBuilding,
  FaBullhorn,
  FaCalendarWeek,
  FaClipboardList,
  FaFile,
  FaFolderOpen,
  FaGraduationCap,
  FaHome,
  FaIdCard,
  FaPhoneAlt,
  FaComments,
  FaUser,
  FaUserGraduate,
  FaUserPlus,
  FaUsers,
  FaVideo,
} from "react-icons/fa";

import { BsClipboard2CheckFill } from "react-icons/bs";

import rightMenu from "../Images/double-arrow.png";
import { NavLink, useLocation } from "react-router-dom";
import abhis from "../Images/abhis.png";

import { SiGooglecardboard } from "react-icons/si";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const SideBar = React.memo(({ handleToggle, open, vrEnabled }) => {
  const LMS_ROLE = localStorage.getItem("lms_role");

  const [instituteData, setInstituteData] = useState([]);
  const [vrData, setVrData] = useState([]);

  console.log("vr permission", instituteData);
  const instId = localStorage.getItem("institute_Unique_Id")
  useEffect(() => {
    const fetchData = async () => {
      if(instId)
      {
      try {
        const response = await axios.get(
          `${apiUrl}/institute/${instId}`
        );
        const institute = response.data.data;
        setInstituteData(institute);   
        setVrData(institute[0].vrEnabled);
      } catch (error) {
        console.error("Error fetching institute data:", error);
 
      }
    };
  
    fetchData();
  }
  }, []);
  

  const instituteMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Branches", icon: <FaBuilding />, path: "/homepage/branch" },
    { title: "Faculty", icon: <FaUser />, path: "/homepage/faculty" },
    { title: "Subjects", icon: <FaClipboardList />, path: "/homepage/subject" },
    {
      title: "Academic Year",
      icon: <FaCalendarWeek />,
      path: "/homepage/academic year",
    },
    { title: "Courses", icon: <FaFile />, path: "/homepage/course" },
    { title: "Sections", icon: <FaBookReader />, path: "/homepage/section" },
    { title: "Students ", icon: <FaUserGraduate />, path: "/homepage/student" },
    { title: "Exams ", icon: <FaGraduationCap />, path: "/homepage/exam" },
    { title: "Results", icon: <FaIdCard />, path: "/homepage/result" },
    { title: "Chat", icon: <FaComments />, path: "/homepage/chat" },
    {
      title: "LMS Meet",
      icon: <FaVideo />,
      path: "/homepage/LMS Meet",
    },
    {
      title: "Attendance",
      icon: <FaCalendarWeek />,
      path: "/homepage/attendance",
    },
    {
      title: "Announcements",
      icon: <FaBullhorn />,
      path: "/homepage/announcement",
    },
    { title: "Admin", icon: <FaUserPlus />, path: "/homepage/institute admin" },
    { title: "Support", icon: <FaPhoneAlt />, path: "/homepage/support list" },
    vrData
      ? {
          title: "Virtual Reality",
          icon: <SiGooglecardboard />,
          path: "/homepage/virtual reality",
        }
      : undefined,
  ];

  const instituteAdminMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Branches", icon: <FaBuilding />, path: "/homepage/branch" },
    { title: "Faculty", icon: <FaUser />, path: "/homepage/faculty" },
    { title: "Subjects", icon: <FaClipboardList />, path: "/homepage/subject" },
    {
      title: "Academic Year",
      icon: <FaCalendarWeek />,
      path: "/homepage/academic year",
    },
    { title: "Courses", icon: <FaFile />, path: "/homepage/course" },
    { title: "Sections", icon: <FaBookReader />, path: "/homepage/section" },
    { title: "Students ", icon: <FaUserGraduate />, path: "/homepage/student" },
    { title: "Exams ", icon: <FaGraduationCap />, path: "/homepage/exam" },
    { title: "Results", icon: <FaIdCard />, path: "/homepage/result" },
    { title: "Chat", icon: <FaComments />, path: "/homepage/chat" },
    {
      title: "LMS Meet",
      icon: <FaVideo />,
      path: "/homepage/LMS Meet",
    },
    {
      title: "Attendance",
      icon: <FaCalendarWeek />,
      path: "/homepage/attendance",
    },
    {
      title: "Announcements",
      icon: <FaBullhorn />,
      path: "/homepage/announcement",
    },
    // { title: "Admin", icon: <FaUserPlus />, path: "/homepage/institute admin" },
    { title: "Support", icon: <FaPhoneAlt />, path: "/homepage/support list" },
    vrData
      ? {
          title: "Virtual Reality",
          icon: <SiGooglecardboard />,
          path: "/homepage/virtual reality",
        }
      : undefined,
  ];

  const BranchMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Faculty", icon: <FaUser />, path: "/homepage/faculty" },
    { title: "Subjects", icon: <FaClipboardList />, path: "/homepage/subject" },
    {
      title: "Academic Year",
      icon: <FaCalendarWeek />,
      path: "/homepage/academic year",
    },
    { title: "Courses", icon: <FaFile />, path: "/homepage/course" },
    { title: "Sections", icon: <FaBookReader />, path: "/homepage/section" },
    { title: "Students ", icon: <FaUserGraduate />, path: "/homepage/student" },
    { title: "Exams ", icon: <FaGraduationCap />, path: "/homepage/exam" },
    { title: "Results", icon: <FaIdCard />, path: "/homepage/result" },
    { title: "Chat", icon: <FaComments />, path: "/homepage/chat" },
    {
      title: "LMS Meet",
      icon: <FaVideo />,
      path: "/homepage/LMS Meet",
    },
    {
      title: "Attendance",
      icon: <FaCalendarWeek />,
      path: "/homepage/attendance",
    },
    {
      title: "Announcements",
      icon: <FaBullhorn />,
      path: "/homepage/announcement",
    },
    { title: "Admin", icon: <FaUserPlus />, path: "/homepage/branch admin" },
    { title: "Support", icon: <FaPhoneAlt />, path: "/homepage/support list" },
    vrData
      ? {
          title: "Virtual Reality",
          icon: <SiGooglecardboard />,
          path: "/homepage/virtual reality",
        }
      : undefined,
  ];
  const BranchAdminMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    { title: "Faculty", icon: <FaUser />, path: "/homepage/faculty" },
    { title: "Subjects", icon: <FaClipboardList />, path: "/homepage/subject" },
    {
      title: "Academic Year",
      icon: <FaCalendarWeek />,
      path: "/homepage/academic year",
    },
    { title: "Courses", icon: <FaFile />, path: "/homepage/course" },
    { title: "Sections", icon: <FaBookReader />, path: "/homepage/section" },
    { title: "Students ", icon: <FaUserGraduate />, path: "/homepage/student" },
    { title: "Exams ", icon: <FaGraduationCap />, path: "/homepage/exam" },
    { title: "Results", icon: <FaIdCard />, path: "/homepage/result" },
    { title: "Chat", icon: <FaComments />, path: "/homepage/chat" },
    {
      title: "LMS Meet",
      icon: <FaVideo />,
      path: "/homepage/LMS Meet",
    },
    {
      title: "Attendance",
      icon: <FaCalendarWeek />,
      path: "/homepage/attendance",
    },
    {
      title: "Announcements",
      icon: <FaBullhorn />,
      path: "/homepage/announcement",
    },
    // { title: "Admin", icon: <FaUserPlus />, path: "/homepage/branch admin" },
    { title: "Support", icon: <FaPhoneAlt />, path: "/homepage/support list" },
    vrData
      ? {
          title: "Virtual Reality",
          icon: <SiGooglecardboard />,
          path: "/homepage/virtual reality",
        }
      : undefined,
  ];

  const FacultyMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    {
      title: "My Classroom",
      icon: <FaUsers />,
      path: "/homepage/My Classroom",
    },
    { title: "Classroom", icon: <FaUsers />, path: "/homepage/Classroom" },
    {
      title: "Announcement",
      icon: <FaBullhorn />,
      path: "/homepage/Faculty Announcement",
    },

    {
      title: "File Management",
      icon: <FaFolderOpen />,
      path: "/homepage/File Management",
    },
    {
      title: "E-Library",
      icon: <FaBook />,
      path: "/homepage/e-Library",
    },
    {
      title: "LMS Meet",
      icon: <FaVideo />,
      path: "/homepage/LMS Meet",
    },
    { title: "Chat", icon: <FaComments />, path: "/homepage/chat" },
    { title: "Support", icon: <FaPhoneAlt />, path: "/homepage/support list" },
    vrData
      ? {
          title: "Virtual Reality",
          icon: <SiGooglecardboard />,
          path: "/homepage/virtual reality",
        }
      : undefined,
  ];

  const StudentMenus = [
    { title: "Home", icon: <FaHome />, path: "/homepage/home" },
    {
      title: "My Course",
      icon: <FaUsers />,
      path: "/homepage/My Course",
    },

    {
      title: "Results",
      icon: <BsClipboard2CheckFill />,
      path: "/homepage/Results",
    },
    {
      title: "My Files",
      icon: <FaFolderOpen />,
      path: "/homepage/File Management",
    },
    { title: "Chat", icon: <FaComments />, path: "/homepage/chat" },
    {
      title: "LMS Meet",
      icon: <FaVideo />,
      path: "/homepage/LMS Meet",
    },
    {
      title: "E-Library",
      icon: <FaBook />,
      path: "/homepage/E Library",
    },
    { title: "Support", icon: <FaPhoneAlt />, path: "/homepage/support list" },
    vrData
      ? {
          title: "Virtual Reality",
          icon: <SiGooglecardboard />,
          path: "/homepage/virtual reality",
        }
      : undefined,
  ];

  const location = useLocation();

  return (
    <div
      className={`${
        open ? "w-[238px]" : "w-[6%]"
      } bg-primary left-0 fixed duration-500 h-screen overflow-y-scroll overflow-x-hidden`}
    >
      <div className="w-full border-b px-[18px] py-3 h-[100px]">
        {open ? (
          <>
            <div className="w-full flex flex-row items-center gap-6">
              <div className="flex flex-row items-center">
                <img
                  src={abhis}
                  className={` w-[45px] cursor-pointer h-fit `}
                />
                <h1 className="text-[45px] text-white font-bold mt-[6px]">
                  bhis
                </h1>
              </div>

              <img
                alt="rightmenu"
                src={rightMenu}
                className={`cursor-pointer w-7 h-7 mt-3 text-gray-900
             `}
                onClick={handleToggle}
              />
            </div>
          </>
        ) : (
          <>
            <div className="w-full flex justify-center items-center h-full ">
              <FaBars
                className={` cursor-pointer w-7 h-7 text-white
             `}
                onClick={handleToggle}
              />
              {/* <FaBars
                  className={` cursor-pointer w-7 h-7 text-white
             `}
                  onClick={handleToggle}
                /> */}
            </div>
          </>
        )}
      </div>

      {LMS_ROLE === "3" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {instituteMenus.map(
            (menu, id) =>
              menu && (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      return (
                        match ||
                        (menu.path &&
                          location.pathname.startsWith(menu.path + "/"))
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,
                          }
                        : {};
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              )
          )}
        </div>
      )}
      {LMS_ROLE === "4" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {instituteAdminMenus.map(
            (menu, id) =>
              menu && (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      return (
                        match ||
                        (menu.path &&
                          location.pathname.startsWith(menu.path + "/"))
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,
                          }
                        : {};
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              )
          )}
        </div>
      )}
      {LMS_ROLE === "5" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {BranchMenus.map(
            (menu, id) =>
              menu && (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      return (
                        match ||
                        (menu.path &&
                          location.pathname.startsWith(menu.path + "/"))
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,
                          }
                        : {};
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              )
          )}
        </div>
      )}
      {LMS_ROLE === "6" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {BranchAdminMenus.map(
            (menu, id) =>
              menu && (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      return (
                        match ||
                        (menu.path &&
                          location.pathname.startsWith(menu.path + "/"))
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,
                          }
                        : {};
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              )
          )}
        </div>
      )}
      {LMS_ROLE === "7" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {FacultyMenus.map(
            (menu, id) =>
              menu && (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      return (
                        match ||
                        (menu.path &&
                          location.pathname.startsWith(menu.path + "/"))
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,
                          }
                        : {};
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              )
          )}
        </div>
      )}
      {LMS_ROLE === "8" && (
        <div className={`w-full flex flex-col gap-1 py-[10px]`}>
          {StudentMenus.map(
            (menu, id) =>
              menu && (
                <Fragment key={id}>
                  <NavLink
                    to={menu.path}
                    isActive={(match, location) => {
                      return (
                        match ||
                        (menu.path &&
                          location.pathname.startsWith(menu.path + "/"))
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            background: `${
                              open
                                ? "linear-gradient(to right, #ffffff 3%, #FF9800 3%)"
                                : "linear-gradient(to right, #ffffff 6%, #FF9800 6%)"
                            }`,
                          }
                        : {};
                    }}
                  >
                    <div className="w-full flex flex-row pl-[18px] pr-[12px] py-[13px] gap-4  hover:bg-custom-gradient">
                      <span
                        className={`text-[24px] text-white ${
                          !open && "mx-auto"
                        }`}
                      >
                        {menu.icon}
                      </span>
                      <h1
                        className={`${
                          open ? "text-[15px]" : "hidden"
                        } duration-500 text-white font-medium`}
                      >
                        {menu.title}
                      </h1>
                    </div>
                  </NavLink>
                </Fragment>
              )
          )}
        </div>
      )}
    </div>
  );
});

export default SideBar;
