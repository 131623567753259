import React, { useEffect, useState } from "react";
import { PiAlarmDuotone, PiHandWavingFill } from "react-icons/pi";
import { FaCircleUser } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Profile from "../Home/Profile";
import { HiMiniCake } from "react-icons/hi2";
import { FaBell } from "react-icons/fa";
import SProfile from "../Home/Profile";
import circle from "../Images/Profile Level definer.png";
import profile from "../Images/28.png";
import Calendar from "../cal/MainCalendar";
import { FaEdit } from "react-icons/fa";
import AnnouncementUI from "./AnnouncementUI";
import axios from "axios";
import { useParams } from "react-router-dom";
import Timetableforall from "./Timetableforall";

const apiUrl = process.env.REACT_APP_API_URL;
const StudentHomePage = () => {
  //const sectionId=useParams();
  const [percentage, setPercentage] = useState(null);
  const [presentPercentage, setPresentPercentage] = useState(null);
  const [absentPercentage, setAbsentPercentage] = useState(null);
  const [odPercentage, setOdPercentage] = useState(null);
  // const [sectionId, setSectionId] = useState(null);
  const [student, setStudent] = useState(null);
  const [name, setName] = useState();
  const [events, setEvents] = useState([]);
  const [department,setDepartment] =useState([])
 const names = localStorage.getItem("name");
 const sectionId = localStorage.getItem("section_Id") ;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/studentAttendancebystudentid/${localStorage.getItem(
            "Unique_id"
          )}`
        );
        const attendanceData = response.data;
        console.log(attendanceData, "Data");

        const percentageAttendance = attendanceData.data.percentageAttendance;
        const presentPercentage = attendanceData.data.presentPercentage;
        const absentPercentage = attendanceData.data.absentPercentage;
        const odPercentage = attendanceData.data.odPercentage;
        console.log(percentageAttendance);

        setPercentage(percentageAttendance);
        setPresentPercentage(presentPercentage);
        setAbsentPercentage(absentPercentage);
        setOdPercentage(odPercentage);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        // Handle error
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`
  //       ); // Update the sectionId accordingly
  //       const student = response.data;
  //       console.log(student, "StudentData");
  
  //       const section = student.data[0].sectionId;
  //       const name = student.data[0].studentName;
  //       const department = student.data[0].courseName; // Corrected this line
  
  //       console.log("dep", department);
  
  //       console.log(section, "sectionId");
  //       setSectionId(section);
  //       setName(name);
  //       setDepartment(department); // Assuming you have a state variable called 'department'
  //     } catch (error) {
  //       console.error("Error fetching section data:", error);
  //       // Handle error
  //     }
  //   };
  
  //   fetchData();
  // }, [sectionId]);
  
console.log("dep",department);

  useEffect(() => {
    if(sectionId)
    {
    axios
      .get(`${apiUrl}/collegebirthdaystudent/${sectionId}`)
      .then((response) => {
        const birthdayStudents = response.data.data;
        console.log(birthdayStudents);
        setStudent(birthdayStudents);
      })
      .catch((error) => console.error("Error fetching data:", error));
    }
  }, [sectionId]);


  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const cDate = new Date();
        const formattedDate = `${cDate.getFullYear()}-${(cDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${cDate.getDate().toString().padStart(2, "0")}`;

        const response = await axios.get(
          `${apiUrl}/getevents/${localStorage.getItem("Unique_id")}`,
          {
            params: {
              date: formattedDate,
            },
          }
        );

        if (response && response.data) {
          setEvents(response.data);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };

    fetchEvent();
  }, []);

  return (
    <>
      <div className="w-full h-auto flex flex-row justify-center gap-4 pb-4">
        <div className="w-[70%] flex flex-col h-auto gap-5 border rounded-lg drop-shadow-sm p-4">
          <div className="">
   
            <h1 className="font-medium text-xl flex items-center mb-1">
              Hello {names}
              <span className="text-orange-300 ml-4 text-xl">
                <PiHandWavingFill />
              </span>
            </h1>
            <h2 className="text-primary font-semibold text-xs">{department}</h2>
       
            <div className="text-gray-500 text-sm font-normal mb-2">
              Let’s learn something new today!
            </div>
            </div>
         
          
        

          <div className="w-full flex h-auto flex-row justify-center items-center gap-10">
            <div className="w-[50%] flex flex-col justify-start items-start">
              <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
                Today's Birthday
              </span>
              <div className="w-full flex flex-col border border-primary rounded-b-lg h-[230px] rounded-tr-lg p-4">
                <div className="w-full flex flex-col items-center gap-[10px] h-[230px] overflow-y-scroll">
                      {student && student.length > 0 ? (
                        student.map((students, index) => (
                          <div key={index} className="w-full flex flex-row justify-between items-center rounded-md border drop-shadow-sm px-2">
                      <div className="text-gray-500 text-xs font-medium">
                              {students.studentName}
                            </div>
                            <div className="mb-2 mt-1">
                      <HiMiniCake className="text-rose-400 w-6 h-6 ml-4" />
                    </div>
                  
                          </div>
                        ))
                      ) : (
                        <div>Sorry!!! No wishes Today</div>
                      )}
                   
                   
                </div>
              </div>
            </div>

            <div className="w-[50%] flex flex-col justify-start items-start">
              <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
                Statistics
              </span>

              <div className="w-full flex flex-row border border-primary gap-4 rounded-b-lg h-[230px] rounded-tr-lg p-4">
                <div className="w-[40%] flex flex-row ">
                  <div className="w-full h-[200px] flex flex-col justify-center gap-5">
                    <div className="flex flex-row gap-3 items-center">
                      <div>
                        <FaCircleUser className="w-10 h-10 text-blue-600" />
                      </div>
                      <div>
                        <p className="text-gray-600 text-[14px]">Present</p>
                        <p className="text-gray-800 text-[14px] font-semibold">
                        {presentPercentage ? Math.round(presentPercentage)  : 0}%
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row gap-3 items-center">
                      <div>
                        <FaCircleUser className="w-10 h-10 text-red-500" />
                      </div>
                      <div>
                        <p className="text-gray-600 text-[14px]">Absent</p>
                        <p className="text-gray-800 text-[14px] font-semibold">
                        {absentPercentage ? Math.round(absentPercentage) : 0}%
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <div>
                        <FaCircleUser className="w-10 h-10 text-orange-400" />
                      </div>
                      <div>
                        <p className="text-gray-600 text-[14px]">On Duty</p>
                        <p className="text-gray-800 text-[14px] font-semibold">
                         {odPercentage ? Math.round(odPercentage) : 0}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[60%] h-[200px] flex justify-centr items-center">
                  <div className="w-[140px] h-[140px]">
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage ? Math.round(percentage) : 0}%`}
                      styles={{
                        root: { width: "100%", height: "100%" },
                        path: { stroke: "#105d50" },
                        text: {
                          fill: "#105d50",
                          fontSize: "10px",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col justify-start items-start">
            <span className="px-6 py-2 bg-white border border-primary text-center text-primary rounded-t-lg text-lg font-medium">
              Time Table
            </span>
            {/* <div className="w-full rounded-b-lg rounded-tr-lg  overflow-hidden border drop-shadow-md border-primary overflow-x-auto">
              <table className="w-full border-collapse border border-primary">
                <thead>
                  <tr className="bg-primary  text-white text-[15px] font-medium">
                    <th className="py-3">Time</th>
                    <th className="p-2">Monday</th>
                    <th className="p-2">Tuesday</th>
                    <th className="p-2">Wednesday</th>
                    <th className="p-2">Thursday</th>
                    <th className="p-2">Friday</th>
                    <th className="p-2">Saturday</th>
                   
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b p-4 border-primary">
                    <div className="p-5"></div>
                  </tr>
                  <tr className="border-b border-primary">
                    <div className="p-5"></div>
                  </tr>
                  <tr className="border-b border-primary">
                    <div className="p-5"></div>
                  </tr>
                  <tr className="border-b border-primary">
                    <div className="p-5"></div>
                  </tr>
                  <tr className="border-b border-primary">
                    <div className="p-5"></div>
                  </tr>
                  <tr className="border-b border-primary">
                    <div className="p-5"></div>
                  </tr>
                  <tr className="border-b rounded-lg border-primary">
                    <div className="p-4"></div>
                  </tr>
                </tbody>
              </table>
            </div> */}
             <div className="w-full rounded-b-lg rounded-tr-lg  overflow-hidden border drop-shadow border-primary overflow-x-auto">
            
            <Timetableforall  sectionId={sectionId}/>
            </div>
           
          </div>
        </div>
        <div className="w-[30%] flex flex-col h-auto items-center gap-4">
          <SProfile sectionId={sectionId}/>
          <AnnouncementUI />
        </div>
      </div>
    </>
  );
};

export default StudentHomePage;
