import React, { useEffect, useRef, useState } from "react";
import { FaBell, FaComments, FaSignOutAlt, FaVideo } from "react-icons/fa";
import profile from "../Images/Profile.png";
import setting from "../Images/setting.png";
import { MdSettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const Navbar = React.memo(({ open }) => {
  const [showlog, setShowlog] = useState(false);

  const LMS_ROLE = JSON.parse(localStorage.getItem("lms_role"));

  const menuRef = useRef(null);
  //  const sideRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowlog(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    const getHiddenAnouncemenet = localStorage.getItem("hiddenAnnouncements");
    localStorage.clear()
    localStorage.setItem("hiddenAnnouncements", getHiddenAnouncemenet)
  
    
    navigate("/");
    toast.success("Logged out successfully");
  };

  const getRole = () => {
    switch (LMS_ROLE) {
      case 1:
        return "Super Admin";

      case 2:
        return "Admin";
      case 3:
        return "Institute";

      case 4:
        return "Institute Admin";
      case 5:
        return "Branch";

      case 6:
        return "Branch Admin";

      case 7:
        return "Faculty";

      default:
        return "Student";
    }
  };

  const profileImagePaths = {
    // 1: "SuperAdminuploads",
    2: "AdminUploads",
    3: "InstituteUploads",
    4: "CollegeInstituteAdminUploads",
    5: "CollegeBranchUploads",
    6: "CollegeBranchAdminUploads",
    7: "CollegeFacultyUploads",
    8: "CollegeStudentUploads",
  };

  const profileImagePath = profileImagePaths[LMS_ROLE];

  const [name, setName] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const[instituteid,setInstituteid]=useState();

  const getName = () => {
    switch (LMS_ROLE) {
      case 1:
        return setName("Sathish Kumar");

      case 2:
        return axios
          .get(`${apiUrl}/admin/${localStorage.getItem("Unique_id")}`)
          .then((res) =>{ setName(res.data.data[0].name);
          localStorage.setItem(
            "profile",
            res.data.data[0].uploadProfilePic
          );
           } );

      case 3:
        return axios
          .get(`${apiUrl}/institute/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].instituteName);
            setProfilePic(res.data.data[0].uploadInstituteLogo);
            localStorage.setItem(
              "profile",
              res.data.data[0].uploadInstituteLogo
            );
            localStorage.setItem(
              "institute_Name",
              res.data.data[0].instituteName
            );
            localStorage.setItem(
              "institute_Unique_Id",
              res.data.data[0].instituteID
            );
            localStorage.setItem(
              "name",
              res.data.data[0].instituteName
            );
          });

      case 4:
        return axios
          .get(`${apiUrl}/collegeinstituteadmin/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].name);
            setProfilePic(res.data.data[0].uploadProfilePic);
            localStorage.setItem(
              "institute_Unique_Id",
              res.data.data[0].instituteUniqueId
            );
            localStorage.setItem(
              "profile",
              res.data.data[0].uploadProfilePic
            );
          });
      case 5:
        return axios
          .get(`${apiUrl}/collegebranch/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].branchName);
            setProfilePic(res.data.data[0].uploadBranchLogo);
            localStorage.setItem(
              "profile",
              res.data.data[0].uploadBranchLogo
            );
            localStorage.setItem(
              "name",
              res.data.data[0].branchName
            );
          });

      case 6:
        return axios
          .get(`${apiUrl}/collegebranchadmin/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].name);
            setProfilePic(res.data.data[0].uploadProfilePic);
            localStorage.setItem(
              "name",
              res.data.data[0].branchAdminName
            );
            localStorage.setItem(
              "profile",
              res.data.data[0].uploadProfilePic
            );
          });

      case 7:
        return axios
          .get(`${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].facultyName);
            setProfilePic(res.data.data[0].uploadProfile);
            localStorage.setItem(
              "institute_id",
              res.data.data[0].instituteUniqueId
            );
            localStorage.setItem(
              "institute_Unique_Id",
              res.data.data[0].instituteUniqueId
            );
            localStorage.setItem(
              "name",
              res.data.data[0].facultyName
            );
            localStorage.setItem(
              "profile",
              res.data.data[0].uploadProfile
            );
          });

      default:
        return axios
          .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
          .then((res) => {
            setName(res.data.data[0].studentName);
            setProfilePic(res.data.data[0].uploadStudentProfile);
            localStorage.setItem(
              "section_Id",
              res.data.data[0].sectionId
            );
            localStorage.setItem(
              "profile",
              res.data.data[0].uploadStudentProfile
            );
            localStorage.setItem(
              "institute_Unique_Id",
              res.data.data[0].instituteUniqueId
            );
            localStorage.setItem(
              "name",
              res.data.data[0].studentName
            );
          });
    }
  };

  useEffect(() => {
    getName();
  }, []);



  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const handleJoinNow = () => {
    navigate("/homepage/LMS video/video Meet");
    closeModal();
  };


  return (
    <nav
      className={`${
        open
          ? "fixed top-0 right-0 left-[262px] flex grow"
          : "fixed top-0 right-0 left-[8%] flex grow"
      } duration-500 z-10 border-b-[1.3px] h-[50px] border-primary justify-end items-center bg-white px-5`}
    >
      <div className="flex items-center gap-4">
        {/* <div className="tooltip tooltip-bottom " data-tip="video"></div> */}
        <div class="relative group flex items-center">
          <button class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500">
            {" "}
            <FaVideo className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Video
          </div>
        </div>
        <div class="relative group flex items-center">
        <button
        onClick={openModal}
        className="w-8 h-8 group-hover:bg-secondary rounded-full duration-500"
      >
        <FaBell className="text-xl cursor-pointer mx-auto" />
      </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Notifications
          </div>
        </div>
        <div class="relative group flex items-center">
          <button class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500">
            {" "}
            <FaComments className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Chat
          </div>
        </div>
        <div class="relative group flex items-center">
          <button
            class="w-8 h-8 group-hover:bg-secondary rounded-full duration-500"
            onClick={handleLogout}
          >
            {" "}
            <FaSignOutAlt className="text-xl cursor-pointer mx-auto" />
          </button>
          <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Logout
          </div>
        </div>

        <div className="relative group flex items-center" ref={menuRef}>
        <div class="">
          <img
            src={
              profilePic
                ? `${ImageUrl}/${profileImagePath}/${profilePic}`
                : profile
            }
            alt="Profile"
            className="w-8 h-8 group-hover:bg-secondary rounded-full duration-500"
            onClick={() => setShowlog(!showlog)}
          />
           <div class="tooltip text-[11.5px] hidden group-hover:block bg-primary text-white text-sm px-3 py-0.5 rounded-md shadow-lg absolute top-[42px] left-4 transform -translate-x-1/2">
            Profile
          </div>
          </div>
          {showlog && (
            <>
              <div className="fixed top-16 right-4 h-fit w-[250px]  bg-gray-200  flex flex-col p-8  rounded-lg shadow border border-gray-50">
                <div className="flex flex-col p-3 gap-2">
                  <span className="flex justify-center font-semibold text-gray-800">
                    Account
                  </span>
                  <div className="flex justify-center">
                    <img
                      src={
                        profilePic
                          ? `${ImageUrl}/${profileImagePath}/${profilePic}`
                          : profile
                      }
                      alt="Profile"
                      className="w-32 h-32 rounded-full bg-white"
                    />
                  </div>

                  <span className="flex justify-center text-nav-ash font-md capitalize font-bold">
                    {name}
                  </span>

                  <span className="flex justify-center text-nav-ash font-md capitalize">
                    Role : {getRole()}
                  </span>
                </div>
                {/* <div className="w-full h-px bg- bg-gray-100"></div> */}
                <div className="w-full flex flex-col p-2 gap-2">
                  <div className="flex flex-col justify-between">
                    <span
                      className="flex flex-row items-center gap-2 cursor-pointer text-gray-600 pl-1 pr-2 py-1 font-md hover:rounded hover:duration-300  hover:bg-secondary hover:text-white"
                      onClick={() => setShowlog(!showlog)}
                    >
                      <MdSettings className="text-[22px]" />
                      Settings
                    </span>
                  </div>
                  <div className="flex flex-col justify-between">
                    <span
                      className="flex flex-row items-center gap-2 cursor-pointer px-2 py-1
                      hover:bg-secondary hover:text-white hover:rounded hover:duration-300 text-gray-600 font-md"
                      onClick={handleLogout}
                    >
                      <FaSignOutAlt className="text-[18px]" />
                      Log out
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex p-6 items-center justify-center z-50 bg-black bg-opacity-80">
         
          <div className="bg-white border rounded-md p-8 shadow-md">
         <div className=" border border-secondary rounded-md p-6 shadow-md">
            <p className="text-sm text-black font-semibold p-4 mb-4">
              Faculty has assigned a meeting. Are you interested to join the meeting?
            </p>
            <div className="flex p-4 justify-center">
              <button
                onClick={handleJoinNow}
                className="px-4 py-2 bg-primary text-white font-bold rounded-md hover:bg-secondary focus:outline-none focus:ring focus:border-secondary transition duration-300 mr-4"
              >
                Join Now
              </button>
              
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-200 text-gray-800 font-bold rounded-md hover:bg-gray-300 focus:outline-none focus:ring focus:border-gray-400 transition duration-300"
              >
                Close
              </button>
            </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
});
export default Navbar;
