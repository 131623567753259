import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "antd";
import DataTable from "react-data-table-component";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Timetableforall({sectionId}) {
  console.log("time sect id",sectionId)
  const [tableData, setTableData] = useState([]);
  const [days, setDays] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFacultyData, setSelectedFacultyData] = useState({});
  const [name, setName] = useState("");

  const LMS_ROLE = JSON.parse(localStorage.getItem("lms_role"));
const facultyId = localStorage.getItem("Unique_id")
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sectionId ) {
          let res;
          if (LMS_ROLE === 8) {
            res = await axios.get(`${apiUrl}/timetabledataa/${sectionId}`);
          }
          const timetableData = res.data.data;
          setTableData(timetableData);
  
          if (timetableData.length > 0) {
            const startDay = timetableData[0].starting_day || "";
            const endDay = timetableData[0].ending_day || "";
            const daysArray = [];
            let currentDay = startDay;
  
            while (currentDay !== endDay) {
              daysArray.push(currentDay);
              currentDay = getNextDay(currentDay);
            }
  
            daysArray.push(endDay);
            setDays(daysArray);
          } else {
            setTableData([]);
          }
        }
      } catch (error) {
        console.error(
          "An error occurred:",
          error.response ? error.response.data : error.message
        );
        if (error.response && error.response.status === 404) {
          console.log("Resource not found");
        }
      }
    };
  
    fetchData();
    console.log("trigger");
  }, [LMS_ROLE, sectionId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (facultyId ) {
          let res;
          if (LMS_ROLE === 7) {
            res = await axios.get(`${apiUrl}/timetabledata/${facultyId}`);
          
          const timetableData = res.data.data;
          setTableData(timetableData);
  
          if (timetableData.length > 0) {
            const startDay = timetableData[0].starting_day || "";
            const endDay = timetableData[0].ending_day || "";
            const daysArray = [];
            let currentDay = startDay;
  
            while (currentDay !== endDay) {
              daysArray.push(currentDay);
              currentDay = getNextDay(currentDay);
            }
  
            daysArray.push(endDay);
            setDays(daysArray);
          } else {
            setTableData([]);
          }
        }
      }
      } catch (error) {
        console.error(
          "An error occurred:",
          error.response ? error.response.data : error.message
        );
        if (error.response && error.response.status === 404) {
          console.log("Resource not found");
        }
      }
    
    };
  
    fetchData();
    console.log("trigger");
  }, [LMS_ROLE,facultyId]);

  const generateColumns = () => {
    const columns = [
      {
        name: "Period",
        selector: "period",
        sortable: true,
        style: {
          background: "#C9FDD7",
        },
      },
    ];

    days.forEach((day, index) => {
      columns.push({
        name: day,
        selector: `day_${index + 1}`,
        sortable: true,
      });
    });

    return columns;
  };

  const generateRows = () => {
    const rows = [];

    tableData.forEach((data) => {
      if (!tableData || !Array.isArray(tableData)) {
        console.error("Invalid tableData:", tableData);
        return rows;
      }
      for (let i = 1; i <= data.number_of_period; i++) {
        const row = {
          period: `Period ${i}`,
        };

        days.forEach((day, index) => {
          const matchingPeriod =
            data.time && data.time.find((p) => p.period === i && p.day === day);

          if (matchingPeriod) {
            const scheduleDetails = matchingPeriod.scheduleDetails[0];
            const facultyName = scheduleDetails.faculty || "Unknown Faculty";
            const subjectName = scheduleDetails.subject || "Unknown Subject";
            row[`day_${index + 1}`] = (
              <span
                className="clickable-faculty"
                onClick={() => handleFacultyClick(scheduleDetails)}
              >
                {subjectName}
                <br></br>
                {facultyName}
              </span>
            );
          } else {
            row[`day_${index + 1}`] = "";
          }
        });

        rows.push(row);
      }
    });

    return rows;
  };

  const customStyles = {
    headRow: {
      style: {
        // border: "none",
        outerWidth: "800px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
        textAlign: "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
      style: {
        borderTop: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        // border: "none",
        color: "#364353",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  const handleFacultyClick = (facultyData) => {
    setSelectedFacultyData(facultyData);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const getNextDay = (currentDay) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentIndex = daysOfWeek.indexOf(currentDay);
    return daysOfWeek[(currentIndex + 1) % 7];
  };
  return (
    <div>
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="430px"
        columns={generateColumns()}
        data={generateRows()}
        pagination
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
        onRow={(record) => ({
          onClick: () => handleFacultyClick(record),
        })}
      />

      <Modal
        open={modalVisible}
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseModal}
        footer={null}
        className="bg-white rounded-md p-6 overflow-hidden transform transition-all ease-in-out duration-300"
      >
        <div className="mb-6">
          <div className="flex items-center">
            <div className="flex items-center mr-3">
              <h2 className="text-2xl font-bold mb-2 text-gray-800">
                Faculty Details
              </h2>
            </div>
          </div>

          <div className="border-b-2 border-primary"></div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Faculty:</p>
            <p className="font-semibold">{selectedFacultyData.faculty}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Faculty ID:</p>
            <p className="font-semibold">{selectedFacultyData.facultyId}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Subject:</p>
            <p className="font-semibold">{selectedFacultyData.subject}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Subject ID:</p>
            <p className="font-semibold">{selectedFacultyData.subjectId}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Start Time:</p>
            <p className="font-semibold">{selectedFacultyData.startTime}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">End Time:</p>
            <p className="font-semibold">{selectedFacultyData.endTime}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}