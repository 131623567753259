import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "antd";
import DataTable from "react-data-table-component";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Box, IconButton, Stack } from "@mui/material";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

export default function Timetablecollege() {
  const [tableData, setTableData] = useState([]);
  const [days, setDays] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFacultyData, setSelectedFacultyData] = useState({});
  const [periods, setPeriods] = useState([]);
  const [hasData, setHasData] = useState(true);


  const LMS_ROLE = localStorage.getItem("lms_role");

  const [searchTerm, setSearchTerm] = useState("");
  const [isStudent, setIsStudent] = useState(false);
  const [item, setItem] = useState([]);
  const [courses, setCourses] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [semesters, setSemesters] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  const [selectedSemesterNumber, setSelectedSemesterNumber] = useState("");
  const [getSectionName, setGetSectionName] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [formData, setFormData] = useState({
    semesterNumber: "",
    sectionId: "",
    courseId: "",
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onStartDateChange = (date, dateString) => {
    setStartDate(date);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(date);
  };

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );
  
    if (selectedCourse) {
      setSelectedCourseId(selectedCourse.courseId);
      setSelectedSemesterNumber("");
      setSemesters([]); // Reset semesters
      setSections([]); // Reset sections
      setSectionsData([]); // Reset sectionsData
      setFormData((prevFormData) => ({
        ...prevFormData,
        semesterNumber: "", // Reset semesterNumber in formData
        sectionId: "", // Reset sectionId in formData
      }));
    }
  };
  

  useEffect(() => {
    if (selectedCourseId) {
      axios
        .get(`${apiUrl}/semesterbycourseid/${selectedCourseId}`)
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Assuming semesters are in response.data.data
            const semestersData = response.data.data;

            // Assuming the semesters are directly available in semestersData
            const courseSemesters = Array.isArray(semestersData)
              ? semestersData.map((course) => course.courseSemesters).flat()
              : [];

            console.log(courseSemesters, "yffufheu  ");
            // Set semesters state with the semesters for the selected course
            setSemesters(courseSemesters);
            console.log(selectedCourseId);
            setHasData(true);
          } else {
            console.log("Failed to fetch semesters.");
            setHasData(false);
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Semesters not found");
          }
          setHasData(false);
        });
    }
  }, [selectedCourseId]);

  useEffect(() => {
    // Fetch sections from the database based on the selected course ID and semester number
    if (selectedCourseId && formData.semesterNumber) {
      axios
        .get(
          `${apiUrl}/collegesectionbycourseidandsemester/${selectedCourseId}/${encodeURIComponent(
            formData.semesterNumber
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            const fetchedSectionsData = response.data.data;

            // Set sections data state with the fetched section data
            setSectionsData(fetchedSectionsData);

            // Set sections state with the section names
            const sectionNames = fetchedSectionsData.map(
              (section) => section.sectionName
            );
            setSections(sectionNames);
          } else {
            console.log("Failed to fetch sections.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Sections not found");
          }
        });
    }
  }, [selectedCourseId, formData.semesterNumber, setSections]);

  const handleSectionChange = (e) => {
    const selectedSectionName = e.target.value;
  
    const selectedSection = sectionsData.find(
      (section) => section.sectionName === selectedSectionName
    );
  
    if (selectedSection) {
      console.log("Selected Section ID:", selectedSection.sectionId);
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        sectionId: selectedSection.sectionId,
      }));
  
      setSelectedSectionId(selectedSection.sectionId);
    }
  };
  
  console.log("institute id", selectedSectionId);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/timetabledataa/${selectedSectionId}`
      );
      const timetableData = response.data.data;
  
      if (timetableData.length > 0) {
        setTableData(timetableData);
        setPeriods(
          Array.from(
            { length: timetableData[0].number_of_period },
            (_, index) => index + 1
          )
        );
        const startDay = timetableData[0].starting_day || "";
        const endDay = timetableData[0].ending_day || "";
  
        const daysArray = [];
        let currentDay = startDay;
        while (currentDay !== endDay) {
          daysArray.push(currentDay);
          currentDay = getNextDay(currentDay);
        }
        daysArray.push(endDay);
  
        setDays(daysArray);
        setHasData(true);
      } else {
        setTableData([]);
        setHasData(false);
        
      }
    } catch (error) {
      console.error(
        "An error occurred:",
        error.response ? error.response.data : error.message
      );
      if (error.response && error.response.status === 404) {
        console.log("Resource not found");
        setHasData(false);
      }
    }
  };
  

  
  useEffect(() => {
    if (selectedSectionId) {
      fetchData();
    }
  }, [selectedSectionId]);
  

  const generateColumns = () => {
    const columns = [
      {
        name: "Period",
        selector: "period",
        sortable: true,
        style: {
          background: "#C9FDD7",
        },
      },
    ];

    days.forEach((day, index) => {
      columns.push({
        name: day,
        selector: `day_${index + 1}`,
        sortable: true,
      });
    });

    return columns;
  };

  const generateRows = () => {
    const rows = [];

    tableData.forEach((data) => {
      if (!tableData || !Array.isArray(tableData)) {
        console.error("Invalid tableData:", tableData);
        return rows;
      }
      for (let i = 1; i <= data.number_of_period; i++) {
        const row = {
          period: `Period ${i}`,
        };

        days.forEach((day, index) => {
          const matchingPeriod =
            data.time && data.time.find((p) => p.period === i && p.day === day);

          if (matchingPeriod) {
            const scheduleDetails = matchingPeriod.scheduleDetails[0];
            const facultyName = scheduleDetails.faculty || "Unknown Faculty";
            row[`day_${index + 1}`] = (
              <span
                className="clickable-faculty"
                onClick={() => handleFacultyClick(scheduleDetails)}
              >
                {facultyName}
              </span>
            );
          } else {
            row[`day_${index + 1}`] = "";
          }
        });

        rows.push(row);
      }
    });

    return rows;
  };

  const customStyles = {
    headRow: {
      style: {
        // border: "none",
        outerWidth: "800px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
        textAlign: "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
      style: {
        borderTop: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        // border: "none",
        color: "#364353",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  const handleFacultyClick = (facultyData) => {
    setSelectedFacultyData(facultyData);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const getNextDay = (currentDay) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentIndex = daysOfWeek.indexOf(currentDay);
    return daysOfWeek[(currentIndex + 1) % 7];
  };
  return (
    <div>
      <div className="w-full h-fit flex flex-col py-12">
        <form
          // onSubmit={handleSubmit}
          className="flex flex-row justify-center items-center gap-4"
        >
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              width={"820px"}
              spacing={2}
            >
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 40,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                  // width: "120%",
                }}
                label="Course Name"
                variant="outlined"
                name="courseName"
                fullWidth
                select
                value={formData.courseName}
                onChange={(e) => {
                  handleOnChange(e);
                  handleCourseChange(e); // Call the course change handler
                }}
                required
              >
                {courses.map((course) => (
                  <MenuItem key={course._id} value={course.courseName}>
                    {course.courseName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },
                  "& .MuiInputBase-root": {
                    height: 40,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    backgroundColor: "#EEEDED",
                  },
                  // width: "120%"
                }}
                label="Semester Number"
                variant="outlined"
                fullWidth
                select
                name="semesterNumber"
                type="text"
                value={formData.semesterNumber}
                onChange={handleOnChange}
                required
              >
                {semesters.map((semester, index) => (
                  <MenuItem key={index} value={semester}>
                    {semester}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 40,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                  // width: "120%"
                }}
                select
                label="Section Name"
                variant="outlined"
                fullWidth
                type="text"
                name="sectionName"
                value={formData.sectionName}
                onChange={(e) => {
                  handleOnChange(e);
                  handleSectionChange(e);
                }}
              >
                {sectionsData.map((section) => (
                  <MenuItem key={section.sectionId} value={section.sectionName}>
                    {`${section.sectionName} `}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
          </Stack>
        </form>
      </div>
      {hasData ? (
  <DataTable
    fixedHeader
    fixedHeaderScrollHeight="430px"
    columns={generateColumns()}
    data={generateRows()}
    pagination
    customStyles={customStyles}
    highlightOnHover
    pointerOnHover
    onRow={(record) => ({
      onClick: () => handleFacultyClick(record),
    })}
  />
) : (
  <p>There are no records to display</p>
)}


      <Modal
        open={modalVisible}
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseModal}
        footer={null}
        className="bg-white rounded-md p-6 overflow-hidden transform transition-all ease-in-out duration-300"
      >
        <div className="mb-6">
          <div className="flex items-center">
            <div className="flex items-center mr-3">
              <h2 className="text-2xl font-bold mb-2 text-gray-800">
                Faculty Details
              </h2>
            </div>
          </div>

          <div className="border-b-2 border-primary"></div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Faculty:</p>
            <p className="font-semibold">{selectedFacultyData.faculty}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Faculty ID:</p>
            <p className="font-semibold">{selectedFacultyData.facultyId}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Subject:</p>
            <p className="font-semibold">{selectedFacultyData.subject}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Subject ID:</p>
            <p className="font-semibold">{selectedFacultyData.subjectId}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Start Time:</p>
            <p className="font-semibold">{selectedFacultyData.startTime}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">End Time:</p>
            <p className="font-semibold">{selectedFacultyData.endTime}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
