import React, { useEffect, useState } from "react";
import { FaArrowDown, FaBook, FaEye, FaFilePdf, FaLink } from "react-icons/fa";

const apiUrl = process.env.REACT_APP_API_URL;
const ImgUrl = process.env.REACT_APP_IMAGE_URL;



const ImageDisplay = () => {
  const [libraryItems, setLibraryItems] = useState([]);
  const instituteID = localStorage.getItem("institute_Unique_Id");
  const superadmin = 'superadmin01';


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [instituteResponse, superadminResponse] = await Promise.all([
          fetch(`${apiUrl}/elibrarybyinstituteuniqueid/${instituteID}`),
          fetch(`${apiUrl}/elibrary/${superadmin}`)
        ]);

        let instituteData = null;
        let superadminData = null;

        if (instituteResponse.ok) {
          instituteData = await instituteResponse.json();
        }

        if (superadminResponse.ok) {
          superadminData = await superadminResponse.json();
        }

        if (instituteData && instituteData.error === false && instituteData.status === 200 && Array.isArray(instituteData.data)) {
          setLibraryItems(prevLibraryItems => [...prevLibraryItems, ...instituteData.data]);
        }

        if (superadminData && superadminData.error === false && superadminData.status === 200 && Array.isArray(superadminData.data)) {
          setLibraryItems(prevLibraryItems => [...prevLibraryItems, ...superadminData.data]);
        }
      } catch (error) {
        console.error("Error fetching library items:", error);
      }
    };

    fetchData();
  }, [instituteID, superadmin]);

  const handleLearnMoreClick = (item) => {
    window.open(item.externalLinks, "_blank");
    console.log('Learn More button clicked');
  };


  return (
    <div className="py-16 border shadow-md rounded-md px-3">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold text-center mb-10">
          Library Items
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 justify-center p-4">
          {libraryItems.map((item) => (
            <div key={item._id} className="group bg-gray-100 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
              <div className="relative pt-8 flex items-center justify-center">
                <a href={item.externalLinks} target="_blank" rel="noopener noreferrer">
                  <img src={`${ImgUrl}/elibraryuploads/${item.uploadThumbnail}`} alt={item.title} className="w-32 h-44 rounded-l-lg object-cover shadow-2xl" />

                </a>
              </div>

              <div className="py-6">
                <h2 className="text-xl font-semibold mb-2 flex justify-center items-center">{item.title}</h2>
                <p className="text-gray-600 mb-2 text-xs flex justify-center items-center">Author: {item.authorName}</p>

                <div className="mt-4 flex justify-around">
                <div className="mt-4 flex justify-center items-center">
  <button
    onClick={() => {
      if (item.uploadFile) {
        window.open(`${ImgUrl}/ELibraryUploads/${item.uploadFile}`, "_blank");
      }
    }}
    className={`bg-primary text-white font-semibold w-12 transition duration-200 ease-in active:translate-y-[2px] hover:active:bg-secondary hover:bg-secondary  py-2 px-2 flex justify-center rounded-l-xl items-center ${!item.uploadFile ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={!item.uploadFile}
  >
    <FaEye />
  </button>
  <button
    onClick={() => {
      if (item.externalLinks) {
        window.open(item.externalLinks, "_blank");
      }
    }}
    className={`bg-primary text-white font-semibold w-12 transition duration-200 ease-in active:translate-y-[2px] hover:active:bg-secondary hover:bg-secondary border border-l-white py-2 px-2 flex justify-center rounded-r-xl items-center ${!item.externalLinks ? 'opacity-50 cursor-not-allowed' : ''}`}
    disabled={!item.externalLinks}
  >
    <FaLink />
  </button>
</div>
                </div>
              </div>
           
              {
              item.status !== 1 && (
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-600 text-xl font-bold">
                  Unavailable!
                </div>
              )
            }
            </div>
          ))}
      </div>

    </div>
    </div >
  );
}


export default ImageDisplay;
