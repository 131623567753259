import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaAngleUp,
  FaAngleDown,
} from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import Tooltip from "@mui/material/Tooltip";

const apiUrl = process.env.REACT_APP_API_URL;

function AddSubject() {
  const [sections, setSections] = useState([]);
  const [newTopic, setNewTopic] = useState("");
  const [editingSectionIndex, setEditingSectionIndex] = useState(-1);
  const [collapsedSections, setCollapsedSections] = useState({});
  const [topics, setTopics] = useState([]);
  const [editedSectionTitle, setEditedSectionTitle] = useState("");
  const { subjectName, selectedSectionId } = useParams();
  const newTopicInputRef = useRef(null);
  const [editingTopicIndex, setEditingTopicIndex] = useState(-1);
  const [editedTopicTitle, setEditedTopicTitle] = useState("");

  console.log(sections);
  console.log("edit", editedSectionTitle);
  

  console.log("subjectId and sectionId",subjectName, selectedSectionId);



  const handleAddSection = () => {
    const newSection = { title: `Section ${sections.length + 1}`, topics: [] };

    // Send a POST request to create a new section
    fetch(`${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSection),
    })
      .then((response) => response.json())
      .then((data) => {
        // After creating the section, fetch the updated sections data
        fetchSectionsAndTopics();
      })
      .catch((error) => console.error("Error adding section:", error));
  };



  const handleAddTopic = (sectionIndex) => {
    if (newTopic) {
      const updatedSections = [...sections];
      const sectionId = updatedSections[sectionIndex]._id;

      const newTopicData = {
        title: newTopic,
      };

      // Send a POST request to create a new topic within a section
      fetch(`${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newTopicData),
      })
        .then((response) => response.json())
        .then((data) => {
          // After successfully adding a new topic, fetch the updated sections and topics data
          fetchSectionsAndTopics();

          // Reset the newTopic input field and editingSectionIndex
          setNewTopic("");
          setEditingSectionIndex(-1);
        })
        .catch((error) => console.error("Error adding topic:", error));
    }
  };



  const handleEditSection = (sectionIndex) => {
    const updatedSections = [...sections];
    const section = updatedSections[sectionIndex];

    // Toggle the section's title to an input field for editing
    section.isEditing = true;

    setSections(updatedSections);
  };




  const saveSectionTitle = (sectionIndex, newTitle) => {
    const updatedSections = [...sections];
    const sectionId = updatedSections[sectionIndex]._id;

 
    fetch(`${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title: newTitle }),
    })
      .then((response) => response.json())
      .then((data) => {
        updatedSections[sectionIndex].title = data.title;
        updatedSections[sectionIndex].isEditing = false; // Disable editing mode
        setSections(updatedSections);
      })
      .catch((error) => console.error("Error editing section:", error));
  };


  // Handle Enter key press to save
  const handleEnterKeyPress = (sectionIndex, newTitle) => {
    if (newTitle) {
      saveSectionTitle(sectionIndex, newTitle);
    }
  };



  const handleDeleteSection = (sectionIndex) => {
    Modal.confirm({
      title: "Are you sure to delete this section?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Send a DELETE request to delete the section
        fetch(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sections[sectionIndex]._id}`,
          {
            method: "DELETE",
          }
        )
          .then(() => {
            const updatedSections = [...sections];
            updatedSections.splice(sectionIndex, 1);
            setSections(updatedSections);
          })
          .catch((error) => console.error("Error deleting section:", error));
      },
    });
  };



  const handleEditTopic = (sectionIndex, topicIndex) => {
    setEditedTopicTitle(sections[sectionIndex].topics[topicIndex].title);
    setEditingTopicIndex(topicIndex);
  };



  const saveTopicTitle = (sectionIndex, topicIndex, newTitle) => {
    const updatedSections = [...sections];
    const sectionId = updatedSections[sectionIndex]._id;
    const topicId = updatedSections[sectionIndex].topics[topicIndex]._id;

    // Send a PATCH request to update the topic title
    fetch(`${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ title: newTitle }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the topic title in the frontend
        updatedSections[sectionIndex].topics[topicIndex].title = data.title;
        setSections(updatedSections);

        // Exit editing mode
        setEditingTopicIndex(-1);
      })
      .catch((error) => console.error("Error editing topic:", error));
  };



  const handleDeleteTopic = (sectionIndex, topicIndex) => {
    Modal.confirm({
      title: "Are you sure you want to delete this topic?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        // Send a DELETE request to delete the topic
        fetch(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sections[sectionIndex]._id}/topics/${sections[sectionIndex].topics[topicIndex]._id}`,
          {
            method: "DELETE",
          }
        )
          .then(() => {
            const updatedSections = [...sections];
            updatedSections[sectionIndex].topics.splice(topicIndex, 1);
            setSections(updatedSections);
          })
          .catch((error) => console.error("Error deleting topic:", error));
      },
    });
  };



  const toggleSectionCollapse = (sectionIndex) => {
    const updatedCollapsedSections = { ...collapsedSections };
    updatedCollapsedSections[sectionIndex] = !collapsedSections[sectionIndex];
    setCollapsedSections(updatedCollapsedSections);
  };

  const onDragStart = (e, sectionIndex) => {
    e.dataTransfer.setData("sectionIndex", sectionIndex);
  };

  const onDragOver = (e, sectionIndex) => {
    e.preventDefault();
    const draggedSectionIndex = parseInt(
      e.dataTransfer.getData("sectionIndex")
    );
    if (draggedSectionIndex !== sectionIndex) {
      const updatedSections = [...sections];
      const [draggedSection] = updatedSections.splice(draggedSectionIndex, 1);
      updatedSections.splice(sectionIndex, 0, draggedSection);
      setSections(updatedSections);
    }
  };

  useEffect(() => {
    // Fetch sections and topics from the server when the component mounts
    fetchSectionsAndTopics();
  }, [subjectName]);

  const fetchSectionsAndTopics = () => {
    // Fetch sections
    axios.get(`${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections`).then((res) => {
      setSections(res.data.data);
      console.log(res.data.data[0]);
      if (res.data.data[0] !== undefined) {
        fetch(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${res.data.data[0]._id}/topics`
        )
          .then((response) => response.json())
          .then((topicsData) => {
            // Initialize topics state with fetched data
            setTopics(topicsData);
          })
          .catch((error) => console.error("Error fetching topics:", error));
      }
    });
  };

  const handleKeyPress = (e, sectionIndex) => {
    if (e.key === "Enter") {
      handleAddTopic(sectionIndex); // Step 3: Call the handleAddTopic function on Enter key press
    }
  };

  return (
    <div className="min-h-screen bg-white rounded-md border drop-shadow">
      <header className="bg-primary p-4 text-white text-center rounded-t-lg">
        <h1 className="text-3xl font-semibold">Add Sections & Topics</h1>
      </header>
      <main className="">
        <div className="flex justify-end">
          <Tooltip
            title="Add Section"
            placement="right"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  background: "#105d50",
                  "& .MuiTooltip-arrow": {
                    color: "#105d50",
                  },
                  fontSize: "0.8em",
                  color: "white",
                  boxShadow: "revert-layer",
                  // border: '1px solid red',
                },
                color: {},
              },
            }}
          >
            <button
              onClick={handleAddSection}
              className=" bg-primary hover:bg-secondary text-white rounded-lg p-2 focus:outline-none my-4 mx-8"
            >
              <FaPlus />
            </button>
          </Tooltip>
        </div>
        <div className="p-3 mt-2 border shadow rounded w-full h-[600px] overflow-y-scroll bg-gray-100">
          {sections &&
            sections?.map((section, sectionIndex) => (
              <div
                key={section._id}
                className="bg-white rounded-lg shadow-lg mt-4"
                onDragOver={(e) => onDragOver(e, sectionIndex)}
                draggable
                onDragStart={(e) => onDragStart(e, sectionIndex)}
              >
                <div className="bg-primary text-white p-3 flex justify-between items-center rounded-t-lg">
                  <div className="flex items-center">
                    <button
                      onClick={() => toggleSectionCollapse(sectionIndex)}
                      className="text-white hover:text-gray-300 p-1 focus:outline-none"
                    >
                      {collapsedSections[sectionIndex] ? (
                        <FaAngleDown />
                      ) : (
                        <FaAngleUp />
                      )}
                    </button>
                    {section.isEditing ? (
                      <input
                        type="text"
                        className="text-black"
                        value={editedSectionTitle}
                        onChange={(e) => setEditedSectionTitle(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            handleEnterKeyPress(
                              sectionIndex,
                              editedSectionTitle
                            );
                          }
                        }}
                        onBlur={() => {
                          if (section.isEditing) {
                            saveSectionTitle(sectionIndex, editedSectionTitle);
                          }
                        }}
                        autoFocus
                      />
                    ) : (
                      <h2 className="text-lg font-semibold ml-2">
                        {section.title}
                      </h2>
                    )}
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => {
                        setEditedSectionTitle(section.title); // Reset the editedSectionTitle
                        handleEditSection(sectionIndex);
                      }}
                      className="text-blue-400 hover:text-blue-300 p-1"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteSection(sectionIndex)}
                      className="text-red-500 hover:text-red-400 p-1 ml-2"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
                {!collapsedSections[sectionIndex] && (
                  <ul className="mt-2">
                    {section.topics.map((topic, topicIndex) => (
                      <li
                        key={topic._id}
                        className="border p-2 my-3 mx-3 rounded-lg shadow-lg hover:shadow-xl bg-white hover:bg-gray-100 duration-500 ease-in-out"
                      >
                        <div className="flex items-center justify-between">
                          {editingTopicIndex === topicIndex ? (
                            <input
                              type="text"
                              value={editedTopicTitle}
                              onChange={(e) =>
                                setEditedTopicTitle(e.target.value)
                              }
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  saveTopicTitle(
                                    sectionIndex,
                                    topicIndex,
                                    editedTopicTitle
                                  );
                                }
                              }}
                              onBlur={() => {
                                if (editingTopicIndex === topicIndex) {
                                  saveTopicTitle(
                                    sectionIndex,
                                    topicIndex,
                                    editedTopicTitle
                                  );
                                }
                              }}
                              autoFocus
                            />
                          ) : (
                            <span className="text-lg font-semibold flex items-center space-x-4 text-gray-800 hover:text-blue-500 transition duration-300 mx-3">
                              {topic.title}
                            </span>
                          )}
                          <div className="flex items-center space-x-4">
                            <Link
                              to={`/homepage/Classroom/${subjectName}/${selectedSectionId}/${section._id}/${topic._id}/content`}
                            
                              className="text-lg font-semibold flex items-center space-x-4 text-gray-800 hover:text-blue-500 transition duration-300"
                            >
                              <Tooltip
                                title="Add content"
                                placement="left"
                                arrow
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      background: "#105d50",
                                      "& .MuiTooltip-arrow": {
                                        color: "#105d50",
                                      },
                                      fontSize: "0.8em",
                                      color: "white",
                                      boxShadow: "revert-layer",
                                      // border: '1px solid red',
                                    },
                                    color: {},
                                  },
                                }}
                              >
                                <span className="rounded-full bg-white hover.bg-gray-100 text-black w-7 h-7 flex items-center justify-center transition duration-300">
                                  <FaPlus />
                                </span>
                              </Tooltip>
                            </Link>
                            <button
                              onClick={() =>
                                handleEditTopic(sectionIndex, topicIndex)
                              }
                              className="text-blue-500 hover:text-blue-600 p-2 hover:underline cursor-pointer transition duration-300"
                            >
                              <FaEdit />
                            </button>

                            <button
                              onClick={() =>
                                handleDeleteTopic(sectionIndex, topicIndex)
                              }
                              className="text-red-500 hover:text-red-600 p-2 hover:underline cursor-pointer transition duration-300"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}

                    {editingSectionIndex === sectionIndex ? (
                      <div className="flex items-center justify-center mx-4 py-4">
                        <input
                          type="text"
                          placeholder="Add topic here ...."
                          value={newTopic}
                          onChange={(e) => setNewTopic(e.target.value)}
                          onKeyPress={(e) => handleKeyPress(e, sectionIndex)}
                          ref={newTopicInputRef}
                          className="flex-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none focus:border-primary"
                        />
                        <button
                          onClick={() => handleAddTopic(sectionIndex)}
                          className="flex justify-center items-center bg-green-500 hover:bg-green-600 text-white rounded-r-lg p-2 h-11 w-14 focus:outline-none"
                        >
                          <FaPlus className="text-lg" />
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-row justify-end mx-3">
                        <button
                          onClick={() => setEditingSectionIndex(sectionIndex)}
                          className="flex flex-row py-3 px-4 my-4 text-[14px] bg-primary text-white rounded-lg hover:bg-secondary  hover:text-green items-center gap-1"
                        >
                          <FaPlus />
                          Add Topic
                        </button>
                      </div>
                    )}
                  </ul>
                )}
              </div>
            ))}
        </div>
      </main>
    </div>
  );
}

export default AddSubject;
