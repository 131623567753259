import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FaEye, FaDownload, FaUpload } from "react-icons/fa";

const apiUrl = process.env.REACT_APP_API_URL;
const ImgUrl = process.env.REACT_APP_IMAGE_URL;

const Descriptive = () => {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState([]);
  const [sectionId, setSectionId] = useState();
  const [uploadFile, setUploadFile] = useState(null);
  const [studentName, setStudentName] = useState([]);
  const [studentId, setStudentId] = useState([]);
  const { examId } = useParams();
  const [exam, setExam] = useState({});
  const [showSubmitPopup, setShowSubmitPopup] = useState(false);
  const [showTimeSubmitPopup, setShowTimeSubmitPopup] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState("");
const [examName,setExamName] = useState([])



  useEffect(() => {
    axios
      .get(`${apiUrl}/collegestudent/${localStorage.getItem('Unique_id')}`)
      .then((res) => {
        const studentsData = res.data.data;

        if (studentsData && studentsData.length > 0) {
          const student = studentsData[0];
          setSectionId(student.sectionId);
          setStudentId(student.studentId);
          setStudentName(student.studentName);
        } else {
          console.warn('No student data found.');
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          toast.info('No Data Found');
        }
      });
  }, []);

  useEffect(() => {
    const savedRemainingTime = localStorage.getItem('remainingTime');
console.log("time", savedRemainingTime);
    try {
      if (sectionId) {
        axios
          .get(`${apiUrl}/collegeexambyexamid/${examId}`)
          .then((res) => {
            const examData = res.data.data;
            if (examData && examData.length > 0) {
              const firstExam = examData[0];
              setExam(firstExam);
              setExamName(examData[0].examName);

              if (!timeRemaining) {
                // If there is no remaining time in state, start the countdown with the original duration
                startCountdown(firstExam.duration);
              }
            } else {
              console.warn('No exam data found.');
            }
          })
          .catch((error) => {
            console.error('Error fetching subjects:', error);
            if (error.response) {
              console.error('Response data:', error.response.data);
              console.error('Response status:', error.response.status);
              toast.error('Failed to fetch subjects');
            }
          });
      }
    } catch (error) {
      console.error('Exception:', error);
      toast.error('An error occurred');
    }
  }, [sectionId, examId]);

  const handleSubmit = async () => {
    if (!uploadFile) {
      toast.error('Please upload a file.');
      return;
    }

    const formData = new FormData();
    formData.append('uploadFile', uploadFile);
    formData.append('uploadFileName', uploadFile.name);
    formData.append('examId', examId);
    formData.append('examName', examName);
    formData.append('studentId', studentId);
    formData.append('studentName', studentName);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    try {
      const res = await axios.post(`${apiUrl}/descriptivequestionAnswered`, formData, config);

      if (res.data.status === 301) {
        toast.warn(res.data.message);
      } else if (res.status === 200) {
        toast.success(res.data.message);
        navigate('/homepage/My Course');
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
      toast.error(error.message);
    }
  };

  const handleFileChange = (files) => {
    const file = files && files[0];

    if (file) {
      setUploadFile(file);
      setIsChecked(!isChecked);
    }else {
      setUploadFile(null);
      setIsChecked(false);
    }
   
  };

  const handleCancel = () => {
    setUploadFile(null);
    setIsChecked(false);
  };





  const handleShowPopup = () => {
    setShowSubmitPopup(true);
  };

  function openFileInNewPage(fileName, imgUrl) {
   window.open(`${imgUrl}/CollegeExamUpload/${fileName}`, '_blank'); 
  }


  function downloadFile(fileName, imgUrl) {
    axios({
      url: `${imgUrl}/CollegeExamUpload/${fileName}`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
        toast.error('Failed to download file');
      });
  }

  const isTimeEndingSoon = timeRemaining < 60;

 
  
    const startCountdown = (duration) => {
      const [hours, minutes, seconds] = duration.split(':').map(Number);
      let totalSeconds = hours * 3600 + minutes * 60 + seconds;
  
      const intervalId = setInterval(() => {
        const remainingHours = Math.floor(totalSeconds / 3600);
        const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
        const remainingSeconds = totalSeconds % 60;
  
        setTimeRemaining(
          `${remainingHours}:${remainingMinutes}:${remainingSeconds}`
        );
  
        totalSeconds--;
  
        if (totalSeconds < 0) {
          clearInterval(intervalId);
          setShowTimeSubmitPopup(true);
        }
      }, 1000);
    };
  
    useEffect(() => {
      // Check for remaining time in localStorage and start the countdown if available
      const savedRemainingTime = localStorage.getItem('remainingTime');
      if (savedRemainingTime) {
        // If there is saved remaining time, start the countdown with that time
        setTimeRemaining(savedRemainingTime);
        startCountdown(savedRemainingTime);
      }
    }, []); // Only runs on initial mount
  
    useEffect(() => {
      // Save the remaining time to localStorage whenever it changes
      localStorage.setItem('remainingTime', timeRemaining);
    }, [timeRemaining]);
  

  return (
    <div className="flex flex-col items-center justify-center p-6 w-[70%] bg-gray-100 mx-auto">
     <div className="mb-6 text-center">
  <h1 className="text-3xl font-bold mb-2 text-primary">{`Exam Name: ${exam.examName}`}</h1>
 
  <p className="text-lg">{`Exam ID: ${exam.examId}`}</p>
  <p className="mr-4 font-bold text-secondary">Time Remaining: {timeRemaining}</p>      
</div>

<div className="flex flex-col mt-6 items-center p-2">
  <button
    onClick={() => openFileInNewPage(exam.uploadFile, ImgUrl)}
    className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-6 border border-gray-300 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer mb-4"
  >
    <div className="flex items-center ">
      <FaEye className="text-2xl text-primary mr-2 " />
      <div className="ml-2">
         <span className="text-sm font-bold">{exam.uploadFile}</span> 
        <p className="text-gray-600">Click to view File</p>
      </div>
    </div>
  </button>

  <div className="flex justify-between w-full mb-4 mt-6">
    <button
      onClick={() => downloadFile(exam.uploadFile, ImgUrl)}
      className="flex items-center px-4 py-2 bg-primary text-white hover:bg-secondary rounded-md mr-2"
    >
      <FaDownload className="text-xl mr-2" />
      Download Questions
    </button>

    <label
      htmlFor="fileInput"
      className="flex items-center px-4 py-2 ml-8 cursor-pointer bg-primary hover:bg-secondary text-white  rounded-md"
    >
      <FaUpload className="text-xl mr-2 " />
      Upload Answer
    </label>
    <input
      type="file"
      id="fileInput"
      className="hidden"
      onChange={(e) => handleFileChange(e.target.files)}
    />
    
  
  </div>

  {uploadFile && (
        <div className="flex flex-col mt-6 items-center p-2">
          <p className="text-gray-600 mb-2">Uploaded File: {uploadFile.name}</p>
          <div className="flex justify-center w-full mb-4">
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded-md mt-2"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

  <div className="flex justify-center w-full mb-4">
    <button 
     
    className={` px-4 py-2 bg-primary text-white hover:bg-secondary rounded-md mt-4 ${
      !isChecked
        ? "opacity-50 cursor-not-allowed"
        : "hover:bg-primary-dark"
    }`}
    onClick={handleShowPopup} 
    disabled={!isChecked}
    >
      Proceed
    </button>
  </div>
</div>
    

      {showSubmitPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-gray-200 border-2 border-primary shadow-md rounded p-20">
            <p className="text-center font-bold mt-8">Submit all questions?</p>
            <div className="flex justify-center items-center mt-4">
              <button className="bg-primary text-white px-4 py-2 rounded mr-4 hover:bg-primary-dark" onClick={handleSubmit}>
                Yes
              </button>
              <button className="bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark" onClick={() => setShowSubmitPopup(false)}>
                No
              </button>
            </div>
          </div>
        </div>
      )}

      {showTimeSubmitPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
          <div className="bg-gray-200 border-2 border-primary shadow-md rounded p-20">
            <p className="text-center font-bold text-red-500 mb-4">Time is Over!</p>
            <div className="mt-4 flex mr-7 items-center justify-center ">
              <label
                htmlFor="fileInput"
                className="ml-8 cursor-pointer bg-primary hover:bg-secondary text-white px-6 py-6 rounded-md"
              >
                Upload Answer
              </label>
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={(e) => handleFileChange(e.target.files)}
              />
            </div>
            {uploadFile && (
        <div className="flex flex-col mt-6 items-center p-2">
          <p className="text-gray-600 mb-2">Uploaded File: {uploadFile.name}</p>
          <div className="flex justify-center w-full mb-4">
            <button
              onClick={handleCancel}
              className="px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded-md mt-2"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

            <input type="file" id="fileInput" className="hidden" onChange={(e) => handleFileChange(e.target.files)} />

            <p className="text-center font-bold mt-8">Submit Answer?</p>
            <div className="flex justify-center items-center mt-4">
              <button className="bg-primary text-white px-4 py-2 rounded mr-4 hover:bg-primary-dark" onClick={handleSubmit}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Descriptive;
