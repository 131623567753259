import React, { Component } from "react";
import io from "socket.io-client";
import { Badge, Button, Modal, Row, message } from "antd";
import { Avatar } from "@mui/material";

import {
  FaVideo,
  FaPhone,
  FaMicrophone,
  FaDesktop,
  FaVideoSlash,
  FaMicrophoneSlash,
  FaUser,
  FaUsers,
} from "react-icons/fa";
import {
  BsFillChatDotsFill,
  BsFillSendFill,
  BsFillShieldLockFill,
  BsFillStopFill,
} from "react-icons/bs";
import { PiAlarmDuotone, PiHandWavingFill } from "react-icons/pi";
import { FiCopy } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import { RiSendPlane2Line } from "react-icons/ri";
import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import profil from "../Images/girlsss.jpeg";
import { MdScreenShare } from "react-icons/md";
import { FaCopy } from "react-icons/fa6";
import { BiSolidMessageMinus } from "react-icons/bi";

const server_url = process.env.REACT_APP_SOCKET_URL;
var connections = {};
const peerConnectionConfig = {
  iceServers: [
//     {urls: ["turn:173.194.72.127:19305?transport=udp",
//     "turn:[2404:6800:4008:C01::7F]:19305?transport=udp",
//     "turn:173.194.72.127:443?transport=tcp",
//     "turn:[2404:6800:4008:C01::7F]:443?transport=tcp"
//     ],
//   username:"CKjCuLwFEgahxNRjuTAYzc/s6OMT",
//   credential:"u1SQDR/SQsPQIxXNWQT7czc/G4c="
//  },
//     { urls: "stun:stun.l.google.com:19302" },
    {
      urls:"turn:68.183.92.48:3478",
      username:"admin",
      credential:"Admin@whyglobal"
    }
  ],
};

// admin, Admin@whyglobal
var socket = null;
var socketId = null;
var elms = 0;
const userName = localStorage.getItem("name");
console.log("user name ", userName);
const defaultProfilePic =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7wjVJcTcfp9US7WZvkWb15ZCHev2arMfwBw&usqp=CAU";

const splitMessage = (message) => {
  const words = message.split(" ");
  const chunks = [];
  let currentChunk = "";
  words.forEach((word, index) => {
    if ((currentChunk + word).length <= 25) {
      currentChunk += word + " ";
    } else {
      chunks.push(currentChunk.trim());
      currentChunk = word + " ";
    }
    if (index === words.length - 1) {
      chunks.push(currentChunk.trim());
    }
  });
  return chunks;
};

class Video extends Component {
  constructor(props) {
    super(props);

    this.localVideoref = React.createRef();

    this.videoAvailable = false;
    this.audioAvailable = false;

    this.state = {
      video: false,
      audio: false,
      screen: false,
      showModal: false,
      screenAvailable: false,
      messages: [],
      message: "",
      showChat: false,
      newmessages: 0,
      askForUsername: true,
      username: userName,
      showParticipants: false,
      participants: [
        {
          id: 1,
          name: "Participant 1",
          profilePic: "https://example.com/profile1.jpg",
          audio: true,
          video: true,
        },
        {
          id: 2,
          name: "Participant 2",
          profilePic: "https://example.com/profile2.jpg",
          audio: false,
          video: false,
        },
        // Add more participants as needed
      ],
    };

    connections = {};
    this.getPermissions();
    this.messagesEndRef = React.createRef();
  }

  scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.messages.length !== this.state.messages.length) {
      this.scrollToBottom();
    }
  }

  toggleParticipantsList = () => {
    this.setState((prevState) => ({
      showParticipants: !prevState.showParticipants,
    }));
  };

  getPermissions = async () => {
    try {
      await navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => (this.videoAvailable = true))
        .catch(() => (this.videoAvailable = false));

      await navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => (this.audioAvailable = true))
        .catch(() => (this.audioAvailable = false));

      if (navigator.mediaDevices.getDisplayMedia) {
        this.setState({ screenAvailable: true });
      } else {
        this.setState({ screenAvailable: false });
      }

      if (this.videoAvailable || this.audioAvailable) {
        navigator.mediaDevices
          .getUserMedia({
            video: this.videoAvailable,
            audio: this.audioAvailable,
          })
          .then((stream) => {
            window.localStream = stream;
            this.localVideoref.current.srcObject = stream;
          })
          .then((stream) => {})
          .catch((e) => console.log(e));
      }
    } catch (e) {
      console.log(e);
    }
  };

  getMedia = () => {
    this.setState(
      {
        video: this.videoAvailable,
        audio: this.audioAvailable,
      },
      () => {
        this.getUserMedia();
        this.connectToSocketServer();
      }
    );
  };

  getUserMedia = () => {
    if (
      (this.state.video && this.videoAvailable) ||
      (this.state.audio && this.audioAvailable)
    ) {
      navigator.mediaDevices
        .getUserMedia({ video: this.state.video, audio: this.state.audio })
        .then(this.getUserMediaSuccess)
        .then((stream) => {})
        .catch((e) => console.log(e));
    } else {
      try {
        let tracks = this.localVideoref.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      } catch (e) {}
    }
  };

  getUserMediaSuccess = (stream) => {
    try {
      window.localStream.getTracks().forEach((track) => track.stop());
    } catch (e) {
      console.log(e);
    }

    window.localStream = stream;
    this.localVideoref.current.srcObject = stream;

    for (let id in connections) {
      if (id === socketId) continue;

      connections[id].addStream(window.localStream);

      connections[id].createOffer().then((description) => {
        connections[id]
          .setLocalDescription(description)
          .then(() => {
            socket.emit(
              "signal",
              id,
              JSON.stringify({ sdp: connections[id].localDescription })
            );
          })
          .catch((e) => console.log(e));
      });
    }

    stream.getTracks().forEach(
      (track) =>
        (track.onended = () => {
          this.setState(
            {
              video: false,
              audio: false,
            },
            () => {
              try {
                let tracks = this.localVideoref.current.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
              } catch (e) {
                console.log(e);
              }

              let blackSilence = (...args) =>
                new MediaStream([this.black(...args), this.silence()]);
              window.localStream = blackSilence();
              this.localVideoref.current.srcObject = window.localStream;

              for (let id in connections) {
                connections[id].addStream(window.localStream);

                connections[id].createOffer().then((description) => {
                  connections[id]
                    .setLocalDescription(description)
                    .then(() => {
                      socket.emit(
                        "signal",
                        id,
                        JSON.stringify({
                          sdp: connections[id].localDescription,
                        })
                      );
                    })
                    .catch((e) => console.log(e));
                });
              }
            }
          );
        })
    );
  };

  getDislayMedia = () => {
    if (this.state.screen) {
      if (navigator.mediaDevices.getDisplayMedia) {
        navigator.mediaDevices
          .getDisplayMedia({ video: true, audio: true })
          .then(this.getDislayMediaSuccess)
          .then((stream) => {})
          .catch((e) => console.log(e));
      }
    }
  };

  getDislayMediaSuccess = (stream) => {
    try {
      window.localStream.getTracks().forEach((track) => track.stop());
    } catch (e) {
      console.log(e);
    }

    window.localStream = stream;
    this.localVideoref.current.srcObject = stream;

    for (let id in connections) {
      if (id === socketId) continue;

      connections[id].addStream(window.localStream);

      connections[id].createOffer().then((description) => {
        connections[id]
          .setLocalDescription(description)
          .then(() => {
            socket.emit(
              "signal",
              id,
              JSON.stringify({ sdp: connections[id].localDescription })
            );
          })
          .catch((e) => console.log(e));
      });
    }

    stream.getTracks().forEach(
      (track) =>
        (track.onended = () => {
          this.setState(
            {
              screen: false,
            },
            () => {
              try {
                let tracks = this.localVideoref.current.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
              } catch (e) {
                console.log(e);
              }

              let blackSilence = (...args) =>
                new MediaStream([this.black(...args), this.silence()]);
              window.localStream = blackSilence();
              this.localVideoref.current.srcObject = window.localStream;

              this.getUserMedia();
            }
          );
        })
    );
  };

  gotMessageFromServer = (fromId, message) => {
    var signal = JSON.parse(message);

    if (fromId !== socketId) {
      if (signal.sdp) {
        connections[fromId]
          .setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(() => {
            if (signal.sdp.type === "offer") {
              connections[fromId]
                .createAnswer()
                .then((description) => {
                  connections[fromId]
                    .setLocalDescription(description)
                    .then(() => {
                      socket.emit(
                        "signal",
                        fromId,
                        JSON.stringify({
                          sdp: connections[fromId].localDescription,
                        })
                      );
                    })
                    .catch((e) => console.log(e));
                })
                .catch((e) => console.log(e));
            }
          })
          .catch((e) => console.log(e));
      }

      if (signal.ice) {
        connections[fromId]
          .addIceCandidate(new RTCIceCandidate(signal.ice))
          .catch((e) => console.log(e));
      }
    }
  };

  changeCssVideos = (main) => {
    const totalVideos = elms + 1; // Including your video
    const containerWidth = main.offsetWidth;

    const calculateDimensions = () => {
      let minWidth = "30%";
      if ((containerWidth * 30) / 100 < 300) {
        minWidth = "300px";
      }

      let videoWidth = "";
      let videoHeight = "";

      switch (totalVideos) {
        case 1:
          videoWidth = "100%";
          break;
        case 2:
          videoWidth = "70%";
          break;
        case 3:
        case 4:
          videoWidth = "45%";
          videoHeight = "50%";
          break;
        default:
          videoWidth = `${100 / totalVideos}%`;
          break;
      }

      return { minWidth, videoWidth, videoHeight };
    };

    const setStyles = (videoContainer, isMainUser) => {
      const { minWidth, videoWidth, videoHeight } = calculateDimensions();

      videoContainer.style.minWidth = minWidth;
      videoContainer.style.width = videoWidth;
      videoContainer.style.height = videoHeight;

      if (isMainUser) {
        videoContainer.style.borderRadius = "5px";
        videoContainer.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      } else {
        videoContainer.style.borderRadius = "15px";
        videoContainer.style.boxShadow = "0 2px 4px rgba(0, 0, 0, 0.1)";
      }
    };

    let videoContainers = main.querySelectorAll(".video-container");
    videoContainers.forEach((container, index) => {
      const isMainUser = index === 0;
      setStyles(container, isMainUser);
    });

    return calculateDimensions();
  };

  connectToSocketServer = () => {
    socket = io.connect(server_url, { secure: true });

    socket.on("signal", this.gotMessageFromServer);

    socket.on("connect", () => {
      socket.emit("join-call", window.location.href);
      socketId = socket.id;

      socket.on("chat-message", this.addMessage);

      socket.on("user-left", (id) => {
        let video = document.querySelector(`[data-socket="${id}"]`);
        if (video !== null) {
          elms--;
          video.parentNode.removeChild(video);

          let main = document.getElementById("main");
          this.changeCssVideos(main);
        }
      });

      socket.on("user-joined", (id, clients) => {
        clients.forEach((socketListId) => {
          connections[socketListId] = new RTCPeerConnection(
            peerConnectionConfig
          );
          // Wait for their ice candidate
          connections[socketListId].onicecandidate = function (event) {
            if (event.candidate != null) {
              socket.emit(
                "signal",
                socketListId,
                JSON.stringify({ ice: event.candidate })
              );
            }
          };

          // Wait for their video stream
          connections[socketListId].onaddstream = (event) => {
            // TODO mute button, full screen button
            var searchVidep = document.querySelector(
              `[data-socket="${socketListId}"]`
            );
            if (searchVidep !== null) {
              // if i don't do this check it make an empyt square
              searchVidep.srcObject = event.stream;
            } else {
              elms = clients.length;
              let main = document.getElementById("main");
              let cssMesure = this.changeCssVideos(main);

              let video = document.createElement("video");

              let css = {
                minWidth: cssMesure.minWidth,
                minHeight: cssMesure.minHeight,
                maxHeight: "100%",
                margin: "10px",
                borderStyle: "solid",
                borderColor: "#bdbdbd",
                objectFit: "fill",
              };
              for (let i in css) video.style[i] = css[i];

              video.style.setProperty("width", cssMesure.width);
              video.style.setProperty("height", cssMesure.height);
              video.setAttribute("data-socket", socketListId);
              video.srcObject = event.stream;
              video.autoplay = true;
              video.playsinline = true;

              main.appendChild(video);
            }
          };

          // Add the local video stream
          if (window.localStream !== undefined && window.localStream !== null) {
            connections[socketListId].addStream(window.localStream);
          } else {
            let blackSilence = (...args) =>
              new MediaStream([this.black(...args), this.silence()]);
            window.localStream = blackSilence();
            connections[socketListId].addStream(window.localStream);
          }
        });

        if (id === socketId) {
          for (let id2 in connections) {
            if (id2 === socketId) continue;

            try {
              connections[id2].addStream(window.localStream);
            } catch (e) {}

            connections[id2].createOffer().then((description) => {
              connections[id2]
                .setLocalDescription(description)
                .then(() => {
                  socket.emit(
                    "signal",
                    id2,
                    JSON.stringify({ sdp: connections[id2].localDescription })
                  );
                })
                .catch((e) => console.log(e));
            });
          }
        }
      });
    });
  };

  silence = () => {
    let ctx = new AudioContext();
    let oscillator = ctx.createOscillator();
    let dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    ctx.resume();
    return Object.assign(dst.stream.getAudioTracks()[0], { enabled: false });
  };
  black = ({ width = 640, height = 480 } = {}) => {
    let canvas = Object.assign(document.createElement("canvas"), {
      width,
      height,
    });
    canvas.getContext("2d").fillRect(0, 0, width, height);
    let stream = canvas.captureStream();
    return Object.assign(stream.getVideoTracks()[0], { enabled: false });
  };

  handleVideo = () =>
    this.setState({ video: !this.state.video }, () => this.getUserMedia());
  handleAudio = () =>
    this.setState({ audio: !this.state.audio }, () => this.getUserMedia());
  handleScreen = () =>
    this.setState({ screen: !this.state.screen }, () => this.getDislayMedia());

  handleEndCall = () => {
    try {
      let tracks = this.localVideoref.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    } catch (e) {}
    window.location.href = "/homepage/LMS Meet";
  };

  openChat = () => {
    this.setState((prevState) => ({
      showChat: true,
      newmessages: 0,
    }));
  };
  closeChat = () => {
    this.setState({
      showChat: false,
    });
  };

  openLink = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      newmessages: 0,
    }));
  };
  closeLink = () => {
    this.setState({
      showModal: false,
    });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal,
      newmessages: 0,
    }));
  };

  handleMessage = (e) => this.setState({ message: e.target.value });

  addMessage = (data, sender, socketIdSender) => {
    this.setState((prevState) => ({
      messages: [...prevState.messages, { sender: sender, data: data }],
    }));
    if (socketIdSender !== socketId) {
      this.setState({ newmessages: this.state.newmessages + 1 });
    }
  };

  handleUsername = (e) => this.setState({ username: e.target.value });

  sendMessage = () => {
    socket.emit("chat-message", this.state.message, this.state.username);
    this.setState({ message: "", sender: this.state.username });
  };

  copyUrl = () => {
    let text = window.location.href;
    if (!navigator.clipboard) {
      let textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
        this.setState({ copiedLink: text });
        message.success("Link copied to clipboard!");
      } catch (err) {
        message.error("Failed to copy");
      }
      document.body.removeChild(textArea);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        this.setState({ copiedLink: text });
        message.success("Link copied to clipboard!");
      }.bind(this),
      () => {
        message.error("Failed to copy");
      }
    );
  };

  connect = () =>
    this.setState({ askForUsername: false }, () => this.getMedia());

  isChrome = function () {
    let userAgent = (navigator && (navigator.userAgent || "")).toLowerCase();
    let vendor = (navigator && (navigator.vendor || "")).toLowerCase();
    let matchChrome = /google inc/.test(vendor)
      ? userAgent.match(/(?:chrome|crios)\/(\d+)/)
      : null;
    // let matchFirefox = userAgent.match(/(?:firefox|fxios)\/(\d+)/)
    // return matchChrome !== null || matchFirefox !== null
    return matchChrome !== null;
  };

  render() {
    if (this.isChrome() === false) {
      return (
        <div className="bg-white w-30 h-auto p-20 min-w-400px text-center mx-auto mt-50 justify-center">
          <h1>Sorry, this works only with Google Chrome</h1>
        </div>
      );
    }

    return (
      <div className="h-screen w-full flex flex-row gap-2 justify-center">
        {this.state.askForUsername === true ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-3xl font-bold mb-8">Welcome to LMS Meet</h1>
            <div className="flex items-center mb-4">
              <h2 className="text-lg font-semibold ">Hi </h2>
              <h2 className="text-lg font-semibold ml-2">
                {this.state.username}
              </h2>
              <PiHandWavingFill className="text-orange-300 text-xl ml-4" />
            </div>
            <div className="relative w-full max-w-xl bg-black rounded-lg overflow-hidden mb-6">
              <video
                id="my-video"
                ref={this.localVideoref}
                autoPlay
                muted
                className="w-full h-full object-cover scale-x-[-1]"
              ></video>
            </div>
            <button
              className="bg-primary text-white px-4 py-2 rounded-md hover:bg-secondary transition-colors duration-300"
              onClick={this.connect}
            >
              Start Meeting
            </button>
          </div>
        ) : (
          <div>
            {this.state.showChat && (
              <div className="fixed right-4 bottom-4 max-w-sm bg-[#f5f5f5] rounded-lg shadow-md">
                <div className="flex justify-between items-center bg-primary rounded-t-lg px-4 py-2">
                  <h2 className="text-lg font-semibold text-white">
                    Chat Room
                  </h2>
                  <button className="text-gray-600" onClick={this.closeChat}>
                    <AiOutlineClose />
                  </button>
                </div>
                <div
                  className="overflow-hidden h-64 p-4"
                  style={{
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  {this.state.messages.length > 0 ? (
                    this.state.messages.map((item, index) => (
                      <div key={index} className="flex flex-col mb-2 ">
                        <div className="flex items-center mt-2">
                          <img
                            src={item.profilePic || defaultProfilePic}
                            alt="Profile"
                            className="w-8 h-8 rounded-full mr-2"
                          />
                          <div className="ml-2">
                            <p className="font-normal text-gray-400 text-xs">
                              {item.sender}
                            </p>
                            {splitMessage(item.data).map((chunk, i) => (
                              <div
                                key={i}
                                className=" bg-primary text-white  rounded-tl-2xl rounded-tr-2xl rounded-br-2xl p-2   mb-1 max-w-xs text-sm"
                              >
                                {chunk}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-500">No messages yet</p>
                  )}
                  <div ref={this.messagesEndRef} />
                </div>
                <div className="flex items-center p-2">
                  <input
                    placeholder="Type a message..."
                    value={this.state.message}
                    onChange={(e) => this.handleMessage(e)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.sendMessage();
                      }
                    }}
                    className="flex-1 text-xs px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-primary"
                  />
                  <button
                    className="ml-1 bg-primary text-white rounded-full p-1"
                    onClick={this.sendMessage}
                  >
                    <BsFillSendFill className="text-xs" />
                  </button>
                </div>
              </div>
            )}
            {this.state.showParticipants && (
              <Modal
                title="Participants"
                visible={this.state.showParticipants}
                onCancel={this.toggleParticipantsList}
                footer={null}
                width={300}
              >
                <div className="p-2">
                  {this.state.participants.map((participant) => (
                    <div
                      key={participant.id}
                      className="flex items-center hover:rounded-tl-2xl hover:rounded-tr-2xl hover:rounded-br-2xl hover:bg-gray-200 p-2 mb-4"
                    >
                      <Avatar
                        src={participant.profilePic}
                        alt="Participant Profile"
                      />
                      <div className="ml-3 flex items-center justify-between">
                        <p className="font-semibold mr-8">{participant.name}</p>

                        {participant.audio ? (
                          <FaMicrophone className="text-primary mr-2" />
                        ) : (
                          <FaMicrophoneSlash className="text-secondary mr-2" />
                        )}

                        {participant.video ? (
                          <FaVideo className="text-primary" />
                        ) : (
                          <FaVideoSlash className="text-secondary" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </Modal>
            )}

            <div className="container p-2">
              <Row id="main" className="flex-container">
                <video
                  id="my-video"
                  ref={this.localVideoref}
                  autoPlay
                  muted
                  className="border-solid border-bdbdbd m-10 object-fill rounded-lg w-full h-full scale-x-[-1]"
                ></video>
              </Row>
            </div>
            <div className="flex items-center justify-center w-full gap-8  p-2">
              <button
                className={`${
                  this.state.video ? "bg-gray-100" : "bg-gray-100"
                } text-white rounded-full p-2`}
                onClick={this.handleVideo}
              >
                {this.state.video ? (
                  <FaVideo className="text-gray-700" />
                ) : (
                  <FaVideoSlash className="text-gray-700" />
                )}
              </button>

              <button
                className={`${
                  this.state.audio ? "bg-gray-100" : "bg-gray-100"
                } text-white rounded-full p-2`}
                onClick={this.handleAudio}
              >
                {this.state.audio ? (
                  <FaMicrophone className="text-gray-700" />
                ) : (
                  <FaMicrophoneSlash className="text-gray-700" />
                )}
              </button>

              <button
                className="bg-gray-100  rounded-full p-2"
                // onClick={this.userIconClickHandler}
                onClick={this.toggleParticipantsList}
              >
                <FaUsers className="text-gray-700" />
              </button>

              <button
                className="bg-red-500  rounded-full p-2"
                onClick={this.handleEndCall}
              >
                <FaPhone className=" text-white" />
              </button>

              {this.state.screenAvailable === true && (
                <button
                  className={`${
                    this.state.screen ? "bg-primary" : "bg-gray-100"
                  }  rounded-full p-2`}
                  onClick={this.handleScreen}
                >
                  {this.state.screen ? (
                    <BsFillStopFill className="text-gray-700" />
                  ) : (
                    <MdScreenShare className="text-gray-700" />
                  )}
                </button>
              )}
              <button
                className="bg-gray-100  rounded-full p-2"
                onClick={this.copyUrl}
              >
                <FaCopy className="text-gray-700" />
              </button>
              <Badge
                count={this.state.newmessages}
                max={999}
                color="secondary"
                onClick={this.openChat}
              >
                <button
                  className="bg-gray-100  rounded-full p-2"
                  onClick={this.openChat}
                >
                  <BiSolidMessageMinus className="text-gray-700" />
                </button>
              </Badge>
            </div>
            <Modal
              title="Your Meeting's Ready"
              visible={this.state.copiedLink !== ""}
              onCancel={() => this.setState({ copiedLink: "" })}
              width={300}
              footer={null}
              style={{ position: "relative", left: 0, bottom: 0 }}
            >
              <div className="p-2 w-auto">
                {" "}
                <p className="py-1 text-xs">
                  Share this meeting link with others you want in the meeting
                </p>
                <div className="flex border mt-4 p-1 px-2 justify-between rounded-md bg-gray-100 items-center">
                  <input
                    value={window.location.href}
                    disabled={true}
                    className="p-1 rounded"
                    style={{ fontSize: "0.625rem" }}
                  />
                  <Tooltip>
                    <button
                      onClick={this.copyUrl}
                      className="ml-1 text-black p-1 rounded-md"
                      data-tip="Copy invite link"
                      style={{
                        display: this.state.copiedLink ? "none" : "block",
                        fontSize: "0.625rem",
                      }}
                    >
                      <FiCopy className="w-3 h-3" />
                    </button>
                  </Tooltip>
                </div>
                <div className="bg-white p-1 mt-4 rounded-md  w-full max-w-xs">
                  {" "}
                  <div className="flex items-center justify-between mb-1">
                    <div className="flex items-center gap-1">
                      <BsFillShieldLockFill className="text-blue-600 bg-blue-100  rounded-full border-blue-600 border p-1 text-xl mr-0.5" />{" "}
                    </div>
                    <span className="text-gray-700 ml-1 text-xs">
                      People who use this meeting link must get your permission
                      before they can join.
                    </span>
                  </div>
                </div>
                <div className="flex items-center mt-2 mb-1">
                  <h2 className="text-xs text-gray-500 ">Joined as </h2>
                  <h2 className="text-xs text-gray-500 ml-2">
                    {this.state.username}
                  </h2>
                </div>
              </div>
            </Modal>
          </div>
        )}
      </div>
    );
  }
}

export default Video;
