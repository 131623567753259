import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import {
  FaSave,
  FaEdit,
  FaTrash,
  FaUpload,
  FaPlus,
  FaTimes,
} from "react-icons/fa";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import ModalVideo from "react-modal-video"; // Import the ModalVideo component
import "react-modal-video/css/modal-video.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@mui/material";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

function Content() {
  const { subjectName,selectedSectionId, sectionId, topicId } = useParams();

  const [editorContent, setEditorContent] = useState("");
  const [savedContent, setSavedContent] = useState([]);
  const [editingContent, setEditingContent] = useState(null);
  const [contentSaved, setContentSaved] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(true); // Default to editor mode
  const [uploadMethod, setUploadMethod] = useState("editor"); // Default to editor mode
  const [selectedFile, setSelectedFile] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [editingFileContent, setEditingFileContent] = useState(null);
  const [hoveredContent, setHoveredContent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showCancelIcon, setShowCancelIcon] = useState(false);

  const openModal = (url) => {
    setIsOpen(true);
    setVideoUrl(url);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const contentResponse = await axios.get(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents`
        );
        setSavedContent(contentResponse.data);
        console.log("New:", contentResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [subjectName, sectionId, topicId]);

  const handleEdit = (content) => {
    if (content.file) {
      // Editing a file content, set the state variable accordingly
      setSelectedFile({ name: content.file }); // Use an object to mimic the File object
      setEditingFileContent(content); // Set the editingFileContent
      setEditorContent(content.file);
    } else {
      // Editing text content
      setEditingContent(content);
      setEditorContent(content.body);
    }
    // Clear the selected file
    setIsFileSelected(false);
  };

  const handleCancelEdit = () => {
    setEditorContent("");
    setEditingContent(null);
    setIsEditorOpen(false); // Close the editor
  };

  const handleCancelNewContent = () => {
    setEditorContent("");
    setContentSaved(false);
    setIsEditorOpen(false);
    setShowOptions(false);
    setShowCancelIcon(false); // Reset showCancelIcon to false
  };

  const handleFileCancel = () => {
    setSelectedFile(null);
    setIsFileSelected(false);
    setEditingFileContent(null);
    setIsEditorOpen(false);
    setShowOptions(false);
    setShowCancelIcon(false); // Reset showCancelIcon to false
  };

  const handleSaveEdit = async () => {
    try {
      if (editingFileContent) {
        if (!selectedFile) {
          console.error("No file selected for editing.");
          return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile); // Append the new file data

        // Use PUT request to update the file content
        const response = await axios.put(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents/${editingFileContent._id}`,
          formData, // Send the new file data
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Update the savedContent with the edited file content
        setSavedContent((prevContent) =>
          prevContent.map((content) =>
            content._id === editingFileContent._id
              ? { ...content, file: response.data.file } // Update the file data
              : content
          )
        );

        // Clear the selected file and reset the editingFileContent state
        setSelectedFile(null);
        setEditingFileContent(null);
      } else if (editingContent) {
        // Handle editing text content
        const updatedContent = {
          body: editorContent,
        };

        await axios.put(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents/${editingContent._id}`,
          updatedContent
        );

        setSavedContent((prevContent) =>
          prevContent.map((content) =>
            content._id === editingContent._id
              ? { ...content, body: editorContent }
              : content
          )
        );
      }

      setEditorContent("");
      setEditingContent(null);

      // Content has been saved, so set contentSaved to true
      setContentSaved(true);

      // Close the editor after saving
      setIsEditorOpen(false);

      // Close the options after saving
      setShowOptions(false);
    } catch (error) {
      console.error("Error saving edited content:", error);
    }
  };

  const handleDelete = async (contentId) => {
    try {
      Modal.confirm({
        title: "Are you sure you want to delete this content?",
        icon: <ExclamationCircleFilled />,
        content: "",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          try {
            await axios.delete(
              `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents/${contentId}`
            );

            setSavedContent((prevContent) =>
              prevContent.filter((content) => content._id !== contentId)
            );
          } catch (error) {
            console.error("Error deleting content:", error);
          }
        },
      });
    } catch (error) {
      console.error("Error showing delete confirmation modal:", error);
    }
  };

  const handleSave = async () => {
    try {
      if (!subjectName) {
        console.error(
          "Subject is not defined or does not have subjectName property."
        );
        return;
      }

      if (uploadMethod === "editor" && editorContent.trim() !== "") {
        // Handle text content
        const newContent = {
          body: editorContent,
        };

        await axios.post(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents`,
          newContent
        );
      } else if (uploadMethod === "file" && selectedFile) {
        // Handle file upload
        const formData = new FormData();
        formData.append("file", selectedFile);

        const response = await axios.post(
          `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Update the uploadedFiles state to include the newly uploaded file
        setSavedContent([...savedContent, response.data]);

        // Reset the selected file
        setSelectedFile(null);
        setShowCancelIcon(false);
      } else {
        console.error("Invalid upload method or no content to save.");
        return;
      }

      // Reset the editor content
      setEditorContent("");

      // Close the editor and options after saving
      setIsEditorOpen(false);
      handleToggleOptions();

      // Fetch and update the content list
      const response = await axios.get(
        `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents`
      );
      setSavedContent(response.data);
      setShowCancelIcon(false);
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  function openFileInNewPage(fileName, imageUrl) {
    window.open(`${imageUrl}/CollegeNotesUploads/${fileName}`, "_blank");
  }

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
    setIsEditorOpen(true); // Add this line to open the editor when options are toggled
  };

  return (
    <div className="bg-white h-auto rounded-md border drop-shadow">
      <div className="bg-primary shadow-lg rounded-t-lg p-4 text-center">
        <h1 className="text-3xl text-white font-semibold mb-2">
          Content Editor
        </h1>
        <p className="text-white">Edit and manage content for your topic.</p>
      </div>

      <div className="flex justify-end mt-4 mx-5">
        <Tooltip
          title={showCancelIcon ? "Cancel" : "Select Content Type"}
          arrow
          placement="left"
          componentsProps={{
            tooltip: {
              sx: {
                background: "#105d50",
                "& .MuiTooltip-arrow": {
                  color: "#105d50",
                },
                fontSize: "0.8em",
                color: "white",
                boxShadow: "revert-layer",
                // border: '1px solid red',
              },
              color: {},
            },
          }}
        >
          <button
            className="bg-primary hover:bg-secondary text-white rounded-lg p-2 focus:outline-none"
            onClick={() => {
              handleToggleOptions();
              setShowCancelIcon(!showCancelIcon);
            }}
          >
            {showCancelIcon ? (
              <FaTimes className="transition-opacity duration-900 ease-in-out" />
            ) : (
              <FaPlus className="transition-opacity duration-900 ease-in-out" />
            )}
          </button>
        </Tooltip>
      </div>

      <div className="p-3">
        {showOptions && (
          <div className="flex items-center mb-4">
            <button
              className={`mr-4 ${
                uploadMethod === "editor"
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-600"
              } py-2 px-4 rounded-lg hover:bg-primary hover:text-white transition duration-300 ease-in-out`}
              onClick={() => setUploadMethod("editor")}
            >
              <FaEdit className="inline-block mr-1" />
              Edit Content
            </button>
            <button
              className={`mr-4 ${
                uploadMethod === "file"
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-gray-600"
              } py-2 px-4 rounded-lg hover:bg-primary hover:text-white transition duration-300 ease-in-out`}
              onClick={() => setUploadMethod("file")}
            >
              <FaUpload className="inline-block mr-1" />
              Upload File
            </button>
          </div>
        )}
        {/* Content Editor */}
        {showOptions && uploadMethod === "editor" && isEditorOpen && (
          <div>
            <Editor
              apiKey="pri6d2yw0lahxwaiaqxk8ks30svv1h5a8ybfi144tzm63pwx"
              value={editorContent}
              onEditorChange={(content) => setEditorContent(content)}
              init={{
                branding: false,
                menubar: true,
                plugins:
                  "preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                toolbar:
                  "formatselect | bold italic underline strikethrough | blocks fontfamily fontsize | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                image_advtab: true,
              }}
            />
            <div className="mt-4 flex justify-end">
              <button
                className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition duration-300 ease-in-out mr-2"
                onClick={handleSave}
              >
                <FaSave className="inline-block mr-2" />
                Save
              </button>
              <button
                className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out mr-2"
                onClick={() => {
                  handleToggleOptions();
                  handleCancelNewContent();
                }}
              >
                <FaTimes className="inline-block mr-2" />
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* File Upload */}
        {showOptions && uploadMethod === "file" && (
          <div className="flex flex-col items-center">
            <label
              htmlFor="file-upload"
              className="block font-medium text-gray-700"
            >
              Choose a File
            </label>
            <input
              type="file"
              id="file-upload"
              onChange={(e) => {
                setSelectedFile(e.target.files[0]);
                setIsFileSelected(true); // Set to true when a file is selected
              }}
              className="mt-2 border-2 border-gray-300 border-dashed py-2 px-3 rounded-lg focus:outline-none focus:border-primary"
            />

            {selectedFile && (
              <p className="mt-2 text-gray-600">
                Selected File: {selectedFile.name}
              </p>
            )}

            <div className="mt-4 space-x-2">
              <button
                className="py-3 px-4 text-[15px] bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                onClick={handleSave}
              >
                Upload
              </button>
              <button
                className="py-3 px-4 text-[15px] bg-red-500 text-white rounded-lg hover:bg-red-600 hover:text-green"
                onClick={() => {
                  handleToggleOptions();
                  handleCancelNewContent();
                  handleFileCancel();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Content Listing */}
        <div className="mt-8">
          {savedContent.map((content) => (
            <div
              key={content._id}
              className="bg-gray-100 p-4 rounded-lg"
              onMouseEnter={() => setHoveredContent(content._id)}
              onMouseLeave={() => setHoveredContent(null)}
            >
              <div className="flex justify-end mb-8">
                <div className="flex justify-end mb-2 space-x-2 fixed">
                  <button
                    className="text-green-500 hover:text-green-600"
                    onClick={() => handleEdit(content)}
                  >
                    <FaEdit className="inline-block mr-1" />
                  </button>
                  <button
                    className="text-red-500 hover:text-red-600"
                    onClick={() => handleDelete(content._id)}
                  >
                    <FaTrash className="inline-block mr-1" />
                  </button>
                </div>
              </div>

              {editingContent === content ? (
                // Display text editor for editing text content
                <>
                  <Editor
                    apiKey="waqw42ketr4kfj67z0q2wgs1qslqjylk8p1ip4rfc3wei3cn"
                    value={editorContent}
                    onEditorChange={(content) => setEditorContent(content)}
                    init={{
                      branding: false,
                      menubar: true,
                      plugins:
                        "preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                      toolbar:
                        "formatselect | bold italic underline strikethrough | blocks fontfamily fontsize | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                      image_advtab: true,
                    }}
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      className="bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition duration-300 ease-in-out mr-2"
                      onClick={handleSaveEdit}
                    >
                      <FaSave className="inline-block mr-2" />
                      Save
                    </button>
                    <button
                      className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-300 ease-in-out"
                      onClick={() => {
                        handleCancelEdit();
                      }}
                    >
                      <FaTimes className="inline-block mr-2" />
                      Cancel
                    </button>
                  </div>
                </>
              ) : editingFileContent === content ? (
                // Display file content editor for editing file content
                <>
                  <div className="flex flex-col items-center">
                    <label
                      htmlFor="file-upload"
                      className="block font-medium text-gray-700"
                    >
                      Choose a File
                    </label>
                    <input
                      type="file"
                      id="file-upload"
                      name="file"
                      onChange={(e) => {
                        setSelectedFile(e.target.files[0]);
                        setIsFileSelected(true); // Set to true when a file is selected
                      }}
                      className="mt-2 border-2 border-gray-300 border-dashed py-2 px-3 rounded-lg focus:outline-none focus:border-primary"
                    />

                    {selectedFile && (
                      <p className="mt-2 text-gray-600">
                        Selected File: {selectedFile.name}
                      </p>
                    )}

                    <div className="mt-4 space-x-2">
                      <button
                        className="py-3 px-4 text-[15px] bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                        onClick={handleSaveEdit}
                      >
                        Upload
                      </button>
                      <button
                        className="py-3 px-4 text-[15px] bg-red-500 text-white rounded-lg hover:bg-red-600 hover:text-green"
                        onClick={() => {
                          handleToggleOptions();
                          handleCancelNewContent();
                          handleFileCancel();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              ) : content.file ? (
                // Display file content with a link to download the file
                <div className="mb-4">
                  {content.file.endsWith(".pdf") ? (
                    <div className="flex justify-center w-full">
                      <button
                        onClick={() =>
                          openFileInNewPage(content.file, ImageUrl)
                        }
                        className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-6 border border-gray-300 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-between"
                      >
                        <div className="flex items-center">
                          <div className="ml-4">
                            <span className="text-xl font-bold">
                              {content.file}
                            </span>
                            <p className="text-gray-600">Click to view PDF</p>
                          </div>
                        </div>
                      </button>
                    </div>
                  ) : content.file.endsWith(".docx") ? (
                    <div className="flex w-full justify-center">
                      <button
                        onClick={() =>
                          openFileInNewPage(content.file, ImageUrl)
                        }
                        className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-6 border border-gray-300 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-between"
                      >
                        <div className="flex items-center">
                          <div className="ml-4">
                            <span className="text-xl font-bold">
                              {content.file}
                            </span>
                            <p className="text-gray-600">Click to view DOC</p>
                          </div>
                        </div>
                      </button>
                    </div>
                  ) : content.file.endsWith(".pptx") ? (
                    <div className="flex justify-center w-full">
                      <button
                        onClick={() =>
                          openFileInNewPage(content.file, ImageUrl)
                        }
                        className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-6 border border-gray-300 rounded-lg shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer flex items-center justify-between"
                      >
                        <div className="flex items-center">
                          <div className="ml-4">
                            <span className="text-xl font-bold">
                              {content.file}
                            </span>
                            <p className="text-gray-600">Click to view PPT</p>
                          </div>
                        </div>
                      </button>
                    </div>
                  ) : content.file.endsWith(".mp4") ||
                    content.file.endsWith(".webm") ? (
                    <div className="flex justify-center w-full">
                      <button
                        className="flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded "
                        onClick={() =>
                          openModal(`${ImageUrl}/CollegeNotesUploads/${content.file}`)
                        }
                      >
                        <FontAwesomeIcon icon={faPlay} className="mr-2" /> Open
                        Video
                      </button>
                    </div>
                  ) : content.file.endsWith(".mp3") ? (
                    <div className="flex justify-center">
                      <div className="w-[500px]">
                        <div className="bg-white rounded-lg p-3">
                          <audio
                            controls
                            className="flex justify-center w-full bg-gray-00"
                          >
                            <source
                              src={`${ImageUrl}/CollegeNotesUploads/${content.file}`}
                              type="audio/mpeg"
                            />
                            Your browser does not support the audio element.
                          </audio>
                        </div>
                      </div>
                    </div>
                  ) : content.file.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                    // Code for Image files
                    <div className="flex justify-center w-full">
                      <img
                        src={`${ImageUrl}/CollegeNotesUploads/${content.file}`}
                        alt={content.file}
                        width="200" // Set your desired width in pixels
                        height="150" // Set your desired height in pixels
                      />
                    </div>
                  ) : (
                    <div className="w-full bg-white p-4 rounded shadow-lg">
                      <h2 className="text-2xl font-bold text-red-600 mb-4">
                        {content.fileContent}
                      </h2>
                      <p className="mb-4">{content.file}</p>
                      <a
                        href={`${ImageUrl}/CollegeNotesUploads/${content.file}`}
                        target="_blank"
                        className="text-blue-500 hover:underline"
                      >
                        Download File
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <div dangerouslySetInnerHTML={{ __html: content.body }}></div>
              )}
            </div>
          ))}
        </div>
        <ModalVideo
          channel="custom"
          isOpen={isOpen}
          url={videoUrl}
          onClose={() => setIsOpen(false)}
        />
      </div>
    </div>
  );
}

export default Content;
