import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Switch } from "antd";
import { FormGroup, IconButton, Stack } from "@mui/material";
import camera from "../Images/camera.png";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";

const InstituteAdminForm = () => {
  const [editMode, setEditMode] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const navigate = useNavigate();

  const { id } = useParams();

  const apiUrl = process.env.REACT_APP_API_URL;
  const ImageUrl = process.env.REACT_APP_IMAGE_URL;

  const initialFormData = {
    uploadProfilePic: null,
    instituteAdminId: "",
    name: "",
    emailId: "",
    password: "",
    mobileNumber: "",
    permission: {
      add: false,
      edit: false,
      delete: false,
    },
    instituteUniqueId: localStorage.getItem("Unique_id"),
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleProfilePicUpload = (e) => {
    const uploadProfilePic = e.target.files[0];

    // Check if a file was selected
    if (uploadProfilePic) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the image URL to the reader's result, which is the base64 data URL of the uploaded image
        setImageUrl(e.target.result);
        console.log(uploadProfilePic);
      };

      reader.readAsDataURL(uploadProfilePic);
      setProfilePic(uploadProfilePic);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formAppendData = new FormData();
    formAppendData.append("uploadProfilePic", profilePic);
    formAppendData.append("instituteAdminId", formData.instituteAdminId);
    formAppendData.append("name", formData.name);
    formAppendData.append("emailId", formData.emailId);
    formAppendData.append("password", formData.password);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("permissionAdd", formData.permission.add);
    formAppendData.append("permissionEdit", formData.permission.edit);
    formAppendData.append("permissionDelete", formData.permission.delete);
    formAppendData.append(
      "instituteUniqueId",
      localStorage.getItem("Unique_id")
    );

    const formSendData = {
      uploadProfilePic: formData.uploadProfilePic,
      instituteAdminId: formData.instituteAdminId,
      name: formData.name,
      emailId: formData.emailId.toLowerCase(),
      password: formData.password,
      mobileNumber: formData.mobileNumber,
      permissionAdd: formData.permission.add,
      permissionEdit: formData.permission.edit,
      permissionDelete: formData.permission.delete,
      instituteUniqueId: localStorage.getItem("Unique_id"),
    };

    if (formData.uploadProfilePic === null && profilePic === null) {
      toast.warn("Please upload your profile");
    } else if (profilePic !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegeinstituteadmin/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institute admin");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegeinstituteadmin`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institute admin");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadProfilePic !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegeinstituteadmin/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institute admin");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegeinstituteadmin`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/institute admin");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  const handleSubmitandAdd = (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const formAppendData = new FormData();
    formAppendData.append("uploadProfilePic", profilePic);
    formAppendData.append("instituteAdminId", formData.instituteAdminId);
    formAppendData.append("name", formData.name);
    formAppendData.append("emailId", formData.emailId);
    formAppendData.append("password", formData.password);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("permissionAdd", formData.permission.add);
    formAppendData.append("permissionEdit", formData.permission.edit);
    formAppendData.append("permissionDelete", formData.permission.delete);
    formAppendData.append(
      "instituteUniqueId",
      localStorage.getItem("Unique_id")
    );

    const formSendData = {
      uploadProfilePic: formData.uploadProfilePic,
      instituteAdminId: formData.instituteAdminId,
      name: formData.name,
      emailId: formData.emailId.toLowerCase(),
      password: formData.password,
      mobileNumber: formData.mobileNumber,
      permissionAdd: formData.permission.add,
      permissionEdit: formData.permission.edit,
      permissionDelete: formData.permission.delete,
      instituteUniqueId: localStorage.getItem("Unique_id"),
    };

    if (formData.uploadProfilePic === null && profilePic === null) {
      toast.warn("Please upload your profile");
    } else if (profilePic !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegeinstituteadmin/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegeinstituteadmin`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadProfilePic !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegeinstituteadmin/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
             setFormData(initialFormData);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegeinstituteadmin`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  const handlePermissionAdd = (checked) => {
    setFormData((prev) => ({
      ...prev,
      permission: {
        ...prev.permission,
        add: checked,
      },
    }));
  };

  const handlePermissionEdit = (checked) => {
    setFormData((prev) => ({
      ...prev,
      permission: {
        ...prev.permission,
        edit: checked,
      },
    }));
  };

  const handlePermissionDelete = (checked) => {
    setFormData((prev) => ({
      ...prev,
      permission: {
        ...prev.permission,
        delete: checked,
      },
    }));
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData(initialFormData);
    }
  }, [id]);

  const getFormData = async (id) => {
    console.log("trigger");
    const formData = await axios.get(`${apiUrl}/collegeinstituteadmin/${id}`);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  const handleReset = (e) => {
    setFormData(initialFormData);
  };

  return (
    <div className="mb-4 flex justify-between items-center top-0">
      <div className="w-full bg-white px-16 py-6 rounded-lg drop-shadow border ">
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              id="image-upload"
              type="file"
              name="uploadProfilePic"
              style={{ display: "none", backgroundColor: "white" }}
              onChange={handleProfilePicUpload}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                <div className="w-[140px] h-[140px] flex flex-col justify-center items-center border-2 border-custom-reen rounded-full bg-white text-primary">
                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/CollegeInstituteAdminUploads/${formData.uploadProfilePic}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {""}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-primary"
                          />
                          <span className="text-[15px] font-normal">
                            Upload Profile Picture
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* </Avatar> */}
              </IconButton>
            </label>
          </div>

          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"390px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Name"
                  variant="outlined"
                  name="name"
                  type="text"
                  fullWidth
                  value={formData.name}
                  onChange={handleOnChange}
                  required
                />
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Institute Admin ID"
                    variant="outlined"
                    name="instituteAdminId"
                    type="text"
                    fullWidth
                    value={formData.instituteAdminId}
                    onChange={handleOnChange}
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Institute Admin ID"
                    variant="outlined"
                    name="instituteAdminId"
                    type="text"
                    fullWidth
                    value={formData.instituteAdminId}
                    onChange={handleOnChange}
                    required
                  />
                )}
                 <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  name="emailId"
                  type="email"
                  fullWidth
                  value={formData.emailId}
                  onChange={handleOnChange}
                  required
                />
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    //value={formData.password}
                    onChange={handleOnChange}
                    // required
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleOnChange}
                    required
                  />
                )}

               

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  name="mobileNumber"
                  type="number"
                  fullWidth
                  value={formData.mobileNumber}
                  onChange={handleOnChange}
                  required
                />

                <FormGroup>
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Permissions"
                    type="button"
                    fullWidth
                    variant="outlined"
                    name="permissions"
                    InputProps={{
                      endAdornment: (
                        <div className="w-full flex flex-row items-center">
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Add
                            </label>
                            <Switch
                              onChange={handlePermissionAdd}
                              checked={formData.permission.add}
                              className={
                                formData.permission.add
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary  font-normal mx-1">
                              Edit
                            </label>
                            <Switch
                              onChange={handlePermissionEdit}
                              checked={formData.permission.edit}
                              className={
                                formData.permission.edit
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                          <div className="flex flex-row items-center">
                            <label className="text-[13px] text-primary font-normal mx-1">
                              Delete
                            </label>
                            <Switch
                              onChange={handlePermissionDelete}
                              checked={formData.permission.delete}
                              className={
                                formData.permission.delete
                                  ? "custom-switch-checked"
                                  : "custom-switch-unchecked"
                              }
                            />
                          </div>
                        </div>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true, // Keep the label in place even when clicking the text field.
                      style: { fontSize: "12px" },
                    }}
                  />
                </FormGroup>
              </Stack>
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-4 pb-2">
            {!editMode && (
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-3 bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
            )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-3 bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-3  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default InstituteAdminForm;
