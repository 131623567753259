//QUestion paper model  not used 



import React, { useEffect, useRef, useState } from "react";
import { Paper, Typography } from "@mui/material";
import axios from "axios";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

import { useNavigate, useParams } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const Question = () => {
  const [sectionId, setSectionId] = useState(null);
  const [examData, setExamData] = useState({});
  const [exam , setExam] = useState([])
  const [questionsData, setQuestionsData] = useState([]);
  const paperRef = useRef(null);
  const [uploadFile, setUploadFile] = useState(null);
  const navigate = useNavigate();
  const [studentName,setStudentName] = useState([]);
  const { examId } = useParams();

const [studentId,setStudentId] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const studentResponse = await axios.get(
          `${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`
        );
        const studentsData = studentResponse.data.data;

        if (studentsData && studentsData.length > 0) {
          const student = studentsData[0];
          setSectionId(student.sectionId);
          setStudentId(student.studentId);
          setStudentName(student.studentName)
        } else {
          console.warn("No student data found.");
        }
      } catch (error) {
        console.error(
          "Error fetching student data:",
          error.response?.data || error.message
        );
      }
    };

    fetchData();
  }, []);



  useEffect(() => {
    const fetchExamData = async () => {
      try {
        if (sectionId) {
          const examResponse = await axios.get(
            `${apiUrl}/collegeexambyexamid/${examId}`
          );
          const examData = examResponse.data.data;

          if (examData && examData.length > 0) {
            setExamData(examData[0]);
            setExam(examData[0].examId);
          } else {
            console.warn("No exam data found.");

          }
        }
      } catch (error) {
        console.error("Error fetching exam data:", error);
      }
    };

    fetchExamData();
  }, [sectionId]);



  useEffect(() => {
    const fetchQuestionsData = async () => {
      try {
        if (examId) {
          const questionsResponse = await axios.get(
            `${apiUrl}/getQuestionsbyexamid/${examId}?questionType=written-test`
          );

          const descriptiveQuestions = questionsResponse.data.data.filter(
            (question) => question.questionType === "written-test"
          );

          setQuestionsData(descriptiveQuestions);
        }

       
      } catch (error) {
        console.error("Error fetching questions data:", error);
      }
    };

    fetchQuestionsData();
  }, [examId]);



  const downloadAsPDF = () => {
    if (!paperRef.current) return;

    html2canvas(paperRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297); // A4 size: 210mm x 297mm
      pdf.save("questions.pdf");
    });
  };

  const renderQuestion = (question, index) => {
    return (
      <div key={index} className="flex items-center mb-7 mt-12">
        <Typography
          variant="body1"
          className="mr-2 "
          style={{ color: "#333333", fontWeight: "bold" }}
        >
          <span className="mr-2">{index + 1}.</span>
          {question.questionText}
        </Typography>
      </div>
    );
  };


const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!uploadFile) {
      toast.error("Please upload a file.");
      return;
    }
  
    // Create a new FormData instance
    const formData = new FormData();
  
    // Append the file to the FormData instance
    formData.append("uploadFile", uploadFile);
    formData.append("uploadFileName", uploadFile.name); // Fix here: Use 'uploadFileName' instead of 'uploadFile.name'
    // Append other data as needed
    formData.append("examId", examId);
    formData.append("studentId", studentId);
    formData.append("studentName", studentName);
  
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  
    try {
      const res = await axios.post(`${apiUrl}/descriptivequestionAnswered`, formData, config);
  
      if (res.data.status === 301) {
        toast.warn(res.data.message);
      } else if (res.status === 200) {
        toast.success(res.data.message);
        navigate(`/homepage/My Course`);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      toast.error(error.message);
    }
  };
  
  
  const handleFileChange = (files) => {
    const file = files && files[0];
  
    if (file) {
      setUploadFile(file);
    }
  };
  
  
  return (
    <>
      <Paper
        ref={paperRef}
        className="p-8 w-4/6 mx-auto border mt-4"
        elevation={4}
        style={{ background: "#f0f0f0", border: "none" }}
      >
        <div className="text-center mt-2">
          <h1 className="text-3xl font-bold ">{examData.examName}</h1>
        </div>
        <div className="flex justify-between mt-8">
          <div className="text-left">
            <p className="text-lg font-bold">Exam ID: {examData.examId}</p>
          </div>
          <div className="text-right">
            <p className="text-lg font-bold">Duration: {examData.duration}</p>
          </div>
        </div>

        <hr className="mt-8 border-solid border-black border-t-2" />

        {questionsData.map((question, index) =>
          renderQuestion(question, index)
        )}
      </Paper>

      <div className="flex flex-row mt-8 text-center  justify-center">
        <button
          className="bg-primary hover:bg-secondary text-white px-4 py-2 rounded-md"
          onClick={downloadAsPDF}
        >
          Download as PDF
        </button>

        <label
          htmlFor="fileInput"
          className="ml-8 cursor-pointer bg-primary hover:bg-secondary text-white px-4 py-2 rounded-md"
        >
          Upload Answer
        </label>
        <input
  type="file"
  id="fileInput"
  className="hidden"
  onChange={(e) => handleFileChange(e.target.files)}
/>


        <button
          onClick={handleSubmit}
          className="bg-primary hover:bg-secondary text-white px-4 py-2 rounded-md ml-8"
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default Question;


// PaperModel