import React, { useEffect, useState }  from "react";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";

import { toast } from 'react-toastify';
import { Link } from "react-router-dom/dist";


export default function StudentSubject() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [subjects, setSubjects] = useState([]);

  const [filteredSubjects, setFilteredSubjects] = useState([]);

  const [sectionId, setSectionId] = useState("");
  const [studentData, setStudentData] = useState([]);
const [academicYear,setAcademicYear]=useState();
  const [searchTerm, setSearchTerm] = useState("");
  console.log("secId" , sectionId);


  useEffect(()=>{
    axios.get(`${apiUrl}/collegesection/${localStorage.getItem("section_Id")}`)
    .then((res)=>setAcademicYear(res.data.data[0].academicYear))
    .catch((err)=>console.log(err));
    console.log(academicYear,"academicYear")
      },[])

  useEffect(() => {
    axios
      .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        const studentsData = res.data.data;

        if (studentsData.length > 0) {
          setStudentData(studentsData);
          setSectionId(studentsData[0].sectionId);
        } else {
          console.warn("No student data found.");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          toast.info("No Data Found");
        }
      });
  }, []);

  useEffect(() => {
    try {
      if (sectionId) {
        axios
          .get(`${apiUrl}/collegeassignsubjectbysectionid/${sectionId}/${academicYear}`)

          .then((res) => {
            const subjectsData = res.data.data;
            setSubjects(subjectsData);
          
          })
          .catch((error) => {
            console.error("Error fetching subjects:", error);
            if (error.response) {
              console.error("Response data:", error.response.data);
              console.error("Response status:", error.response.status);
             console.log("Failed to fetch subjects");
            }
          });
      }
    } catch (error) {
      console.error("Exception:", error);
      toast.error("An error occurred");
    }
  }, [sectionId]);

  console.log("sectionId", subjects);

  useEffect(() => {
    const filtered = subjects.filter((subject) =>
      subject.subjectName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSubjects(filtered);
  }, [searchTerm, subjects]);

  return (
    <div className="W-full h-fit drop-shadow border bg-white rounded-md ">
     
      <div className="flex items-center justify-between px-4 py-2 rounded-lg mt-5">
        <h2 className="text-xl font-semibold">Subjects</h2>
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
        </div>
      </div>
      <hr className="border-t bg-gray-800 h-0.5 mx-4 shadow-md mt-3"/>{" "}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 p-6">
        {Array.isArray(subjects) &&
          subjects.map((subject, index) => (
            <div
              key={index}
              className="bg-white hovr:bg-gray-100 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105"
            >
             <Link
                to={`/homepage/My Course/Subject/${encodeURIComponent(subject.subjectId)}/${sectionId}`}
                className="block"
              >
                <div className="bg-gray-300 text-white text-8xl flex items-center justify-center h-32">
                  {subject.subjectName[0]}
                </div>
                <div className="p-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-2">
                    {subject.subjectName}
                  </h2>
                  <p className="text-sm text-gray-600 mb-2">
                    Subject Code: {subject.subjectId}
                  </p>
                  <p className="text-sm text-gray-600">
                    Faculty Name: {subject.facultyName}
                  </p>
                </div>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
}
