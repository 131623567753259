import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faRandom } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const Questions = ({
  examId,
  sectionId,
  subjectId,
  subjectName,
  facultyId,
  facultyName,
  onCancel,
  totalMarks
}) => {
  const defaultQuestion = {
    questionType: "multiple-choice",
    questionText: "",
    answerComment: "",
    options: [""],
    examId: "",
    sectionId: "",
    subjectName: "",
    facultyName: "",
    facultyId: "",
    answerText: "", // Add answerText field
    points: 0,
    subjectId: "", // Add points field
  };
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([defaultQuestion]);

console.log("totalmark",totalMarks);

  const handleAnswerTextChange = (questionIndex, text) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answerText = text;
    setQuestions(newQuestions);
  };

  const handlePointsChange = (questionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].points = value;
    setQuestions(newQuestions);
  };

  const handleQuestionTypeChange = (questionIndex, selectedType) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].questionType = selectedType;
    setQuestions(newQuestions);
  };

  const handleQuestionTextChange = (questionIndex, text) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].questionText = text;
    setQuestions(newQuestions);
  };

  const handleAnswerCommentChange = (questionIndex, text) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].answerComment = text;
    setQuestions(newQuestions);
  };

  const handleOptionTextChange = (questionIndex, optionIndex, text) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = text;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([...questions, { ...defaultQuestion }]);
  };

  const addOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push("");
    setQuestions(newQuestions);
  };

  const deleteOption = (questionIndex, optionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  const deleteQuestion = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };

  const shuffleOptions = (questionIndex) => {
    const newQuestions = [...questions];
    const shuffledOptions = [...newQuestions[questionIndex].options].sort(
      () => Math.random() - 0.5
    );
    newQuestions[questionIndex].options = shuffledOptions;
    setQuestions(newQuestions);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(questions);
  
    // Calculate total marks
    const total = questions.reduce((acc, question) => acc + parseInt(question.points), 0);
  
    // Check if total marks equal the specified totalMarks
    if (total === parseInt(totalMarks)) {
      const questionsWithExamId = questions.map((question) => ({
        ...question,
        examId: examId,
        sectionId: sectionId,
        subjectId: subjectId,
        subjectName: subjectName,
        facultyId: facultyId,
        facultyName: facultyName,
      }));
  
      try {
        const res = await axios.post(`${apiUrl}/assessment`, questionsWithExamId);
        if (res.data.status === 301) {
          toast.warn(res.data.message);
        } else if (res.status === 200) {
          toast.success(res.data.message);
          // navigate("/homepage/exam");
          onCancel(true)
        }
      } catch (error) {
        toast.error(error.message);
        console.error("Error submitting form:", error.message);
      }
    } else {
      // Display a message if total marks are not equal
      toast.error("Total marks are not equal to specified total marks.");
    }
  };
  const handleRadioChange = (e, questionIndex, optionIndex) => {
    const optionText = questions[questionIndex].options[optionIndex];
    handleAnswerTextChange(questionIndex, optionText);
  };

  
  return (
    <form onSubmit={handleSubmit}>
      <div
        className="mb-6 p-4 border rounded-lg"
        style={{
          overflowY: "auto",
          maxHeight: "500px",
          width: "800px",
        }}
      >
        {questions.map((question, questionIndex) => (
          <div key={questionIndex} className="mb-6 p-4 border rounded-lg">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Question Type
              </label>
              <select
                value={question.questionType}
                onChange={(e) =>
                  handleQuestionTypeChange(questionIndex, e.target.value)
                }
                className="mt-1 block w-full p-2 border border-gray-300 rounded-lg"
              >
                <option value="multiple-choice">Multiple Choice</option>
              </select>
            </div>

            <div className="flex items-center">
              <input
                type="text"
                placeholder="Enter your question here"
                className="w-full p-2 border border-gray-300 rounded-lg flex"
                value={question.questionText}
                onChange={(e) =>
                  handleQuestionTextChange(questionIndex, e.target.value)
                }
              />

              <div className="ml-3 flex">
                <button
                  type="button"
                  onClick={() => addQuestion(questionIndex)}
                  className="mt-2 text-primary"
                  data-tip="Add Question"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <button
                  type="button"
                  onClick={() => deleteQuestion(questionIndex)}
                  className="mt-2 ml-3 text-primary"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>

            {question.questionType === "multiple-choice" && (
              <div>
                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="mt-2 flex items-center">
                   <input
      type="radio"
      className="mr-2"
      name={`question_${questionIndex}`}
      value={option}
      checked={option === question.answerText}
      onChange={(e) =>
        handleRadioChange(e, questionIndex, optionIndex)
      }
    />
    <input
      type="text"
      placeholder={`Option ${optionIndex + 1}`}
      className="w-full p-2 border border-gray-300 rounded-lg"
      value={option}
      onChange={(e) =>
        handleOptionTextChange(
          questionIndex,
          optionIndex,
          e.target.value
        )
      }
    />

                    <button
                      type="button"
                      onClick={() => addOption(questionIndex)}
                      className="ml-3 text-primary"
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button
                      type="button"
                      onClick={() => deleteOption(questionIndex, optionIndex)}
                      className="ml-3 text-primary"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    {question.questionType === "multiple-choice" && (
                      <button
                        type="button"
                        onClick={() => shuffleOptions(questionIndex)}
                        className="ml-3 text-secondary"
                      >
                        <FontAwesomeIcon icon={faRandom} />
                      </button>
                    )}
                  </div>
                ))}

                {/* "Enter your answer" field for multiple-choice questions */}
                <div className="mt-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Answer
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your answer here"
                    className="w-full p-2 border border-gray-300 rounded-lg flex"
                    value={question.answerText}
                    onChange={(e) =>
                      handleAnswerTextChange(questionIndex, e.target.value)
                    }
                  />
                </div>
                <div>
                  {/* "Points" field for descriptive questions */}
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Marks
                    </label>
                    <input
                      type="number"
                      placeholder="Enter points here"
                      className="w-full p-2 border border-gray-300 rounded-lg flex"
                      value={question.points}
                      onChange={(e) =>
                        handlePointsChange(questionIndex, e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-center">
          {questions.length > 0 && (
            <button
              type="submit"
              className="mt-4 ml-2 text-white bg-primary hover:bg-secondary px-2 py-1 rounded-lg"
              onClick={handleSubmit}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default Questions;
