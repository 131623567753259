import React from 'react';


const Virtual = () => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center">
      <div className="max-w-3xl text-center">
        <h1 className="text-4xl font-extrabold mb-6">Explore Learning in Virtual Reality</h1>
        <p className="text-lg leading-relaxed">
          Welcome to our Virtual Reality learning platform. Immerse yourself in a world of knowledge through interactive simulations and engaging experiences. Discover a new dimension of education with the power of VR.
        </p>
      </div>
      <div className="flex items-center justify-center mt-8">
        {/* YouTube video embedded using iframe */}
        <iframe 
          width="560" 
          height="315" 
          src="https://www.youtube.com/embed/your-video-id" 
          title="Virtual Reality Introduction" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-8 text-lg">  
        <p className="text-center">
          <strong>Under Construction:</strong> Exciting features and content are on the way. Stay tuned for an immersive learning experience that will revolutionize the way you learn.
        </p>
      </div>
    </div>
  );
};

export default Virtual;
