import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import { DatePicker as AntDatePicker } from 'antd';
import axios from "axios";

function StudentsAttendance() {
  const [searchTerm, setSearchTerm] = useState("");
  const [item, setItem] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { RangePicker } = AntDatePicker;

  const apiUrl = process.env.REACT_APP_API_URL;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onStartDateChange = (date, dateString) => {
    setStartDate(date);
  };

  const onEndDateChange = (date, dateString) => {
    setEndDate(date);
  };

  // Define your columns
  const columns = [
    {
      name:  <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "70px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Student Name</h1>,
      selector: "studentName",
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Student Id</h1>,
      selector: "studentId",
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[250px]">Total Working Days</h1>,
      selector: "totalWorkingDays",
      sortable: true,
      width: "200px",
    },

    // {
    //   name: "Leave Taken",
    //   selector: "leaveTaken",
    //   sortable: true,
    // },
    // {
    //   name: "OD Percentage",
    //   selector: "odPercentage",
    //   sortable: true,
    //   width: "200px",
    //   cell: (row) => (row.odPercentage ? `${row.odPercentage.toFixed(2)}%` : "0%"),
    // },
    // {
    //   name: "Present Percentage",
    //   selector: "presentPercentage",
    //   sortable: true,
    //   width: "200px",
    //   cell: (row) => (row.presentPercentage ? `${row.presentPercentage.toFixed(2)}%` : "0%"),
    // },
    // {
    //   name: "Absent Percentage",
    //   selector: "absentPercentage",
    //   sortable: true,
    //   width: "200px",
    //   cell: (row) => (row.absentPercentage ? `${row.absentPercentage.toFixed(2)}%` : "0%"),
    // },
    {
      name: <h1 className="text-white pl-3 w-[150px]">No,Of OD's</h1>,
      selector: "odCount",
      sortable: true,
      width: "200px",
     // cell: (row) => (row.odCount ? `${row.odCount}` : "0%"),
    },
    {
      name:<h1 className="text-white pl-3 w-[150px]">No.of Presents</h1>,
      selector: "presentCount",
      sortable: true,
      width: "200px",
     // cell: (row) => (row.presentPercentage ? `${row.presentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">No.of Absents</h1>,
      selector: "absentCount",
      sortable: true,
      width: "200px",
     // cell: (row) => (row.absentPercentage ? `${row.absentPercentage.toFixed(2)}%` : "0%"),
    },
    {
      name:<h1 className="text-white pl-3 w-[250px]">Attendance Percentage </h1>,
      selector: "percentageAttendance",
      sortable: true,
      width: "200px",
      cell: (row) => (row.percentageAttendance ? `${Math.round(row.percentageAttendance)}%` : "0"),
    },
  
  ];
  useEffect(() => {
    // Check if item is an array before filtering
    if (Array.isArray(item)) {
      const result = item.filter((value) => {
        // Check if studentName is defined before using toLowerCase
        return value.studentName && value.studentName.toLowerCase().includes(searchTerm.toLowerCase());
      });
      setFilteredData(result);
    }
  }, [item, searchTerm]);
  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth:"600px"
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
      
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width:"150px",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: '1px solid #ddd',
        textAlign: "center", 
      },
    }
  };
  useEffect(() => {
    if (startDate && endDate) {
    const fetchStudentAttendanceData = async () => {
      try {
        if (!startDate || !endDate) {

          return;
        }

        // Format the dates to match your backend expectations
        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();
        const endpoint = `${apiUrl}/studentAttendance/${localStorage.getItem("Unique_id")}/${formattedStartDate}/${formattedEndDate}`;
        const response = await axios.get(endpoint);
        // if (response && response.data && Array.isArray(response.data.data)) {
          const fetchedStudentAttendanceData = response.data.data;
          console.log("Fetched Data:", fetchedStudentAttendanceData);
         // Ensure fetched data is an array
        const dataArray = Array.isArray(fetchedStudentAttendanceData)
          ? fetchedStudentAttendanceData
          : [fetchedStudentAttendanceData];

        setItem(dataArray);
        setFilteredData(dataArray);

        // } else {
        //   console.error("Invalid response format or data is not an array:", response);
        // }
      } catch (error) {
        console.log(error.response?.data);
        if (error.response?.data?.status === 404) {
          console.error("Response data:", error.response.data);
        }
        throw error;
      }
    };
  
   // Call the fetch function only when both start date and end date are selected
   if (startDate && endDate) {
    fetchStudentAttendanceData();
  }
}
},
 [startDate, endDate]);


 useEffect(() => {
  
  const fetchStudentAttendance = async () => {
    try {

      const endpoint = `${apiUrl}/studentAttendancebystudentid/${localStorage.getItem("Unique_id")}`;
      const response = await axios.get(endpoint);
      // if (response && response.data && Array.isArray(response.data.data)) {
        const fetchedStudentAttendanceData = response.data.data;
        console.log("Fetched Data:", fetchedStudentAttendanceData);
       // Ensure fetched data is an array
      const dataArray = Array.isArray(fetchedStudentAttendanceData)
        ? fetchedStudentAttendanceData
        : [fetchedStudentAttendanceData];

      setItem(dataArray);
      setFilteredData(dataArray);

      // } else {
      //   console.error("Invalid response format or data is not an array:", response);
      // }
    } catch (error) {
      console.log(error.response?.data);
      if (error.response?.data?.status === 404) {
        console.error("Response data:", error.response.data);
      }
      throw error;
    }
  };

 // Call the fetch function only when both start date and end date are selected
 
 fetchStudentAttendance();

},
[]);

  return (
    <div className="W-full h-fit bg-white flex flex-col items-center gap-3 rounded-md pt-5  px-1 drop-shadow border">
      {/* Your search input and buttons */}
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
        </div>
        <label className="label flex flex-row items-center gap-2">Date</label>
        <RangePicker
          value={[startDate, endDate]}
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              onStartDateChange(dates[0]);
              onEndDateChange(dates[1]);
            }
          }}
          placeholder={['Start Date', 'End Date']}
          format="YYYY/MM/DD"
          className="custom-range-picker"
        />
      </div>

      {/* Use the DataTable component */}
      <div className="flex flex-col w-full rounded-md ">

          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="250px"
            columns={columns}
            data={filteredData}
            selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover
            pointerOnHover
          />
      </div>
    </div>
  );
}

export default StudentsAttendance;
