import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Attendance from "../../Images/checking-attendance.png";
import Students from "../../Images/education.png";
import Exam from "../../Images/exam.png";
import Result from "../../Images/checklist.png";
import { FaPlus, FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Chip,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Modal } from "antd";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Select } from "antd";
import { MdDelete } from "react-icons/md";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { ChromePicker } from "react-color";

const apiUrl = process.env.REACT_APP_API_URL;

const MyClassroom = ({ handleToggle }) => {
  const [subjects, setSubjects] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFaculty, setSelectedFaculty] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedSection, setSelectedSection] = useState(0);
  const [sectionName, setSectionName] = useState("");
  const [sectionId, setSectionId] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedSubjectSectionId, setSelectedSubjectSectionId] =
    useState(null);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [courseId, setCourseId] = useState();
  const [courseName, setCourseName] = useState();
  const [addedSubjects, setAddedSubjects] = useState([]);
  const [semesterNumber, setSemesterNumber] = useState();
  const [mentorId, setMentorId] = useState([]);
  const [semaster, setSemastert] = useState([]);
  const [instituteId, setInstituteId] = useState([]);
  const [mentor, setMentor] = useState([]);
  const [indexId, setIndexId] = useState([]);
  const [selectedSectionName, setSelectedSectionName] = useState("");
  const [selectedSemesterNumber, setSelectedSemesterNumber] = useState("");
  const [selectedCourseName, setSelectedCourseName] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");

  // Time Table Code's

  const [showAddTimetable, setShowAddTimetable] = useState([]);

  const [numPeriods, setNumPeriods] = useState(null);
  const [startDay, setStartDay] = useState(null);
  const [endDay, setEndDay] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [days, setDays] = useState([]);
  const [timetableDataAvailable, setTimetableDataAvailable] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [editMode, setEditMode] = useState(false);
  const [academicYear,setAcademicYear]=useState();
  const [editDataForm, setEditDataForm] = useState(null);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  console.log("Edit data", editData);

  const openEditModal = (data) => {
    setEditData(data);
    setIsAddModalOpen(true);
  };

  const openEditModalTable = (editData) => {
    setEditData(editData);
    setIsEditFormOpen(true);
  };

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleGenerateTimetable = async () => {
    try {
        if (numPeriods > 10) {
          toast.error('Number of periods cannot exceed 10.');
          return;
        }
      const timetableData = {
        number_of_period: numPeriods,
        starting_day: startDay,
        ending_day: endDay,
        mentor_faculty: mentor,
        mentor_facultyId: mentorId,
        sectionId: selectedSectionId,
        instituteId: instituteId,
      };
      const response = await axios.post(`${apiUrl}/timetable`, timetableData);
      setTimetableDataAvailable(true);
      fetchData();
      console.log("Backend response:", response.data);
    } catch (error) {
      console.error("Error sending data to backend:", error);
    }
  };

  const handleEditTimeable = async () => {
    try {
      const timetableData = {
        number_of_period: numPeriods,
        starting_day: startDay,
        ending_day: endDay,
      };
      const response = await axios.put(
        `${apiUrl}/timetable/${selectedSectionId}`,
        timetableData
      );
      setTimetableDataAvailable(true);
      fetchData();
      setIsEditFormOpen(false);
      console.log("Backend response:", response.data);
    } catch (error) {
      console.error("Error sending data to backend:", error);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/timetable/${selectedSectionId}`
      );
      const timetableData = response.data.data;
      setTableData(timetableData);
      if (timetableData.length > 0) {
        setTableData(timetableData);
        setPeriods(
          Array.from(
            { length: timetableData[0].number_of_period },
            (_, index) => index + 1
          )
        );
        const startDay = timetableData[0].starting_day || "";
        const endDay = timetableData[0].ending_day || "";

        const daysArray = [];
        let currentDay = startDay;
        while (currentDay !== endDay) {
          daysArray.push(currentDay);
          currentDay = getNextDay(currentDay);
        }
        daysArray.push(endDay);

        setDays(daysArray);
        setTimetableDataAvailable(true);
      } else {
        setTableData([]);
        setTimetableDataAvailable(false);
      }
    } catch (error) {
      console.error(
        "An error occurred:",
        error.response ? error.response.data : error.message
      );
      if (error.response && error.response.status === 404) {
        console.log("Resource not found");
      }
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleDeleteTimetable = async () => {
    try {
      Modal.confirm({
        title: "Are you sure to delete the Timetable?",
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          try {
            const response = await axios.delete(
              `${apiUrl}/timetable/${selectedSectionId}`
            );
            if (response.status === 200) {
              setTableData([]);
              setDays([]);
              setTimetableDataAvailable(false);
              setShowAddTimetable(false);
              setNumPeriods(null);
              setStartDay(null);
              setEndDay(null);
              fetchData();
            } else {
              console.log("Failed to delete timetable");
            }
          } catch (error) {
            console.error("Error deleting timetable:", error);
          }
        },
        onCancel: () => {
          console.log("Deletion canceled");
        },
      });
    } catch (error) {
      console.error("Error displaying confirmation modal:", error);
    }
  };

  const generateColumns = () => {
    const columns = [
      {
        name: "Period",
        selector: "period",
        sortable: true,
        style: {
          background: "#C9FDD7",
        },
      },
    ];

    days.forEach((day, index) => {
      columns.push({
        name: day,
        selector: `day_${index + 1}`,
        sortable: true,
      });
    });

    return columns;
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFacultyData, setSelectedFacultyData] = useState({});
  const [selectedTimeId, setSelectedTimeId] = useState(null);
  const [selectedDay, setSelectedDay] = useState([]);

  const handleFacultyClick = (facultyData, timeId, matchingPeriod) => {
    setSelectedFacultyData(facultyData);
    setModalVisible(true);
    setEditData(facultyData._id);
    setSelectedTimeId(timeId);
    setSelectedDay(matchingPeriod);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const generateRows = () => {
    const rows = [];

    tableData.forEach((data) => {
      if (!tableData || !Array.isArray(tableData)) {
        console.error("Invalid tableData:", tableData);
        return rows;
      }
      for (let i = 1; i <= data.number_of_period; i++) {
        const row = {
          period: `Period ${i}`,
        };

        days.forEach((day, index) => {
          const matchingPeriod =
            data.time && data.time.find((p) => p.period === i && p.day === day);

          if (matchingPeriod) {
            const TimeId = matchingPeriod._id;
            const scheduleDetails = matchingPeriod.scheduleDetails[0];
            const facultyName = scheduleDetails.faculty || "Unknown Faculty";
            row[`day_${index + 1}`] = (
              <span
                className="clickable-faculty"
                onClick={() =>
                  handleFacultyClick(scheduleDetails, TimeId, matchingPeriod)
                }
              >
                {facultyName}
              </span>
            );
          } else {
            row[`day_${index + 1}`] = "";
          }
        });

        rows.push(row);
      }
    });

    return rows;
  };

  console.log("time data", indexId);

  const getNextDay = (currentDay) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentIndex = daysOfWeek.indexOf(currentDay);
    return daysOfWeek[(currentIndex + 1) % 7];
  };

  const customStyles = {
    headRow: {
      style: {
        // border: "none",
        outerWidth: "800px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
        textAlign: "center",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
      style: {
        borderTop: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        // border: "none",
        color: "#364353",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (subjects) {
      const filtered = subjects.filter(
        (subject) =>
          subject &&
          subject.subjectName &&
          subject.subjectName.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredSubjects(filtered);
    }
  }, [searchText, subjects]);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/collegesectionbymentorFacultyIduniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        );

        const sectionData = response.data.data;

        setSectionData(sectionData);

        console.log("sectionData", sectionData);
        // Check if there are sections available and set the default selected section
        ///if (sectionData.length > 0) {
        //const initialSectionId = sectionData[0].sectionId;
        //   const initialSemesterNumber = sectionData[0].semesterNumber; // Assuming semester number is a property in your section data
        //   const initialCourseId = sectionData[0].courseId; // Add this line
        //   const initialCourseName = sectionData[0].courseName; // Add this line
        //   setSelectedSectionId(initialSectionId);
        //setSelectedSection(0); // Set the default selected section to the first one
        //   setSectionName(sectionData[0].sectionName); // Set the section name
        //   setSemesterNumber(initialSemesterNumber); // Set the semester number
        //   setCourseId(initialCourseId);
        //   setCourseName(initialCourseName);
        //   setSemastert(sectionData[0].semesterNumbe);
        setInstituteId(sectionData[0].instituteUniqueId);
        setMentor(sectionData[0].mentorFacultyName);
        setMentorId(sectionData[0].mentorFacultyId);
        // }
      } catch (error) {
        console.error(
          "An error occurred:",
          error.response ? error.response.data : error.message
        );
        if (error.response && error.response.status === 404) {
          console.log("Resource not found");
        }
      }
    };

    fetchData(); // Call the async function
  }, []);

  console.log("Mentor Id", mentorId);

  const [error, setError] = useState("");

  const [facultyNameList, setFacultyNameList] = useState([]);

  useEffect(() => {
    if (selectedSubjectId !== null && selectedSubjectId !== undefined) {
      axios
        .get(`${apiUrl}/collegefacultybySubjectUniqueId/${selectedSubjectId}`)
        .then((res) => {
          const facultyData = res.data.data || [];
          setFaculty(facultyData);
          setFacultyNameList(facultyData[0].facultyName);

          console.log("faculty data", facultyData);
        })
        .catch((error) => {});
    }
  }, [selectedSubjectId]);

  useEffect(() => {
    fetchSubjectCards();
  }, [selectedSectionId]);

  const fetchSubjectCards = async () => {
    try {
      if (selectedSectionId !== null) {
        const response = await axios.get(
          `${apiUrl}/collegeassignsubjectbysectionidanduniqueid/${selectedSectionId}/${localStorage.getItem(
            "Unique_id")}/${academicYear}`
        );
        const subjectCardsData = response.data.data;
        setAddedSubjects(subjectCardsData);
      }
    } catch (error) {
      console.error("Error fetching subject cards:", error);
      setError("Error fetching subject cards. Please try again.");
    }
  };

  const openInsertForm = () => {
    setIsAddModalOpen(true);
    setIsEditMode(false);
  };
  const handleSubjectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedSubjectId(selectedId);
  };

  const subjectOptions =
    subjects.length > 0
      ? subjects[0].subjectList.map((subject) => ({
          value: subject.id,
          label: subject.name,
        }))
      : [];

  console.log(subjectOptions, "Subjects");

  const facultyOptions = facultyNameList.map((f) => ({
    value: f.id,
    label: f.name,
  }));

  const [formData, setFormData] = useState({
    period: [],
    day: [],
    subject: "",
    faculty: "",
    color: "",
    startTime: null,
    endTime: null,
  });

 
  const handleDeleteChip = (index) => {
    setFormData((prevData) => {
      const period = [...prevData.period];
      const day = [...prevData.day];

      period.splice(index, 1);
      day.splice(index, 1);

      return {
        ...prevData,
        period,
        day,
      };
    });
  };


  const [showColorPicker, setShowColorPicker] = useState(false);
  const [selectedColor, setSelectedColor] = useState('#FFFFFF'); // Default color

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  const handleColorClick = () => {
    setShowColorPicker((prev) => !prev);
  };



  const handleTimeChange = (value, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  console.log("edit data", formData);

  const openPeriodEditModal = (selectedFacultyData) => {
    if (selectedFacultyData) {
      setIsEditMode(true);
      setFormData({
        period: selectedFacultyData.period,
        day: selectedFacultyData.day,
        subject: selectedFacultyData.subject || "",
        faculty: selectedFacultyData.faculty || "",
        startTime: selectedFacultyData.startTime,
        endTime: selectedFacultyData.endTime,
        color: selectedFacultyData.color,
      });

    } else {
      setIsEditMode(false);
      setFormData({
        period: "",
        day: "",
        subject: "",
        faculty: "",
        startTime: null,
        endTime: null,
        color: "",
      });
    }
    setIsAddModalOpen(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      period: formData.period,
      day: formData.day,
      scheduleDetails: [
        {
          faculty:
            facultyOptions.find((f) => f.value === formData.faculty)?.label ||
            "",
          facultyId: formData.faculty,
          subject:
            subjectOptions.find((s) => s.value === formData.subject)?.label ||
            "",
          subjectId: formData.subject,
          startTime: formData.startTime,
          endTime: formData.endTime,
          color: formData.color,
        },
      ],
    };

    try {
      if (isEditMode) {
        const response = await axios.put(
          `${apiUrl}/updateperiod/${selectedSectionId}/${selectedTimeId}`,
          postData
        );
        console.log("Update API Response:", response.data);
      } else {
        const response = await axios.post(
          `${apiUrl}/insertperiod/${selectedSectionId}`,
          postData
        );
        console.log("Insert API Response:", response.data);
      }

      setIsAddModalOpen(false);
      setModalVisible(false);
      fetchData();
    } catch (error) {
      console.error(
        "An error occurred:",
        error.response ? error.response.data : error.message
      );
    }
  };

  console.log("id", tableData);

  const handleDeletePeriod = () => {
    try {
      Modal.confirm({
        title: "Are you sure to delete this period?",
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          fetch(
            `${apiUrl}/deleteTime/${selectedSectionId}/${selectedTimeId}`, // Use selectedTimeId
            {
              method: "DELETE",
            }
          )
            .then(() => {
              console.log("Time period deleted successfully");
              fetchData();
              handleCloseModal();
            })
            .catch((error) =>
              console.error("Error deleting time period:", error)
            );
        },
      });
    } catch {
      console.error("Invalid tableData at index", selectedTimeId);
    }
  };

  const cardData = [
    {
      title: "Students",
      icon: Students,
      path: `/homepage/My Classroom/Students/${selectedSectionId}`,
    },
    {
      title: "Attendance",
      icon: Attendance,
      path: `/homepage/My Classroom/Attendance/${selectedSectionId}`,
    },
    {
      title: "Exam",
      icon: Exam,
      path: `/homepage/My Classroom/Exam/${selectedSectionId}`,
    },
    {
      title: "Result",
      icon: Result,
      path: `/homepage/My Classroom/${selectedSectionId}`,
    },
  ];


  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => {
      // Check if the field is color and handle it separately
      if (name === "color") {
        return {
          ...prevData,
          [name]: value,
        };
      } else if (name === "faculty" || name === "subject") {
        // If the changed field is faculty or subject, update facultyId or subjectId accordingly
        const fieldId = name + "Id";
        return {
          ...prevData,
          [name]: value,
          [fieldId]: Array.isArray(prevData[fieldId])
            ? [...prevData[fieldId], value]
            : [value],
        };
      } else {
        // For other fields, handle them normally
        return {
          ...prevData,
          [name]: Array.isArray(prevData[name])
            ? [...prevData[name], value]
            : [value],
        };
      }
    });
  };



  useEffect(() => {
    if (subjects) {
      const filtered = subjects.filter(
        (subject) =>
          subject &&
          subject.subjectName &&
          subject.subjectName.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredSubjects(filtered);
    }
  }, [searchText, subjects]);

  const handleSectionClick = (event, newValue) => {
    const selectedSectionId = sectionData[newValue].sectionId;
    console.log("Clicked sectionId:", sectionData[newValue].sectionId);
    setSelectedSectionId(sectionData[newValue].sectionId);
    setSelectedSection(newValue);
    const sectionName = sectionData[newValue].sectionName;
    const semesterNumber = sectionData[newValue].semesterNumber;
    const courseName = sectionData[newValue].courseName;
    const courseId = sectionData[newValue].courseId;
    const academicYear = sectionData[newValue].academicYear;
    console.log("Clicked sectionId:", selectedSectionId);
    setSelectedSectionId(selectedSectionId);
    setSelectedSectionName(sectionName);
    setSelectedSemesterNumber(semesterNumber);
    setSelectedCourseId(courseId);
    setSelectedCourseName(courseName);
    setAcademicYear(academicYear);
    console.log(courseName, "name");
    console.log(academicYear,"academicYear")
    axios
      .get(`${apiUrl}/collegesubjectbysectioniduniqueid/${selectedSectionId}/${academicYear}`)
      .then((res) => {
        const subjectData = res.data.data || [];
        setSubjects(subjectData);
        if (
          subjectData &&
          subjectData.length > 0 &&
          subjectData.courseId &&
          subjectData.courseName &&
          subjectData.semesterNumber
        ) {
          // Retrieve courseId, courseName, and semesterNumber
          const courseId = subjectData.courseId;
          const courseName = subjectData.courseName;
          const semesterNumber = subjectData.semesterNumber;

          // Set the values in state
          setCourseId(courseId);
          setCourseName(courseName);
          setSemesterNumber(semesterNumber);
        }
       

        console.log("courseId", courseId);
        console.log("courseName", courseName);
        console.log("semesterNumber", semesterNumber);
        console.log("subjectData", subjectData);
        const subjectNames = subjectData.map((subject) => subject.subjectName);
        console.log("subject names", subjectNames);

        console.log("subject data", subjectData);
      })
      .catch((error) => {
        console.error("Error fetching subject data:", error);
      });
  };
  useEffect(() => {
    // Assuming sectionData is an array with at least one element
    if (sectionData && sectionData.length > 0) {
      const defaultSectionIndex = 0; // Index of the first section
      handleSectionClick(null, defaultSectionIndex); // Passing null for event as it's not used in your function
   
    }
  }, [sectionData]); // Add other dependencies as needed

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (selectedSubjectId && selectedFaculty) {
        // Fetch faculty data for the selected faculty
        const facultyData = facultyNameList.find(
          (f) => f.id === selectedFaculty
        );
        const facultyName = facultyData ? facultyData.name : null;

        const subjectData = subjects[0]?.subjectList.find(
          (subject) => subject.id === selectedSubjectId
        );
        const subjectName = subjectData ? subjectData.name : null;

        const formSendData = {
          subjectId: selectedSubjectId,
          subjectName: subjectName,
          facultyId: selectedFaculty,
          facultyName: facultyName,
          sectionId: selectedSectionId,
          sectionName: selectedSectionName,
          semesterNumber: selectedSemesterNumber,
          courseId: selectedCourseId, // Include course ID here
          courseName: selectedCourseName, // Include course name here
          UniqueId: localStorage.getItem("Unique_id"),
          instituteUniqueId:localStorage.getItem("institute_Unique_Id"),
          academicYear:academicYear,
        };

        const response = await axios.post(
          `${apiUrl}/collegeassignsubject`,
          formSendData
        ) .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
          
          }
          // setModalVisible(false);
          setShowAddForm(false)
          fetchSubjectCards();
        })
        .catch((err) => {
          toast.error(err.message);
        });

        console.log("Subject assignment saved:", response.data);
        setAddedSubjects([...addedSubjects, response.data.data]);
        // Clear the selections for the next entry

     
        setSelectedSubjectId(null);
        setSelectedFaculty(null);
        setError(null);
      } else {
        setError("Please select both subject and faculty before adding.");
      }
    } catch (error) {
      console.error(
        "Error saving subject assignment:",
        error.response ? error.response.data : error.message
      );
      // setError("Error saving subject assignment. Please try again.");
    }
  };

  const onChangeFaculty = (value) => {
    setSelectedFaculty(value);
  };

  const onChangeSubject = async (value) => {
    console.log("cc", value);

    setSelectedSubjectId(value); // Make sure to set the selectedSubjectId, not selectedSubject
    console.log(selectedSubjectId, "uhuhu");
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (subjectId) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        axios.delete(`${apiUrl}/deleteSubject/${subjectId}`).then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            // Update the UI after successful deletion
            setAddedSubjects(prevSubjects =>
              prevSubjects.filter(subject => subject.subjectId !== subjectId)
            );
          } else {
            toast.error(res.data.message);
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  

  return (
    <div className="bg-white h-auto rounded-md border drop-shadow">
      <div className="flex items-center mt-2 ml-2">
        <Tabs
          value={selectedSection}
          onChange={handleSectionClick}
          indicatorColor="none"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: { backgroundColor: "#F5A70D", padding: "2px" },
          }}
          sx={{
            "& button": {
              borderRadius: 2,
              height: "auto", // Adjust the height as needed
              padding: "0", // Adjust padding if needed
            },
          }}
        >
            {sectionData.map((section, index) => (
              <Tab
                key={section.id}
                label={section.sectionName}
                style={{
                  // borderLeft: "1px groove #105D50",
                  // borderBottom: "1px groove #105D50",
                  // borderRight: "4px groove #105D50",
                  // borderTop: "2px groove #105D50",
                  backgroundColor: "#fff",
                  color: "black",
                  gap: "2px",
                }}
              />
            ))}
        </Tabs>
      </div>

      <div className="flex flex-col justify-between mx-auto max-w-screen-xl p-3">
        <div className="flex justify-center items-center ">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-6">
            {cardData.map((card, index) => (
              <Link
                key={index}
                to={card.path}
                onClick={handleToggle}
                className="hover:bg-gray-100 bg-white border rounded-2xl h-[60px] w-[200px] mt-8 transition-colors duration-300 flex items-center justify-center shadow-md"
              >
                <img
                  src={card.icon}
                  alt={card.title}
                  className="w-5 h-5 mr-2"
                />
                <p className="font-semibold text-center text-md">
                  {card.title}
                </p>
              </Link>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-between px-4 py-2 rounded-lg mt-10">
          <h2 className="text-xl font-semibold mt-8">Subjects</h2>
          <div className="relative ml-[200px] ">
            <input
              placeholder="Search"
              type="text"
              required
              className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
                focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
              rounded-md "
              onChange={(event) => setSearchText(event.target.value)}
              value={searchText}
            />
            <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
          </div>
          <button
            className="flex flex-row mx-4 py-3 px-4 text-[14px] bg-primary text-white rounded-lg hover:bg-secondary  hover:text-green items-center gap-1 "
            onClick={(event) => {
              setSelectedSubject(null);
              setShowAddForm(true);
              showModal();
              setShowAddTimetable(false);
            }}
          >
            <FaPlus />
            Assign Subjects
          </button>
          <button
            className="flex flex-row py-3  px-4 text-[14px] bg-primary text-white rounded-lg hover:bg-secondary  hover:text-green items-center gap-1 "
            onClick={(event) => {
              setShowAddForm(false);
              setShowAddTimetable(true);
              showModal();
              setTimetableDataAvailable(false);
              setNumPeriods("");
              setStartDay("");
              setEndDay("");
              fetchData();
            }}
          >
            <FaPlus />
            Assign Timetable
          </button>
        </div>

        {/* ..................ADD SUBJECT FORM........................... */}

        {showAddForm && (
          <Modal
            open={isModalOpen}
            width={800}
            okButtonProps={{ style: { display: "none" } }}
            cancelButtonProps={{ style: { display: "none" } }}
            onCancel={() => setShowAddForm(false)}
          >
            <div className="w-full h-fit flex justify-center items-center py-12">
              <form
                onSubmit={handleSubmit}
                className="w-fit h-[300px] flex flex-col  justify-center items-center rounded border shadow gap-10 p-10"
              >
                <div className="w-full flex flex-row justify-center items-center gap-4">
                  <Select
                    showSearch
                    placeholder="Select a Subject"
                    optionFilterProp="children"
                    onChange={onChangeSubject}
                    value={selectedSubjectId}
                    options={subjectOptions}
                    className="w-[200px]"
                  />

                  <Select
                    showSearch
                    placeholder="Select a Faculty Name"
                    optionFilterProp="children"
                    onChange={onChangeFaculty}
                    value={selectedFaculty}
                    options={facultyOptions}
                    className="w-[200px]"
                  />

                  <button
                    type="submit" // Change to "button" to prevent form submission
                    // onClick={handleAdd}
                    className="py-1 px-5 text-[14px] font-sm rounded-md bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300"
                  >
                    Add
                  </button>
                </div>
                {error && (
                  <div className="w-full flex justify-center text-red-600">
                    {error}
                  </div>
                )}

                {/*<div className="w-full flex justify-center">
                  <button
                    type="submit"
                    className="py-2 px-5 text-[14px] font-sm rounded-md bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300"
                  >
                    Submit
                  </button>}
                </div>*/}
              </form>
            </div>
          </Modal>
        )}

        <hr className="border-t bg-gray-800 h-0.5 mx-4 shadow-md" />

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-4 mt-8">
        {addedSubjects.length > 0 ? (
         addedSubjects.map((subject, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-lg overflow-hidden transition duration-300 transform hover:scale-105 relative"
            >
              <div className="bg-gray-300 text-white text-8xl flex items-center justify-center h-32">
                {subject.subjectName[0]}
              </div>
              <div className="p-6 mb-4">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">
                  {subject.subjectName}
                </h2>

                <p className="text-sm text-gray-600">
                  Faculty Name: {subject.facultyName}
                </p>
                <div className="absolute bottom-2 right-10">
                  {/* <button
                    className="text-blue-500 hover:text-blue-700"
                    onClick={() => handleEditSubject(index)}
                  >
                    <FaEdit />
                  </button> */}
                </div>
                <div className="absolute bottom-2 right-4">
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => showDeleteConfirm (subject.subjectId)}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            </div>
          ))
          ) : (
            <div className="text-gray-600 text-center">
            {/* {console.log("No subjects assigned to this section")} 
            No subjects assigned to this section */}
          </div>
          )}
          
        </div>
      </div>
       
      {showAddTimetable && (
        <Modal
          open={isModalOpen}
          width={800}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => setShowAddTimetable(false)}
          footer={null}
        >
          {!timetableDataAvailable ? (
            <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
              <h2 className=" text-2xl font-bold text-center mb-4">
                Timetable Generator
              </h2>

              <Stack
                spacing={2}
                sx={{
                  margin: "auto",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  "& > :not(style)": {},
                  "& .MuiInputLabel-root": {
                    fontSize: "13px",
                    color: "#105D50",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "1.3px solid #105D50",
                    },
                    "&:hover fieldset": {
                      border: "1.3px solid #105D50",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1.34px solid #105D50",
                      fontSize: "13px",
                    },
                  },
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ marginBottom: "20px", width: "100%" }}
                  spacing={2}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    width={"390px"}
                    spacing={2}
                  >
                    <TextField
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                          height: 45,
                          fontSize: "14px",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEDED",
                        },
                      }}
                      label="Number of Periods"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={editMode ? editDataForm.numPeriods : numPeriods}
                      onChange={(e) => setNumPeriods(e.target.value)}
                    />

                    <TextField
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                          height: 45,
                          fontSize: "14px",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEDED",
                        },
                      }}
                      select
                      label="Select Starting Day"
                      variant="outlined"
                      fullWidth
                      value={editMode ? editDataForm.startDay : startDay}
                      onChange={(e) => setStartDay(e.target.value)}
                    >
                      {daysOfWeek.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                          height: 45,
                          fontSize: "14px",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEDED",
                        },
                      }}
                      select
                      label="Select Ending Day"
                      variant="outlined"
                      fullWidth
                      value={editMode ? editDataForm.endDay : endDay}
                      onChange={(e) => setEndDay(e.target.value)}
                    >
                      {daysOfWeek.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Stack>
                <Grid item xs={10}>
                  <div className="flex justify-center space-x-8 pt-4 pb-4">
                    <button
                      onClick={handleGenerateTimetable}
                      className="w-28 text-sm font-normal h-10 px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary"
                    >
                      Generate
                    </button>
                  </div>
                </Grid>
              </Stack>
            </div>
          ) : (
            <div className="bg-gray-100 p-4 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold text-center">Timetable</h2>
              <div className="flex items-center justify-between mb-2">
                <button
                  onClick={() => {
                    openInsertForm();
                    setFormData({
                      period: "",
                      day: "",
                      subject: "",
                      faculty: "",
                      color: "",
                      startTime: null,
                      endTime: null,
                    });
                  }}
                  className="flex flex-row py-3 px-4 text-[14px] bg-primary text-white rounded-lg hover:bg-secondary  hover:text-green items-center gap-1"
                >
                  Insert Period
                </button>

                <div className="flex items-center">
                  <div className="w-6 h-6 bg-white rounded-md border drop-shado mx-3">
                    <FaEdit
                      onClick={() => openEditModalTable(editData)}
                      className="w-6 h-6 text-blue-500 cursor-pointer"
                    />
                  </div>
                  <div className="w-6 h-6 bg-white rounded-md border drop-shado">
                    <MdDelete
                      onClick={() => handleDeleteTimetable(selectedSectionId)}
                      className="w-6 h-6 text-red-500 cursor-pointer bg-white rounded-md border drop-shadow"
                    />
                  </div>
                </div>
              </div>

              <DataTable
                fixedHeader
                fixedHeaderScrollHeight="430px"
                columns={generateColumns()}
                data={generateRows()}
                pagination
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                onRow={(record) => ({
                  onClick: () => handleFacultyClick(record),
                })}
              />
            </div>
          )}
        </Modal>
      )}

      {isEditFormOpen && (
        <Modal
          open={isEditFormOpen}
          width={600}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => setIsEditFormOpen(false)}
          footer={null}
        >
          <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
            <h2 className=" text-2xl font-bold text-center mb-4">
              Edit Timetable
            </h2>

            <Stack
              spacing={2}
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  fontSize: "13px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50",
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px",
                  },
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ marginBottom: "20px", width: "100%" }}
                spacing={2}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  width={"390px"}
                  spacing={2}
                >
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Number of Periods"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={numPeriods}
                    onChange={(e) => setNumPeriods(e.target.value)}
                  />

                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    select
                    label="Select Starting Day"
                    variant="outlined"
                    fullWidth
                    value={startDay}
                    onChange={(e) => setStartDay(e.target.value)}
                  >
                    {daysOfWeek.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    select
                    label="Select Ending Day"
                    variant="outlined"
                    fullWidth
                    value={endDay}
                    onChange={(e) => setEndDay(e.target.value)}
                  >
                    {daysOfWeek.map((day) => (
                      <MenuItem key={day} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Stack>
              <Grid item xs={10}>
                <div className="flex justify-center space-x-8 pt-4 pb-4">
                  <button
                    onClick={handleEditTimeable}
                    className="w-28 text-sm font-normal h-10 px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary"
                  >
                    Update
                  </button>
                </div>
              </Grid>
            </Stack>
          </div>
        </Modal>
      )}

      {isAddModalOpen && (
        <Modal
          open={openEditModal}
          width={600}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => setIsAddModalOpen(false)}
          footer={null}
        >
          <div className="your-form-modal">
            <h2 className=" text-2xl font-bold text-center mb-4">
              {isEditMode ? "Edit Period" : "Insert Period"}
            </h2>
            <Stack
              spacing={2}
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  fontSize: "13px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50",
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px",
                  },
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                sx={{ marginBottom: "20px", width: "100%" }}
                spacing={2}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  width={"390px"}
                  spacing={2}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ marginBottom: "20px", width: "100%" }}
                    spacing={2}
                  >
                    <TextField
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                          height: 45,
                          fontSize: "14px",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEDED",
                        },
                      }}
                      select
                      label="Select Period"
                      name="period"
                      value={formData.period}
                      onChange={handleFormChange}
                      fullWidth
                      margin="normal"
                    >
                      {periods.map((period) => (
                        <MenuItem key={period} value={period}>
                          {period}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      sx={{
                        backgroundColor: "#F5F5F5",
                        color: "#105D50",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                          height: 45,
                          fontSize: "14px",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEDED",
                        },
                      }}
                      select
                      label="Select Day"
                      name="day"
                      value={formData.day}
                      onChange={handleFormChange}
                      fullWidth
                      margin="normal"
                    >
                      {days.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ overflow: "hidden", flexWrap: "wrap" }}
                  >
                    {formData.period &&
                      formData.day &&
                      formData.period.map((period, index) => (
                        <Chip
                          key={index}
                          label={`${period}-${formData.day[index]}`}
                          onDelete={() => handleDeleteChip(index)}
                        />
                      ))}
                  </Stack>

                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    select
                    label="Select Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={(e) => {
                      handleSubjectChange(e);
                      handleFormChange(e);
                    }}
                    fullWidth
                    margin="normal"
                  >
                    {subjectOptions.map((subject) => (
                      <MenuItem key={subject.value} value={subject.value}>
                        {subject.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    select
                    label="Select Faculty"
                    name="faculty"
                    value={formData.faculty}
                    onChange={handleFormChange}
                    fullWidth
                    margin="normal"
                  >
                    {facultyOptions.map((facultyOption) => (
                      <MenuItem
                        key={facultyOption.value}
                        value={facultyOption.value}
                      >
                        {facultyOption.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ marginBottom: "20px", width: "100%" }}
                    spacing={2}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{
                          backgroundColor: "#F5F5F5",
                          color: "#105D50",
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#105D50",
                          },
                          "& .MuiInputBase-root": {
                            height: 45,
                            fontSize: "14px",
                          },
                          "&:hover": {
                            backgroundColor: "#EEEDED",
                          },
                        }}
                        label="Start Time"
                        variant="outlined"
                        name="startTime"
                        fullWidth
                        value={formData.startTime}
                        onChange={(value) =>
                          handleTimeChange(value, "startTime")
                        }
                        format="HH:mm"
                        required
                      />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{
                          backgroundColor: "#F5F5F5",
                          color: "#105D50",
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#105D50",
                          },
                          "& .MuiInputBase-root": {
                            height: 45,
                            fontSize: "14px",
                          },
                          "&:hover": {
                            backgroundColor: "#EEEDED",
                          },
                        }}
                        label="End Time"
                        variant="outlined"
                        name="endTime"
                        fullWidth
                        value={formData.endTime}
                        onChange={(value) => handleTimeChange(value, "endTime")}
                        format="HH:mm"
                        required
                      />
                    </LocalizationProvider>
                  </Stack>

                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Select Color"
                    name="color"
                    value={selectedColor}
                    onChange={handleFormChange}
                    onClick={handleColorClick}
                    fullWidth
                    margin="normal"
                  />

{showColorPicker && (
        <div style={{ position: 'absolute', zIndex: 2 }}>
          <ChromePicker color={selectedColor} onChange={handleColorChange} />
        </div>
      )}
                </Stack>
              </Stack>
              <Grid item xs={10}>
                <div className="flex justify-center space-x-8 pt-4 pb-4">
                  <button
                    onClick={handleFormSubmit}
                    className="w-28 text-sm font-normal h-10 px-4 py-2 bg-primary text-white rounded-lg hover:bg-secondary"
                  >
                    {isEditMode ? "Update" : "Submit"}
                  </button>
                </div>
              </Grid>
            </Stack>
          </div>
        </Modal>
      )}

      <Modal
        open={modalVisible}
        width={600}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCloseModal}
        footer={null}
        className="bg-white rounded-md p-6 overflow-hidden transform transition-all ease-in-out duration-300"
      >
        <div className="mb-6">
          <div className="flex items-center">
            <div className="flex items-center mr-3">
              <h2 className="text-2xl font-bold mb-2 text-gray-800">
                Faculty Details
              </h2>
              <div className="flex ml-64">
                <div className="w-6 h-6 bg-white rounded-md border drop-shadow">
                  <FaEdit
                    onClick={() => openPeriodEditModal(selectedFacultyData)}
                    className="w-6 h-6 text-blue-500 cursor-pointer"
                  />
                </div>
                <div className="w-6 h-6 bg-white rounded-md border drop-shadow ml-2">
                  <MdDelete
                    onClick={() => handleDeletePeriod(selectedFacultyData._id)}
                    className="w-6 h-6 text-red-500 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b-2 border-primary"></div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Faculty:</p>
            <p className="font-semibold">{selectedFacultyData.faculty}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Faculty ID:</p>
            <p className="font-semibold">{selectedFacultyData.facultyId}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Subject:</p>
            <p className="font-semibold">{selectedFacultyData.subject}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Subject ID:</p>
            <p className="font-semibold">{selectedFacultyData.subjectId}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">Start Time:</p>
            <p className="font-semibold">{selectedFacultyData.startTime}</p>
          </div>

          <div className="col-span-2 sm:col-span-1">
            <p className="text-gray-600 mb-2">End Time:</p>
            <p className="font-semibold">{selectedFacultyData.endTime}</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyClassroom;
