import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";


import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const CourseForm = () => {

  const LMS_ROLE = localStorage.getItem("lms_role");

  const [uploadFile, setUploadFile] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const initialFormData ={
    courseName: "",
    courseId: "",
    courseDuration: "",
    courseCode:"",
    courseDescription: "",
    courseKeywords: "",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
    LMS_ROLE === "3" || LMS_ROLE === "5"
    ? localStorage.getItem("Unique_id")
    :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

  };
  const [formData, setFormData] = useState(initialFormData);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    if (name === 'courseDuration') {
      const semesters = Array.from({ length: parseInt(value, 10) }, (_, index) => `semester ${index + 1}`);
      setFormData((prev) => ({
        ...prev,
        courseDuration: value,
        courseSemesters: semesters, // Assuming you have a courseSemesters field in your formData
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (editMode) {
      axios
        .put(`${apiUrl}/collegecourseaddition/${id}`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/course");
          }
          else {
            console.log(res.data.message)
          }} );
        
    } else {
      axios
        .post(`${apiUrl}/collegecourseaddition`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/course");
          }
          else {
            console.log(res.data.message)
          }} );
    }
  };

  
  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    if (editMode) {
      axios
        .put(`${apiUrl}/collegecourseaddition/${id}`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            setFormData(initialFormData);
          }
          else {
            console.log(res.data.message)
          }} );
        
    } else {
      axios
        .post(`${apiUrl}/collegecourseaddition`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
           setFormData(initialFormData);
          }
          else {
            console.log(res.data.message)
          }} );
    }
  };


  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({...formData}) ;
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(
      `${apiUrl}/collegecourseaddition/${id}`
    );

    console.log(formData.data.data);

    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  const handleReset = (e) => {
   setFormData(initialFormData);
  };




  return (
    <div className="mb-4 flex justify-between items-center ">
      <div className="w-full h-fit bg-white px-16 py-12 rounded-lg drop-shadow border ">
        {/* Add Branch Form */}

        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Course Name"
                  variant="outlined"
                  name="courseName"
                  type="text"
                  fullWidth
                  value={formData.courseName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Course Duration (No of Semesters)"
                  variant="outlined"
                  fullWidth
                  name="courseDuration"
                  type="number"
                  value={formData.courseDuration}
                  onChange={handleOnChange}
                  required
                />
                  {/* Add options for faculty list
                  <MenuItem value="3 Months" style={{ fontSize: "14px" }}>
                    3 Months
                  </MenuItem>
                  <MenuItem value="6 Months" style={{ fontSize: "14px" }}>
                    6 Months
                  </MenuItem>
                  {/* Add more faculty options as needed */}
              
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                {editMode ? ( <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                  }}
                  label="Course ID"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="courseId"
                  value={formData.courseId}
                  onChange={handleOnChange}
                  required
                  InputProps={{
                    readOnly:true
                  }}
                />) : ( <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                  }}
                  label="Course ID"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="courseId"
                  value={formData.courseId}
                  onChange={handleOnChange}
                  required
                />)}
               
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Course Code"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="courseCode"
                  value={formData.courseCode}
                  onChange={handleOnChange}
                 
                />
              </Stack>
            </Stack>

            <Stack>
            <Autocomplete
  multiple
  id="course-keywords"
  options={[]} // Add your options here if you have predefined keywords
  freeSolo
  value={formData.courseKeywords}
  onChange={(event, newValue) => handleOnChange({ target: { name: 'courseKeywords', value: newValue } })}
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <Chip label={option} {...getTagProps({ index })} />
    ))
  }
  renderInput={(params) => (
    <TextField
      {...params}
      sx={{
        backgroundColor: "#F5F5F5",
        color: "#105D50",
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#105D50",
        },
        "& .MuiInputBase-root": {
          width: 575,
          fontSize: "14px",
          
        },
        "&:hover": {
          backgroundColor: "#EEEDED",
        },
      }}
      label="Course Keywords"
      variant="outlined"
      fullWidth
    />
  )}
/>
                </Stack>
                <Stack sx={{ marginTop: 2 }}>
              
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 120,
                    width: 575,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                }}
                id="outlined-multiline-static"
                label="Description"
                type="text"
                name="courseDescription"
                value={formData.courseDescription}
                onChange={handleOnChange}
                multiline
                rows={4}
                //defaultValue="Default Value"
              />
            </Stack>

            
           

            <div className="flex justify-center items-center space-x-8 pt-8 pb-8">
            {!editMode && (
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
            )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default CourseForm;
