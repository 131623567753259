import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, Switch } from "antd";
import DatePicker from "react-datepicker";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import { FaCheck, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";
import {  Row, Col, Typography, Divider } from 'antd';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MenuItem, Stack, TextField } from "@mui/material";

function TodayAttendance() {
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const history = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date()); // State for selected date
  const [selectedRow, setSelectedRow] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;
  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");
  const PERMISSION_EDIT = localStorage.getItem("permission_edit");
  const PERMISSION_DELETE = localStorage.getItem("permission_delete");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [academicYear,setAcademicYear]=useState();

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Faculty ID</h1>,
      selector: (row) => row.facultyId,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-3 w-[200px]">Faculty Name</h1>,
      selector: (row) => row.facultyName,
      sortable: true,
    },
    {
      name: "Present/Absent",
      cell: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              checkedChildren={`Absent`}
              unCheckedChildren={`Present`}
              checked={row.leaveTaken}
              disabled={status === 1 ? false : true}
              className={
                row.leaveTaken
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
              onChange={() => handleSwitchChange(row, "leaveTaken")}
            />
          </div>
        </>
      ),
    },
    {
      name: "CL / ML / OD",
      cell: (row) => (
        <DropdownCell
          options={["Select", "CL", "ML", "OD"]}
          //value={row.typeOfLeave}
          selected={row.typeOfLeave}
          onChange={handleDropdownChange}
          facultyId={row.facultyId}
          disabled={status === 1 ? (row.leaveTaken ? false : true) : true}
        />
      ),
    },
    {
      name: "Remark",
      selector: "remark",
      sortable: true,
      cell: (row) => (
        <input
          type="text"
          value={row.remark || ""}
          onChange={(e) => handleRemarkChange(row.facultyId, e.target.value)}
          className="w-24 h-8"
          disabled={status === 1 ? (row.leaveTaken ? false : true) : true}
        />
      ),
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            {LMS_ROLE === "3" || LMS_ROLE === "5" || LMS_ROLE === "7" ? (
              <>
                <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)}/>
                {/* <Link
                  to={`/homepage/attendance/today attendace/${row.facultyId}`}
                > */}
                {status === 0 && (
                  <FaEdit
                    className="w-5 h-5 text-blue-500 cursor-pointer"
                    onClick={() => handleEdit(row)}
                  />
                )}

                {/* </Link> */}
              </>
            ) : (
              <>
                <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)}/>
                {PERMISSION_EDIT === "true" && status === 0 && (
                  // <Link
                  //   to={`/homepage/attendance/today attendace//${row.facultyId}`}
                  // >
                  <FaEdit
                    className="w-5 h-5 text-blue-500 cursor-pointer"
                    onClick={() => handleEdit(row)}
                  />
                  // </Link>
                )}
              </>
            )}
          </div>
        </>
      ),
    },
  ];

  console.log(item);

  const columnsForEdit = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Faculty ID</h1>,
      selector: (row) => row.facultyId,
      sortable: true,
    },
    {
      name: <h1 className="text-white pl-3 w-[200px]">Faculty Name</h1>,
      selector: (row) => row.facultyName,
      sortable: true,
    },
    {
      name: "Present/Absent",
      cell: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              checkedChildren={`Absent`}
              unCheckedChildren={`Present`}
              checked={row.leaveTaken}
              className={
                row.leaveTaken
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
              onChange={() =>
                handleSwitchChangeEdit(row.facultyId, "leaveTaken")
              }
            />
          </div>
        </>
      ),
    },
    {
      name: "CL / ML / OD",
      cell: (row) => (
        <DropdownCell
          options={["Select", "CL", "ML", "OD"]}
          //value={row.typeOfLeave}
          selected={row.typeOfLeave}
          onChange={handleDropdownChangeForEdit}
          facultyId={row.facultyId}
        />
      ),
    },
    {
      name: "Remark",
      selector: "remark",
      sortable: true,
      cell: (row) => (
        <input
          type="text"
          value={row.remark || ""}
          onChange={(e) =>
            handleRemarkChangeForEdit(row.facultyId, e.target.value)
          }
          className="w-24 h-8"
        />
      ),
    },
  ];

  const DropdownCell = ({
    options,
    selected,
    onChange,
    facultyId,
    disabled,
  }) => {
    return (
      <select
        value={selected}
        onChange={(e) => onChange(facultyId, e.target.value)}
        disabled={disabled}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    );
  };

  const handleDropdownChange = (facultyId, selectedOption) => {
    setItem((prevItem) =>
      prevItem.map((row) =>
        row.facultyId === facultyId
          ? { ...row, typeOfLeave: selectedOption }
          : row
      )
    );
  };

  const handleDropdownChangeForEdit = (facultyId, selectedOption) => {
    setSelectedRow((prevItem) =>
      prevItem.facultyId === facultyId
        ? { ...prevItem, typeOfLeave: selectedOption }
        : prevItem
    );
  };

  const handleRemarkChange = (facultyId, value) => {
    setItem((prevItem) =>
      prevItem.map((row) =>
        row.facultyId === facultyId ? { ...row, remark: value } : row
      )
    );
  };

  const handleRemarkChangeForEdit = (facultyId, value) => {
    setSelectedRow((prevItem) =>
      prevItem.facultyId === facultyId
        ? { ...prevItem, remark: value }
        : prevItem
    );
  };

  const handleSwitchChange = (row, columnName) => {
    setItem((prevItem) =>
      prevItem.map((prevRow) =>
        prevRow.facultyId === row.facultyId
          ? { ...prevRow, leaveTaken: !prevRow.leaveTaken }
          : prevRow
      )
    );
  };

  const handleSwitchChangeEdit = (row, columnName) => {
    setSelectedRow((prevItem) =>
      prevItem.facultyId === row
        ? { ...prevItem, leaveTaken: !prevItem.leaveTaken }
        : prevItem
    );
  };
  const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  const [endDate, setEndDate] = useState(null);

  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  // const handlePickChange = ([newStartDate, newEndDate]) => {
  //   setStartDate(newStartDate);
  //   setEndDate(newEndDate);
  // };
  // const onStartMonthYearChange = (date, dateString) => {
  //   setStartMonthYear(date);
  // };

  // const onEndMonthYearChange = (date, dateString) => {
  //   setEndMonthYear(date);
  // };

 
  // const handleFromDateChange = (date) => {
  //   setFromDate(date);
  // };

  // const handleToDateChange = (date) => {
  //   setToDate(date);
  // };


  const [formAcademicData, setFormAcademicData] = useState({
    academicYear:"",
   });
   const handleAcademicChange = (e) => {
     setFormAcademicData((prev) => ({
       ...prev,
       [e.target.name]: e.target.value,
     }));
   };
   //get academic year
   useEffect(() => {
     if (LMS_ROLE === "3" || LMS_ROLE === "5") {
       axios
         .get(
           `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
             "Unique_id"
           )}`
         )
         .then((response) => {
           if (response.status === 200 && Array.isArray(response.data.data)) {
             setAcademicYear(response.data.data);
             console.log("data",academicYear);
           } else {
             console.log("Failed to fetch courses.");
           }
         })
         .catch((error) => {
           console.log(error.response.data);
           if (error.response.data.status === 404) {
             toast.info("Course name not found");
           }
         });
     } else if (LMS_ROLE === "4") {
       axios
         .get(
           `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
             "institute_Unique_Id"
           )}`
         )
         .then((response) => {
           if (response.status === 200 && Array.isArray(response.data.data)) {
             setAcademicYear(response.data.data);
           } else {
             console.log("Failed to fetch courses.");
           }
         })
         .catch((error) => {
           console.log(error.response.data);
           if (error.response.data.status === 404) {
             toast.info("Course name not found");
           }
         });
     } else if (LMS_ROLE === "6") {
       axios
         .get(
           `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
             "branch_Unique_Id"
           )}`
         )
         .then((response) => {
           if (response.status === 200 && Array.isArray(response.data.data)) {
             setAcademicYear(response.data.data);
           } else {
             console.log("Failed to fetch courses.");
           }
         })
         .catch((error) => {
           console.log(error.response.data);
           if (error.response.data.status === 404) {
             toast.info("Course name not found");
           }
         });
     }
   }, []);
 
   const handleAcademicYearChange = (e) => {
     const selectedAcademicYear = e.target.value;
     setFormAcademicData((prevData) => ({
       ...prevData,
       academicYear: selectedAcademicYear,
     }));
   };

   
  useEffect(() => {
    if (Array.isArray(item)) {
      const result = item.filter((value) => {
        // Check if facultyName is defined before trying to access its properties
        return (
          value.facultyName &&
          value.facultyName.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [item, searchTerm]);

  const fetchData = async (date) => {
    try {
      let apiUrlEndpoint;

      if (LMS_ROLE == "3") {
        if (date) {
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

          apiUrlEndpoint = `${apiUrl}/fetchUpdatedAttendancebyuniqueid/${localStorage.getItem(
            "Unique_id"
          )}?date=${formattedDate}`;
        }

        // console.log("API URL Endpoint:", apiUrlEndpoint);

        const response = await axios.get(apiUrlEndpoint);

        // console.log("API Response:", response);

        if (response.data.data.length === 0 && date) {
          const fallbackApiUrlEndpoint = `${apiUrl}/facultiesattendancebyuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`;
          const fallbackResponse = await axios.get(fallbackApiUrlEndpoint);
          // console.log("Fallback API URL Endpoint:", fallbackApiUrlEndpoint);
          // console.log("Fallback API Response:", fallbackResponse);

          const updatedData = fallbackResponse.data.data;
          setItem(updatedData);
          setStatus(1);
          // console.log("Fetched Data:", updatedData);
        } else {
          const updatedData = response.data.data;
          setItem(updatedData);
          setStatus(0);
        }
      }
      //Instituteadmin And Branch admin attendance data
      else if (LMS_ROLE == "4") {
        if (date) {
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
          apiUrlEndpoint = `${apiUrl}/fetchUpdatedAttendancebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}?date=${formattedDate}`;
        }

        // console.log("API URL Endpoint:", apiUrlEndpoint);

        const response = await axios.get(apiUrlEndpoint);

        // console.log("API Response:", response);

        if (response.data.data.length === 0 && date) {
          const fallbackApiUrlEndpoint = `${apiUrl}/facultiesattendancebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`;
          const fallbackResponse = await axios.get(fallbackApiUrlEndpoint);
          // console.log("Fallback API URL Endpoint:", fallbackApiUrlEndpoint);
          // console.log("Fallback API Response:", fallbackResponse);

          const updatedData = fallbackResponse.data.data;
          setItem(updatedData);
          setStatus(1);
          // console.log("Fetched Data:", updatedData);
        } else {
          const updatedData = response.data.data[0].facultyAttendanceData;
          setItem(updatedData);
          setStatus(0);
        }
      } else if (LMS_ROLE == "6") {
        if (date) {
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
          apiUrlEndpoint = `${apiUrl}/fetchUpdatedAttendancebybranchuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}?date=${formattedDate}`;
        }

        // console.log("API URL Endpoint:", apiUrlEndpoint);

        const response = await axios.get(apiUrlEndpoint);

        // console.log("API Response:", response);

        if (response.data.data.length === 0 && date) {
          const fallbackApiUrlEndpoint = `${apiUrl}/facultiesattendancebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`;
          const fallbackResponse = await axios.get(fallbackApiUrlEndpoint);
          // console.log("Fallback API URL Endpoint:", fallbackApiUrlEndpoint);
          // console.log("Fallback API Response:", fallbackResponse);

          const updatedData = fallbackResponse.data.data;
          setItem(updatedData);
          setStatus(1);
          // console.log("Fetched Data:", updatedData);
        } else {
          const updatedData = response.data;
          setItem(updatedData);
          setStatus(0);
        }
      }
      //Branch attendance data
      else {
        if (date) {
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
          apiUrlEndpoint = `${apiUrl}/fetchUpdatedAttendancebybranchuniqueid/${localStorage.getItem(
            "Unique_id"
          )}?date=${formattedDate}`;
        }

        // console.log("API URL Endpoint:", apiUrlEndpoint);

        const response = await axios.get(apiUrlEndpoint);

        // console.log("API Response:", response);

        if (response.data.data.length === 0 && date) {
          const fallbackApiUrlEndpoint = `${apiUrl}/facultiesattendancebyuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`;
          const fallbackResponse = await axios.get(fallbackApiUrlEndpoint);
          // console.log("Fallback API URL Endpoint:", fallbackApiUrlEndpoint);
          // console.log("Fallback API Response:", fallbackResponse);

          const updatedData = fallbackResponse.data.data;
          setItem(updatedData);
          setStatus(1);
          // console.log("Fetched Data:", updatedData);
        } else {
          const updatedData = response.data.data;
          setItem(updatedData);
          setStatus(0);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(selectedDate); // Fetch data for the initial selected date
  }, [selectedDate]);

  const handleDateChange = async (date) => {
    try {
      setSelectedDate(date);
      await fetchData(date);
    } catch (error) {
      console.error(error);
    }
  };

  const submitAttendance = async () => {
    try {
      let response;
      // const getMonthYear = (date) => {
      //   if (!date) return null;
      //   const year = date.getFullYear();
      //   const month = date.getMonth() + 1; // Months are zero-based, so add 1
      //   return `${month}/${year}`;
      // };
      const updatedAttendance = item.map((row) => ({
        facultyId: row.facultyId,
        facultyName: row.facultyName,
        leaveTaken: row.leaveTaken ? 1 : 0,
        typeOfLeave: row.typeOfLeave === "Select" ? "" : row.typeOfLeave,
        remark: row.remark || "",
        instituteUniqueId: row.instituteUniqueId,
        uniqueId: localStorage.getItem("Unique_id"),
        date: selectedDate.toISOString().split("T")[0], 
        // startDate: getMonthYear(startDate), // Store month and year for 'startDate'
        // endDate: getMonthYear(endDate), 
        academicYear:formAcademicData.academicYear,
      }));
      console.log(
        "Remark values:",
        updatedAttendance.map((entry) => entry)
      );

      response = await axios.post(
        `${apiUrl}/attendance/updateAttendance`,
        updatedAttendance,
        {
          // Include the status in the request
          params: { status },
        }
      );

      setItem(response.data);
      setStatus(0);
      toast.success("Attendance updated successfully");
    } catch (error) {
      console.log(error, "error");
      toast.error("Error submitting attendance");
    }
  };

  const updateAttendance = async () => {
    try {
      const updatedRow = {
        facultyId: selectedRow.facultyId,
        facultyName: selectedRow.facultyName,
        leaveTaken: selectedRow.leaveTaken ? 1 : 0,
        typeOfLeave:selectedRow.typeOfLeave === "Select" ? "" : selectedRow.typeOfLeave,
        remark: selectedRow.remark,
        uniqueId: localStorage.getItem("Unique_id"),
        instituteUniqueId: selectedRow.instituteUniqueId,
        date: selectedDate.toISOString().split("T")[0],
        // startDate: startDate ? startDate.toLocaleDateString('en-US') : null,
        // endDate: endDate ? endDate.toLocaleDateString('en-US') : null,
        academicYear:formAcademicData.academicYear,
      };

      console.log("Updated Row:", updatedRow);
      await axios.put(
        `${apiUrl}/attendance/updateAttendance/${selectedRow._id}`,
        updatedRow
      );
      // console.log(response);
      fetchData(selectedDate);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating attendance:", error.response.data);
      toast.error("Error updating attendance");
    }
  };

  const handleEdit = (row) => {
    setIsModalVisible(true);
    setSelectedRow(row);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRow();
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px",
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        textAlign: "center",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Text, Title} = Typography;

  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };

  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="W-full h-fit bg-white flex flex-col items-center gap-3 rounded-md pt-5  px-1 drop-shadow border  pb-2">
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
      <div className="flex items-center relative gap-2" style={{ zIndex: 2 }}>
        {/* Academic Year
      <DatePicker
      selected={startDate}
      onChange={handleChange}
      selectsRange
      startDate={startDate}
      endDate={endDate}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      className="p-3 w-[150px] text-[14px] bg-gray-100 text-gray-700
      focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
    rounded-md "

    /> */}
        <Stack
              sx={{
                margin: "auto",
                width: "100%",
                display: "flex",
                alignItems: "center",
                "& > :not(style)": {},
                "& .MuiInputLabel-root": {
                  // Reduce label font size
                  fontSize: "13px",
                  color: "#105D50",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1.3px solid #105D50", // Set the default outline color to black
                  },
                  "&:hover fieldset": {
                    border: "1.3px solid #105D50",
                    // Change the border color on hover
                  },

                  "&.Mui-focused fieldset": {
                    border: "1.34px solid #105D50",
                    fontSize: "13px", // Set the outline color to yellow on focus
                  },
                },
              }}
            >
    <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                width={"250px"}
                spacing={2}
              >
    <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 40,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formAcademicData.academicYear}
                  onChange={(e) => {
                    handleAcademicChange(e);
                    handleAcademicYearChange(e); // Call the course change handler
                  }}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>
                </Stack>
                </Stack>
    </div>
    <div className="flex items-center relative gap-2" style={{ zIndex: 2 }}>
          {/* Date picker component */}
          Date
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            className="p-3 w-[150px] text-[14px] bg-gray-100 text-gray-700
            focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          rounded-md "
            maxDate={new Date()}
          />
        </div>
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
        </div>
      </div>
      <div className="flex flex-col w-full mt-4">
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
      <div className="w-full flex justify-end px-4">
        {status === 1 ? (
          <button
            className="py-2 px-6 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
            onClick={submitAttendance}
          >
            Submit
          </button>
        ) : (
          <button
            className="py-2 px-6 text-[12px] font-sm bg-gray-400 text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary  ease-in-out duration-300 flex flex-row items-center gap-1"
            // <button
            //   className="bg-gray-400 text-white py-2 px-4 rounded-md"
            disabled
          >
            Submit
          </button>
        )}
      </div>

      <Modal
        title="Edit Attendance"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null} // Disable the default footer
        width={800}
      >
        <div className="flex flex-col w-full mt-4">
          <DataTable
            fixedHeader
            fixedHeaderScrollHeight="250px"
            columns={columnsForEdit}
            data={[selectedRow]}
            selectableRows
            pagination
            customStyles={customStyles}
            highlightOnHover
            pointerOnHover
          />
        </div>
        <div className="w-full flex justify-end px-4">
          <button
            className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"
            onClick={updateAttendance}
          >
            Update Attendance
          </button>
        </div>
      </Modal>
      <div className='flex justify-end ml-14 '>
        <Modal
          //title="Branch Preview"
          visible={isPreviewModalVisible}
          onOk={hidePreviewModal}
          onCancel={hidePreviewModal}
          width={600}
          footer={null}
        >
          {previewData && (
            <div className="py-4">
               <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                  <Col span={2.1}>
                  <img src={preview1} className="flex justify-start" ></img>
                  </Col>
                
                {/* <Row gutter={[16, 16]} align="left" > */}
                  <div className="flex">
                    <Col span={4.5} className="z-50 flex flex-col ">
                          {previewData.uploadProfile && (
                              <img
                              src={`${ImageUrl}/CollegeFacultyUploads/${previewData.uploadProfile}`}
                              alt="Profile"
                              className="rounded-full w-20 h-20 border-white border-[3px] drop-shadow-xl"
                              />
                          )}
                    </Col>
                    <Col span={12} className="flex flex-col justify-center mt-2">
                      <Title level={3} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 text-white rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1 ">
                        {previewData.facultyName}
                      </Title>
                    </Col>
                  </div>
                {/* </Row> */}
            </Row>
            <div className="sm:ml-20 my-6 space-y-2">
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Faculty ID:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{previewData.facultyId}</Text>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Number of Leaves Taken:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{previewData.leaveTaken ? previewData.leaveTaken : 0}</Text>
                  </Col>
                </Row>


                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">Type Of Leave:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{previewData.typeOfLeave ? previewData.typeOfLeave : "nil"}</Text>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col flex="200px">
                    <Text strong className="text-primary">remark:</Text>
                  </Col>
                  <Col flex="auto">
                    <Text>{previewData.remark ? previewData.remark : "nil"}</Text>
                  </Col>
                </Row>
            </div>

             <Row className="flex justify-end -mt-20" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
              </Row>
            </div>
          )}
        </Modal>

      </div>
    </div>
  );
}

export default TodayAttendance;
