import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { FormGroup, Stack, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Checkbox from "@mui/material/Checkbox";
import dayjs from 'dayjs';

const apiUrl = process.env.REACT_APP_API_URL;

const LMS_ROLE = localStorage.getItem("lms_role");

const AnnouncementForm = () => {
  const [editMode, setEditMode] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const history = useNavigate();
  const id = useParams();

  const initialFormData = {
    announcementTitle: "",
    announcementDate: "",
    announcementSubject: "",
    category:"",
    visibility:"",
    contactNumber:"",
    contactEmailId:"",
    expirationDate:"",
    description:"",
    uploadFile:"",
    uploadedFileName: "",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
    //  batchId: localStorage.getItem("batch_id"),
    role: LMS_ROLE,
  };

  const [formData, setFormData] = useState(initialFormData);






  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      uploadFile: file,
      uploadedFileName: file ? file.name : "",
    });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleDoeChange = (date) => {
    setFormData({
      ...formData,
      announcementDate: date.format("DD/MM/YYYY hh:mm A"),
  
    });
  };

  const [selectedVisibility, setSelectedVisibility] = useState([]);
  const visibilities = [
    { visibilities: 'Branch admins', value: 'Branch', role: "5" },
    { visibilities: 'Faculties', value: 'Faculty', role: "7" },
    { visibilities: 'Students', value: 'Student', role: "8" },
    { visibilities: 'All', value: 'Institute All', role: "10" },
  ];

  const handleCheckboxChange = (e, visibility) => {
    const { checked } = e.target;
    let updatedSelectedVisibility = [...selectedVisibility];

    if (visibility.visibilities === 'All') {
      if (checked) {
        updatedSelectedVisibility = visibilities
          .filter(v => v.visibilities !== 'All')
          .map(v => ({ name: v.visibilities, value: v.value }));
      } else {
        updatedSelectedVisibility = [];
      }
    } else {
      if (checked) {
        updatedSelectedVisibility.push({ name: visibility.visibilities, value: visibility.value });
      } else {
        updatedSelectedVisibility = updatedSelectedVisibility.filter(v => v.name !== visibility.visibilities);
      }
      if (selectedVisibility.some(v => v.name === 'All') && !checked) {
        updatedSelectedVisibility = updatedSelectedVisibility.filter(v => v.name !== 'All');
      }
    }

    setSelectedVisibility(updatedSelectedVisibility);
  };


  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      expirationDate: date.format("DD/MM/YYYY"),
     
    });
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      //getFormData(id);
    } else {
      setEditMode(false);
      setFormData({ ...formData });
    }
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const formAppendData = new FormData();
    formAppendData.append("announcementTitle", formData.announcementTitle);
    formAppendData.append("announcementDate", formData.announcementDate);
    formAppendData.append("expirationDate", formData.expirationDate);
    formAppendData.append("announcementSubject", formData.announcementSubject);
    formAppendData.append("category", formData.category);
    formAppendData.append('visibility', JSON.stringify(selectedVisibility));
    formAppendData.append(
      'visibilityList',
      selectedVisibility.map((v) => v.name).join(',')
    );

    formAppendData.append("contactNumber", formData.contactNumber);
    formAppendData.append("contactEmailId", formData.contactEmailId);
    formAppendData.append("description", formData.description);
    formAppendData.append("uploadFile",  formData.uploadFile);
    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id")
    );
  
    // Check if uploadFile is null
    // if (uploadFile === null) {
    //   toast.warn("Please upload file");
    //   return; // Stop execution if uploadFile is null
    // }
  
    // Create the Axios config object with the headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  
      try {
        const res = await axios.post(`${apiUrl}/collegeannouncement`, formAppendData, config);
  
        if (res.data.status === 301) {
          toast.warn(res.data.message);
        } else if (res.status === 200) {
          toast.success(res.data.message);
          history("/homepage/announcement");
        }
      } catch (error) {
        console.error("Error creating announcement:", error);
      }
    
  };
  
  const handleReset = (e) => {
    setFormData(initialFormData);
  };




  return (
    <div className="mb-4 flex justify-between items-center top-0">
      <div className="w-full bg-white px-16 py-6 rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
        <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                 <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Announcement Title"
                  variant="outlined"
                  name="announcementTitle"
                  fullWidth
                  value={formData.announcementTitle}
                  onChange={handleOnChange}
                  required
                />

<TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Category"
                  variant="outlined"
                  fullWidth
                  name="category"
                  select
                  value={formData.category}
                  onChange={handleOnChange}
                  required
                >
                  <MenuItem value="Event" style={{ fontSize: "14px" }}>
                    Event
                  </MenuItem>
                  <MenuItem value="Circular" style={{ fontSize: "14px" }}>
                    Circular
                  </MenuItem>
                  <MenuItem value="Information" style={{ fontSize: "14px" }}>
                    Information
                  </MenuItem>
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Announcement Date"
                    format="DD/MM/YYYY hh:mm A"
                    variant="outlined"
                    type="date"
                    name="announcementDate"
                    fullWidth
                    //value={formData.dob}
                    onChange={handleDoeChange}

                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Announcement Subject"
                  variant="outlined"
                  name="announcementSubject"
                  fullWidth
                  value={formData.announcementSubject}
                  onChange={handleOnChange}
                  required
                />


                </Stack>

                <Stack 
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
                >
             <TextField
      sx={{
        backgroundColor: '#F5F5F5',
        color: '#105D50',
        '& .MuiFormLabel-root.Mui-focused': {
          color: '#105D50',
        },
        '& .MuiInputBase-root': {
          height: 45,
          fontSize: '14px',
        },
        '&:hover': {
          backgroundColor: '#EEEDED',
        },
      }}
      label="Visibility"
      variant="outlined"
      fullWidth
      name="visibility"
      select
      multiple
      value={selectedVisibility.map((s) => s.name) || []}
      SelectProps={{
        multiple: true,
        renderValue: (selected) => selected.join(', '),
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '160px',
            },
          },
        },
      }}
      required
    >
      {visibilities.map((visibility) => (
        <MenuItem key={visibility.value} value={visibility.value} style={{ fontSize: '14px' }}>
          <Checkbox
            checked={selectedVisibility.some((s) => s.name === visibility.visibilities)}
            onChange={(e) => handleCheckboxChange(e, visibility)}
            style={{
              color: '#105D50',
              width: 36,
              height: 36,
            }}
          />
          {visibility.visibilities}
        </MenuItem>
      ))}
    </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Contact Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Contact Email ID"
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="contactEmailId"
                  value={formData.contactEmailId}
                  onChange={handleOnChange}
                  autoComplete="off"
                  required
                />

<LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Expiration Date"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    type="date"
                    name="expirationDate"
                    fullWidth
                    //value={formData.dob}
                    onChange={handleDateChange }
                    minDate={dayjs()} 
                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>



</Stack>{" "}
</Stack>
<Stack>
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 120,
                    width: 580,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                }}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                type="text"
                name="description"
                value={formData.description}
                onChange={handleOnChange}
                //defaultValue="Default Value"
              />
            </Stack>{" "}
<div className="pt-4">
               <Button
                  sx={{
                    height: 45,
                    width: 580,
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    borderColor: "#105D50",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                      borderColor: "#105D50",
                    },
                  }}
                  variant="outlined"
                  fullWidth
                  startIcon={<CloudUploadIcon />}
                  component="label"
                  name= "uploadFile"
                >

{formData.uploadedFileName ? (
                    <p>{formData.uploadedFileName}</p>
                  ) : (
                    <p>Attachment</p>
                  )}
  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => handleFileChange(e)}
                    required
                  />
                </Button>
           
                </div>
        

                <Grid item xs={10}>
              <div className="flex  justify-center pt-6 pb-4  space-x-8">
                <button
                  type="button"
                  onClick={handleReset}
                  className="w-28 text-[12px] font-normal h-10 px-4 py-2   bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  className="w-28 text-[12px] font-normal h-10 px-4 py-2  bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Submit
                </button>
                {/* <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className="w-34 text-[12px] font-normal h-10 px-4 py-2   bg-primary text-white rounded-lg hover:bg-secondary hover:text-green"
                >
                  Submit & Add
                </button> */}
              </div>
            </Grid>
        
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default AnnouncementForm;
