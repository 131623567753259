import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Stack } from "@mui/material";
import camera from "../Images/camera.png";
import axios from "axios";
import vr from "../Images/vr.png";
import "../App.css";
import { Switch, Space, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

function BranchForm() {
  // const cloudSpaceOptions = ["GB", "TB"];
  const [editMode, setEditMode] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [branchLogo, setBranchLogo] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState(""); 
  const navigate = useNavigate();
  const { id } = useParams();

  const LMS_ROLE = localStorage.getItem("lms_role");

  //   //for countries states cities
  //   const [ countries, setCountries ] = useState([]);
  //   const [ states, setStates ] = useState([]);
  //   const [ cities, setCities ] = useState([]);
  //   const [ selectedCountry, setSelectedCountry ] = useState(null);
  //   const [ selectedState, setSelectedState ] = useState(null);
  //   const [selectedDistrict, setSelectedDistrict] = useState(null);
   
  //   useEffect(()=> {
  //       fetch('/countries+states+cities.json')
  //              .then((response)=> (
  //               response.json()
  //           ))
  //           .then((response)=> {
  //               setCountries(response);
  //           })
  //   },[])

  //   const handleCountry = (e)=> {
  //       if(e.target.textContent != null){
  //           const selectedCountryA = countries.find((country)=> country.name === e.target.textContent);
  //           setSelectedCountry(selectedCountryA);
  //           setSelectedState(null);
  //           setSelectedDistrict(null); 
  //  }

   
  //   }
    

  //   useEffect(() => {
  //       if (selectedCountry) {
  //           const statesA = selectedCountry.states || [];
  //           setStates(statesA);
  //           setCities([]);
  //       } else {
  //           setSelectedState(null);
  //           setSelectedDistrict(null);
  //           setStates([]);
  //           setCities([]);
  //       }
  //   }, [selectedCountry]);
    

  //   const handleState = (e)=> {
  //       if(e.target.textContent != null){
  //           const selectedState1 = states.find((state)=> state.name === e.target.textContent)
  //           setSelectedState(selectedState1)
  //           setSelectedDistrict(null);
  //           console.log(selectedState)
  //       }
  //   }

  //   useEffect(()=> {
  //       if(selectedState){
  //           const citiesA = selectedState.cities || [];
  //           setCities(citiesA)
  //       }
  //       else{
  //           setCities([])
  //       }
  //   }, [selectedState])

  //   const handleDistrict = (e)=> {
  //       if(e.target.textContent != null){
  //           const selectedDistrict = cities.find((cistrict)=> cistrict.name === e.target.textContent)
  //           setSelectedDistrict(selectedDistrict);
  //       }
  //   }
 
  const countries = {
    India: {
      states: [
        {
          name: "Andaman and Nicobar Islands",
          districts: ["Nicobar", "North and Middle Andaman", "South Andaman"],
        },
        {
          name: "Andhra Pradesh",
          districts: [
            "Anantapur",
            "Chittoor",
            "East Godavari",
            "Guntur",
            "Krishna",
            "Kurnool",
            "Prakasam",
            "Srikakulam",
            "Visakhapatnam",
            "Vizianagaram",
            "West Godavari",
            "Y.S.R.",
          ],
        },
        {
          name: "Arunachal Pradesh",
          districts: [
            "Anjaw",
            "Changlang",
            "Dibang Valley",
            "East Kameng",
            "East Siang",
            "Kamle",
            "Kra Daadi",
            "Kurung Kumey",
            "Lepa Rada",
            "Lohit",
            "Longding",
            "Lower Dibang Valley",
            "Lower Siang",
            "Lower Subansiri",
            "Namsai",
            "Pakke-Kessang",
            "Papum Pare",
            "Shi Yomi",
            "Siang",
            "Tawang",
            "Tirap",
            "Upper Siang",
            "Upper Subansiri",
            "West Kameng",
            "West Siang",
          ],
        },
        {
          name: "Assam",
          districts: [
            "Baksa",
            "Barpeta",
            "Biswanath",
            "Bongaigaon",
            "Cachar",
            "Charaideo",
            "Chirang",
            "Darrang",
            "Dhemaji",
            "Dhubri",
            "Dibrugarh",
            "Dima Hasao",
            "Goalpara",
            "Golaghat",
            "Hailakandi",
            "Hojai",
            "Jorhat",
            "Kamrup",
            "Kamrup Metropolitan",
            "Karbi Anglong",
            "Karimganj",
            "Kokrajhar",
            "Lakhimpur",
            "Majuli",
            "Morigaon",
            "Nagaon",
            "Nalbari",
            "Sivasagar",
            "Sonitpur",
            "South Salmara-Mankachar",
            "Tinsukia",
            "Udalguri",
            "West Karbi Anglong",
          ],
        },
        {
          name: "Bihar",
          districts: [
            "Araria",
            "Arwal",
            "Aurangabad",
            "Banka",
            "Begusarai",
            "Bhagalpur",
            "Bhojpur",
            "Buxar",
            "Darbhanga",
            "East Champaran",
            "Gaya",
            "Gopalganj",
            "Jamui",
            "Jehanabad",
            "Kaimur",
            "Katihar",
            "Khagaria",
            "Kishanganj",
            "Lakhisarai",
            "Madhepura",
            "Madhubani",
            "Munger",
            "Muzaffarpur",
            "Nalanda",
            "Nawada",
            "Patna",
            "Purnia",
            "Rohtas",
            "Saharsa",
            "Samastipur",
            "Saran",
            "Sheikhpura",
            "Sheohar",
            "Sitamarhi",
            "Siwan",
            "Supaul",
            "Vaishali",
            "West Champaran",
          ],
        },
        {
          name: "Chandigarh",
          districts: ["Chandigarh"],
        },
        {
          name: "Chhattisgarh",
          districts: [
            "Balod",
            "Baloda Bazar",
            "Balrampur",
            "Bastar",
            "Bemetara",
            "Bijapur",
            "Bilaspur",
            "Dantewada",
            "Dhamtari",
            "Durg",
            "Gariaband",
            "Janjgir-Champa",
            "Jashpur",
            "Kanker",
            "Kabirdham",
            "Kondagaon",
            "Korba",
            "Koriya",
            "Mahasamund",
            "Mungeli",
            "Narayanpur",
            "Raigarh",
            "Raipur",
            "Rajnandgaon",
            "Sukma",
            "Surajpur",
            "Surguja",
          ],
        },
        {
          name: "Dadra and Nagar Haveli and Daman and Diu",
          districts: ["Dadra and Nagar Haveli", "Daman", "Diu"],
        },
        {
          name: "Delhi",
          districts: [
            "Central Delhi",
            "East Delhi",
            "New Delhi",
            "North Delhi",
            "North East Delhi",
            "North West Delhi",
            "Shahdara",
            "South Delhi",
            "South East Delhi",
            "South West Delhi",
            "West Delhi",
          ],
        },
        {
          name: "Goa",
          districts: ["North Goa", "South Goa"],
        },
        {
          name: "Gujarat",
          districts: [
            "Ahmedabad",
            "Amreli",
            "Anand",
            "Aravalli",
            "Banaskantha",
            "Bharuch",
            "Bhavnagar",
            "Botad",
            "Chhota Udaipur",
            "Dahod",
            "Dang",
            "Devbhoomi Dwarka",
            "Gandhinagar",
            "Gir Somnath",
            "Jamnagar",
            "Junagadh",
            "Kheda",
            "Kutch",
            "Mahisagar",
            "Mehsana",
            "Morbi",
            "Narmada",
            "Navsari",
            "Panchmahal",
            "Patan",
            "Porbandar",
            "Rajkot",
            "Sabarkantha",
            "Surat",
            "Surendranagar",
            "Tapi",
            "Vadodara",
            "Valsad",
          ],
        },
        {
          name: "Haryana",
          districts: [
            "Ambala",
            "Bhiwani",
            "Charkhi Dadri",
            "Faridabad",
            "Fatehabad",
            "Gurugram",
            "Hisar",
            "Jhajjar",
            "Jind",
            "Kaithal",
            "Karnal",
            "Kurukshetra",
            "Mahendragarh",
            "Nuh",
            "Palwal",
            "Panchkula",
            "Panipat",
            "Rewari",
            "Rohtak",
            "Sirsa",
            "Sonipat",
            "Yamunanagar",
          ],
        },
        {
          name: "Himachal Pradesh",
          districts: [
            "Bilaspur",
            "Chamba",
            "Hamirpur",
            "Kangra",
            "Kinnaur",
            "Kullu",
            "Lahaul and Spiti",
            "Mandi",
            "Shimla",
            "Sirmaur",
            "Solan",
            "Una",
          ],
        },
        {
          name: "Jharkhand",
          districts: [
            "Bokaro",
            "Chatra",
            "Deoghar",
            "Dhanbad",
            "Dumka",
            "East Singhbhum",
            "Garhwa",
            "Giridih",
            "Godda",
            "Gumla",
            "Hazaribagh",
            "Jamtara",
            "Khunti",
            "Koderma",
            "Latehar",
            "Lohardaga",
            "Pakur",
            "Palamu",
            "Ramgarh",
            "Ranchi",
            "Sahibganj",
            "Seraikela-Kharsawan",
            "Simdega",
            "West Singhbhum",
          ],
        },
        {
          name: "Karnataka",
          districts: [
            "Bagalkot",
            "Ballari",
            "Belagavi",
            "Bengaluru Rural",
            "Bengaluru Urban",
            "Bidar",
            "Chamarajanagar",
            "Chikballapur",
            "Chikmagalur",
            "Chitradurga",
            "Dakshina Kannada",
            "Davanagere",
            "Dharwad",
            "Gadag",
            "Hassan",
            "Haveri",
            "Kalaburagi",
            "Kodagu",
            "Kolar",
            "Koppal",
            "Mandya",
            "Mysuru",
            "Raichur",
            "Ramanagara",
            "Shivamogga",
            "Tumakuru",
            "Udupi",
            "Uttara Kannada",
            "Vijayapura",
            "Yadgir",
          ],
        },
        {
          name: "Kerala",
          districts: [
            "Alappuzha",
            "Ernakulam",
            "Idukki",
            "Kannur",
            "Kasaragod",
            "Kollam",
            "Kottayam",
            "Kozhikode",
            "Malappuram",
            "Palakkad",
            "Pathanamthitta",
            "Thiruvananthapuram",
            "Thrissur",
            "Wayanad",
          ],
        },
        {
          name: "Ladakh",
          districts: ["Kargil", "Leh"],
        },
        {
          name: "Lakshadweep",
          districts: ["Lakshadweep"],
        },
        {
          name: "Madhya Pradesh",
          districts: [
            "Agar Malwa",
            "Alirajpur",
            "Anuppur",
            "Ashoknagar",
            "Balaghat",
            "Barwani",
            "Betul",
            "Bhind",
            "Bhopal",
            "Burhanpur",
            "Chhatarpur",
            "Chhindwara",
            "Damoh",
            "Datia",
            "Dewas",
            "Dhar",
            "Dindori",
            "Guna",
            "Gwalior",
            "Harda",
            "Hoshangabad",
            "Indore",
            "Jabalpur",
            "Jhabua",
            "Katni",
            "Khandwa",
            "Khargone",
            "Mandla",
            "Mandsaur",
            "Morena",
            "Narsinghpur",
            "Neemuch",
            "Panna",
            "Raisen",
            "Rajgarh",
            "Ratlam",
            "Rewa",
            "Sagar",
            "Satna",
            "Sehore",
            "Seoni",
            "Shahdol",
            "Shajapur",
            "Sheopur",
            "Shivpuri",
            "Sidhi",
            "Singrauli",
            "Tikamgarh",
            "Ujjain",
            "Umaria",
            "Vidisha",
          ],
        },
        {
          name: "Maharashtra",
          districts: [
            "Ahmednagar",
            "Akola",
            "Amravati",
            "Aurangabad",
            "Beed",
            "Bhandara",
            "Buldhana",
            "Chandrapur",
            "Dhule",
            "Gadchiroli",
            "Gondia",
            "Hingoli",
            "Jalgaon",
            "Jalna",
            "Kolhapur",
            "Latur",
            "Mumbai City",
            "Mumbai Suburban",
            "Nagpur",
            "Nanded",
            "Nandurbar",
            "Nashik",
            "Osmanabad",
            "Palghar",
            "Parbhani",
            "Pune",
            "Raigad",
            "Ratnagiri",
            "Sangli",
            "Satara",
            "Sindhudurg",
            "Solapur",
            "Thane",
            "Wardha",
            "Washim",
            "Yavatmal",
          ],
        },
        {
          name: "Manipur",
          districts: [
            "Bishnupur",
            "Chandel",
            "Churachandpur",
            "Imphal East",
            "Imphal West",
            "Jiribam",
            "Kakching",
            "Kamjong",
            "Kangpokpi",
            "Noney",
            "Pherzawl",
            "Senapati",
            "Tamenglong",
            "Tengnoupal",
            "Thoubal",
            "Ukhrul",
          ],
        },
        {
          name: "Meghalaya",
          districts: [
            "East Garo Hills",
            "East Jaintia Hills",
            "East Khasi Hills",
            "North Garo Hills",
            "Ri Bhoi",
            "South Garo Hills",
            "South West Garo Hills",
            "South West Khasi Hills",
            "West Garo Hills",
            "West Jaintia Hills",
            "West Khasi Hills",
          ],
        },
        {
          name: "Mizoram",
          districts: [
            "Aizawl",
            "Champhai",
            "Hnahthial",
            "Khawzawl",
            "Kolasib",
            "Lawngtlai",
            "Lunglei",
            "Mamit",
            "Saiha",
            "Saitual",
            "Serchhip",
          ],
        },
        {
          name: "Nagaland",
          districts: [
            "Dimapur",
            "Kiphire",
            "Kohima",
            "Longleng",
            "Mokokchung",
            "Mon",
            "Peren",
            "Phek",
            "Tuensang",
            "Wokha",
            "Zunheboto",
          ],
        },
        {
          name: "Odisha",
          districts: [
            "Angul",
            "Balangir",
            "Balasore",
            "Bargarh",
            "Bhadrak",
            "Boudh",
            "Cuttack",
            "Deogarh",
            "Dhenkanal",
            "Gajapati",
            "Ganjam",
            "Jagatsinghpur",
            "Jajpur",
            "Jharsuguda",
            "Kalahandi",
            "Kandhamal",
            "Kendrapara",
            "Kendujhar",
            "Khordha",
            "Koraput",
            "Malkangiri",
            "Mayurbhanj",
            "Nabarangpur",
            "Nayagarh",
            "Nuapada",
            "Puri",
            "Rayagada",
            "Sambalpur",
            "Subarnapur",
            "Sundargarh",
          ],
        },
        {
          name: "Puducherry",
          districts: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
        },
        {
          name: "Punjab",
          districts: [
            "Amritsar",
            "Barnala",
            "Bathinda",
            "Faridkot",
            "Fatehgarh Sahib",
            "Fazilka",
            "Ferozepur",
            "Gurdaspur",
            "Hoshiarpur",
            "Jalandhar",
            "Kapurthala",
            "Ludhiana",
            "Mansa",
            "Moga",
            "Pathankot",
            "Patiala",
            "Rupnagar",
            "Sangrur",
            "Shaheed Bhagat Singh Nagar",
            "Sri Muktsar Sahib",
            "Tarn Taran",
          ],
        },
        {
          name: "Rajasthan",
          districts: [
            "Ajmer",
            "Alwar",
            "Banswara",
            "Baran",
            "Barmer",
            "Bharatpur",
            "Bhilwara",
            "Bikaner",
            "Bundi",
            "Chittorgarh",
            "Churu",
            "Dausa",
            "Dholpur",
            "Dungarpur",
            "Hanumangarh",
            "Jaipur",
            "Jaisalmer",
            "Jalore",
            "Jhalawar",
            "Jhunjhunu",
            "Jodhpur",
            "Karauli",
            "Kota",
            "Nagaur",
            "Pali",
            "Pratapgarh",
            "Rajsamand",
            "Sawai Madhopur",
            "Sikar",
            "Sirohi",
            "Sri Ganganagar",
            "Tonk",
            "Udaipur",
          ],
        },
        {
          name: "Sikkim",
          districts: [
            "East Sikkim",
            "North Sikkim",
            "South Sikkim",
            "West Sikkim",
          ],
        },
        {
          name: "Tamil Nadu",
          districts: [
            "Ariyalur",
            "Chengalpattu",
            "Chennai",
            "Coimbatore",
            "Cuddalore",
            "Dharmapuri",
            "Dindigul",
            "Erode",
            "Kallakurichi",
            "Kanchipuram",
            "Kanyakumari",
            "Karur",
            "Krishnagiri",
            "Madurai",
            "Nagapattinam",
            "Namakkal",
            "Nilgiris",
            "Perambalur",
            "Pudukkottai",
            "Ramanathapuram",
            "Ranipet",
            "Salem",
            "Sivaganga",
            "Tenkasi",
            "Thanjavur",
            "Theni",
            "Thoothukudi",
            "Tiruchirapalli",
            "Tirunelveli",
            "Tirupathur",
            "Tiruppur",
            "Tiruvallur",
            "Tiruvannamalai",
            "Vellore",
            "Viluppuram",
            "Virudhunagar",
          ],
        },
        {
          name: "Telangana",
          districts: [
            "Bhadradri Kothagudem",
            "Hyderabad",
            "Jagtial",
            "Jangaon",
            "Jayashankar Bhupalpally",
            "Jogulamba Gadwal",
            "Kamareddy",
            "Karimnagar",
            "Khammam",
            "Komaram Bheem Asifabad",
            "Mahabubabad",
            "Mahabubnagar",
            "Mancherial",
            "Medak",
            "Medchal-Malkajgiri",
            "Nagarkurnool",
            "Nalgonda",
            "Nirmal",
            "Nizamabad",
            "Peddapalli",
            "Rajanna Sircilla",
            "Rangareddy",
            "Sangareddy",
            "Siddipet",
            "Suryapet",
            "Vikarabad",
            "Wanaparthy",
            "Warangal",
            "Yadadri Bhuvanagiri",
          ],
        },
        {
          name: "Tripura",
          districts: [
            "Dhalai",
            "Gomati",
            "Khowai",
            "North Tripura",
            "Sepahijala",
            "South Tripura",
            "Unakoti",
            "West Tripura",
          ],
        },
        {
          name: "Uttar Pradesh",
          districts: [
            "Agra",
            "Aligarh",
            "Ambedkar Nagar",
            "Amethi",
            "Amroha",
            "Auraiya",
            "Ayodhya",
            "Azamgarh",
            "Baghpat",
            "Bahraich",
            "Ballia",
            "Balrampur",
            "Banda",
            "Barabanki",
            "Bareilly",
            "Basti",
            "Bhadohi",
            "Bijnor",
            "Budaun",
            "Bulandshahr",
            "Chandauli",
            "Chitrakoot",
            "Deoria",
            "Etah",
            "Etawah",
            "Farrukhabad",
            "Fatehpur",
            "Firozabad",
            "Gautam Buddh Nagar",
            "Ghaziabad",
            "Ghazipur",
            "Gonda",
            "Gorakhpur",
            "Hamirpur",
            "Hapur",
            "Hardoi",
            "Hathras",
            "Jalaun",
            "Jaunpur",
            "Jhansi",
            "Kannauj",
            "Kanpur Dehat",
            "Kanpur Nagar",
            "Kasganj",
            "Kaushambi",
            "Kushinagar",
            "Lakhimpur Kheri",
            "Lalitpur",
            "Lucknow",
            "Mahoba",
            "Maharajganj",
            "Mainpuri",
            "Mathura",
            "Mau",
            "Meerut",
            "Mirzapur",
            "Moradabad",
            "Muzaffarnagar",
            "Pilibhit",
            "Pratapgarh",
            "Prayagraj",
            "Raebareli",
            "Rampur",
            "Saharanpur",
            "Sambhal",
            "Sant Kabir Nagar",
            "Shahjahanpur",
            "Shamli",
            "Shravasti",
            "Siddharthnagar",
            "Sitapur",
            "Sonbhadra",
            "Sultanpur",
            "Unnao",
            "Varanasi",
          ],
        },
        {
          name: "Uttarakhand",
          districts: [
            "Almora",
            "Bageshwar",
            "Chamoli",
            "Champawat",
            "Dehradun",
            "Haridwar",
            "Nainital",
            "Pauri Garhwal",
            "Pithoragarh",
            "Rudraprayag",
            "Tehri Garhwal",
            "Udham Singh Nagar",
            "Uttarkashi",
          ],
        },
        {
          name: "West Bengal",
          districts: [
            "Alipurduar",
            "Bankura",
            "Birbhum",
            "Cooch Behar",
            "Dakshin Dinajpur",
            "Darjeeling",
            "Hooghly",
            "Howrah",
            "Jalpaiguri",
            "Jhargram",
            "Kalimpong",
            "Kolkata",
            "Malda",
            "Murshidabad",
            "Nadia",
            "North 24 Parganas",
            "Paschim Bardhaman",
            "Purba Bardhaman",
            "Purba Medinipur",
            "Purulia",
            "South 24 Parganas",
            "Uttar Dinajpur",
          ],
        },
      ],
    },
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState("");
    setSelectedDistrict("");
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedDistrict("");
  };

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((country) => (
    <MenuItem key={country} value={country}>
      {country}
    </MenuItem>
  ));
  
  const stateOptions = selectedCountry
  ? countries[selectedCountry].states.map((state) => (
      <MenuItem key={state.name} value={state.name}>
        {state.name}
      </MenuItem>
    ))
  : [];

  const districtOptions = selectedState
    ? countries[selectedCountry].states
        .find((state) => state.name === selectedState)
        .districts.map((district) => (
          <MenuItem key={district} value={district}>
            {district}
          </MenuItem>
        ))
    : [];

  const initialFormData = {
    uploadBranchLogo: null,
    branchId: "",
    branchName: "",
    branchType: "",
    emailId: "",
    password: "",
    mobileNumber: "",
    landlineNumber: "",
    pointOFContact: "",
    cloudSpace: "",
    cloudSpaceType: false,
    vrEnabled: false,
    addressLine1: "",
    addressLine2: "",
    area: "",
    country: "",
    state: "",
    district: "",
    pincode: "",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };
  const [formData, setFormData] = useState(initialFormData);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formAppendData = new FormData();
    formAppendData.append("uploadBranchLogo", branchLogo);
    formAppendData.append("branchId", formData.branchId);
    formAppendData.append("branchName", formData.branchName);
    formAppendData.append("branchType", formData.branchType);
    formAppendData.append("pointOFContact", formData.pointOFContact);
    formAppendData.append("landlineNumber", formData.landlineNumber);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("emailId", formData.emailId.toLowerCase());
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("addressLine2", formData.addressLine2);
    formAppendData.append("area", formData.area);
    formAppendData.append("pincode", formData.pincode);
    formAppendData.append("country",selectedCountry);
    formAppendData.append("state", selectedState);
    formAppendData.append("district", selectedDistrict);
    formAppendData.append("cloudSpace", formData.cloudSpace);
    formAppendData.append(
      "cloudSpaceType",
      formData.cloudSpaceType ? "GB" : "TB"
    );
    formAppendData.append("vrEnabled", formData.vrEnabled);
    formAppendData.append("password", formData.password);
    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
    );

    const formSendData = {
      uploadBranchLogo: formData.uploadBranchLogo,
      branchId: formData.branchId,
      branchName: formData.branchName,
      branchType: formData.branchType,
      pointOFContact: formData.pointOFContact,
      landlineNumber: formData.landlineNumber,
      mobileNumber: formData.mobileNumber,
      emailId: formData.emailId.toLowerCase(),
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      area: formData.area,
      pincode: formData.pincode,
      country: selectedCountry,
      state: selectedState,
      district: selectedDistrict,
      cloudSpace: formData.cloudSpace,
      cloudSpaceType: formData.cloudSpaceType ? "GB" : "TB",
      vrEnabled: formData.vrEnabled,
      password: formData.password,
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

    };

    // Create the Axios config object with the headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    if (formData.uploadBranchLogo === null && branchLogo === null) {
      toast.warn("Please upload your branch logo");
    } else if (branchLogo !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegebranch/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/branch");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegebranch`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/branch"); 
            }
          })
          .catch((err) => {
            toast.error(err.message);   
          });
      }
    } else if (formData.uploadBranchLogo !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegebranch/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/branch");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegebranch`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/branch");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };
  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({ ...formData });
    }
  }, [id]);

  
  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/collegebranch/${id}`);

    console.log(formData.data.data);

    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  const handleImageUpload = (event) => {
    const uploadBranchLogo = event.target.files[0];

    if (uploadBranchLogo) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUrl(e.target.result);
        console.log(uploadBranchLogo);
      };

      reader.readAsDataURL(uploadBranchLogo);
      setBranchLogo(uploadBranchLogo);
    }
  };

  const handleCloudSpaceType = (checked) => {
    setFormData((prev) => ({
      ...prev,
      cloudSpaceType: checked,
    }));
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setImageUrl(""); // Reset image URL if needed
    setBranchLogo(null); // Reset branch logo if needed
    setSelectedCountry("");
    setSelectedState("");
    setSelectedDistrict("");
  };

  const handleVr = (checked) => {
    setFormData((prev) => ({
      ...prev,
      vrEnabled: checked,
    }));
  };

  const handleSubmitandAdd = async (event) => {
    event.preventDefault();
    const formAppendData = new FormData();
    formAppendData.append("uploadBranchLogo", branchLogo);
    formAppendData.append("branchId", formData.branchId);
    formAppendData.append("branchName", formData.branchName);
    formAppendData.append("branchType", formData.branchType);
    formAppendData.append("pointOFContact", formData.pointOFContact);
    formAppendData.append("landlineNumber", formData.landlineNumber);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("emailId", formData.emailId);
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("addressLine2", formData.addressLine2);
    formAppendData.append("area", formData.area);
    formAppendData.append("pincode", formData.pincode);
    formAppendData.append("country", selectedCountry);
    formAppendData.append("state", selectedState);
    formAppendData.append("district", selectedDistrict);
    formAppendData.append("cloudSpace", formData.cloudSpace);
    formAppendData.append(
      "cloudSpaceType",
      formData.cloudSpaceType ? "GB" : "TB"
    );
    formAppendData.append("vrEnabled", formData.vrEnabled);
    formAppendData.append("password", formData.password);
    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
    );

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (formData.uploadBranchLogo === null && branchLogo === null) {
      toast.warn("Please upload your branch logo");
    } else if (branchLogo !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegebranch/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData({
                ...initialFormData,
                country: "",    // Reset country to its initial state
                state: "",      // Reset state to its initial state
                district: "",   // Reset district to its initial state
              });
              setSelectedCountry("");  // Reset selectedCountry to its initial state
              setSelectedState("");    // Reset selectedState to its initial state
              setSelectedDistrict(""); // Reset selectedDistrict to its initial state
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegebranch`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData({
                ...initialFormData,
                country: "",    // Reset country to its initial state
                state: "",      // Reset state to its initial state
                district: "",   // Reset district to its initial state
              });
              setSelectedCountry("");  // Reset selectedCountry to its initial state
              setSelectedState("");    // Reset selectedState to its initial state
              setSelectedDistrict(""); // Reset selectedDistrict to its initial state
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadBranchLogo !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegebranch/${id}`, formData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData({
                ...initialFormData,
                country: "",    // Reset country to its initial state
                state: "",      // Reset state to its initial state
                district: "",   // Reset district to its initial state
              });
              setSelectedCountry("");  // Reset selectedCountry to its initial state
              setSelectedState("");    // Reset selectedState to its initial state
              setSelectedDistrict(""); // Reset selectedDistrict to its initial state
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegebranch`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData({
                ...initialFormData,
                country: "",    // Reset country to its initial state
                state: "",      // Reset state to its initial state
                district: "",   // Reset district to its initial state
              });
              setSelectedCountry("");  // Reset selectedCountry to its initial state
              setSelectedState("");    // Reset selectedState to its initial state
              setSelectedDistrict(""); // Reset selectedDistrict to its initial state
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  return (
    <div className="w-full flex justify-between items-center pb-10">
      <div className="w-full h-[800px] bg-white px-16 pb-9 rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              name="uploadBranchLogo"
              id="image-upload"
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none", backgroundColor: "white" }}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                <div className="w-[140px] h-[140px] flex flex-col justify-center items-center border-2 border-primary rounded-full bg-white text-primary">
                  {/* Display the uploaded image */}
                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/CollegeBranchUploads/${formData.uploadBranchLogo}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {""}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-primary"
                          />
                          <span className="text-[15px] font-normal">
                            Upload Branch Logo
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>
              </IconButton>
            </label>
          </div>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",

              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", 
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", 
                },
              },
              color: "#105D50",
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#105D50",
              },

              "& .MuiInputBase-root": {
                height: 45,
                fontSize: "14px",
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    }
                  }}
                  label="Branch Name"
                  variant="outlined"
                  type="text"
                  fullWidth
                  name="branchName"
                  value={formData.branchName}
                  onChange={handleOnChange}
                  required
                  inputProps={{
                    style: { textTransform: "capitalize" }, // Apply text transformation
                  }}
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  select
                  label="Branch Type"
                  variant="outlined"
                  fullWidth
                  type="Text"
                  name="branchType"
                  value={formData.branchType}
                  onChange={handleOnChange}
                  required
                >
                  <MenuItem value="College">College</MenuItem>
                  <MenuItem value="Private Institute">
                    Private Institute
                  </MenuItem>
                  <MenuItem value="Schools">Schools</MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Landline Number"
                  variant="outlined"
                  fullWidth
                  type="Number"
                  min="10"
                  name="landlineNumber"
                  value={formData.landlineNumber}
                  onChange={handleOnChange}
                  inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                />
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    //value={formData.password}
                    onChange={handleOnChange}
                    //required
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleOnChange}
                    inputProps={{
                      autoComplete: 'new-password',
                  }}
                    required
                  />
                )}

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Address Line 1"
                  variant="outlined"
                  fullWidth
                  type="Text"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  label="Area"
                  variant="outlined"
                  fullWidth
                  type="Text"
                  name="area"
                  value={formData.area}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  label="State"
                  variant="outlined"
                  fullWidth
                  name="state"
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {stateOptions}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",                 
                    "&:hover": {                  
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  type="Number"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleOnChange}
                  required
                />

                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ margin: "auto", width: "100%" }}
                  spacing={2}
                >
                  <div className="flex items-center gap-4">
                    <img alt="vr" src={vr} className="w-[36px] h-[22px]" />
                    <Switch
                      checkedChildren={`ON`}
                      unCheckedChildren={`OFF`}
                      onChange={handleVr}
                      checked={formData.vrEnabled}
                      className={
                        formData.vrEnabled
                          ? "custom-switch-checked"
                          : "custom-switch-unchecked"
                      }
                    />
                  </div>
                </Stack>
              </Stack>
              <Stack direction="column" width={"280px"} spacing={2}>
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                    //   color: "#105D50",
                    //   "& .MuiFormLabel-root.Mui-focused": {
                    //     color: "#105D50",
                    //   },
                    //   "& .MuiInputBase-root": {
                    //     height: 45,
                    //     fontSize: "14px",
                    //   },
                    //   "&:hover": {
                    //     backgroundColor: "#EEEDED",
                    //   },
                    //   "& .MuiOutlinedInput-root": {
                    //     "& fieldset": {
                    //       border: "1.3px solid #105D50",
                    //     },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                    //     "&.Mui-focused fieldset": {
                    //       border: "1.34px solid #105D50",
                    //     },
                    //   },
                    }}
                    label="Branch ID"
                    variant="outlined"
                    fullWidth
                    type="Text"
                    name="branchId"
                    value={formData.branchId}
                    onChange={handleOnChange}
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                   
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                  
                    }}
                    label="Branch ID"
                    variant="outlined"
                    fullWidth
                    type="Text"
                    name="branchId"
                    value={formData.branchId}
                    onChange={handleOnChange}
                    required
                  />
                )}
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  name="emailId"
                  type="email"
                  value={formData.emailId}
                  onChange={handleOnChange}
                  required
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  type="Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleOnChange}
                  required
                  className="no-spinner"
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Contact Person"
                  variant="outlined"
                  fullWidth
                  type="Text"
                  name="pointOFContact"
                  value={formData.pointOFContact}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED", 
                    },
                  }}
                  label="Address Line 2"
                  variant="outlined"
                  type="Text"
                  fullWidth
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleOnChange}
                />
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  label="Country"
                  variant="outlined"
                  fullWidth
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countryOptions}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  label="District"
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {districtOptions}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  label="Cloud Space"
                  variant="outlined"
                  name="cloudSpace"
                  type="number"
                  fullWidth
                  value={formData.cloudSpace}
                  onChange={handleOnChange}
                  required
                  InputProps={{
                    endAdornment: (
                      
                      <div className="flex items-center">
                        <h1 className="mx-1">TB</h1>
                        <Switch
                          // checkedChildren={`TB`}
                          // unCheckedChildren={`GB`}
                          onChange={handleCloudSpaceType}
                          checked={formData.cloudSpaceType}
                          className={"custom-switch-checked"}
                        />
                        <h1 className="mx-1">GB</h1>
                      </div>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <div className="flex justify-center items-center space-x-8 ">
              {!editMode && (
                <button
                  type="button"
                  onClick={handleReset}
                  className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Reset
                </button>
              )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
}

export default BranchForm;
