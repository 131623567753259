import React, { useEffect, useState } from "react";
import { PiAlarmDuotone, PiHandWavingFill } from "react-icons/pi";
import { BsCalendar2CheckFill } from "react-icons/bs";
import image1 from "../Images/abhis lms icons (24).png";
import image2 from "../Images/abhis lms icons (18).png";
import cake from "../Images/Desktop - 12.png";
import { FaCircleUser } from "react-icons/fa6";
import { CircularProgressbar } from "react-circular-progressbar";
import { HiMiniCake } from "react-icons/hi2";
import axios from "axios";
import AnnouncementUI from "./AnnouncementUI";
import { FaBell, FaClock } from "react-icons/fa";
import SProfile from "./Profile";
import Timetable_icon from "../Images/schedule.png";
import { Tooltip } from "@mui/material";
import Timetablecollege from "./Timetableforall";
import { Modal } from "antd";

const FacultyHomePage = () => {
  const [percentage, setPercentage] = useState(null);
  const [presentPercentage, setPresentPercentage] = useState(null);
  const [absentPercentage, setAbsentPercentage] = useState(null);
  const [odPercentage, setOdPercentage] = useState(null);
  const [section, setSection] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [student, setStudent] = useState(null);
  const [studentData, setStudentData] = useState(null);
  const [faculty, setFaculty] = useState(null);
 // const [name, setName] = useState();
  const [events, setEvents] = useState([]);
  const [count, setCount] = useState();
  const [studentCount, setStudentCount] = useState();
  const [activeTab, setActiveTab] = useState('faculty');
  const apiUrl = process.env.REACT_APP_API_URL;
  const name = localStorage.getItem("name");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/getfacultyattendancebyuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        );
        const attendanceData = response.data;

        console.log(attendanceData, "Data");

        const percentageAttendance = attendanceData.data.percentageAttendance;
        const presentPercentage = attendanceData.data.presentPercentage;
        const absentPercentage = attendanceData.data.absentPercentage;
        const odPercentage = attendanceData.data.odPercentage;
        console.log(percentageAttendance);

        setPercentage(percentageAttendance);
        setPresentPercentage(presentPercentage);
        setAbsentPercentage(absentPercentage);
        setOdPercentage(odPercentage);
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        // Handle error
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`
  //       ); // Update the sectionId accordingly
  //       const faculty = response.data;
  //       console.log(faculty, "facultyData");

  //       //const section = faculty.data[0].sectionId;
  //       const name = faculty.data[0].facultyName;

  //       //console.log(section, "sectionId");
  //       //setSectionId(section);
  //       setName(name);
  //     } catch (error) {
  //       console.error("Error fetching section data:", error);
  //       // Handle error
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchSectionData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/collegesectionbymentorFacultyIduniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        );

        const sectionData = response.data;
        const count = sectionData.data.length;
        const sectionId = sectionData.data[0].sectionId;
        setSection(sectionData);
        setSectionId(sectionId);
        setCount(count);
        console.log("sections find", sectionData);
        console.log("sectionCount", count);
        console.log("sectionId", sectionId);
      } catch (error) {
        console.error(
          "An error occurred:",
          error.response ? error.response.data : error.message
        );
        if (error.response && error.response.status === 404) {
          console.log("Resource not found");
        }
      }
    };

    fetchSectionData(); // Call the async function
  }, []);

  useEffect(() => {
    if(sectionId)
    {
    axios
      .get(`${apiUrl}/collegebirthdaystudent/${sectionId}`)
      .then((response) => {
        const birthdayStudents = response.data.data;
        console.log(birthdayStudents, "bday stud");
        setStudent(birthdayStudents);
      })
      .catch((error) => console.error("Error fetching data:", error));
    }
  }, [sectionId]);

// get institute id from localstorage
  // useEffect(() => {
    
  //     axios
  //       .get(`${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           localStorage.setItem("institute_Id", res.data.data[0].instituteUniqueId);
  //     getName();
  //         }
  //       });
    
  // }, []);

  const insId = localStorage.getItem("institute_Unique_Id");
 useEffect(()=>{
  if(insId)
  {
    axios
      .get(
        `${apiUrl}/collegefacultybirthdaybyinstituteuniqueid/${insId}`
      )
      .then((response) => {
        const birthdayFaculties = response.data.data;
        console.log(birthdayFaculties, "bday fac");
        setFaculty(birthdayFaculties);
      })
      .catch((error) => console.error("Error fetching data:", error));
    }},[]);

  useEffect(() => {
    if(sectionId)
    {
    axios
      .get(`${apiUrl}/collegestudentbysectionuniqueid/${sectionId}`)
      .then((res) => {
       // setStudentData(res.data.data);
        const studentCount = res.data.data.length;
        console.log("stud count", studentCount);
        console.log("API Response:", res.data);
        setStudentCount(studentCount);
      })
      .catch((err) => console.log(err));
    }
  }, [sectionId]);
  console.log(sectionId);

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const cDate = new Date();
        const formattedDate = `${cDate.getFullYear()}-${(cDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${cDate.getDate().toString().padStart(2, "0")}`;

        const response = await axios.get(
          `${apiUrl}/getevents/${localStorage.getItem("Unique_id")}`,
          {
            params: {
              date: formattedDate,
            },
          }
        );

        if (response && response.data) {
          setEvents(response.data);
        }
      } catch (error) {
        console.error("Error fetching event:", error);
      }
    };
    fetchEvent();
  }, []);

  const [modalVisible, setModalVisible] = useState(false);

  const handleTimetableClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div className="w-full h-auto flex flex-row justify-center gap-4 pb-4">
        <div className="w-[70%] flex flex-col h-auto gap-5 border rounded-lg drop-shadow-sm p-4">
          <div className="flex">
            {/* Existing code for the timetable */}
            <div>
              <h1 className="font-medium text-xl flex items-center mb-1">
                Hello {name}
                <span className="text-orange-300 ml-4 text-xl">
                  <PiHandWavingFill />
                </span>
              </h1>
              <div className="text-gray-500 text-sm font-normal mb-2">
                Empowering Minds, Transforming Futures!
              </div>

            </div>

            {/* New div that you want to display beside the timetable */}
            <div>
              <div className="ml-4 flex items-center">
                <Tooltip title="Timetable" arrow>
                  <img
                    src={Timetable_icon}
                    onClick={handleTimetableClick}
                    alt="Timetable Icon"
                    className="w-11 h-11 cursor-pointer"
                  />
                </Tooltip>
              </div>

              <Modal
                open={modalVisible}
                onClose={handleCloseModal}
                onCancel={handleCloseModal}
                okButtonProps={{ style: { display: "none" } }}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                width={900}
                className="bg-white rounded-md p-6 overflow-hidden transform transition-all ease-in-out duration-300"
              >
                <Timetablecollege onClose={handleCloseModal} />
              </Modal>
            </div>
          </div>

          {/* ...........................Student Card .................... */}

          <div className="w-full h-auto flex flex-row justify-between items-center my-4">
            <div className="flex items-center ">
              <div className="flex-shrink-0 w-24 h-24 p-[2px] rounded-full border bg-white border-primary overflow-hidden absolute">
                <img
                  className="w-full h-full object-cover"
                  src={image1}
                  alt="Profile"
                />
              </div>
              <div className="flex">
                <div className="text-xl font-medium ml-12 rounded-md px-3 py-3 border w-[190px] border-primary flex flex-col text-center">
                  <span className="ml-4 text-primary">Students </span>
                  <span className="font-semibold text-xl">{studentCount}</span>
                </div>
              </div>
            </div>
            <div className="flex items-center mr-10">
              <div className="flex-shrink-0 w-24 h-24 p-[2px] rounded-full border bg-white border-primary overflow-hidden absolute">
                <img
                  className="w-full h-full object-cover"
                  src={image2}
                  alt="Profile"
                />
              </div>
              <div className="flex">
                <div className="text-xl font-medium ml-12 rounded-md px-3 py-3 border w-[190px] border-primary flex flex-col text-center">
                  <span className="ml-4 text-primary">Classes </span>{" "}
                  <span className="font-semibold text-xl">{count}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex h-auto flex-row justify-center items-center gap-10">
            <div className="w-[50%] flex flex-col justify-start items-start">
              <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
                <button
                  onClick={() => setActiveTab('faculty')}
                  className={`${activeTab === 'faculty'
                    ? 'bg-white text-primary'
                    : 'bg-primary text-white'
                    } px-4  rounded-t-lg`}
                >
                  Faculty
                </button>
                <button
                  onClick={() => setActiveTab('student')}
                  className={`${activeTab === 'student'
                    ? 'bg-white text-primary'
                    : 'bg-primary text-white'
                    } px-4   rounded-t-lg`}
                >
                  Student
                </button>
              </span>
              <div className="w-full flex flex-col border border-primary rounded-b-lg h-[250px] rounded-tr-lg p-4">
                <div className="w-full flex flex-col items-center gap-[10px] h-[250px] overflow-y-scroll">

                  {activeTab === 'faculty' ? (
                    faculty && faculty.length > 0 ? (
                      faculty.map((faculties, index) => (
                        <div key={index} className="w-full flex flex-row justify-between items-center rounded-md border drop-shadow-sm px-2">
                          <div className="text-gray-500 text-xs font-medium">
                            {faculties.facultyName}
                          </div>
                          <div className="mb-2 mt-1">
                            <HiMiniCake className="text-rose-400 w-6 h-6 ml-4" />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>Sorry!!! No wishes Today</div>
                    )

                  ) : (
                    student && student.length > 0 ? (
                      student.map((students, index) => (
                        <div key={index} className="w-full flex flex-row justify-between items-center rounded-md border drop-shadow-sm px-2">
                          <div className="text-gray-500 text-xs font-medium">
                            {students.studentName}
                          </div>
                          <div className="mb-2 mt-1">
                            <HiMiniCake className="text-rose-400 w-6 h-6 ml-4" />
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>Sorry!!! No wishes Today</div>
                    )
                  )}
                </div>
              </div>
            </div>

            <div className="w-[50%] flex flex-col justify-start items-start">
              <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
                Statistics
              </span>

              <div className="w-full flex flex-row border border-primary gap-4 rounded-b-lg h-[250px] rounded-tr-lg p-4">
                <div className="w-[40%] flex flex-row ">
                  <div className="w-full h-[200px] flex flex-col justify-center gap-5">
                    <div className="flex flex-row gap-3 items-center">
                      <div>
                        <FaCircleUser className="w-10 h-10 text-blue-600" />
                      </div>
                      <div>
                        <p className="text-gray-600 text-[14px]">Present</p>
                        <p className="text-gray-800 text-[14px] font-semibold">
                          {presentPercentage ? Math.round(presentPercentage) : 0}%
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row gap-3 items-center">
                      <div>
                        <FaCircleUser className="w-10 h-10 text-red-500" />
                      </div>
                      <div>
                        <p className="text-gray-600 text-[14px]">Absent</p>
                        <p className="text-gray-800 text-[14px] font-semibold">
                          {absentPercentage ? Math.round(absentPercentage) : 0}%
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-row items-center gap-3">
                      <div>
                        <FaCircleUser className="w-10 h-10 text-orange-400" />
                      </div>
                      <div>
                        <p className="text-gray-600 text-[14px]">On Duty</p>
                        <p className="text-gray-800 text-[14px] font-semibold">
                          {odPercentage ? Math.round(odPercentage) : 0}%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[60%] h-[200px] flex justify-centr items-center">
                  <div className="w-[140px] h-[140px]">
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage ? Math.round(percentage) : 0}%`}
                      styles={{
                        root: { width: "100%", height: "100%" },
                        path: { stroke: "#105d50" },
                        text: {
                          fill: "#105d50",
                          fontSize: "10px",
                          fontWeight: "bold",
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[30%] flex flex-col h-auto justify-center items-center gap-4">
          <SProfile />
          <AnnouncementUI />
        </div>
      </div>
    </>
  );
};

export default FacultyHomePage;
