import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import { toast } from "react-toastify";

import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";

import { CSVLink } from "react-csv";
import download from "../../Images/download.png";
import upload from "../../Images/upload.png";
const apiUrl = process.env.REACT_APP_API_URL;

function StudentExam() {
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const history = useNavigate();

  const [exam, setExam] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [sectionId, setSectionId] = useState();
  const [date, setDate] = useState(""); // State to store date
  const [studentStatus, setStudentStatus] = useState([]);



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const formattedDate = date || new Date().toISOString().split("T")[0]; // Use provided date or set to current date
  //       if (sectionId) {

  //         const response = await axios.get(`${apiUrl}/collegeexambysectionidanddate/${sectionId}`
  //         );
  //         console.log(formattedDate, "date");
  //         const examData = response.data.data;

  //         const filteredExams = examData.filter((examItem) => {
  //           return examItem.dateofexamination === formattedDate;
  //         });
  //         setExam(filteredExams);
  //         console.log(filteredExams, "examdata");
  //       }
  //     } catch (error) {
  //       //console.error("Error fetching exams:", error);
  //       if (error.response) {
  //         console.error("Response data:", error.response.data);
  //         console.error("Response status:", error.response.status);
  //         //toast.error("Failed to fetch exams");
  //       } else {
  //         console.error("Exception:", error);
  //        // toast.error("An error occurred");
  //       }
  //     }
  //   };

  //   fetchData(); // Call the fetchData function

  // }, [sectionId]); // useEffect will re-run when either sectionId or date changes


  useEffect(() => {
    const result =
      exam &&
      exam.filter((value) => {
        return value.examName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [exam, searchTerm]);

  useEffect(() => {
    try {
      if (sectionId) {
        axios
          .get(`${apiUrl}/collegeexambysectionid/${sectionId}`)
          .then((res) => {
            const examData = res.data.data;
            // Filter the exams based on status
            const filteredExams = examData.filter(exam => exam.status === 1);
            setExam(filteredExams);
          })
          .catch((error) => {
            console.error("Error fetching subjects:", error);
            if (error.response) {
              console.error("Response data:", error.response.data);
              console.error("Response status:", error.response.status);
              console.error("Failed to fetch subjects");
            }
          });
      }
    } catch (error) {
      console.error("Exception:", error);
      toast.error("An error occurred");
    }
  }, [sectionId]);


  useEffect(() => {
    axios
      .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        const studentsData = res.data.data;

        if (studentsData.length > 0) {
          setStudentData(studentsData);
          setSectionId(studentsData[0].sectionId);
        } else {
          console.warn("No student data found.");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          console.log("No Data Found");
        }
      });
  }, []);


  useEffect(() => {
    axios
      .get(`${apiUrl}/examstatus/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        const studentExamStatus = res.data.data;

        if (studentExamStatus.length > 0) {
          setStudentStatus(studentExamStatus);
        } else {
          console.warn("No student data found.");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          console.log("No Data Found");
        }
      });
  }, []);





  const getStatus = (examId) => {
    const statusObj = studentStatus.find((status) => status.examId === examId);
    return statusObj ? statusObj.status : 0; // Assuming 0 as the default status
  };

  const toggleFormView = (examId) => {
    history(`/homepage/My Course/${examId}`);
  };



  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject ID</h1>,
      selector: (row) => row.subjectId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject Name</h1>,
      selector: (row) => row.subjectList,
      sortable: true,
      width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam ID</h1>,
      selector: (row) => row.examId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam Name</h1>,
      selector: (row) => row.examName,
      sortable: true,
      width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Date of Exam</h1>,
      selector: (row) => row.dateOfExamination,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Duration</h1>,
      selector: (row) => row.duration,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => {
        // // Parse the dateOfExamination string and extract date and time components
        // // Parse the dateOfExamination string and extract date and time components
        // const dateOfExamination = row.dateOfExamination;
        // const [examDate, examTime] = dateOfExamination.split(' ');
        // const [day, month, year] = examDate.split('/'); // Corrected order for day and month
        // let [hour, minute] = examTime.split(':');
        // const isPM = examTime.includes("PM");

        // // Convert hour to 24-hour format if PM
        // if (isPM && hour !== '12') {
        //   hour = parseInt(hour, 10) + 12;
        // }

        // // Create a Date object with the extracted components
        // const examStartTime = new Date(year, month - 1, day, parseInt(hour, 10), parseInt(minute, 10));
        // console.log("examStartTime:", examStartTime);

        // // Add 15 minutes for the grace period
        // const graceTime = new Date(examStartTime.getTime() + 15 * 60000); // Adding 15 minutes in milliseconds
        // console.log("graceTime:", graceTime);

        // const currentTime = new Date();

        // // Convert current time to 12-hour format
        // const formattedCurrentTime = currentTime.toLocaleString('en-US', {
        //   hour: 'numeric',
        //   minute: 'numeric',
        //   second: 'numeric',
        //   hour12: true
        // });

        // console.log("formattedCurrentTime:", formattedCurrentTime);



        // // Check if the current time is within the grace period
        // const isWithinGraceTime = formattedCurrentTime <= graceTime;
        // console.log("isWithinGraceTime:", isWithinGraceTime);
        const dateOfExamination = row.dateOfExamination;
        const [examDate] = dateOfExamination.split(' ');
        const [day, month, year] = examDate.split('/'); // Corrected order for day, month, and year
        
        // Get the current date without time
        const currentDate = new Date();
        const formattedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        
        // Parse the exam start date from the dateOfExamination string
        const examStartDate = new Date(year, month - 1, day);
        
        // Add 15 minutes for the grace period
        const graceEndDate = new Date(examStartDate.getTime() + 15 * 60000);
        
        // Check if the current date is within the grace period
        const isWithinGraceDate = formattedCurrentDate <= graceEndDate;


        return (
          <button
            onClick={() => toggleFormView(row.examId)}
            className={`py-2 px-4 rounded-md ${getStatus(row.examId) === 1 || !isWithinGraceDate ? 'bg-gray-400' : 'bg-primary hover:bg-secondary'
              } text-white hover:text-green items-center gap-1`}
            disabled={getStatus(row.examId) === 1 || !isWithinGraceDate}
            style={{ width: "150px" }}
          >
            Start
          </button>
        );
      },
      width: "150px",
    }



  ];

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Exam ID": item.examId,
        "Exam Name": item.examName,
        Semester: item.semesterNumber,
        "Date Of Exam": item.dateOfExamination,
        "Subject Name": item.subjectName,
        "Subject Id": item.subjectId,
      }));
      return csvData;
    }
    return [];
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  return (
    <div className="flex flex-col  w-full ">
      <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
        <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
          <div className="flex flex-row items-center gap-4">
            <div className="relative">
              <input
                placeholder="Search"
                type="text"
                required
                className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />
              <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
            </div>
          </div>
          {/* Use the DataTable component */}
          <div className="flex flex-row items-center gap-6">
            {/* <div class="relative group flex items-center">
              <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                {" "}
                <img
                  alt="upload"
                  src={upload}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </span>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Upload
              </div>
            </div> */}
            <div class="relative group flex items-center">
              <CSVLink
                data={handleCsv()}
                filename="Exams.csv"
                class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
              >
                {" "}
                <img
                  alt="download"
                  src={download}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </CSVLink>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Download
              </div>
            </div>
          </div>
        </div>
        {/* Use the DataTable component */}
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        // Apply custom styles here
        />
      </div>
    </div>
  );
}

export default StudentExam;
