import { useEffect, useState } from "react";
import { PiHandWavingFill } from "react-icons/pi";
import image1 from "../Images/abhis lms icons (21).png";
import image2 from "../Images/abhis lms icons (24).png";
import image3 from "../Images/abhis lms icons (16).png";
import { HiMiniCake } from "react-icons/hi2";
import SProfile from "./Profile";
import GaugeChart from "react-gauge-chart";
import axios from "axios";
import AnnouncementUI from "./AnnouncementUI";
import Timetablecollege from "./TimetableInstite";
import { Modal, Tooltip } from "antd";
import Timetable_icon from "../Images/schedule.png";

const apiUrl = process.env.REACT_APP_API_URL;

const InstituteHomePage = () => {
  const [facultyCount, setFacultyCount] = useState();
  const [studentCount, setStudentCount] = useState();
  const [faculty, setFaculty] = useState(null);
  const [branchCount, setBranchCount] = useState();
  const LMS_ROLE = localStorage.getItem("lms_role");
  const name = localStorage.getItem("institute_Name");
  useEffect(() => {
    const fetchFaculty = async () => {
      try {
        let response;
        if (LMS_ROLE == 3 || LMS_ROLE == 5) {
          response = await axios.get(`${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem("Unique_id")}`);
        } else if (LMS_ROLE == 4) {
          response = await axios.get(`${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem("institute_Unique_Id")}`);
        } else if (LMS_ROLE == 6) {
          response = await axios.get(`${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem("branch_Unique_Id")}`);
        } else {
          console.log("error fetching data");
          return; // Return early if no valid role
        }
        const facultyData = response.data;
        const count = facultyData.data.length;
        setFacultyCount(count);
        console.log(facultyData, "facultylist");
      } catch (error) {
        console.error('Error fetching faculty data:', error);
      }
    };
    fetchFaculty();
  }, [LMS_ROLE]);
  
  useEffect(() => {
    const fetchFacultyBirthday = async () => {
      try {
        if (LMS_ROLE == 3 || LMS_ROLE == 5) {
          const response = await axios.get(
            `${apiUrl}/collegefacultybirthdaybyinstituteuniqueid/${localStorage.getItem(
              "Unique_id"
            )}`
          );
          const birthdayFaculties = response.data.data;
          console.log(birthdayFaculties, "bday fac");
          setFaculty(birthdayFaculties);
        } else if (LMS_ROLE == 4) {
          const response = await axios.get(
            `${apiUrl}/collegefacultybirthdaybyinstituteuniqueid/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          const birthdayFaculties = response.data.data;
          console.log(birthdayFaculties, "bday fac");
          setFaculty(birthdayFaculties);
        } else if (LMS_ROLE == 6) {
          const response = await axios.get(
            `${apiUrl}/collegefacultybirthdaybyinstituteuniqueid/${localStorage.getItem(
              "branch_Unique_Id"
            )}`
          );
          const birthdayFaculties = response.data.data;
          console.log(birthdayFaculties, "bday fac");
          setFaculty(birthdayFaculties);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchFacultyBirthday();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (LMS_ROLE == 3 || LMS_ROLE == 5) {
          const response = await axios.get(
            `${apiUrl}/collegestudentbyinstituteuniqueid/${localStorage.getItem(
              "Unique_id"
            )}`
          );
          const student = response.data;
          console.log(student, "StudentData");

          const count = student.data.length;
          setStudentCount(count);
        } else if (LMS_ROLE == 4) {
          const response = await axios.get(
            `${apiUrl}/collegestudentbyinstituteuniqueid/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          const student = response.data;
          console.log(student, "StudentData");

          const count = student.data.length;
          setStudentCount(count);
        } else if (LMS_ROLE == 6) {
          const response = await axios.get(
            `${apiUrl}/collegestudentbyinstituteuniqueid/${localStorage.getItem(
              "branch_Unique_Id"
            )}`
          );
          const student = response.data;
          console.log(student, "StudentData");

          const count = student.data.length;
          setStudentCount(count);
        }
      } catch (error) {
        console.error("Error fetching section data:", error);
        // Handle error
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (LMS_ROLE == 3) {
          const response = await axios.get(
            `${apiUrl}/collegebranchbyinstituteuniqueid/${localStorage.getItem(
              "Unique_id"
            )}`
          );
          const branch = response.data;
          console.log(branch, "StudentData");

          const count = branch.data.length;
          setBranchCount(count);
        } else if (LMS_ROLE == 4) {
          const response = await axios.get(
            `${apiUrl}/collegebranchbyinstituteuniqueid/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          const branch = response.data;
          console.log(branch, "StudentData");

          const count = branch.data.length;
          setBranchCount(count);
        }
      } catch (error) {
        console.error("Error fetching section data:", error);
        // Handle error
      }
    };

    fetchData();
  }, []);

  const gaugeOptions = {
    // Customize options as needed
    colors: ["#45A8A3", "#105D50"], // Example colors for different ranges
    percent: 0.8, // Adjust this value to set the gauge needle position (between 0 and 1)
    textColor: "#333", // Text color
    arcPadding: 0.1, // Padding between arcs
    needleColor: "#F5A70D",
    needleBaseColor: "#F5A70D",
  };

  const [modalVisible, setModalVisible] = useState(false);

  const handleTimetableClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="w-full h-auto flex flex-row justify-center gap-4 pb-4">
      <div className="w-[70%] flex flex-col h-auto gap-5 border rounded-lg drop-shadow-sm p-4">
        <div className="flex">
          {/* Existing code for the timetable */}
          <div>
            <h1 className="font-medium text-xl flex items-center mb-1">
              Hello {name}
              <span className="text-orange-300 ml-4 text-xl">
                <PiHandWavingFill />
              </span>
            </h1>
            <div className="text-gray-500 text-sm font-normal mb-2">
              Let’s learn something new today!
            </div>
          </div>

          {/* New div that you want to display beside the timetable */}
          <div>
            <div className="ml-4 flex items-center">
              <Tooltip title="Timetable" arrow>
                <img
                  src={Timetable_icon}
                  onClick={handleTimetableClick}
                  alt="Timetable Icon"
                  className="w-11 h-11 cursor-pointer"
                />
              </Tooltip>
            </div>

            <Modal
              open={modalVisible}
              onClose={handleCloseModal}
              onCancel={handleCloseModal}
              okButtonProps={{ style: { display: "none" } }}
              cancelButtonProps={{ style: { display: "none" } }}
              footer={null}
              width={900}
              className="bg-white rounded-md p-6 overflow-hidden transform transition-all ease-in-out duration-300"
            >
              <Timetablecollege onClose={handleCloseModal} />
            </Modal>
          </div>
        </div>

        <div className="w-full h-auto flex flex-row justify-between items-center my-4">
          {/* Card 1 */}
          <div className="flex flex-row justify-between items-center px-4  border-primary border rounded-lg shadow">
            <img src={image1} alt="Image 1" className="w-20 h-auto flex mr-4" />
            <div className="text-container text-center flex flex-col ">
              <span className="text-primary font-bold">Faculties</span>
              <span className="text-3xl">{facultyCount}</span>
            </div>
          </div>

          {/* Card 2 */}
          <div className="flex flex-row justify-between items-center px-4  border-primary border rounded-lg shadow">
            <img src={image2} alt="Image 1" className="w-20 h-auto flex mr-4" />
            <div className="text-container text-center flex flex-col ">
              <span className="text-primary font-bold">Students</span>
              <span className="text-3xl">{studentCount}</span>
            </div>
          </div>

          {(LMS_ROLE == 3 || LMS_ROLE == 4) && (
            <div className="flex flex-row justify-between items-center px-4  border-primary border rounded-lg shadow">
              <img
                src={image3}
                alt="Image 1"
                className="w-20 h-auto flex mr-4"
              />
              <div className="text-container text-center flex flex-col ">
                <span className="text-primary font-bold">Branches</span>
                <span className="text-3xl">{branchCount}</span>
              </div>
            </div>
          )}
        </div>

        <div className="w-full flex h-auto flex-row justify-center items-center gap-10">
          <div className="w-[60%] flex flex-col justify-start items-start">
            <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
              Today's Birthday
            </span>
            <div className="w-full flex flex-col border border-primary rounded-b-lg h-[250px] rounded-tr-lg p-4">
              <div className="w-full flex flex-col items-center gap-[10px] h-[250px] overflow-y-scroll">
                {faculty && faculty.length > 0 ? (
                  faculty.map((faculties, index) => (
                    <div key={index} className="w-full flex flex-row justify-between items-center rounded-md border drop-shadow-sm px-2">
                      <div className="text-gray-500 text-xs font-medium">
                        {faculties.facultyName}
                      </div>
                      <div className="mb-2 mt-1">
                        <HiMiniCake className="text-rose-400 w-6 h-6 ml-4" />
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Sorry!!! No wishes Today</div>
                )}

          
              </div>
            </div>
          </div>

          <div className="w-[40%] flex flex-col justify-start items-start">
            <span className="px-4 py-2 bg-primary text-center text-white rounded-t-lg">
              Performance
            </span>

            <div className="flex flex-col border h-[250px] border-[#105D50] w-full rounded-b-lg rounded-tr-lg drop-shadow-lg p-4 ">
              <div className="flex flex-col-2 justify-between">
                <div className="flex">
                  <div className="h-[15px] w-[15px] rounded-[3px] bg-[#45A8A3]"></div>
                  <p className="text-gray-500 text-sm ml-2">34mb spent</p>
                </div>
                <div className="flex flex-row justify-center items-centers gap-[7px]">
                  <div className="h-[15px] w-[15px] rounded-[3px] bg-[#105D50] mt-[1px]"></div>
                  <p className="text-gray-500 text-sm">20tb spent</p>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center mt-8">
                <GaugeChart id="speedometer" {...gaugeOptions} />
                <p>
                  Storage spent: <b>8.966</b>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="w-[30%] flex flex-col h-auto justify-center items-center gap-4">
        <SProfile />
        <AnnouncementUI />
      </div>
    </div>
  );
};

export default InstituteHomePage;
