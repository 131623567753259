import React, { useEffect, useRef, useState } from "react";
import {
  AiOutlineSearch,
  AiOutlineSend,
  AiOutlineSmile,
  AiOutlineFile,
} from "react-icons/ai";
import { useParams } from "react-router-dom";
import axios from "axios";
import EmojiPicker from "emoji-picker-react";
import { FaComments, FaRegAddressBook, FaUserCog } from "react-icons/fa";
import user from "../../src/Images/user.png";
import sectionGroup from "../../src/Images/use.webp";
import subjectimage from "../../src/Images/subject.png";
import chatuser from "../../src/Images/chatuser.webp";
import io from "socket.io-client";
import userPro from "../../src/Images/userProfile.png";


const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;


const ChatList1 = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [roomId, setRoomId] = useState();
  const [showEmojis, setShowEmojis] = useState(false);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [receiverName, setReceiverName] = useState([]);
  const [senderId, setSenderId] = useState();
  const [receiverId, setReceiverId] = useState([]);
  const [data, setData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [profile, setProfile] = useState();
  const name = localStorage.getItem("name");
  const uniqueId = localStorage.getItem("Unique_id");
  const sectionId = localStorage.getItem("section_Id");
  const [subjectGroupName, setSubjectGroupName] = useState([]);
  const [groupNames, setGroupNames] = useState({
    overall: "",
    faculty: "",
    student: "",
    section: "",
    subject: "",
  });

  const socketRef = useRef(null);

  const LMS_Role = localStorage.getItem("lms_role");

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/getuserdetailsbyid/${localStorage.getItem(
          "Unique_id"
        )}`
      )
      .then((res) => {
        console.log("API Response:", res.data);
        setData(res.data.data);

        const overallGroupName = res.data.data[0].groupName;
        const facultyGroupName = res.data.data[0].faculty[0].groupName;
        const studentGroupName = res.data.data[0].student[0].groupName;
        const sectionGroupName = res.data.data[0].section[0].groupName;
        // const subjectGroupName = res.data.data[0].subjects[1].groupName;
        setGroupNames({
          overall: overallGroupName,
          faculty: facultyGroupName,
          student: studentGroupName,
          section: sectionGroupName,
          // subject: subjectGroupName,
        });

        console.log("Overall Group Name:", overallGroupName);
        console.log("Faculty Group Name:", facultyGroupName);
        console.log("Student Group Name:", studentGroupName);
      })
      .catch((err) => console.log(err));
    if (LMS_Role === '7') {
      axios
        .get(`${apiUrl}/subject/byfaculties/forchat/${localStorage.getItem("Unique_id")}`)
        .then((subjectRes) => {
          const subjectGroupNames = subjectRes.data.map((subject) => subject.groupName);
          // Update group names including the subject
          setGroupNames((prevGroupNames) => ({
            ...prevGroupNames,
            subject: subjectGroupNames,
          }));
          setSubjectGroupName(subjectGroupNames);
          console.log("Subject Group Name:", subjectGroupNames);
        })
        .catch((subjectErr) => console.log(subjectErr));
    }
    if (LMS_Role === '8') {
      axios
        .get(`${apiUrl}/subject/bystudent/forchat/${sectionId}`)
        .then((subjectRes) => {
          const subjectGroupNames = subjectRes.data.map((subject) => subject.groupName);
          // Update group names including the subject
          setGroupNames((prevGroupNames) => ({
            ...prevGroupNames,
            subject: subjectGroupNames,
          }));
          setSubjectGroupName(subjectGroupNames);
          console.log("Subject Group Name:", subjectGroupNames);
        })

        .catch((subjectErr) => console.log(subjectErr));
    }
  }, []);

  //fetch section groupnames
  const [facultySectionGroupNames, setFacultySectionGroupNames] = useState([]);
  const [studentSectionGroupNames, setStudentSectionGroupNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (LMS_Role === "7") {
          // Fetch faculty section group names
          const response = await axios.get(
            `${apiUrl}/createSectionGroupByMentorFacultyID/${uniqueId}/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          const sectionNames = response.data.data.map(
            (section) => section.groupId
          );
          setFacultySectionGroupNames(sectionNames);
          console.log("Faculty Section Group Names:", sectionNames);
        } else if (LMS_Role === "8") {
          // Fetch student section group names
          const response = await axios.get(
            `${apiUrl}/createSectionGroupBystudentID/${uniqueId}/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          const sectionNames = response.data.data.map(
            (section) => section.groupName
          );
          setStudentSectionGroupNames(sectionNames);
          console.log("Student Section Group Names:", sectionNames);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData(); // Call the async function immediately
  }, [LMS_Role, uniqueId]);



  const handleFetch = async (type) => {
    try {
      let participantsArray = [];
      let groupData = {};
      let response;
      switch (type) {
        case "all":
          if (LMS_Role === '3') {
            response = await axios.get(
              `${apiUrl}/createSubjectGroupBysectionId/${localStorage.getItem(
                "institute_Unique_Id"
              )}`
            );
          }
          else if (LMS_Role === '5') {
            response = await axios.get(
              `${apiUrl}/createBranchGroupByBranchId/${localStorage.getItem(
                "Unique_id"
              )}`
            );
          }
          groupData = response.data;
          if (groupData.groups) {
            participantsArray = groupData.groups.participants;
          } else {
            console.error("Invalid response data:", response);
          }

          setRoomId(groupData.groups.roomId);
          setSelectedGroup(groupData.groups.groupName);
          setParticipants(participantsArray);
          setFilteredParticipants(participantsArray);
          break;

        case "faculties":
          const responseData = await axios.get(
            `${apiUrl}/faculty/chat/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          groupData = responseData.data;
          if (groupData.groups) {
            participantsArray = groupData.values;
            setRoomId(groupData.groups.roomId);
            setSelectedGroup(groupData.groups.groupName);
            setParticipants(participantsArray);
            setFilteredParticipants(participantsArray);
          } else {
            console.error("Error fetching data: 'participants' property is undefined");
          }
          break;

        case "students":
          const responseStudentData = await axios.get(
            `${apiUrl}/student/chat/${localStorage.getItem(
              "institute_Unique_Id"
            )}`
          );
          groupData = responseStudentData.data;

          if (groupData.groups) {
            participantsArray = groupData.values;
          } else {
            console.error("Error fetching data: 'participants' property is undefined");
          }

          setRoomId(groupData.groups.roomId);
          setSelectedGroup(groupData.groups.groupName);
          setParticipants(participantsArray);
          setFilteredParticipants(participantsArray);
          break;
        case "section":
          let sectionResponse;
          if (LMS_Role === "7") {
            sectionResponse = await axios.get(
              `${apiUrl}/createSectionGroupByMentorFacultyID/${uniqueId}/${localStorage.getItem(
                "institute_Unique_Id"
              )}`
            );
          } else if (LMS_Role === "8") {
            sectionResponse = await axios.get(
              `${apiUrl}/createSectionGroupBystudentID/${uniqueId}/${localStorage.getItem(
                "institute_Unique_Id"
              )}`
            );
          }
          if (
            sectionResponse.data &&
            sectionResponse.data.data &&
            sectionResponse.data.data.length > 0
          ) {
            groupData = sectionResponse.data.data[0];
            participantsArray = groupData.participants || [];
            console.log("Parray", participantsArray);
            setSenderId(uniqueId);
            setRoomId(groupData.roomId);
            setSelectedGroup(groupData.groupName);
            setParticipants(participantsArray);
            setFilteredParticipants(participantsArray);
          } else {
            console.error("Invalid section response data:", sectionResponse);
            console.error("Section response data:", sectionResponse.data);
            return;
          }
          break;
        case "subject":
          let subjectResponse;
          if (LMS_Role === "7") {
            subjectResponse = await axios.get(
              `${apiUrl}/subject/byfaculties/forchat/${uniqueId}`
            );
          }
          else if (LMS_Role === "8") {
            subjectResponse = await axios.get(
              `${apiUrl}/subject/bystudent/forchat/${sectionId}`
            );
          }
          if (subjectResponse.data && subjectResponse.data.length > 0) {
            const groupData = subjectResponse.data[0];
            console.log("group data", groupData);
            participantsArray = groupData.participants || [];
            console.log("Parti subject", participantsArray);
            setSenderId(uniqueId);
            setRoomId(groupData.roomId);
            setSelectedGroup(groupData.groupName);
            setParticipants(participantsArray);
            setFilteredParticipants(participantsArray);
          } else {
            console.error("Invalid subject response data:", subjectResponse);
            console.error("Subject response data:", subjectResponse.data);
            return;
          }
          break;
        default:
          console.error("Invalid type:", type);
          return;
      }
      setData(participantsArray || []);
      setSelectedData(participantsArray || []);
      setReceiverId(participantsArray.map((participant) => participant.userId));
      setReceiverName(participantsArray.map((participant) => participant.userName));
      setProfile(participantsArray.map((participant) => participant.userProfile));
      setSenderId(uniqueId);
      console.log("roomid", roomId);
      console.log("senderId", senderId);
      console.log("ReceiverId", receiverId);
      console.log("selected Data", selectedData);
      // } else {
      //   console.error("Invalid response data:", response);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("Data after update:", data);
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    if (senderId && receiverId.length > 0 && roomId) {
      socketRef.current.emit("joinRoom", { senderId, receiverId, roomId });
    }

    socketRef.current.on("message", (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);
    });

    return () => {
      socketRef.current.disconnect();
    };
  }, [senderId, receiverId, roomId]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        if (roomId) {
          const response = await axios.get(`${apiUrl}/message/${roomId}`);
          setMessages(response.data.messages || []);
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [roomId]);

  const handleEmojiClick = () => {
    setShowEmojis(!showEmojis);
  };

  const onEmojiClick = (emojiObject, event) => {
    const emoji = emojiObject.emoji;
    setMessage(message + emoji);
  };

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
  };
  //set profile image
  const [image, setImage] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      try {
        if (LMS_Role === 3) {
          const response = await axios.get(`${apiUrl}/institute`);
          setImage(response.data[0].uploadInstituteLogo);
          console.log("image", image);
        } else {
          // Handle other roles if needed
        }
      } catch (error) {
        console.error("Error fetching image:", error.message);
      }
    };

    fetchImage();
  }, [LMS_Role]);

  const currentDate = new Date();
  // Format date as dd/mm/yyyy
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = currentDate.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  // Format time without minutes and seconds
  const hours = currentDate.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
  const formattedTime = `${formattedHours}:${currentDate.getMinutes().toString().padStart(2, '0')} ${ampm}`;

  const handleSendClick = async () => {
    if (message.trim() !== "" && selectedData) {
      const room = roomId;
      const senderId = uniqueId;
      console.log("room", room);
      const senderParticipant = participants.find(participant => participant.userId === senderId);

      if (senderParticipant) {
        const senderProfile = senderParticipant.userProfile;

        socketRef.current.emit("sendMessage", {
          senderId: senderId,
          receiverId: receiverId,
          message: message,
          roomId: roomId,
          senderName: name,
          date: formattedDate,
          time: formattedTime,
          userProfile: senderProfile,
          tag: "",
          profile: "",
        });

        setMessage("");
      } else {
        console.error(`Participant with userId ${senderId} not found.`);
      }
    }
  };

  console.log("message", messages);

  const [showUserList, setShowUserList] = useState(false);
  const [filteredParticipants, setFilteredParticipants] = useState();
  const handleUserIconClick = async () => {
    //setShowUserList(!showUserList);
    // Fetch participants for the selected group when the user icon is clicked
    if (selectedGroup) {
      setParticipants(selectedGroup.participants || []);
      console.log(participants, "part....");
    }
    setShowUserList(true);
    setFilteredParticipants(filteredParticipants);
    console.log("selectedGroup:", selectedGroup);
    console.log("participants:", participants);
    console.log("filteredParticipants:", filteredParticipants);
    console.log("user triggered");
  };

  const handleUserClick = (type) => {
    //setSelectedGroup(type);
    setShowUserList(false);
    handleFetch(type);
    console.log("fetch triggered");
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/createSubjectGroupBySectionId/${uniqueId}`
        );
        console.log(response.data, "API");
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [uniqueId]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  const chatContainerRef = useRef(null);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  // const imageUrl = `${ImageUrl}/${participants.find
  //   (participant => participant.userId === message.senderId)?.userProfile}`;
  // console.log("Generated Image URL:", imageUrl);
  // console.log("message.senderId:", message.senderId);


  // const profileImagePaths = {
  //   // 1: "SuperAdminuploads",
  //   2: "AdminUploads",
  //   3: "InstituteUploads",
  //   4: "CollegeInstituteAdminUploads",
  //   5: "CollegeBranchUploads",
  //   6: "CollegeBranchAdminUploads",
  //   7: "CollegeFacultyUploads",
  //   8: "CollegeStudentUploads",
  // };

  // const profileImagePath = profileImagePaths[LMS_Role];

  // useEffect(()=>{
  // switch (LMS_Role) {
  //   case 1:
  //     return setImage("Sathish Kumar");
  //   case 2:

  //     axios
  //       .get(`${apiUrl}/admin/${localStorage.getItem("Unique_id")}`)
  //       .then((res) =>   setImage(res.data.data[0].uploadProfile));
  //     break;
  //   case 3:

  //     axios
  //       .get(`${apiUrl}/institute/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         setImage(res.data.data[0].uploadInstituteLogo);
  //       });
  //     break;
  //   case 4:

  //   axios
  //       .get(`${apiUrl}/collegeinstituteadmin/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         setImage(res.data.data[0].uploadProfilePic);
  //       });
  //   break;
  //   case 5:

  //   axios
  //       .get(`${apiUrl}/collegebranch/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         setImage(res.data.data[0].uploadBranchLogo);
  //       });
  //    break;
  //   case 6:

  //   axios
  //       .get(`${apiUrl}/collegebranchadmin/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         setImage(res.data.data[0].uploadProfilePic);
  //       });
  //    break;
  //   case 7:

  //     axios
  //       .get(`${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         setImage(res.data.data[0].uploadProfile);
  //         console.log("Generated Image URL:", `${ImageUrl}/${profileImagePath}/${image}`);
  //       });
  //     break;
  //   default:

  //    axios
  //       .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
  //       .then((res) => {
  //         setImage(res.data.data[0].uploadStudentProfile);
  //       });
  //     break;

  // }

  //   if(LMS_Role === '2')
  //   {
  //     axios
  //           .get(`${apiUrl}/admin/${localStorage.getItem("Unique_id")}`)
  //           .then((res) =>   setImage(res.data.data[0].uploadProfile));
  //   }
  //   else if(LMS_Role === '3')
  //   {
  //     axios
  //           .get(`${apiUrl}/institute/${localStorage.getItem("Unique_id")}`)
  //           .then((res) => {
  //             setImage(res.data.data[0].uploadInstituteLogo);
  //           });
  //   }
  //   else if(LMS_Role === '4')
  //   {
  //     axios
  //         .get(`${apiUrl}/collegeinstituteadmin/${localStorage.getItem("Unique_id")}`)
  //         .then((res) => {
  //           setImage(res.data.data[0].uploadProfilePic);
  //         });
  //   }
  //   else if(LMS_Role === '5')
  //   {
  //     axios
  //         .get(`${apiUrl}/collegebranch/${localStorage.getItem("Unique_id")}`)
  //         .then((res) => {
  //           setImage(res.data.data[0].uploadBranchLogo);
  //         });
  //   }
  //   else if(LMS_Role === '6')
  //   {
  //     axios
  //         .get(`${apiUrl}/collegebranchadmin/${localStorage.getItem("Unique_id")}`)
  //         .then((res) => {
  //           setImage(res.data.data[0].uploadProfilePic);
  //         });
  //   }
  //   else if(LMS_Role === '7')
  //   {
  //     axios
  //         .get(`${apiUrl}/collegefaculty/${localStorage.getItem("Unique_id")}`)
  //         .then((res) => {
  //           setImage(res.data.data[0].uploadProfile);
  //           console.log("Generated Image URL:", `${ImageUrl}/${profileImagePath}/${image}`);
  //         });
  //   }
  //   else if(LMS_Role === '8')
  //   {
  //     axios
  //           .get(`${apiUrl}/collegestudent/${localStorage.getItem("Unique_id")}`)
  //           .then((res) => {
  //             setImage(res.data.data[0].uploadStudentProfile);
  //           });
  //   }
  //   else{
  //     console.log("error fetching data");
  //   }
  // },[LMS_Role])

  return (
    <div className="flex h-screen drop-shadow bg-white border rounded-md shadow-md">
      <div
        className={`w-2/5 border-r flex-grow border-lmsgreen p-2 overflow-y-auto`}
      >
        <h3 className="font-semibold pl-1 text-xl"></h3>
        <div className="relative pl-1">
          <span className="absolute inset-y-0 left-7 flex items-center">
            <AiOutlineSearch className="text-gray-400" />
          </span>
          <input
            type="text"
            name="uniqueSearchField"
            className="font-sm my-4 p-2 pl-12 pr-6 w-[100%] text-xs text-gray-500 border border-b-2 hover:border-b-primary bg-gray-50 rounded-lg border-gray-200 focus:bg-primary focus:text-white"
            placeholder="Search to Start a new Chat"
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
            autoComplete="off"
            autoCapitalize="none"
          />
        </div>

        <div>
          <ul>
            <li
              key={data._id}
              onClick={() => handleUserClick("all")}
              className="flex flex-col px-3 hover:bg-slate-200 rounded-lg space-y-2 py-4 cursor-pointer"
            >
              <div className="flex items-center">
                <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                  <img
                    src="/college.png"
                    alt="Profile"
                    className="h-full w-full rounded-full"
                  />
                </div>
                <span className="flex-grow pl-4 font-semibold text-sm">
                  <p>{groupNames.overall}</p>
                </span>
                <p className="text-xs font-normal text-gray-500">3.00 PM</p>
              </div>
            </li>
            {(LMS_Role === "3" ||
              LMS_Role === "4" ||
              LMS_Role === "5" ||
              LMS_Role === "6" ||
              LMS_Role === "7") && (
                <li
                  key={data._id}
                  onClick={() => {
                    handleUserClick("faculties");
                  }}
                  className="flex flex-col px-3 hover:bg-slate-200 rounded-lg space-y-2 py-4 cursor-pointer"
                >
                  <div className="flex items-center">
                    <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                      <img
                        src="/faculty.png"
                        alt="Profile"
                        className="h-full w-full rounded-full"
                      />
                    </div>

                    <span className="flex-grow pl-4 font-semibold text-sm">
                      <p>{groupNames.faculty}</p>
                    </span>
                    <p className="text-xs font-normal text-gray-500">3.00 PM</p>
                  </div>
                </li>
              )}
            {(LMS_Role === "3" ||
              LMS_Role === "4" ||
              LMS_Role === "5" ||
              LMS_Role === "6" ||
              LMS_Role === "8") && (
                <li
                  key={data._id}
                  onClick={() => handleUserClick("students")}
                  className="flex flex-col px-3 hover:bg-slate-200 rounded-lg space-y-2 py-4 cursor-pointer"
                >
                  <div className="flex items-center">
                    <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                      <img
                        src="/Student.png"
                        alt="Profile"
                        className="h-full w-full rounded-full"
                      />
                    </div>
                    <span className="flex-grow pl-4 font-semibold text-sm">
                      <p>{groupNames.student}</p>
                    </span>
                    <p className="text-xs font-normal text-gray-500">3.00 PM</p>
                  </div>
                </li>
              )}
            {LMS_Role === "7" && (
              <>
                {facultySectionGroupNames.map((section, index) => (
                  <li
                    key={index}
                    onClick={() => handleUserClick("section")}
                    className="flex flex-col px-3 hover:bg-slate-200 rounded-lg space-y-2 py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                        <img
                          src={sectionGroup}
                          alt="Profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <span className="flex-grow pl-4 font-semibold text-sm">
                        <p>{section}</p>
                      </span>
                      <p className="text-xs font-normal text-gray-500">
                        3.00 PM
                      </p>
                    </div>
                  </li>
                ))}
              </>
            )}
            {LMS_Role === "8" && (
              <>
                {studentSectionGroupNames.map((section, index) => (
                  <li
                    key={index}
                    onClick={() => handleUserClick("section")}
                    className="flex flex-col px-3 hover:bg-slate-200 rounded-lg space-y-2 py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                        <img
                          src={user}
                          // alt="Profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <span className="flex-grow pl-4 font-semibold text-sm">
                        <p>{section}</p>
                      </span>
                      <p className="text-xs font-normal text-gray-500">
                        3.00 PM
                      </p>
                    </div>
                  </li>
                ))}
              </>
            )}
            {(LMS_Role === "7" || LMS_Role === "8") && (
              <>
                {subjectGroupName.map((subject, index) => (
                  <li
                    key={data._id}
                    onClick={() => handleUserClick("subject")}
                    className="flex flex-col px-3 hover:bg-slate-200 rounded-lg space-y-2 py-4 cursor-pointer"
                  >
                    <div className="flex items-center">
                      <div className="h-10 w-10 rounded-full bg-gray-300 flex items-center justify-center">
                        <img
                          src={subjectimage}
                          alt="Profile"
                          className="h-full w-full rounded-full"
                        />
                      </div>
                      <span className="flex-grow pl-4 font-semibold text-sm">
                        <p>{subject}</p>
                      </span>
                      <p className="text-xs font-normal text-gray-500">3.00 PM</p>
                    </div>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      </div>
      {selectedGroup ? (
        <div className="w-3/5 flex-grow border-lmsgreen">
          <div className="flex flex-row items-center bg-gray-100 px-4 py-2">
            <div className="h-10 w-10 rounded-full bg-gray-100 flex items-center">
              <img
                src={user}
                alt="Profile"
                className="h-full w-full rounded-full"
              />
            </div>
            <span className="pl-8 font-semibold text-sm ">{selectedGroup}</span>
            <div
              className="flex flex-row ml-[60%] cursor-pointer "
              onClick={handleUserIconClick}
            >
              <FaRegAddressBook className="text-[30px] text-primary" />
            </div>
          </div>
          {showUserList && filteredParticipants.length > 0 && (
            <div className="absolute top-14 right-0 bg-white border rounded-md shadow-md p-4 overflow-y-scroll">
              <h3 className="font-normal text-lg mb-2">Users List</h3>
              <hr></hr>
              <ul>
                {filteredParticipants.map((participant, id) => (
                  <li
                    key={participant.id}
                    className="flex items-center mb-4  mt-2"
                  >
                    <div className="h-7 w-7 rounded-full bg-white mr-2">
                      <img src={user} alt="profile" />
                    </div>
                    <span className="font-normal">{participant.username || participant.userName}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div
            className="overflow-y-auto"
            style={{ maxHeight: "calc(100vh - 120px)" }}
            ref={chatContainerRef}
          >
            <div className="flex flex-col space-y-2 px-4 py-2">
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex flex-col ${message.senderId === uniqueId ? "items-end" : "items-start"}`}
                >
                  {message.senderName && (
                    <div className="flex items-center">
                      {message.senderId !== uniqueId && (
                        <>
                          {message.userProfile ? (
                            <>
                              {console.log("Image URL:", `${ImageUrl}/InstituteUploads/${message.userProfile}`)}
                              <img
                                src={`${ImageUrl}/AdminUploads/${message.userProfile}` ||
                                  `${ImageUrl}/InstituteUploads/${message.userProfile}` ||
                                  `${ImageUrl}/CollegeInstituteAdminUploads/${message.userProfile}` ||
                                  `${ImageUrl}/CollegeBranchUploads/${message.userProfile}` ||
                                  `${ImageUrl}/CollegeBranchAdminUploads/${message.userProfile}` ||
                                  `${ImageUrl}/CollegeFacultyUploads/${message.userProfile}` ||
                                  `${ImageUrl}/CollegeStudentUploads/${message.userProfile}`
                                }
                                alt="Profile"
                                className="h-6 w-6 rounded-full mr-2"
                              />
                            </>
                          ) : (
                            <img
                              src={userPro}
                              alt="Default"
                              className="h-6 w-6 rounded-full mr-2"
                            />
                          )}
                        </>
                      )}
                      {message.senderId !== uniqueId && (
                        <p className={`text-xs font-medium ${message.senderId === uniqueId ? "text-right text-primary" : "text-left text-primary"}`}>
                          {message.senderName}
                        </p>
                      )}
                    </div>
                  )}
                  <div
                    className={`p-2 max-w-[70%] ${message.senderId === uniqueId
                      ? "bg-primary text-white self-end rounded-bl-2xl rounded-br-2xl rounded-tl-2xl "
                      : "bg-gray-200 text-gray-800 rounded-bl-2xl rounded-br-2xl rounded-tr-2xl "
                      }`}
                  >
                    <div className="flex flex-col ">
                      <span
                        className={`${message.senderId === uniqueId
                          ? " text-gray-100"
                          : " text-gray-600"
                          }`}
                      >
                        {message.message}
                      </span>
                    </div>
                    {/* <hr
          className={`w-full border-t border-gray-300 my-1 ${message.senderId === uniqueId
            ? "self-end"
            : "self-start"
          }`}
        /> */}

                    <div className="flex flex-row justify-between items-center">
                      <p
                        className={`text-xs text-gray-400 mr-2 ${message.senderId === uniqueId
                          ? "text-right"
                          : "text-left"
                          }`}
                      >
                        {/* {message.date} */}
                      </p>


                    </div>

                  </div>
                  <span
                    className={`text-xs  text-[8px] text-gray-400 ${message.senderId === uniqueId
                      ? "text-right"
                      : "text-left"
                      }`}
                  >
                    {message.time}
                  </span>

                </div>
              ))}
            </div>
          </div>
          <div className="flex item-center w-3/5 bg-gray-100 px-4 py-2 fixed bottom-0">
            <button
              className="text-gray-400 hover:bg-gray-200 rounded-full px-2 py-1"
              onClick={handleEmojiClick}
            >
              <AiOutlineSmile />
            </button>
            {showEmojis && (
              <div className="absolute bottom-16 right-0">
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </div>
            )}
            <button className="text-gray-400 hover:bg-gray-200 rounded-full p-2 ">
              <label htmlFor="file-upload" className="cursor-pointer">
                <AiOutlineFile />
              </label>
              <input
                id="file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
              />
            </button>
            <input
              type="text"
              placeholder="Type your message..."
              className="flex-grow border rounded-full px-4 py-2 mr-4 focus:outline-none "
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button
              className="text-primary hover:bg-primary hover:text-white rounded-full px-2 py-2"
              onClick={handleSendClick}
            >
              <AiOutlineSend />
            </button>
          </div>
        </div>
      ) : (
        <div className="w-3/5 border-lmsgreen flex flex-col justify-center items-center bg-gray-100">
          <FaComments className="text-6xl text-gray-400" />
          <h2 className="text-lg text-black">Chat For LMS</h2>
          <p className="text-base text-gray-400">
            Send and receive messages without keeping your phone online
          </p>
        </div>
      )}
    </div>



  );
};



export default ChatList1;
