import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Switch } from "antd";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";

import { CSVLink } from "react-csv";
import download from "../../Images/download.png";
import upload from "../../Images/upload.png";

const apiUrl = process.env.REACT_APP_API_URL;

function FacultyMyResult() {


  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const history = useNavigate();
  const { sectionId } = useParams();
  const [exam, setExam] = useState([]);



   const handleIconClick = (examId) => {
    history(`/homepage/My Classroom/${sectionId}/${examId}`);
    console.log(examId, "examIds");
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: "60px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam ID</h1>,
      selector: (row) => row.examId,
      sortable: true,
      // width: "200px",
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Exam Name</h1>,
      selector: (row) => row.examName,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject ID</h1>,
      selector: (row) => row.subjectId,
      sortable: true,
      // width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Subject Name</h1>,
      selector: (row) => row.subjectList,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[150px]">Duration</h1>,
      selector: (row) => row.duration,
      sortable: true,
      width: "200px",
    },

    {
      name: <h1 className="text-white pl-3 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            <>
              <FaEye
                onClick={() => handleIconClick(row.examId)}
                className="w-6 h-6 text-gray-600 cursor-pointer"
              />
            </>
          </div>
        </>
      ),
    },
  ];

  // Define your custom date format function here
  const formatDate = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.examName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/getexambysectionid/${sectionId}`)
      .then((res) => {
        const exams = res.data.data;
      
        const filteredExams = exams.filter(exam => exam.status === 1);
        if (filteredExams.length > 0) {
         
          setItem(filteredExams);
          setExam(filteredExams[0].examId);
        } else {
          
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          // Handle 404 error if needed
        }
      });
  }, [status]);

  console.log("Exam Id", exam);

 

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Exam ID": item.examId,
        "Exam Name": item.examName,
        Semester: item.semesterNumber,
        "Date Of Exam": item.dateOfExamination,
        "Subject Name": item.subjectName,
        "Subject Id": item.subjectId,
      }));
      return csvData;
    }
    return [];
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  return (
    <div className="flex flex-col  w-full ">
      <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
        <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
          <div className="flex flex-row items-center gap-4">
            <div className="relative">
              <input
                placeholder="Search"
                type="text"
                required
                className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />
              <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
            </div>
          
          </div>
          {/* Use the DataTable component */}
          <div className="flex flex-row items-center gap-6">
            {/* <div class="relative group flex items-center">
              <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                {" "}
                <img
                  alt="upload"
                  src={upload}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </span>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Upload
              </div>
            </div> */}
            <div class="relative group flex items-center">
              <CSVLink
                data={handleCsv()}
                filename="Faculty List.csv"
                class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
              >
                {" "}
                <img
                  alt="download"
                  src={download}
                  className="w-5 h-5 mx-auto mt-2"
                />
              </CSVLink>
              <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                Download        
              </div>
            </div>
          </div>
        </div>
        {/* Use the DataTable component */}
        <DataTable
          fixedHeader
          fixedHeaderScrollHeight="250px"
          columns={columns}
          data={filteredData}
          selectableRows
          pagination
          customStyles={customStyles}
          highlightOnHover
          pointerOnHover
         
        />
      </div>
    </div>
  );
}

export default FacultyMyResult;
