import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

const AcademicYear = () => {

  const LMS_ROLE = localStorage.getItem("lms_role");


  const [editMode, setEditMode] = useState(false);
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;

  const initialFormData ={
    from:"",
    to:"",
    NoOfWorkingdays:"",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
    LMS_ROLE === "3" || LMS_ROLE === "5"
    ? localStorage.getItem("Unique_id")
    :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

  };
  const [formData, setFormData] = useState(initialFormData);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (editMode) {
      axios
        .put(`${apiUrl}/academicdetails/${id}`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/academic year");
          }
          else {
            console.log(res.data.message)
          }} );
        
    } else {
      axios
        .post(`${apiUrl}/academicYear`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/academic year");
          }
          else {
            console.log(res.data.message)
          }} );
    }
  };

  
  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    if (editMode) {
      axios
        .put(`${apiUrl}/academicdetails/${id}`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            setFormData(initialFormData);
            
          }
          else {
            console.log(res.data.message)
          }} );
        
    } else {
      axios
        .post(`${apiUrl}/academicYear`, formData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
           setFormData(initialFormData);
          }
          else {
            console.log(res.data.message)
          }} );
    }
  };


  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({ ...formData });
    }
  }, [id]);

  const getFormData = async (id) => {
      const formData = await axios.get(`${apiUrl}/academicyear/${id}`);
      console.log(formData.data.data);
      if (formData.status === 200) {
        setFormData({ ...formData.data.data});
      } else {
        console.log("Something went wrong !!");
      }
    
  }

  const handleReset = (e) => {
   setFormData(initialFormData);
  };

  const handleFromDateChange = (date) => {
    setFormData({ ...formData, from: date ? date.format("YYYY-MM") : "" });
  };
  
  const handleToDateChange = (date) => {
    setFormData({ ...formData, to: date ? date.format("YYYY-MM") : "" });
  };
  
  return (
    <div className="mb-4 flex justify-between items-center ">
      <div className="w-full h-fit bg-white px-16 py-12 rounded-lg drop-shadow border ">
        {/* Add Branch Form */}

        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", 
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", 
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                {editMode ? ( <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="From"
                    variant="outlined"
                    name="from"
                    fullWidth
                    type="date"
                    views={['month', 'year']}
                    value={ formData.from
                      ? dayjs(formData.from)
                      : null}
                    onChange={(date) => handleFromDateChange(date)}
                  />
                </LocalizationProvider>):( <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="From"
                    variant="outlined"
                    name="from"
                    fullWidth
                    type="date"
                    views={['month', 'year']}
                    //value={formData.from}
                    onChange={(date) => handleFromDateChange(date)}
                  />
                </LocalizationProvider>)}
                
                {editMode?( <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="To"
                    variant="outlined"
                    name="to"
                    fullWidth
                    type="date"
                    views={['month', 'year']}
                    value={ formData.to
                      ? dayjs(formData.to)
                      : null}
                    onChange={(date) => handleToDateChange(date)}
                  />
                </LocalizationProvider>):( <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="To"
                    variant="outlined"
                    name="to"
                    fullWidth
                    type="date"
                    views={['month', 'year']}
                    // value={ formData.to
                    //   ? dayjs(formData.to)
                    //   : null}
                    onChange={(date) => handleToDateChange(date)}
                  />
                </LocalizationProvider>)}
               
             
              <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                       color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                  }}
                  label="Working Days"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="NoOfWorkingdays"
                  value={formData.NoOfWorkingdays}
                  onChange={handleOnChange}
                  required
                /> 
                </Stack>
               
            </Stack>

            
           

            <div className="flex justify-center items-center space-x-8 pt-8 pb-8">
            {!editMode && (
              <button
                type="button"
                onClick={handleReset}
                className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                Reset
              </button>
            )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default AcademicYear;
