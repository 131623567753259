import React, { useState } from "react";
import lmsgif from "../Images/abhis lms icons.gif";
import { useNavigate, useParams } from "react-router-dom";

function CreateMeet() {
  const { sectionId } = useParams();
  const navigate = useNavigate();
  const [url, setUrl] = useState("");
  // const handleChange = (e) => setUrl(e.target.value);
  const join = () => {
    if (url !== "") {
      var urlParts = url.split("/");
      window.location.href = `/${urlParts[urlParts.length - 1]}`;
    } else {
      var randomUrl = Math.random().toString(36).substring(2, 7);
      navigate(`/homepage/LMS Meet/${randomUrl}`);
    }
  };
  const handleNavigate = () => {
    navigate(
      `/homepage/My Classroom/Students/${sectionId}/LMS Meet/Meet Table`
    );
  };

  return (
    <div className="flex h-screen w-full bg-white border shadow-lg rounded-md">
      <div className="flex-1 flex flex-col justify-center items-center p-16">
        <div className="text-4xl font-bold text-center mb-5">
          Virtual Meeting Space
        </div>

        <div className="flex flex-col pt-4 w-full max-w-md">
          <button
            onClick={join}
            className="py-6 text-black hover:shadow-lg border transition duration-200 ease-in border-primary shadow-md drop-shadow font-bold rounded active:translate-y-[2px] hover:active:bg-[#ADBC9F] w-full"
          >
            create a meet
          </button>
        </div>

        <div className="pt-10 text-center w-full max-w-md">
          <button className="px-4 py-2 mb-3 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold rounded inline-block">
            Connect Last Meet
          </button>
          <p className="text-gray-600 pt-4 text-sm">
            View detailed information about your last meet, including
            participants and key details. Explore our documentation to
            understand more about different meeting types, features, and best
            practices.
          </p>
          <button
            onClick={handleNavigate}
            className="mt-8 px-4 py-2 bg-primary hover:bg-secondary text-white font-bold rounded inline-block"
          >
            Check it
          </button>
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center">
        <img src={lmsgif} alt="GIF" className="" />
      </div>
    </div>
  );
}

export default CreateMeet;
