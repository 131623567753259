import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { Tooltip, Modal, Row, Col,Typography } from 'antd';
import { FaBullhorn } from "react-icons/fa";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";


const apiUrl = process.env.REACT_APP_API_URL;
const ImgUrl = process.env.REACT_APP_IMAGE_URL;

const AnnouncementUI = ({announcement}) => {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const [items, setItems] = useState([]);
  const {Title} = Typography;
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fontSize, setFontSize] = useState('14px');
  const [status, setStatus] = useState(false);
  const [hiddenAnnouncements, setHiddenAnnouncements] = useState(() => {
    const storedHiddenAnnouncements = JSON.parse(localStorage.getItem('hiddenAnnouncements')) || {};
    return storedHiddenAnnouncements;
  });
 

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = (announcement) => {
    if (announcement) {
      setModalIsOpen(true);
      setSelectedAnnouncement(announcement);
      // Save the announcement ID to local storage to keep it hidden on refresh
      if(LMS_ROLE === "3"){
        setHiddenAnnouncements({
          ...hiddenAnnouncements,
          [announcement._id]: true
        });
      }
      if(LMS_ROLE === "4"){
        setHiddenAnnouncements({
          ...hiddenAnnouncements,
          [announcement._id]: true
        });
      }
      if(LMS_ROLE === "5"){
        setHiddenAnnouncements({
          ...hiddenAnnouncements,
          [announcement._id]: true
        });
      }
      if(LMS_ROLE === "6"){
        setHiddenAnnouncements({
          ...hiddenAnnouncements,
          [announcement._id]: true
        });
      }
      if(LMS_ROLE === "7"){
        setHiddenAnnouncements({
          ...hiddenAnnouncements,
          [announcement._id]: true
        });
      }
      if(LMS_ROLE === "8"){
        setHiddenAnnouncements({
          ...hiddenAnnouncements,
          [announcement._id]: true
        });
      }
      
    }
  };

  useEffect(() => {
    localStorage.setItem('hiddenAnnouncements', JSON.stringify(hiddenAnnouncements));
  }, [hiddenAnnouncements]);
 
  

  const initialFormData = {
    name: "",
    id: "",
    mobileNumber: "",
    emailId: "",
  }

  const [formData, setFormData] = useState(initialFormData);

   const handleSubmit = async (event) => {
    event.preventDefault();
    const formSendData = {
      name: formData.name,
      id: formData.id,
      mobileNumber: formData.mobileNumber,
      emailId: formData.emailId,
      UniqueId: localStorage.getItem("Unique_id"),
      announcementUniqueId: selectedAnnouncement.announcementUniqueId 
    };
    axios.post(`${apiUrl}/collegeAnnouncementEvent`, formSendData).then((res) => {
      if (res.data.status === 301) {
        toast.warn(res.data.message);
      } else if (res.status === 200) {
        toast.success(res.data.message);
        setFormData(initialFormData);
        closeModal();
      } else {
        console.log(res.data.message);
      }
    });
  }
  
  function openFileInNewPage(fileName, imgUrl) {
    window.open(`${imgUrl}/CollegeAnnouncementUpload/${fileName}`, '_blank'); 
  }

  const handleOnChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }

  // const openModal = (announcement) => {
  //   setSelectedAnnouncement(announcement);
  //   setModalIsOpen(true);
  // };


  const closeModal = () => {
    setSelectedAnnouncement(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "4" || LMS_ROLE === "5" || LMS_ROLE === "6" || LMS_ROLE === "7" || LMS_ROLE === "8") {
      axios
        .get(`${apiUrl}/collegeannouncement`)
        .then((res) => {
          const announcements = res.data.data;
    
          if (announcements && Array.isArray(announcements)) {
            console.log("All Announcements:", announcements);
      
            let filteredAnnouncements = [];
            const currentDate = new Date();
            const formattedCurrentDate = currentDate.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
          console.log("Current date:", formattedCurrentDate);
            
        
            if (LMS_ROLE === "7") {
              filteredAnnouncements = announcements.filter((announcement) => {
                const expirationDate = announcement.expirationDate;

        console.log("expirationDate:",expirationDate);

                return announcement.visibility.some((role) =>
                  role.name === "Faculties" 
                )  &&  expirationDate >= formattedCurrentDate ;

              });
            } else if (LMS_ROLE === "8") {
              filteredAnnouncements = announcements.filter((announcement) => {
                const expirationDate = announcement.expirationDate;
                console.log("expirationDate:",expirationDate);
        
                return announcement.visibility.some((role) =>
                  role.name === "Students" 
                )  &&  expirationDate >= formattedCurrentDate ;
              });
            } else if (LMS_ROLE === "5" || LMS_ROLE === "6") {
              filteredAnnouncements = announcements.filter((announcement) => {
                const expirationDate = announcement.expirationDate;
                console.log("expirationDate:",expirationDate);
        
                return announcement.visibility.some((role) =>
                  role.name === "Branch admins"
                ) &&  expirationDate >= formattedCurrentDate ;
              });
            } else if (LMS_ROLE === "3" || LMS_ROLE === "4") {
              filteredAnnouncements = announcements.filter((announcement) => {
                const expirationDate = announcement.expirationDate;
                console.log("expirationDate:",expirationDate);
        
                return announcement.visibility.some((role) =>
                  role.name === "Institute admins" 
                ) &&  expirationDate >= formattedCurrentDate ;
              });
            }
            setItems(filteredAnnouncements);
          } else {
            toast.error("No announcements found.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.status === 404) {
            toast.error("No announcements found.");
          } else {
            toast.error("Failed to fetch announcements.");
          }
        });
    }
  }, [LMS_ROLE]);

  return (
    <div className="grid gap-2 border rounded-[10px] bg-white shadow-md w-full h-[260px] p-5">
      <div className="w-full flex flex-col h-full overflow-y-scroll">
        <h1 className="text-lg font-bold text-primary px-2">Announcements</h1>
        <div className="w-full flex flex-col justify-center items-center py-2">
          {items.map((announcement) => (
            <div
              key={announcement._id}
              className="w-full flex flex-col justify-between rounded-md py-1 gap-1"
            >
              <div className="flex">
              {!hiddenAnnouncements[announcement._id] && (
                  <div className="h-[6px] w-[6px] rounded-full bg-[#00FF00]"></div>
                  )}
                <h2 className="text-[14px] font-semibold text-gray-600 px-2">
                  {announcement.announcementTitle}
                </h2>
              </div>
              <div
                className="text-gray-500 text-[14px] font-medium px-2 cursor-pointer"
                onClick={() => openModal(announcement)}
                data-tip="Click to view details"
              >
                {announcement.category}
                <span className="text-gray-300 text-[14px] font-bold mx-1">
                  |
                </span>
                <span className="text-[14px] font-medium text-secondary">
                  {announcement.announcementDate}
                </span>
              </div>
           

              <hr className="w-full border border-gray-200" />
            </div>
          ))}
        </div>
      </div>
      <Tooltip />

      <Modal
        visible={modalIsOpen}
        onCancel={closeModal}
        footer={null}
        width={600}
        centered
      >
        {selectedAnnouncement && (
          <div className="py-4">
    <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                <Col span={2.1}>
                 <img src={preview1} className="flex justify-start" ></img>
                </Col>
               <div className="flex">
               <Col span={4.5} className="z-50 flex flex-col ">
               <FaBullhorn className="text-[#F5A70D] bg-white  border-primary text-[32px] rounded-full w-20 h-20 p-2 border-[3px] drop-shadow-xl" />
                </Col>
                <Col span={6} className="flex flex-col justify-center mt-2">
                  <Title level={3} style={{fontSize,color:"white"}} className="bg-primary sm:w-96 rounded-full  capitalize relative  pt-3 pb-3 sm:pb-4 pl-10 -ml-12 mt-1 ">
                    {selectedAnnouncement.announcementTitle.toUpperCase()}
                  </Title>
                </Col>
               </div>
              </Row>

            <div className="mt-4">
              <Row gutter={[8, 8]} align="middle">
                <Col flex="200px" offset={2}>
                  <p className="p-2 text-primary text-[14px] font-bold">Category:</p>
                </Col>
                <Col flex="auto">
                  <p className="p-2"> {selectedAnnouncement.category}</p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col flex="200px" offset={2}>
                  <p className="p-2  text-primary text-[14px] font-bold">Announcement Date: </p>
                </Col>
                <Col flex="auto">
                  <p className="p-2">{selectedAnnouncement.announcementDate}</p>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col flex="200px" offset={2}>
                  <p className="p-2  text-primary text-[14px] font-bold">File: </p>
                </Col>
                <Col flex="auto">
                  <button onClick={() => openFileInNewPage(selectedAnnouncement.uploadFile, ImgUrl)}>
                    <p className="p-2 gap-2">{selectedAnnouncement.uploadFile} <span> <FontAwesomeIcon icon={faDownload} className="text-secondary" /></span></p>
                  </button>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col flex="200px" offset={2}>
                  <p className="p-2  text-primary text-[14px] font-bold">Expiration Date: </p>
                </Col>
                <Col flex="auto">
                  <p className="p-2">{selectedAnnouncement.expirationDate}</p>
                </Col>
              </Row>
              

              {selectedAnnouncement.category === "Event" ? (
                <div className="W-full  bg-white flex flex-col items-center gap-6 rounded-md my-6 px-1 drop-shadow border">
                  <span className="text-primary mt-4 text-xl font-medium ">If you are intrested Please fill the form </span>
                  <form className="mt-2" onSubmit={handleSubmit}>
                    <Stack
                      sx={{
                        margin: "auto",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        "& > :not(style)": {},
                        "& .MuiInputLabel-root": {
                          fontSize: "13px",
                          color: "#105D50",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "1.3px solid #105D50",
                          },
                          "&:hover fieldset": {
                            border: "1.3px solid #105D50",
                          },
                          "&.Mui-focused fieldset": {
                            border: "1.34px solid #105D50",
                            fontSize: "13px",
                          },
                        },
                      }}
                    >
                      <Stack
                        direction="column"
                        width={"280px"}
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <TextField
                          sx={{
                            backgroundColor: "#F5F5F5",
                            color: "#105D50",
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#105D50",
                              fontSize: "14px",
                            },
                            "& .MuiInputBase-root": {
                              height: 45,
                            },
                            "&:hover": {
                              backgroundColor: "#EEEDED",
                            },
                          }}
                          label="Name"
                          variant="outlined"
                          fullWidth
                          name="name"
                          type="text"
                          value={formData.name}
                          onChange={handleOnChange}
                          required
                        />
                        <TextField
                          sx={{
                            backgroundColor: "#F5F5F5",
                            color: "#105D50",
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#105D50",
                            },
                            "& .MuiInputBase-root": {
                              height: 45,
                              fontSize: "14px",
                            },
                          }}
                          label="ID"
                          variant="outlined"
                          fullWidth
                          name="id"
                          type="text"
                          value={formData.id}
                          onChange={handleOnChange}
                          required
                        />
                        <TextField
                          sx={{
                            backgroundColor: "#F5F5F5",
                            color: "#105D50",
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#105D50",
                            },
                            "& .MuiInputBase-root": {
                              height: 45,
                              fontSize: "14px",
                            },
                            "&:hover": {
                              backgroundColor: "#EEEDED",
                            },
                          }}
                          label="Mobile Number"
                          variant="outlined"
                          fullWidth
                          type="number"
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          onChange={handleOnChange}
                          required
                        />
                        <TextField
                          sx={{
                            backgroundColor: "#F5F5F5",
                            color: "#105D50",
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#105D50",
                            },
                            "& .MuiInputBase-root": {
                              height: 45,
                              fontSize: "14px",
                            },
                            "&:hover": {
                              backgroundColor: "#EEEDED",
                            },
                          }}
                          label="Email ID"
                          variant="outlined"
                          fullWidth
                          type="email"
                          name="emailId"
                          value={formData.emailId}
                          onChange={handleOnChange}
                          autoComplete="off"
                          required
                        />
                      </Stack>
                    </Stack>
                    <div className="flex flex-row justify-center items-center py-8">
                      <button
                        type="submit"
                        className="text-[12px] font-normal px-4 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}

              

<Row className="flex justify-end -mt-2  mr-4" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
              </Row>

            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AnnouncementUI;
