// src/components/Timetable.js
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Select,
  Input,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";
import { Space } from "antd";

const { Option } = Select;

const Timetable = () => {
  const [numPeriods, setNumPeriods] = useState(0);
  const [startDay, setStartDay] = useState("Monday");
  const [endDay, setEndDay] = useState("Saturday");
  const [timetable, setTimetable] = useState(
    Array.from({ length: numPeriods }, () => Array.from({ length: 7 }))
  );
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCol, setSelectedCol] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFaculty, setSelectedFaculty] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedColor, setSelectedColor] = useState("#CBD5E0");
  const [displayTimetable, setDisplayTimetable] = useState(false);
  const [displayGenerator, setDisplayGenerator] = useState(true);
  const [displayModel, setDisplayModel] = useState(false);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const storedTimetable = JSON.parse(localStorage.getItem("timetable")) || [];
    setTimetable(storedTimetable);
  }, []);

  useEffect(() => {
    localStorage.setItem("timetable", JSON.stringify(timetable));
  }, [timetable]);

  const handleGenerateTimetable = () => {
    const startDayIndex = daysOfWeek.indexOf(startDay);
    const endDayIndex = daysOfWeek.indexOf(endDay);

    if (
      startDayIndex === -1 ||
      endDayIndex === -1 ||
      startDayIndex > endDayIndex
    ) {
      alert("Invalid day selection");
      return;
    }

    const numDays = endDayIndex - startDayIndex + 1;

    const newTimetable = Array.from({ length: numPeriods }, () =>
      Array.from({ length: numDays })
    );
    setTimetable(newTimetable);
    setDisplayTimetable(true);
    setDisplayGenerator(false);
  };

  const handleCellClick = (row, col) => {
    setSelectedRow(row);
    setSelectedCol(col);
    setModalVisible(true);
    setDisplayModel(true)
  };

  const handleModalOk = () => {
    if (
      selectedRow !== null &&
      selectedCol !== null &&
      selectedFaculty &&
      selectedSubject
    ) {
      const updatedTimetable = [...timetable];
      updatedTimetable[selectedRow][selectedCol] = {
        faculty: selectedFaculty,
        subject: selectedSubject,
        color: selectedColor,
      };
      setTimetable(updatedTimetable);
      setModalVisible(false);
    } else {
      alert("Please select Faculty and Subject");
    }
  };
  

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const columns = [
    { id: "period", label: "Period/Day" },
    ...daysOfWeek.map((day, index) => ({ id: `day${index}`, label: day })),
  ];

  return (
    <div className="container mx-auto p-4">
      {displayGenerator && (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-md shadow-md">
          <h2 className="text-2xl font-bold mb-4">Timetable Generator</h2>

          <div className="mb-4">
            <label className="block mb-2">
              Number of Periods:
              <input
                type="number"
                className="border p-2 w-full"
                value={numPeriods}
                onChange={(e) => setNumPeriods(e.target.value)}
              />
            </label>
          </div>

          <div className="mb-4">
            <label className="block mb-2">
              Select Starting Day:
              <select
                value={startDay}
                onChange={(e) => setStartDay(e.target.value)}
                className="border p-2 w-full"
              >
                {daysOfWeek.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <div className="mb-4">
            <label className="block mb-2">
              Select Ending Day:
              <select
                value={endDay}
                onChange={(e) => setEndDay(e.target.value)}
                className="border p-2 w-full"
              >
                {daysOfWeek.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </label>
          </div>

          <button
            onClick={handleGenerateTimetable}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Generate Timetable
          </button>
        </div>
      )}
      {displayTimetable && timetable.length > 0 && (
        <Table className="mt-4">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} className="border p-2">
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {timetable.map((period, periodIndex) => (
            <TableRow key={periodIndex} onClick={() => handleCellClick(periodIndex, 0)}>
              <TableCell className="border p-2">{`Period ${periodIndex + 1}`}</TableCell>
              {period.map((slot, dayIndex) => (
                <TableCell
                  key={dayIndex}
                  className="border p-2 cursor-pointer hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCellClick(periodIndex, dayIndex);
                  }}
                >
                  <div className="mb-2">
                    {slot && (
                      <div>
                        <span className="font-bold">Faculty:</span> {slot.faculty}
                        <br />
                        <span className="font-bold">Subject:</span> {slot.subject}
                        <br />
                        <div
                          className="w-6 h-6 rounded-full mt-2"
                          style={{ backgroundColor: slot.color || "#CBD5E0" }}
                        ></div>
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      )}

      { displayModel && (

<Modal
open={modalVisible}
onOk={handleModalOk}
onCancel={handleModalCancel}
style={{ width: "500px", height: "auto" }}// Adjust the width as needed
>
<Space direction="vertical" style={{ width: "100%" }}>
  <Select
    style={{ width: "100%" }}
    placeholder="Select Faculty"
    onChange={(value) => setSelectedFaculty(value)}
  >
    {/* Faculty options */}
  </Select>
  <Select
    style={{ width: "100%" }}
    placeholder="Select Subject"
    onChange={(value) => setSelectedSubject(value)}
  >
    {/* Subject options */}
  </Select>
  <Input
    type="color"
    value={selectedColor}
    onChange={(e) => handleColorChange(e.target.value)}
    style={{ width: "100%", marginTop: "10px" }}
  />
</Space>
</Modal>

      )}
    </div>
  );
};

export default Timetable;
