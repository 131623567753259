import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import camera from "../Images/camera.png";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, IconButton, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Switch, Space, Select } from "antd";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const StudentForm = () => {
  const [editMode, setEditMode] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState("");
  const [studentProfile, setStudentProfile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [semesters, setSemesters] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionsData, setSectionsData] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [selectedFacultyId, setSelectedFacultyId] = useState("");
  const [subjects, setSubjects] = useState([]);
  const[academicYear,setAcademicYear]=useState();
  const [item, setItem] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const LMS_ROLE = localStorage.getItem("lms_role");

  const countries = {
    India: {
      states: [
        {
          name: "Andaman and Nicobar Islands",
          districts: ["Nicobar", "North and Middle Andaman", "South Andaman"],
        },
        {
          name: "Andhra Pradesh",
          districts: [
            "Anantapur",
            "Chittoor",
            "East Godavari",
            "Guntur",
            "Krishna",
            "Kurnool",
            "Prakasam",
            "Srikakulam",
            "Visakhapatnam",
            "Vizianagaram",
            "West Godavari",
            "Y.S.R.",
          ],
        },
        {
          name: "Arunachal Pradesh",
          districts: [
            "Anjaw",
            "Changlang",
            "Dibang Valley",
            "East Kameng",
            "East Siang",
            "Kamle",
            "Kra Daadi",
            "Kurung Kumey",
            "Lepa Rada",
            "Lohit",
            "Longding",
            "Lower Dibang Valley",
            "Lower Siang",
            "Lower Subansiri",
            "Namsai",
            "Pakke-Kessang",
            "Papum Pare",
            "Shi Yomi",
            "Siang",
            "Tawang",
            "Tirap",
            "Upper Siang",
            "Upper Subansiri",
            "West Kameng",
            "West Siang",
          ],
        },
        {
          name: "Assam",
          districts: [
            "Baksa",
            "Barpeta",
            "Biswanath",
            "Bongaigaon",
            "Cachar",
            "Charaideo",
            "Chirang",
            "Darrang",
            "Dhemaji",
            "Dhubri",
            "Dibrugarh",
            "Dima Hasao",
            "Goalpara",
            "Golaghat",
            "Hailakandi",
            "Hojai",
            "Jorhat",
            "Kamrup",
            "Kamrup Metropolitan",
            "Karbi Anglong",
            "Karimganj",
            "Kokrajhar",
            "Lakhimpur",
            "Majuli",
            "Morigaon",
            "Nagaon",
            "Nalbari",
            "Sivasagar",
            "Sonitpur",
            "South Salmara-Mankachar",
            "Tinsukia",
            "Udalguri",
            "West Karbi Anglong",
          ],
        },
        {
          name: "Bihar",
          districts: [
            "Araria",
            "Arwal",
            "Aurangabad",
            "Banka",
            "Begusarai",
            "Bhagalpur",
            "Bhojpur",
            "Buxar",
            "Darbhanga",
            "East Champaran",
            "Gaya",
            "Gopalganj",
            "Jamui",
            "Jehanabad",
            "Kaimur",
            "Katihar",
            "Khagaria",
            "Kishanganj",
            "Lakhisarai",
            "Madhepura",
            "Madhubani",
            "Munger",
            "Muzaffarpur",
            "Nalanda",
            "Nawada",
            "Patna",
            "Purnia",
            "Rohtas",
            "Saharsa",
            "Samastipur",
            "Saran",
            "Sheikhpura",
            "Sheohar",
            "Sitamarhi",
            "Siwan",
            "Supaul",
            "Vaishali",
            "West Champaran",
          ],
        },
        {
          name: "Chandigarh",
          districts: ["Chandigarh"],
        },
        {
          name: "Chhattisgarh",
          districts: [
            "Balod",
            "Baloda Bazar",
            "Balrampur",
            "Bastar",
            "Bemetara",
            "Bijapur",
            "Bilaspur",
            "Dantewada",
            "Dhamtari",
            "Durg",
            "Gariaband",
            "Janjgir-Champa",
            "Jashpur",
            "Kanker",
            "Kabirdham",
            "Kondagaon",
            "Korba",
            "Koriya",
            "Mahasamund",
            "Mungeli",
            "Narayanpur",
            "Raigarh",
            "Raipur",
            "Rajnandgaon",
            "Sukma",
            "Surajpur",
            "Surguja",
          ],
        },
        {
          name: "Dadra and Nagar Haveli and Daman and Diu",
          districts: ["Dadra and Nagar Haveli", "Daman", "Diu"],
        },
        {
          name: "Delhi",
          districts: [
            "Central Delhi",
            "East Delhi",
            "New Delhi",
            "North Delhi",
            "North East Delhi",
            "North West Delhi",
            "Shahdara",
            "South Delhi",
            "South East Delhi",
            "South West Delhi",
            "West Delhi",
          ],
        },
        {
          name: "Goa",
          districts: ["North Goa", "South Goa"],
        },
        {
          name: "Gujarat",
          districts: [
            "Ahmedabad",
            "Amreli",
            "Anand",
            "Aravalli",
            "Banaskantha",
            "Bharuch",
            "Bhavnagar",
            "Botad",
            "Chhota Udaipur",
            "Dahod",
            "Dang",
            "Devbhoomi Dwarka",
            "Gandhinagar",
            "Gir Somnath",
            "Jamnagar",
            "Junagadh",
            "Kheda",
            "Kutch",
            "Mahisagar",
            "Mehsana",
            "Morbi",
            "Narmada",
            "Navsari",
            "Panchmahal",
            "Patan",
            "Porbandar",
            "Rajkot",
            "Sabarkantha",
            "Surat",
            "Surendranagar",
            "Tapi",
            "Vadodara",
            "Valsad",
          ],
        },
        {
          name: "Haryana",
          districts: [
            "Ambala",
            "Bhiwani",
            "Charkhi Dadri",
            "Faridabad",
            "Fatehabad",
            "Gurugram",
            "Hisar",
            "Jhajjar",
            "Jind",
            "Kaithal",
            "Karnal",
            "Kurukshetra",
            "Mahendragarh",
            "Nuh",
            "Palwal",
            "Panchkula",
            "Panipat",
            "Rewari",
            "Rohtak",
            "Sirsa",
            "Sonipat",
            "Yamunanagar",
          ],
        },
        {
          name: "Himachal Pradesh",
          districts: [
            "Bilaspur",
            "Chamba",
            "Hamirpur",
            "Kangra",
            "Kinnaur",
            "Kullu",
            "Lahaul and Spiti",
            "Mandi",
            "Shimla",
            "Sirmaur",
            "Solan",
            "Una",
          ],
        },
        {
          name: "Jharkhand",
          districts: [
            "Bokaro",
            "Chatra",
            "Deoghar",
            "Dhanbad",
            "Dumka",
            "East Singhbhum",
            "Garhwa",
            "Giridih",
            "Godda",
            "Gumla",
            "Hazaribagh",
            "Jamtara",
            "Khunti",
            "Koderma",
            "Latehar",
            "Lohardaga",
            "Pakur",
            "Palamu",
            "Ramgarh",
            "Ranchi",
            "Sahibganj",
            "Seraikela-Kharsawan",
            "Simdega",
            "West Singhbhum",
          ],
        },
        {
          name: "Karnataka",
          districts: [
            "Bagalkot",
            "Ballari",
            "Belagavi",
            "Bengaluru Rural",
            "Bengaluru Urban",
            "Bidar",
            "Chamarajanagar",
            "Chikballapur",
            "Chikmagalur",
            "Chitradurga",
            "Dakshina Kannada",
            "Davanagere",
            "Dharwad",
            "Gadag",
            "Hassan",
            "Haveri",
            "Kalaburagi",
            "Kodagu",
            "Kolar",
            "Koppal",
            "Mandya",
            "Mysuru",
            "Raichur",
            "Ramanagara",
            "Shivamogga",
            "Tumakuru",
            "Udupi",
            "Uttara Kannada",
            "Vijayapura",
            "Yadgir",
          ],
        },
        {
          name: "Kerala",
          districts: [
            "Alappuzha",
            "Ernakulam",
            "Idukki",
            "Kannur",
            "Kasaragod",
            "Kollam",
            "Kottayam",
            "Kozhikode",
            "Malappuram",
            "Palakkad",
            "Pathanamthitta",
            "Thiruvananthapuram",
            "Thrissur",
            "Wayanad",
          ],
        },
        {
          name: "Ladakh",
          districts: ["Kargil", "Leh"],
        },
        {
          name: "Lakshadweep",
          districts: ["Lakshadweep"],
        },
        {
          name: "Madhya Pradesh",
          districts: [
            "Agar Malwa",
            "Alirajpur",
            "Anuppur",
            "Ashoknagar",
            "Balaghat",
            "Barwani",
            "Betul",
            "Bhind",
            "Bhopal",
            "Burhanpur",
            "Chhatarpur",
            "Chhindwara",
            "Damoh",
            "Datia",
            "Dewas",
            "Dhar",
            "Dindori",
            "Guna",
            "Gwalior",
            "Harda",
            "Hoshangabad",
            "Indore",
            "Jabalpur",
            "Jhabua",
            "Katni",
            "Khandwa",
            "Khargone",
            "Mandla",
            "Mandsaur",
            "Morena",
            "Narsinghpur",
            "Neemuch",
            "Panna",
            "Raisen",
            "Rajgarh",
            "Ratlam",
            "Rewa",
            "Sagar",
            "Satna",
            "Sehore",
            "Seoni",
            "Shahdol",
            "Shajapur",
            "Sheopur",
            "Shivpuri",
            "Sidhi",
            "Singrauli",
            "Tikamgarh",
            "Ujjain",
            "Umaria",
            "Vidisha",
          ],
        },
        {
          name: "Maharashtra",
          districts: [
            "Ahmednagar",
            "Akola",
            "Amravati",
            "Aurangabad",
            "Beed",
            "Bhandara",
            "Buldhana",
            "Chandrapur",
            "Dhule",
            "Gadchiroli",
            "Gondia",
            "Hingoli",
            "Jalgaon",
            "Jalna",
            "Kolhapur",
            "Latur",
            "Mumbai City",
            "Mumbai Suburban",
            "Nagpur",
            "Nanded",
            "Nandurbar",
            "Nashik",
            "Osmanabad",
            "Palghar",
            "Parbhani",
            "Pune",
            "Raigad",
            "Ratnagiri",
            "Sangli",
            "Satara",
            "Sindhudurg",
            "Solapur",
            "Thane",
            "Wardha",
            "Washim",
            "Yavatmal",
          ],
        },
        {
          name: "Manipur",
          districts: [
            "Bishnupur",
            "Chandel",
            "Churachandpur",
            "Imphal East",
            "Imphal West",
            "Jiribam",
            "Kakching",
            "Kamjong",
            "Kangpokpi",
            "Noney",
            "Pherzawl",
            "Senapati",
            "Tamenglong",
            "Tengnoupal",
            "Thoubal",
            "Ukhrul",
          ],
        },
        {
          name: "Meghalaya",
          districts: [
            "East Garo Hills",
            "East Jaintia Hills",
            "East Khasi Hills",
            "North Garo Hills",
            "Ri Bhoi",
            "South Garo Hills",
            "South West Garo Hills",
            "South West Khasi Hills",
            "West Garo Hills",
            "West Jaintia Hills",
            "West Khasi Hills",
          ],
        },
        {
          name: "Mizoram",
          districts: [
            "Aizawl",
            "Champhai",
            "Hnahthial",
            "Khawzawl",
            "Kolasib",
            "Lawngtlai",
            "Lunglei",
            "Mamit",
            "Saiha",
            "Saitual",
            "Serchhip",
          ],
        },
        {
          name: "Nagaland",
          districts: [
            "Dimapur",
            "Kiphire",
            "Kohima",
            "Longleng",
            "Mokokchung",
            "Mon",
            "Peren",
            "Phek",
            "Tuensang",
            "Wokha",
            "Zunheboto",
          ],
        },
        {
          name: "Odisha",
          districts: [
            "Angul",
            "Balangir",
            "Balasore",
            "Bargarh",
            "Bhadrak",
            "Boudh",
            "Cuttack",
            "Deogarh",
            "Dhenkanal",
            "Gajapati",
            "Ganjam",
            "Jagatsinghpur",
            "Jajpur",
            "Jharsuguda",
            "Kalahandi",
            "Kandhamal",
            "Kendrapara",
            "Kendujhar",
            "Khordha",
            "Koraput",
            "Malkangiri",
            "Mayurbhanj",
            "Nabarangpur",
            "Nayagarh",
            "Nuapada",
            "Puri",
            "Rayagada",
            "Sambalpur",
            "Subarnapur",
            "Sundargarh",
          ],
        },
        {
          name: "Puducherry",
          districts: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
        },
        {
          name: "Punjab",
          districts: [
            "Amritsar",
            "Barnala",
            "Bathinda",
            "Faridkot",
            "Fatehgarh Sahib",
            "Fazilka",
            "Ferozepur",
            "Gurdaspur",
            "Hoshiarpur",
            "Jalandhar",
            "Kapurthala",
            "Ludhiana",
            "Mansa",
            "Moga",
            "Pathankot",
            "Patiala",
            "Rupnagar",
            "Sangrur",
            "Shaheed Bhagat Singh Nagar",
            "Sri Muktsar Sahib",
            "Tarn Taran",
          ],
        },
        {
          name: "Rajasthan",
          districts: [
            "Ajmer",
            "Alwar",
            "Banswara",
            "Baran",
            "Barmer",
            "Bharatpur",
            "Bhilwara",
            "Bikaner",
            "Bundi",
            "Chittorgarh",
            "Churu",
            "Dausa",
            "Dholpur",
            "Dungarpur",
            "Hanumangarh",
            "Jaipur",
            "Jaisalmer",
            "Jalore",
            "Jhalawar",
            "Jhunjhunu",
            "Jodhpur",
            "Karauli",
            "Kota",
            "Nagaur",
            "Pali",
            "Pratapgarh",
            "Rajsamand",
            "Sawai Madhopur",
            "Sikar",
            "Sirohi",
            "Sri Ganganagar",
            "Tonk",
            "Udaipur",
          ],
        },
        {
          name: "Sikkim",
          districts: [
            "East Sikkim",
            "North Sikkim",
            "South Sikkim",
            "West Sikkim",
          ],
        },
        {
          name: "Tamil Nadu",
          districts: [
            "Ariyalur",
            "Chengalpattu",
            "Chennai",
            "Coimbatore",
            "Cuddalore",
            "Dharmapuri",
            "Dindigul",
            "Erode",
            "Kallakurichi",
            "Kanchipuram",
            "Kanyakumari",
            "Karur",
            "Krishnagiri",
            "Madurai",
            "Nagapattinam",
            "Namakkal",
            "Nilgiris",
            "Perambalur",
            "Pudukkottai",
            "Ramanathapuram",
            "Ranipet",
            "Salem",
            "Sivaganga",
            "Tenkasi",
            "Thanjavur",
            "Theni",
            "Thoothukudi",
            "Tiruchirapalli",
            "Tirunelveli",
            "Tirupathur",
            "Tiruppur",
            "Tiruvallur",
            "Tiruvannamalai",
            "Vellore",
            "Viluppuram",
            "Virudhunagar",
          ],
        },
        {
          name: "Telangana",
          districts: [
            "Bhadradri Kothagudem",
            "Hyderabad",
            "Jagtial",
            "Jangaon",
            "Jayashankar Bhupalpally",
            "Jogulamba Gadwal",
            "Kamareddy",
            "Karimnagar",
            "Khammam",
            "Komaram Bheem Asifabad",
            "Mahabubabad",
            "Mahabubnagar",
            "Mancherial",
            "Medak",
            "Medchal-Malkajgiri",
            "Nagarkurnool",
            "Nalgonda",
            "Nirmal",
            "Nizamabad",
            "Peddapalli",
            "Rajanna Sircilla",
            "Rangareddy",
            "Sangareddy",
            "Siddipet",
            "Suryapet",
            "Vikarabad",
            "Wanaparthy",
            "Warangal",
            "Yadadri Bhuvanagiri",
          ],
        },
        {
          name: "Tripura",
          districts: [
            "Dhalai",
            "Gomati",
            "Khowai",
            "North Tripura",
            "Sepahijala",
            "South Tripura",
            "Unakoti",
            "West Tripura",
          ],
        },
        {
          name: "Uttar Pradesh",
          districts: [
            "Agra",
            "Aligarh",
            "Ambedkar Nagar",
            "Amethi",
            "Amroha",
            "Auraiya",
            "Ayodhya",
            "Azamgarh",
            "Baghpat",
            "Bahraich",
            "Ballia",
            "Balrampur",
            "Banda",
            "Barabanki",
            "Bareilly",
            "Basti",
            "Bhadohi",
            "Bijnor",
            "Budaun",
            "Bulandshahr",
            "Chandauli",
            "Chitrakoot",
            "Deoria",
            "Etah",
            "Etawah",
            "Farrukhabad",
            "Fatehpur",
            "Firozabad",
            "Gautam Buddh Nagar",
            "Ghaziabad",
            "Ghazipur",
            "Gonda",
            "Gorakhpur",
            "Hamirpur",
            "Hapur",
            "Hardoi",
            "Hathras",
            "Jalaun",
            "Jaunpur",
            "Jhansi",
            "Kannauj",
            "Kanpur Dehat",
            "Kanpur Nagar",
            "Kasganj",
            "Kaushambi",
            "Kushinagar",
            "Lakhimpur Kheri",
            "Lalitpur",
            "Lucknow",
            "Mahoba",
            "Maharajganj",
            "Mainpuri",
            "Mathura",
            "Mau",
            "Meerut",
            "Mirzapur",
            "Moradabad",
            "Muzaffarnagar",
            "Pilibhit",
            "Pratapgarh",
            "Prayagraj",
            "Raebareli",
            "Rampur",
            "Saharanpur",
            "Sambhal",
            "Sant Kabir Nagar",
            "Shahjahanpur",
            "Shamli",
            "Shravasti",
            "Siddharthnagar",
            "Sitapur",
            "Sonbhadra",
            "Sultanpur",
            "Unnao",
            "Varanasi",
          ],
        },
        {
          name: "Uttarakhand",
          districts: [
            "Almora",
            "Bageshwar",
            "Chamoli",
            "Champawat",
            "Dehradun",
            "Haridwar",
            "Nainital",
            "Pauri Garhwal",
            "Pithoragarh",
            "Rudraprayag",
            "Tehri Garhwal",
            "Udham Singh Nagar",
            "Uttarkashi",
          ],
        },
        {
          name: "West Bengal",
          districts: [
            "Alipurduar",
            "Bankura",
            "Birbhum",
            "Cooch Behar",
            "Dakshin Dinajpur",
            "Darjeeling",
            "Hooghly",
            "Howrah",
            "Jalpaiguri",
            "Jhargram",
            "Kalimpong",
            "Kolkata",
            "Malda",
            "Murshidabad",
            "Nadia",
            "North 24 Parganas",
            "Paschim Bardhaman",
            "Purba Bardhaman",
            "Purba Medinipur",
            "Purulia",
            "South 24 Parganas",
            "Uttar Dinajpur",
          ],
        },
      ],
    },
  };

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState("");
    setSelectedDistrict("");
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedDistrict("");
  };

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((country) => (
    <MenuItem key={country} value={country}>
      {country}
    </MenuItem>
  ));

  const stateOptions = selectedCountry
    ? countries[selectedCountry].states.map((state) => (
        <MenuItem key={state.name} value={state.name}>
          {state.name}
        </MenuItem>
      ))
    : [];

  const districtOptions = selectedState
    ? countries[selectedCountry].states
        .find((state) => state.name === selectedState)
        .districts.map((district) => (
          <MenuItem key={district} value={district}>
            {district}
          </MenuItem>
        ))
    : [];

  const initialFormData = {
    uploadStudentProfile: null,
    studentName: "", // Define these variables
    studentId: "",
    admissionNo: "",
    yoj: "",
    courseId: "",
    courseName: "",
    sectionName: "",
    sectionId: "",
    semesterNumber: "",
    gender: "",
    dob: "",
    emailId: "",
    mobileNumber: "",
    parentName: "",
    parentNumber: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    pincode: "",
    country: "",
    state: "",
    district: "",
    description: "",
    bloodGroup: "",
    cloudSpace: "",
    cloudSpaceType: false,
    vrEnabled: false,
    academicYear:"",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };

  const [formData, setFormData] = useState(initialFormData);

  const [dob, setDob] = useState(null);
  const [yoj, setYoj] = useState(null);

  // console.log(sections);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCloudSpaceType = (checked) => {
    setFormData((prev) => ({
      ...prev,
      cloudSpaceType: checked,
    }));
  };

  const handleVr = (checked) => {
    setFormData((prev) => ({
      ...prev,
      vrEnabled: checked,
    }));
  };

  const [getSectionName, setGetSectionName] = useState([]);

  console.log(getSectionName);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formAppendData = new FormData();
    formAppendData.append("uploadStudentProfile", studentProfile);
    formAppendData.append("studentId", formData.studentId);
    formAppendData.append("studentName", formData.studentName);
    formAppendData.append("admissionNo", formData.admissionNo);
    formAppendData.append("yoj", formData.yoj);
    formAppendData.append("gender", formData.gender);
    formAppendData.append("courseName", formData.courseName);
    formAppendData.append("courseId", selectedCourseId);
    formAppendData.append("sectionName", formData.sectionName);
    formAppendData.append(
      "sectionId",
      selectedSectionId !== "" ? selectedSectionId : formData.sectionId
    );
    formAppendData.append("semesterNumber", formData.semesterNumber);
    formAppendData.append("dob", formData.dob);
    formAppendData.append("emailId", formData.emailId);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("parentName", formData.parentName);
    formAppendData.append("parentNumber", formData.parentNumber);
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("addressLine2", formData.addressLine2);
    formAppendData.append("area", formData.area);
    formAppendData.append("pincode", formData.pincode);
    formAppendData.append("state", selectedState);
    formAppendData.append("country", selectedCountry);
    formAppendData.append("district", selectedDistrict);
    formAppendData.append("description", formData.description);
    formAppendData.append("password", formData.password);
    formAppendData.append("cloudSpace", formData.cloudSpace);
    formAppendData.append("academicYear", formData.academicYear);
    formAppendData.append(
      "cloudSpaceType",
      formData.cloudSpaceType ? "GB" : "TB"
    );
    formAppendData.append("vrEnabled", formData.vrEnabled);

    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
    );

    const formSendData = {
      uploadStudentProfile: formData.uploadStudentProfile,
      studentName: formData.studentName, // Define these variables
      studentId: formData.studentId,
      admissionNo: formData.admissionNo,
      semesterNumber: formData.semesterNumber,
      yoj: formData.yoj,
      courseId: selectedCourseId,
      courseName: formData.courseName,
      sectionName: formData.sectionName,
      sectionId:
        selectedSectionId !== "" ? selectedSectionId : formData.sectionId,
      dob: formData.dob,
      gender: formData.gender,
      emailId: formData.emailId.toLowerCase(),
      mobileNumber: formData.mobileNumber,
      parentName: formData.parentName,
      parentNumber: formData.parentNumber,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      area: formData.area,
      pincode: formData.pincode,
      country: selectedCountry,
      state: selectedState,
      district: selectedDistrict,
      description: formData.description,
      password: formData.password,
      cloudSpace: formData.cloudSpace,
      academicYear:formData.academicYear,
      cloudSpaceType: formData.cloudSpaceType ? "GB" : "TB",
      vrEnabled: formData.vrEnabled,
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
  
    };

    // Create the Axios config object with the headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (formData.uploadStudentProfile === null && studentProfile === null) {
      toast.warn("Please upload your profile");
    } else if (studentProfile !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegestudent/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/student");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegestudent`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/student");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadStudentProfile !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegestudent/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/student");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegestudent`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/student");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    const formAppendData = new FormData();
    formAppendData.append("uploadStudentProfile", studentProfile);
    formAppendData.append("studentId", formData.studentId);
    formAppendData.append("studentName", formData.studentName);
    formAppendData.append("admissionNo", formData.admissionNo);
    formAppendData.append("yoj", formData.yoj);
    formAppendData.append("gender", formData.gender);
    formAppendData.append("courseName", formData.courseName);
    formAppendData.append("courseId", selectedCourseId);
    formAppendData.append("sectionName", formData.sectionName);
    formAppendData.append("sectionId", selectedSectionId);
    formAppendData.append("semesterNumber", formData.semesterNumber);
    formAppendData.append("dob", formData.dob);
    formAppendData.append("emailId", formData.emailId);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("parentName", formData.parentName);
    formAppendData.append("parentNumber", formData.parentNumber);
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("addressLine2", formData.addressLine2);
    formAppendData.append("area", formData.area);
    formAppendData.append("pincode", formData.pincode);
    formAppendData.append("state", selectedState);
    formAppendData.append("country", selectedCountry);
    formAppendData.append("district", selectedDistrict);
    formAppendData.append("description", formData.description);
    formAppendData.append("password", formData.password);
    formAppendData.append("cloudSpace", formData.cloudSpace);
    formAppendData.append("academicYear", formData.academicYear);
    formAppendData.append(
      "cloudSpaceType",
      formData.cloudSpaceType ? "GB" : "TB"
    );
    formAppendData.append("vrEnabled", formData.vrEnabled);

    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
  );

    const formSendData = {
      uploadStudentProfile: formData.uploadStudentProfile,
      studentName: formData.studentName, // Define these variables
      studentId: formData.studentId,
      admissionNo: formData.admissionNo,
      semesterNumber: formData.semesterNumber,
      yoj: formData.yoj,
      courseId: selectedCourseId,
      courseName: formData.courseName,
      sectionName: formData.sectionName,
      sectionId: selectedSectionId,
      dob: formData.dob,
      gender: formData.gender,
      emailId: formData.emailId.toLowerCase(),
      mobileNumber: formData.mobileNumber,
      parentName: formData.parentName,
      parentNumber: formData.parentNumber,
      addressLine1: formData.addressLine1,
      addressLine2: formData.addressLine2,
      area: formData.area,
      pincode: formData.pincode,
      country: selectedCountry,
      state: selectedState,
      district: selectedDistrict,
      description: formData.description,
      password: formData.password,
      cloudSpace: formData.cloudSpace,
      academicYear:formData.academicYear,
      cloudSpaceType: formData.cloudSpaceType ? "GB" : "TB",
      vrEnabled: formData.vrEnabled,
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

    };

    // Create the Axios config object with the headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (formData.uploadStudentProfile === null && studentProfile === null) {
      toast.warn("Please upload your profile");
    } else if (studentProfile !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegestudent/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setImageUrl("");
              setSelectedCourseId("");
              setSelectedSectionId("");
              setSelectedCountry("");
              setSelectedState("");
              setSelectedDistrict("");
              setFormData((prevData) => ({ ...prevData, password: "" }));
              setDob(null); // Assuming dob is a date object state variable
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegestudent`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setImageUrl("");
              setSelectedCourseId("");
              setSelectedSectionId("");
              setSelectedCountry("");
              setSelectedState("");
              setSelectedDistrict("");
              setFormData((prevData) => ({ ...prevData, password: "" }));
              setDob(null); // Assuming dob is a date object state variable
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadStudentProfile !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegestudent/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setImageUrl("");
              setSelectedCourseId("");
              setSelectedSectionId("");
              setSelectedCountry("");
              setSelectedState("");
              setSelectedDistrict("");
              setFormData((prevData) => ({ ...prevData, password: "" }));
              setDob(null); // Assuming dob is a date object state variable
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegestudent`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setImageUrl("");
              setSelectedCourseId("");
              setSelectedSectionId("");
              setSelectedCountry("");
              setSelectedState("");
              setSelectedDistrict("");
              setFormData((prevData) => ({ ...prevData, password: "" }));
              setDob(null); // Assuming dob is a date object state variable
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({ ...formData, semesterNumber: "" });
      // setSelectedSemesterNumber(formData.semesterNumber);
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/collegestudent/${id}`);

    console.log(formData.data.data);

    if (formData.status === 200) {
      const fetchedData = formData.data.data[0];
      console.log("fetchedData", fetchedData);

      setFormData({
        ...fetchedData,
        semesterNumber: fetchedData.semesterNumber
          ? fetchedData.semesterNumber.toString()
          : "",
      });

      //setSelectedSemesterNumber(fetchedData.semesterNumber.toString());
      setSelectedCourseId(fetchedData.courseId || ""); // Assuming this is the ssetFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  console.log("initialFormData", formData);

  const handleImageUpload = (e) => {
    const uploadStudentProfile = e.target.files[0];

    // Check if a file was selected
    if (uploadStudentProfile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the image URL to the reader's result, which is the base64 data URL of the uploaded image
        setImageUrl(e.target.result);
        console.log(uploadStudentProfile);
      };

      reader.readAsDataURL(uploadStudentProfile);
      setStudentProfile(uploadStudentProfile);
    }
  };

  const handleReset = (e) => {
    setFormData(initialFormData);
    setImageUrl("");
    setSelectedCourseId("");
    setSelectedSectionId("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedDistrict("");
    setFormData((prevData) => ({ ...prevData, password: "" }));
    setDob(null); // Assuming dob is a date object state variable
    setYoj(null);
  };

  const handleDobChange = (date) => {
    if (date) {
      setFormData({ ...formData, dob: date.format("DD/MM/YYYY") });
    } else {
      // Handle the case where date is null
      console.error("Received null date in handleDobChange");
    }
  };

  console.log(semesters);
  const handleDojChange = (date) => {
    if (date) {
      setFormData({ ...formData, yoj: date.format("DD/MM/YYYY") });
    } else {
      // Handle the case where date is null
      console.error("Received null date in handleDojChange");
    }
  };
  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegecoursebyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setCourses(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const handleCourseChange = (e) => {
    const selectedCourseName = e.target.value;
    // Find the course object in the courses array that matches the selected course name
    const selectedCourse = courses.find(
      (course) => course.courseName === selectedCourseName
    );

    if (selectedCourse) {
      setSelectedCourseId(selectedCourse.courseId);
      // setSelectedSemesterNumber("");
    }
  };




const handleSemesterChange = (e) => {
  const selectedSemesterNumber = e.target.value;

  // Update the semester number in the form data
  setFormData((prevFormData) => ({
    ...prevFormData,
    semesterNumber: selectedSemesterNumber,
  }));

  // Fetch sections based on the selected course ID, academic year, and semester number
  if (selectedCourseId && formData.academicYear && selectedSemesterNumber) {
    axios
      .get(
        `${apiUrl}/sectionbycourseandsemesterandacademicyear/${selectedCourseId}/${encodeURIComponent(
          selectedSemesterNumber
        )}/${formData.academicYear}`
      )
      .then((response) => {
        if (response.status === 200 && Array.isArray(response.data.data)) {
          const fetchedSectionsData = response.data.data;

          // Set sections data state with the fetched section data
          setSectionsData(fetchedSectionsData);

          // Set sections state with the section names
          const sectionNames = fetchedSectionsData.map(
            (section) => section.sectionName
          );
          setSections(sectionNames);
          console.log("sec",sections)
        } else {
          console.log("Failed to fetch sections.");
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          toast.info("Sections not found");
        }
      });
  }

  console.log("Selected Semester Number:", selectedSemesterNumber);
};


  const handleSectionChange = (e) => {
    const selectedSectionName = e.target.value;

    const selectedSection = sectionsData.find(
      (section) => section.sectionName === selectedSectionName
    );

    if (selectedSection) {
      console.log("Selected Section ID:", selectedSection.sectionId);

      setFormData((prevFormData) => ({
        ...prevFormData,
        sectionId: selectedSection.sectionId,
      }));
      setSelectedSectionId(selectedSection.sectionId);
    }
  };

  useEffect(() => {
    if (selectedSectionId) {
      axios
        .get(`${apiUrl}/collegesubjectbysectionid/${selectedSectionId}`)
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch sections.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            console.log("Section not found");
          }
        });
    }
  }, [selectedSectionId]);

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  const handleFacultyChange = (e) => {
    const selectedFacultyName = e.target.value;
    // Find the faculty object in the faculties array that matches the selected faculty name
    const selectedFaculty = faculties.find(
      (faculty) => faculty.facultyName === selectedFacultyName
    );

    if (selectedFaculty) {
      setSelectedFacultyId(selectedFaculty.facultyId);
    }
  };

  const handleAcademicYearChange = (e) => {
    const selectedAcademicYear = e.target.value;

    // setFormData((prevData) => ({
    //   ...prevData,
    //   academicYear: selectedAcademicYear,
    // }));

    // check if both courseId and selectedAcademicYear are available
    if (selectedCourseId !== null && selectedAcademicYear !== "") {
      const encodedAcademicYear = encodeURIComponent(selectedAcademicYear);
      axios
        .get(`${apiUrl}/semesterbycourseidandacademicyear/${selectedCourseId}/${encodedAcademicYear}`)
        .then((res) => {
          setItem(res.data.data);
          if (res.data.data.length > 0) {
            // Use a Set to remove duplicates and then convert it back to an array
            const uniqueSemesters = [...new Set(res.data.data.map((semester) => semester.semesterNumber))];
            setSemesters(uniqueSemesters);
          }

          console.log("API Response:", res.data);
          console.log("data", item);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/getAcademicDetailsByInstituteUniqueId/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            // Filter academic years with status equal to 1
            const filteredAcademicYears = response.data.data.filter(
              (year) => year.status === 1
            );
            setAcademicYear(filteredAcademicYears);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Course name not found");
          }
        });
    }
  }, []);

  return (
    <div className="w-full flex justify-between items-center pb-10 ">
      <div className="w-full h-[900px] bg-white px-16  rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              name="uploadStudentProfile"
              id="image-upload"
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none", backgroundColor: "white" }}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                {/* <Avatar sx={{ width: 150, height: 150 }}> */}

                <div className="w-[140px] h-[140px]  flex flex-col justify-center items-center border-2 border-primary rounded-full bg-white text-primary">
                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/CollegeStudentUploads/${formData.uploadStudentProfile}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {" "}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-primary text-[10px]"
                          />
                          <span className="text-[15px] font-normal">
                            Upload Student Profile
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* </Avatar> */}
              </IconButton>
            </label>
          </div>

          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Student Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="studentName"
                  value={formData.studentName}
                  onChange={handleOnChange}
                  required
                />

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Course ID"
                    variant="outlined"
                    fullWidth
                    name="courseId"
                    value={
                      selectedCourseId !== ""
                        ? selectedCourseId
                        : formData.courseId
                    }
                    // onChange={handleOnChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Course ID"
                    variant="outlined"
                    fullWidth
                    name="courseId"
                    value={selectedCourseId}
                    required
                  />
                )}

{editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    select
                    label="Section Name"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionName"
                    value={formData.sectionName}
                    onChange={(e) => {
                      handleOnChange(e);
                      handleSectionChange(e);
                    }}
                  >
                  </TextField>
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    select
                    label="Section Name"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionName"
                    value={formData.sectionName}
                    onChange={(e) => {
                      handleOnChange(e);
                      handleSectionChange(e);
                    }}
                  >
                    {sections.flat().map((sectionName, id) => (
                      <MenuItem key={id} value={sectionName}>
                        {sectionName}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleOnChange}
                  required
                />

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="D.O.B *"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    name="dob"
                    fullWidth
                    type="date"
                    //value={formData.dob}
                    onChange={handleDobChange}

                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Parent Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="parentName"
                  value={formData.parentName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Address Line 2"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleOnChange}
                />

<TextField
 sx={{
  backgroundColor: "#F5F5F5",
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#105D50",
  },
  "& .MuiInputBase-root": {
    height: 45,
    fontSize: "14px",
  },
  "&:hover": {
    backgroundColor: "#EEEDED",
  },
}}
select
label="State"
variant="outlined"
fullWidth
name="state"
value={selectedState}
onChange={handleStateChange}
>
<MenuItem value="">
  <em>None</em>
</MenuItem>
{stateOptions}
</TextField>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Student ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="studentId"
                    value={formData.studentId}
                    onChange={handleOnChange}
                    required
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Student ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="studentId"
                    value={formData.studentId}
                    onChange={handleOnChange}
                    required
                  />
                )}

<TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                    // width: "120%",
                  }}
                  label="Academic Year"
                  variant="outlined"
                  name="academicYear"
                  fullWidth
                  select
                  value={formData.academicYear}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleAcademicYearChange(e); 
                  }}
                  // onChange={handleOnChange}
                  required
                >
                  {academicYear?.map((academicyears) => (
                    <MenuItem key={academicyears._id} value={academicyears.academicYear}>
                      {academicyears.academicYear}
                    </MenuItem>
                  ))}
                </TextField>

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Section ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionId"
                    value={
                      selectedSectionId !== ""
                        ? selectedSectionId
                        : formData.sectionId
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={handleOnChange}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Section ID"
                    variant="outlined"
                    fullWidth
                    type="text"
                    name="sectionId"
                    value={selectedSectionId}
                    InputProps={{
                      readOnly: true,
                    }}
                    onChange={handleOnChange}
                  />
                )}


     
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Date Of Joining"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    type="date"
                    name="yoj"
                    fullWidth
                    //value={formData.yoj}
                    onChange={handleDojChange}

                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    //value={formData.password}
                    onChange={handleOnChange}
                    //required
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleOnChange}
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                    required
                  />
                )}

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Gender"
                  variant="outlined"
                  fullWidth
                  select
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                  required
                >
                  <MenuItem value="male" style={{ fontSize: "12px" }}>
                    Male
                  </MenuItem>
                  <MenuItem value="female" style={{ fontSize: "12px" }}>
                    Female
                  </MenuItem>
                  <MenuItem value="other" style={{ fontSize: "12px" }}>
                    Other
                  </MenuItem>
                </TextField>

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Parent Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="parentNumber"
                  value={formData.parentNumber}
                  onChange={handleOnChange}
                  required
                />

                {/* <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Area"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="area"
                  value={formData.area}
                  onChange={handleOnChange}
                /> */}

<TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  label="District"
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {districtOptions}
                </TextField>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Course Name"
                  variant="outlined"
                  name="courseName"
                  fullWidth
                  select
                  value={formData.courseName}
                  onChange={(e) => {
                    handleOnChange(e);
                    handleCourseChange(e); // Call the course change handler
                  }}
                  required
                >
                  {courses.map((course) => (
                    <MenuItem key={course._id} value={course.courseName}>
                      {course.courseName}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
  sx={{
    backgroundColor: "#F5F5F5",
    color: "#105D50",
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#105D50",
    },
    "& .MuiInputBase-root": {
      height: 45,
      fontSize: "14px",
    },
    "&:hover": {
      backgroundColor: "#EEEDED",
    },
  }}
  label="Semester Number"
  variant="outlined"
  fullWidth
  select
  name="semesterNumber"
  type="text"
  value={formData.semesterNumber}
  onChange={(e) => {
    handleOnChange(e);
    handleSemesterChange(e);
  }}
  required
>
  {semesters.map((semester, index) => (
    <MenuItem key={index} value={semester}>
      {semester}
    </MenuItem>
  ))}
</TextField>

             

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Admission Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="admissionNo"
                  onChange={handleOnChange}
                  value={formData.admissionNo}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="mobileNumber"
                  onChange={handleOnChange}
                  value={formData.mobileNumber}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      width: 280,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&:hover fieldset": {
                        border: "1.3px solid #105D50",
                      },
                      "&.Mui-focused fieldset": {
                        border: "1.34px solid #105D50",
                      },
                    },
                  }}
                  label="Cloud Space"
                  variant="outlined"
                  name="cloudSpace"
                  type="number"
                  fullWidth
                  value={formData.cloudSpace}
                  onChange={handleOnChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <div className="flex items-center">
                        <h1 className="mx-1">TB</h1>
                        <Switch
                          // checkedChildren={`TB`}
                          // unCheckedChildren={`GB`}
                          onChange={handleCloudSpaceType}
                          checked={formData.cloudSpaceType}
                          className={"custom-switch-checked"}
                        />
                        <h1 className="mx-1">GB</h1>
                      </div>
                    ),
                  }}
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Address Line1"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleOnChange}
                  required
                />

<TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  label="Country"
                  variant="outlined"
                  fullWidth
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countryOptions}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleOnChange}
                  required
                />
              </Stack>
            </Stack>
            <Stack>
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 120,
                    width: 870,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                }}
                id="outlined-multiline-static"
                label="Description"
                value={formData.description}
                multiline
                type="text"
                name="description"
                rows={4}
                onChange={handleOnChange}
                //defaultValue="Default Value"
              />
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-4">
              {!editMode && (
                <button
                  type="button"
                  onClick={handleReset}
                  className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Reset
                </button>
              )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default StudentForm;
