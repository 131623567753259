import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { Switch } from "antd";
import { Modal, Row, Col, Typography, Divider } from 'antd';
import { ExclamationCircleFilled } from "@ant-design/icons";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import "../App.css";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import { CSVLink } from "react-csv";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";

const apiUrl = process.env.REACT_APP_API_URL;


function Exams({ students }) {

  const LMS_ROLE = localStorage.getItem("lms_role");
  const PERMISSION_ADD = localStorage.getItem("permission_add");
  const PERMISSION_EDIT = localStorage.getItem("permission_edit");
  const PERMISSION_DELETE = localStorage.getItem("permission_delete"); 
  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [item, setItem] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);
  const history = useNavigate();
  const { Text, Title } = Typography;

  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggle = async (id, value) => {
    const newStatus = value ? 0 : 1;
    try {
      await axios
        .put(`${apiUrl}/collegeexamstatus/${id}`, {
          status: newStatus,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              `Exam ${newStatus ? "activated" : "deactivated"} successfully`
            );
            setStatus(!status);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (id, examId) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/collegeexam/${id}`).then((res) => {
          //  console.log(res.data);
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
        //axios.delete(`${apiUrl}/collegefacultylogin/${facultyId}`);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      name:<h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      sortable: true,
      width: '60px',
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Exam ID</h1>,
      selector:(row) => row.examId,
      sortable: true,
      // width: '200px',
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Exam Name</h1>,
      selector: (row) => row.examName,
      sortable: true,
      width: '200px',
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Subject ID</h1>,
      selector:(row) => row.subjectId,
      sortable: true,
      // width: '200px',
    },
    {
      name: <h1 className="text-white pl-4 w-[150px]">Subject Name</h1>,
      selector: (row) => row.subjectList,
      sortable: true,
      width: '200px',
    },
    {
        name: <h1 className="text-white pl-4 w-[150px]">Course Name</h1>,
        selector: (row) => row.courseName,
        sortable: true,
        width: '250px',
       
      },

      {
        name:<h1 className="text-white pl-4 w-[150px]">Section Name</h1>,
        selector:(row) => row.sectionName,
        sortable: true,
        // width: '200px',
      },
      {
        name: <h1 className="text-white pl-4 w-[150px]">Semester</h1>,
        selector: (row) => row.semesterNumber,
        sortable: true,
        // width: '200px',
      },

      {
        name:<h1 className="text-white pl-4 w-[150px]">Date of Exam</h1>,
        selector: (row) => row.dateOfExamination,
        sortable: true,
        // width: '200px',
      },

    {
      name:<h1 className="text-white pl-4 w-[150px]">Duration</h1>,
      selector: (row) => row.duration,
      sortable: true,
      // width: '200px',
    },
   
   
    {
      name: <h1 className="text-white pl-4 w-[100px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            {LMS_ROLE === "3" || LMS_ROLE === '5' ? (
              <>
                <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)} />
                {/* <Link to={`/homepage/exam/${row.examId}`}>
                  <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                </Link> */}
                <MdDelete
                  className="w-6 h-6 text-red-500 cursor-pointer"
                  onClick={() => showDeleteConfirm(row._id, row.examId)}
                />
              </>
            ) : (
              <>
                <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={() => showPreviewModal(row)} />
                {/* {PERMISSION_EDIT === "true" && (
                  <Link to={`/homepage/exam/${row.examId}`}>
                    <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
                  </Link>
                )} */}
                {PERMISSION_DELETE === "true" && (
                  <MdDelete
                    className="w-6 h-6 text-red-500 cursor-pointer"
                    onClick={() => showDeleteConfirm(row._id, row.facultyId)}
                  />
                )}
              </>
            )}
          </div>
        </>
      ),
      // width:"100px"
    },
    {
      name: <h1 className="text-white pl-4 w-[100px]">Status</h1>,
      selector: (row) => (
        <>
          <div className="flex flex-row items-center ">
            <Switch
              checked={row.status === 1}
              onChange={() =>
                handleToggle( row.examId, row.status === 1)
              }
              className={
                row.status === 1
                  ? "custom-switch-checked"
                  : "custom-switch-unchecked"
              }
            />
          </div>
        </>
      ),
      // width:"150px"
    },

  ];
 

  // Define your custom date format function here
  const formatDate = (date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    return `${day}-${month}-${year}`;
  };

//   const handleToggleVR = (branchId) => {
//     // Implement your toggle logic here
//   };

 
  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.examName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]); 

  useEffect(() => {
    if (LMS_ROLE === "3"  || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegeexambyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
           // toast.info(error.response.data.message);
          }
        });
    } 
    else if ( LMS_ROLE === "6"){
      axios
      .get(
        `${apiUrl}/collegeexambyinstituteuniqueid/${localStorage.getItem("branch_Unique_Id")}`
      )
      .then((res) => {
        setItem(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error.response.data);
        if (error.response.data.status === 404) {
          // toast.info(error.response.data.message);
        }
      });
    }else {
      axios
        .get(
          `${apiUrl}/collegeexambyinstituteuniqueid/${localStorage.getItem("institute_Unique_Id")}`
        )
        .then((res) => {
          setItem(res.data.data);
          console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
           toast.info(error.response.data.message);
          }
        });
    }
  }, [status]);

  const toggleFormView = () => {
    history("/homepage/exam/add exam");
  };

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Exam ID": item.examId,
        "Exam Name": item.examName,
        "Semester":item.semesterNumber,
        "Date Of Exam":item.dateOfExamination,
        "Subject Name" :item.subjectList,
        "Subject Id": item.subjectId,
        
      }));
      return csvData;
    }
    return [];
  };



  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };



  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  return (
    <div className="flex flex-col  w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow border">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-bg-secondary" />
        </div>
        {LMS_ROLE === "3" || LMS_ROLE === "5" ? (
       <button
       className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"               
        onClick={toggleFormView}
     >
       <FaPlus />
       Add Exams
     </button>
        ):(
          <>
          {PERMISSION_ADD === "true" && (
            <button
            className="py-3 px-4 text-[12px] font-sm bg-primary text-white rounded-md shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row items-center gap-1"               
             onClick={toggleFormView}
          >
            <FaPlus />
            Add Exams
          </button>
          )}
        </>
      )}
    </div>
      {/* Use the DataTable component */}
      <div className="flex flex-row items-center gap-6">
              {/* <div class="relative group flex items-center">
              <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="upload"
                    src={upload}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div> */}
              <div class="relative group flex items-center">
              <CSVLink
                  data={handleCsv()}
                  filename="Exam List.csv"
                  class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
                >
                  {" "}
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2"
                  />
               </CSVLink>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>
      {/* Use the DataTable component */}
      <div className="flex flex-col w-full">
      <DataTable
       fixedHeader
       fixedHeaderScrollHeight="400px"
        columns={columns}
        data={filteredData}
        selectableRows
        pagination
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
        onChangePage={handlePageChange}
      />
      </div>
    </div>
    <div className='flex justify-end ml-14'>
      <Modal
  //title="Course Preview"
  visible={isPreviewModalVisible}
  onOk={hidePreviewModal}
  onCancel={hidePreviewModal}
  width={600}
  footer={null}
>
  
  {previewData && (
    <div className="py-4">
          <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                  <Col span={2.1}>
                  <img src={preview1} className="flex justify-start" ></img>
                  </Col> 
                    
                    <Col span={6} className="flex flex-col justify-center ">
                    <div className="sm:w-96 w-60 pt-4 pb-1 bg-primary rounded-full flex flex-col justify-center items-center">
                    <Title level={3} style={{fontSize,color:"white"}} className="capitalize">
                        {previewData.examName.toUpperCase()}
                    </Title>
                    </div>
                    </Col>                            
         </Row>

      <div className="sm:ml-20 my-6 space-y-2">
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Exam ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.examId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Course Name:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseName}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Course Id:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.courseId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Section Name:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.sectionName}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Section ID:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.sectionId}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Subject Name:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.subjectList}</Text>
        </Col>
      </Row>


      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Duration:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.duration}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Date:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.dateOfExamination}</Text>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Pass Mark:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.passMarks}</Text>
        </Col>
      </Row>
    
      <Row gutter={[16, 16]}>
        <Col flex="200px">
          <Text strong className="text-primary">Total Mark:</Text>
        </Col>
        <Col flex="auto">
          <Text>{previewData.totalMarks}</Text>
        </Col>
      </Row>
      </div>
      
      <Row className="flex justify-end -mt-10" gutter={[16, 16]}>
                <Col span={6}>
                <img src={preview2}></img>
                </Col>
      </Row>
    </div>
  )}
</Modal>
    </div>
    </div>
   
  );
}

export default Exams;