import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { FaComments, FaVideo } from "react-icons/fa";

import { AiOutlineSearch } from "react-icons/ai";
import download from "../../Images/download.png";

import axios from "axios";
import { CSVLink } from "react-csv";
import { useParams, useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

function Students() {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const { sectionId } = useParams();

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => index + 1,
      //sortable: false,
      width: "80px",
    },
    {
      name: <h1 className="text-white pl-3 w-[320px]">Student ID</h1>,
      selector: (row) => row.studentId,
      sortable: true,
      width: "280px",
    },
    {
      name: <h1 className="text-white pl-3 w-[320px]">Student Name</h1>,
      selector: (row) => row.studentName,
      sortable: true,
      width: "280px",
    },
    {
      name: <h1 className="text-white pl-3 w-[300px]">Mail ID</h1>,
      selector: (row) => row.emailId,
      sortable: true,
      //width:"300px"
    },
  ];

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.studentName.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);
  console.log(filteredData);

  useEffect(() => {
    if (sectionId !== null) {
      axios
        .get(`${apiUrl}/collegestudentbysectionuniqueid/${sectionId}`)
        .then((res) => {
          setItem(res.data.data);
          console.log("API Response:", res.data);
        })

        .catch((err) => console.log(err));
    }
  }, [sectionId]);
  console.log(sectionId);

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "Student ID": item.studentId,
        "Student Name": item.studentName,
        "EMail ID": item.emailId,
      }));
      return csvData;
    }
    return [];
  };

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        paddingLeft: "8px", 
        paddingRight: "8px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
      },
    },
    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width:"150px",
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",
      },
    },
  };

  const handleNavigate = () =>{
    navigate(`/homepage/My Classroom/Students/shift semester/${sectionId}`);
  }

  return (
    <div className="W-full h-auto flex flex-col items-center gap-3 pt-5  px-1 drop-shadow bg-white  border shadow-md">
      <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
        <div className="relative">
          <input
            placeholder="Search"
            type="text"
            required
            className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
        </div>
        <button 
        className="py-2 px-4 rounded-lg bg-primary text-white transition duration-300 ease-in-out"
        onClick={handleNavigate}>
             Shift Semester
        </button>
        <div className="flex flex-row items-center gap-6">
          <div class="relative group flex items-center">
            <CSVLink
              data={handleCsv()}
              filename="Student List.csv"
              class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
            >
              {" "}
              <img
                alt="download"
                src={download}
                className="w-5 h-5 mx-auto mt-2"
              />
            </CSVLink>
            <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
              Download
            </div>
          </div>
         </div>
      </div>

      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="400px"
        columns={columns}
        data={filteredData}
        pagination
        customStyles={customStyles}
        highlightOnHover
        pointerOnHover
      />
    </div>
  );
}

export default Students;
