import React from "react";
import DataTable, { SortOrder } from "react-data-table-component";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { FaCheck, FaEdit, FaEye, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AiOutlinePlus, AiOutlineSearch } from "react-icons/ai";
import { TiCancel } from "react-icons/ti";
import { MdDelete } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import upload from "../Images/upload.png";
import download from "../Images/download.png";
import axios from "axios";
import { Modal, Switch ,Row, Col ,Typography} from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import preview1 from "../Images/preview2.png";
import preview2 from "../Images/preview1.png";


const apiUrl = process.env.REACT_APP_API_URL;

const ELibrary = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [status, setStatus] = useState(false);
  const rowsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleToggle = async (id, value) => {
    const newStatus = value ? 0 : 1;
    try { 
      await axios
        .put(`${apiUrl}/elibrarystatus/${id}`, {
          status: newStatus,
        })
        .then((res) => {
          if (res.status === 200) {
            toast.success(
              `Document ${newStatus ? "activated" : "deactivated"} successfully`
            );
            setStatus(!status);
          }
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure to delete this data?",
      icon: <ExclamationCircleFilled />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axios.delete(`${apiUrl}/elibrary/${id}`).then((res) => {
          //  console.log(res.data);
          if (res.status === 200) {
            setFilteredData((prevData) =>
              prevData.filter((item) => item._id !== id)
            );
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      name: <h1 className="text-white pl-3 w-[60px]">S.No</h1>,
      selector: (row, index) => (currentPage - 1) * rowsPerPage + index + 1,    
      // sortable: true, // Enable sorting for this column
      width: '70px',
    },
    {
      name: <h1 className="text-white pl-2 w-[150px]">Title</h1>,
      selector: (row) => row.title,
      sortable: true,
      
    },

    {
      name: <h1 className="text-white pl-3 w-[200px]">Author Name</h1>,
      selector: (row) => row.authorName,
      sortable: true,
      
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Library Category</h1>,
      selector: (row) => row.LibraryCategory,
      sortable: true,
      
    },
    {
      name: <h1 className="text-white pl-3 w-[100px]">Status</h1>,
      selector: (row) => (
        <div className="flex flex-row items-center ">
          <Switch
            checked={row.status === 1}
            onChange={() => handleToggle(row._id, row.status === 1)}
            className={
              row.status === 1
                ? "custom-switch-checked"
                : "custom-switch-unchecked"
            }
          />
        </div>
      ),
      
    },
    {
      name: <h1 className="text-white pl-3 w-[150px]">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-4">
            
            <FaEye className="w-6 h-6 text-gray-600 cursor-pointer" onClick={()=>showPreviewModal(row)}/>
            
            <Link to={`/homepage/e-Library/${row._id}`}>
              <FaEdit className="w-5 h-5 text-blue-500 cursor-pointer" />
            </Link>
            <MdDelete
              className="w-6 h-6 text-red-500 cursor-pointer"
              onClick={() => showDeleteConfirm(row._id)}
            />
          </div>
        </>
      ),
    
    },
  ];

  const customStyles = {
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "1px", // override the cell padding for head cells
        paddingRight: "2px",
        backgroundColor: "#105D50",
        color: "#F9FAFB",
        fontWeight: "400",

        // border: '1px solid #105d50',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "rgb(230, 244, 244)",
        borderBottomColor: "#FFFFFF",
        outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        // paddingLeft: "15px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "14px",
        color: "#364353",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.title.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/elibrarybyuniqueid/${localStorage.getItem("Unique_id")}`)
      .then((res) => {
        setItem(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [status]);

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        Title: item.title,
        "Author Name": item.authorName,
        "Library Category": item.LibraryCategory,
      }));
      return csvData;
    }
    return [];
  };



  const [previewData, setPreviewData] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { Text, Title} = Typography;

  const showPreviewModal = (data) => {
    setPreviewData(data);
    setIsPreviewModalVisible(true);
  };

  // Function to hide the preview modal
  const hidePreviewModal = () => {
    setIsPreviewModalVisible(false);
  };

  //for responsive in preview to change font-size
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 640) {
        setFontSize('24px');
      } else {
        setFontSize('14px');
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <div className="flex flex-col  w-full ">
        <div className="W-full h-fit bg-white flex flex-col items-center gap-6 rounded-md pt-5 px-1 drop-shadow">
          <div className="w-full flex flex-row justify-end items-center gap-10 px-4">
            <div className="flex flex-row items-center gap-4">
              <div className="relative">
                <input
                  placeholder="Search"
                  type="text"
                  required
                  className="p-3 w-[250px] text-[14px] bg-gray-100 text-gray-700
          focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        rounded-md "
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                />
                <AiOutlineSearch className="absolute top-3 right-3 text-xl text-gray-400 focus:text-secondary" />
              </div>

              <Link to="/homepage/e-Library/add resource">
                <button
                  className="py-3 px-4 text-[12px] font-sm rounded-md bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 flex flex-row 
          items-center gap-1"
                >
                  <FaPlus className="text-sm font-normal mb-0.5" />
                  Add Resource
                </button>
              </Link>
            </div>

            <div className="flex flex-row items-center gap-6">
              <div class="relative group flex items-center">
                <span class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer">
                  {" "}
                  <img
                    alt="upload"
                    src={upload}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </span>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Upload
                </div>
              </div>
              <div class="relative group flex items-center">
                <CSVLink
                  data={handleCsv()}
                  filename="Elibrary.csv"
                  class="w-10 h-10 group-hover:bg-gray-200 rounded-full duration-500 cursor-pointer"
                >
                  <img
                    alt="download"
                    src={download}
                    className="w-5 h-5 mx-auto mt-2"
                  />
                </CSVLink>
                <div class="tooltip text-[11.5px] hidden group-hover:block bg-gray-600 text-white text-sm px-3 py-0.5 rounded shadow-lg absolute top-[42px] left-4 z-10 transform -translate-x-1/2">
                  Download
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <DataTable className="w-full"
              fixedHeader={true}
              columns={columns}
              data={filteredData}
              selectableRows
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              
              fixedHeaderScrollHeight="358px"
              onChangePage={handlePageChange}
            />
          </div>
        </div>
        <div className='flex justify-end ml-14'>
        <Modal
    //title="Course Preview"
    visible={isPreviewModalVisible}
    onOk={hidePreviewModal}
    onCancel={hidePreviewModal}
    width={600}
    footer={null}
  >
    
    {previewData && (
      <div className="py-4">
          
          <Row gutter={[16, 16]} className="flex flex-col sm:flex-row">
                    <Col span={2.1}>
                    <img src={preview1} className="flex justify-start" ></img>
                    </Col>
                  
      
                      <Col span={6} className="flex flex-col justify-center ">
                      <div className="sm:w-96 w-60 pt-4 pb-1 px-2 bg-primary rounded-full flex flex-col justify-center items-center">
                      <Title level={3} style={{fontSize,color:"white"}} className="capitalize">
                          {previewData.title}
                      </Title>
                      </div>
                      </Col>                                
          </Row>

        <div className="sm:ml-20 my-6 space-y-2">
        <Row gutter={[16, 16]}>
          <Col flex="200px">
            <Text strong className="text-primary">Course Name:</Text>
          </Col>
          <Col flex="auto">
            <Text>{previewData.CourseName}</Text>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col flex="200px">
            <Text strong className="text-primary">Author Name :</Text>
          </Col>
          <Col flex="auto">
            <Text>{previewData.authorName}</Text>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col flex="200px">
            <Text strong className="text-primary">Library Category :</Text>
          </Col>
          <Col flex="auto">
            <Text>{previewData.LibraryCategory}</Text>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col flex="200px">
            <Text strong className="text-primary">Description :</Text>
          </Col>
          <Col flex="auto">
            <Text>{previewData.description}</Text>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col flex="200px">
            <Text strong className="text-primary">External Links :</Text>
          </Col>
          <Col flex="auto">
            <Text>{previewData.externalLinks}</Text>
          </Col>
        </Row>

        </div>
        <Row className="flex justify-end -mt-10" gutter={[16, 16]}>
                  <Col span={6}>
                  <img src={preview2}></img>
                  </Col>
        </Row>
      </div>
    )}
  </Modal>
        </div>
      </div>
    </>
  );
};

export default ELibrary;
