import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Collapse, Select } from "antd";
import axios from "axios";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompress,
  faExpand,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import ReactDocViewer from "react-doc-viewer";
import { FaEye, FaDownload, FaUpload } from "react-icons/fa";

const { Option } = Select;
const { Panel } = Collapse;

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const Subjects = () => {
  const { subjectName } = useParams();
  const { sectionId } = useParams();
  const selectedSectionId = sectionId;
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [topicContent, setTopicContent] = useState([]);
  const [sections, setSections] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const wordsPerPage =2; // Adjust the average number of words per page

  const indexOfLastItem = currentPage * wordsPerPage;
  const indexOfFirstItem = indexOfLastItem - wordsPerPage;
  const currentItems = topicContent.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  console.log("select", selectedSectionId);

  useEffect(() => {
    axios
      .get(`${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections`)
      .then((res) => setSections(res.data.data))
      .catch((err) => {
        if (err) {
          setSections([]);
        }
      });
  }, [subjectName, selectedSectionId]);

  console.log(sections);
  console.log("sectionIds", sectionId);

  const handleTopicSelect = async (sectionId, topicId) => {
    setSelectedSection(sectionId);
    setSelectedTopic(topicId);

    try {
      const response = await fetch(
        `${apiUrl}/${subjectName}/${selectedSectionId}/Collegesections/${sectionId}/topics/${topicId}/contents`
      );
      if (response.ok) {
        const data = await response.json();
        setTopicContent(data);
      } else {
        console.error(`Error fetching topic content: ${response.status}`);
        setTopicContent([]); // Clear content on error
      }
    } catch (error) {
      console.error("Error fetching topic content:", error);
      setTopicContent([]); // Clear content on error
    }

    // Scroll to the selected section when it is expanded
    const selectedSectionElement = document.getElementById(
      `panel_${sectionId}`
    );
    if (selectedSectionElement) {
      selectedSectionElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const openModal = (url) => {
    setIsOpen(true);
    setVideoUrl(url);
  };

  function openFileInNewPage(fileName, imageUrl) {
    window.open(`${imageUrl}/CollegeNotesUploads/${fileName}`, "_blank");
  }

  const whiteBackgroundStyle = {
    backgroundColor: "white",
  };

  const toggleContentFullscreen = () => {
    const contentDiv = document.querySelector(".content-fullscreen");
    if (contentDiv) {
      if (!contentDiv.fullscreenElement) {
        contentDiv.requestFullscreen().then(() => {
          Object.assign(contentDiv.style, whiteBackgroundStyle);
        });
      } else if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          contentDiv.style.backgroundColor = null;
        });
      }
    }
  };

  const downloadAllContent = () => {
    topicContent.forEach((content) => {
      if (content.file) {
        const fileUrl = `${ImageUrl}/CollegeNotesUploads/${content.file}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = fileUrl;
        downloadLink.target = "_blank";
        downloadLink.download = content.file;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    });
  };

  return (
    <div className="flex">
      <div
        className={`p-4 border shadow font-roboto rounded w-full ${
          isFullscreen ? "h-screen" : "h-[500px]"
        } overflow-y-scroll content-fullscreen`}
        style={isFullscreen ? whiteBackgroundStyle : {}}
      >
        <button
          onClick={downloadAllContent}
          className="absolute top-4 right-16 text-gray-700 hover:text-gray-900 focus:outline-none"
        >
          <FaDownload className="text-xl text-primary" />
        </button>
        {topicContent.length > 0 &&
          currentItems.map((content, id) => (
            <Fragment key={id}>
              <div
                className="mb-4 py-2"
                style={{ fontFamily: "Roboto, sans-serif" }}
              >
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: content.body }}
                ></div>
              </div>
              {content.file ? (
                <div className="mb-4 mt-4">
                  {content.file.endsWith(".pdf") ? (
                    <div className="flex font-roboto w-full">
                      <div
                        className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-36 rounded-lg shadow-md flex items-center justify-start "
                      >
                        <div className="flex flex-row justify-start items-center drop-shadow-md font-roboto">
                          <BsFileEarmarkPdfFill className="text-4xl text-primary  border border-primary p-1 rounded-lg mr-2 " />
                          <div className="flex flex-row justify-evenly gap-2 ml-4">
                            <span className="text-base font-medium">
                              {content.file}
                            </span>
                            <p className=" font-semibold text-primary cursor-pointer"  
                            onClick={() =>
                             openFileInNewPage(content.file, ImageUrl)
                              }>
                              Click to view PDF
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : content.file.endsWith(".docx") ? (
                    <div className="flex justify-start w-full">
                      <div
                        className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-36 rounded-lg shadow-md flex items-center justify-start "
                      >
                         <div className="flex flex-row justify-evenly items-center drop-shadow-md font-roboto">
                          <BsFileEarmarkPdfFill className="text-4xl text-primary  border border-primary p-1 rounded-lg mr-2 " />
                          <div className="flex flex-row justify-evenly gap-2 ml-4">
                            <span className="text-base font-medium">
                              {content.file}
                            </span>
                            <p className="text-primary font-semibold cursor-pointer"
                              onClick={() =>
                                openFileInNewPage(content.file, ImageUrl)
                              }
                              >
                              Click to view DOC
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : content.file.endsWith(".pptx") ? (
                    <div className="flex justify-start w-full">
                     <div
                        className="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-4 px-36 rounded-lg shadow-md flex items-center justify-start "
                      >
                        <div className="flex flex-row justify-evenly items-center drop-shadow-md font-roboto">
                          <BsFileEarmarkPdfFill className="text-4xl text-primary  border border-primary p-1 rounded-lg mr-2 " />
                          <div className="flex flex-row justify-evenly gap-2 ml-4">
                            <span className="text-base font-medium">
                              {content.file}
                            </span>
                            <p className="text-primary font-semibold"
                              onClick={() =>
                                openFileInNewPage(content.file, ImageUrl)
                              }
                              >
                              Click to view PPT
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : content.file.endsWith(".mp4") ||
                    content.file.endsWith(".webm") ? (
                    <div className="w-[496px] pb-4 ">
                      <button
                        className="w-full bg-blue-500 hover:bg-blue-600 text-white py-4 px-4 rounded flex items-center justify-center relative"
                        onClick={() =>
                          openModal(
                            `${ImageUrl}/CollegeNotesUploads/${content.file}`
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlay}
                          className="mr-2"
                        />{" "}
                        Open Video
                      </button>
                      <div className="mt-2 text-gray-600 text-sm font-medium">
                        File Name: {content.file}
                      </div>
                    </div>
                  ) : content.file.endsWith(".mp3") ? (
                    <div className="w-full">
                      <div className="bg-blue-100 rounded-lg p-4">
                        <audio
                          controls
                          className="w-full"
                        >
                          <source
                            src={`${ImageUrl}/CollegeNotesUploads/${content.file}`}
                            type="audio/mpeg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </div>
                  ) : content.file.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                    <div className="flex justify-center border rounded-md shadow-md  py-8 w-[496px]">
                      <img
                        src={`${ImageUrl}/CollegeNotesUploads/${content.file}`}
                        alt={content.file}
                        width="200"
                        height="150"
                      />
                    </div>
                  ) : (
                    <div className="w-full bg-white p-4 rounded shadow-lg">
                      <h2 className="text-2xl font-bold text-red-600 mb-4">
                        {content.fileContent}
                      </h2>
                      <p className="mb-4">{content.file}</p>
                      <a
                        href={`${ImageUrl}/CollegeNotesUploads/${content.file}`}
                        target="_blank"
                        className="text-blue-500 hover:underline"
                      >
                        Download File
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <p></p>
              )}
            </Fragment>
          ))}

        <div className="flex justify-center mt-4 bottom-0">
          {Array.from(
            { length: Math.ceil(topicContent.length / wordsPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`mx-2 px-3 py-1 rounded-full ${
                  currentPage === index + 1
                    ? "bg-primary text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
              >
                {index + 1}
              </button>
            )
          )}
        </div>
        <button
          onClick={toggleContentFullscreen}
          className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 focus:outline-none"
        >
          {isFullscreen ? (
            <FontAwesomeIcon icon={faCompress} />
          ) : (
            <FontAwesomeIcon icon={faExpand} />
          )}
        </button>
      </div>

      <div className="rounded w-96 ml-4 flex-row drop-shadow-lg justify-end">
        {sections.length > 0 ? (
          <div>
            <Collapse>
              {sections.map((section) => (
                <Panel
                  header={
                    <strong
                      className={`text-base ${
                        selectedSection === section._id
                          ? "text-secondary"
                          : "text-primary"
                      } font-roboto`}
                    >
                      {section.title}
                    </strong>
                  }
                  key={section.id}
                  forceRender={true}
                  id={`panel_${section._id}`}
                >
                  <div className="overflow-x-auto w-full h-fit">
                    <ul className="whitespace-nowrap w-full flex flex-col gap-1">
                      {section.topics.map((topic, topicIndex) => (
                        <li
                          key={topic._id}
                          onClick={() =>
                            handleTopicSelect(section._id, topic._id)
                          }
                          value={topic.title}
                          className={`cursor-pointer p-2 rounded ${
                            selectedTopic === topic._id
                              ? "bg-blue-100 w-full font-semibold text-primary text-2px font-roboto"
                              : "hover:bg-blue-100 w-full font-semibold text-sm"
                          }`}
                          style={{
                            transition: "background-color 0.3s",
                            borderBottom:
                              topicIndex < section.topics.length - 1
                                ? "1px solid #ddd"
                                : "none",
                          }}
                        >
                          {`${topicIndex + 1}. ${topic.title}`}
                        </li>
                      ))}
                    </ul>
                  </div>
                </Panel>
              ))}
            </Collapse>
          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>

      <ModalVideo
        channel="custom"
        isOpen={isOpen}
        url={videoUrl}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
};

export default Subjects;
