import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";

const apiUrl = process.env.REACT_APP_API_URL;

const SubjectForm = () => {
  const LMS_ROLE = localStorage.getItem("lms_role");

  const [editMode, setEditMode] = useState(false);
  const [faculties, setFaculties] = useState([]);
  const [selectedFacultyIds, setSelectedFacultyIds] = useState([]);
  const [selectedFacultyNames, setSelectedFacultyNames] = useState([]);

  const { id } = useParams();

  const initialFormData = {
    subjectName: "",
    subjectId: "",
    subjectCode: "",
    facultyName: "",
    facultyId: "",

    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };
  const [formData, setFormData] = useState(initialFormData);

  const navigate = useNavigate();

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const [selectFaculty, setSelectFaculty] = useState([]);
  const [getFaculty, setGetFaculty] = useState({
    facultyname: [],
  });

  console.log("selected faculty", selectFaculty);



  const handleSubmit = async (event) => {
    event.preventDefault();

    const formSendData = {
      subjectName: formData.subjectName,
      subjectId: formData.subjectId,
      subjectCode: formData.subjectCode,
      facultyName: selectFaculty,
      facultyNameList: selectFaculty.map((faculty) => faculty.name).join(", "),
      facultyId: selectFaculty.map((faculty) => faculty.id).join(", "), // Combine IDs into a comma-separated string
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

    };
    console.log("data", formSendData);

    if (editMode) {
      axios
        .put(`${apiUrl}/collegesubject/${id}`, formSendData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/subject");
          }
        })
        .catch((error) => {
          if (error.response.data.status === 404) {
            toast.warn(error.response.data.message);
          }
        });
    } else {
      axios
        .post(`${apiUrl}/collegesubject`, formSendData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            navigate("/homepage/subject");
          }
        })
        .catch((error) => {
          if (error.response.data.status === 404) {
            toast.warn(error.response.data.message);
          }
        });
    }
  };
  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    const formSendData = {
      subjectName: formData.subjectName,
      subjectId: formData.subjectId,
      subjectCode: formData.subjectCode,
      facultyName: selectFaculty,
      facultyNameList: selectFaculty.map((faculty) => faculty.name).join(", "),
      facultyId: selectFaculty.map((faculty) => faculty.id).join(", "), // Combine IDs into a comma-separated string
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

    };
    console.log("data", formSendData);

    if (editMode) {
      axios
        .put(`${apiUrl}/collegesubject/${id}`, formSendData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            setFormData(initialFormData);
            setGetFaculty({ facultyname: [] });
            setSelectFaculty([]);
            
          }
        })
        .catch((error) => {
          if (error.response.data.status === 404) {
            toast.warn(error.response.data.message);
          }
        });
    } else {
      axios
        .post(`${apiUrl}/collegesubject`, formSendData)
        .then((res) => {
          if (res.data.status === 301) {
            toast.warn(res.data.message);
          } else if (res.status === 200) {
            toast.success(res.data.message);
            setFormData(initialFormData);
            setGetFaculty({ facultyname: [] });
          setSelectFaculty([]);
          }
        })
        .catch((error) => {
          if (error.response.data.status === 404) {
            toast.warn(error.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({ ...formData });
      console.log({ ...formData }, "edit");
      // Initialize selected faculties based on existing data or set it to an empty array
      setSelectedFacultyIds(
        formData.facultyId ? formData.facultyId.split(", ") : []
      );
      setSelectedFacultyNames(
        formData.facultyName ? formData.facultyName.split(", ") : []
      );
    }
  }, [id]);

  const getFormData = async (id) => {
    try {
      const response = await axios.get(`${apiUrl}/collegesubject/${id}`);

      if (response.status === 200) {
        const subjectData = response.data.data[0];

        setFormData({ ...subjectData });

        subjectData.facultyName.forEach(element => {

          setSelectFaculty((prev) => [
            ...prev,
            { name: element.name, id: element.id },
          ]);
  
        });

        // Initialize selected faculties based on fetched data
        if (subjectData && subjectData.facultyId) {
          setSelectedFacultyIds(subjectData.facultyId.split(", "));
          setSelectedFacultyNames(subjectData.facultyName.split(", "));
        } else {
          setSelectedFacultyIds([]);
          setSelectedFacultyNames([]);
        }
      } else {
        console.log("Failed to fetch subject data");
      }
    } catch (error) {
      console.error("Error fetching subject data:", error);
    }
  };

  const handleReset = (e) => {
    setFormData(initialFormData);
    setGetFaculty({ facultyname: [] });
    setSelectFaculty([]);
  };

  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegefacultybyinstituteuniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch faculty");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Faculty name not found");
          }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyuniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch faculty");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Faculty name not found");
          }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyuniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setFaculties(response.data.data);
          } else {
            console.log("Failed to fetch faculty");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          if (error.response.data.status === 404) {
            toast.info("Faculty name not found");
          }
        });
    }
  }, []);

  const handleCheckboxChange = (e, faculty) => {
    console.log("trigger", faculty, e.target.checked);

    const selectedFacultyName = faculty.facultyName;
    const selectedFacultyId = faculty.facultyId;

    if (e.target.checked) {
      setSelectFaculty((prev) => [
        ...prev,
        { name: selectedFacultyName, id: selectedFacultyId },
      ]);
     
    } else {
      setSelectFaculty((prev) =>
        prev.filter(
          (item) =>
            item.id !== selectedFacultyId
        )
      );
     
    }
  };

  return (
    <div className="mb-4 flex justify-between items-center top-0">
      <div className="w-full h-[500px] bg-white px-16 py-[65px] rounded-lg drop-shadow border ">
        {/* Add Branch Form */}
        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Subject Name"
                  variant="outlined"
                  name="subjectName"
                  fullWidth
                  value={formData.subjectName}
                  onChange={handleOnChange}
                  required
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Subject Code"
                  variant="outlined"
                  name="subjectCode"
                  fullWidth
                  value={formData.subjectCode}
                  onChange={handleOnChange}
                  required
                />
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Subject ID"
                  variant="outlined"
                  fullWidth
                  name="subjectId"
                  type="text"
                  value={formData.subjectId}
                  onChange={handleOnChange}
                  required
                />

                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Faculty ID"
                    variant="outlined"
                    name="facultyId"
                    fullWidth
                    value={selectFaculty
                      .map((faculty) => faculty.id)
                      .join(", ")}
                    // onChange={handleOnChange}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Faculty ID"
                    variant="outlined"
                    name="facultyId"
                    fullWidth
                    value={selectFaculty
                      .map((faculty) => faculty.id)
                      .join(", ")}
                  />
                )}
              </Stack>
            </Stack>
            <Stack>

              {
                editMode ? 
                (
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
  
                    "& .MuiInputBase-root": {
                      height: 45,
                      width: 575,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Faculty Name"
                  variant="outlined"
                  fullWidth
                  name="facultyName"
                  type="text"
                  select
                  SelectProps={{
                    multiple: true, // Allow multiple selections
                    renderValue: (selected) => selected.join(", "),
                    // Display selected options as comma-separated values
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: "160px", // Set the maximum height for the dropdown
                        },
                      },
                    },
                  }}
                  value={selectFaculty.map(s => s.name) || []}
                  // onChange={
                  //   (e) =>
                  //     setGetFaculty({
                  //       ...getFaculty,
                  //       facultyname: e.target.value,
                  //     }) // Call the course change handler
                  // }
                  required
                >
                  {faculties.map((faculty) => (
                    <MenuItem
                      key={faculty._id}
                      value={faculty.facultyName}
                      style={{
                        fontSize: "14px",
                        height: "40px",
                        color: "#105d50",
                      }}
                    >
                      <Checkbox
                       checked={selectFaculty.some(s => s.id ===
                        faculty.facultyId
                      )}
                        onChange={(e) => handleCheckboxChange(e, faculty)}
                        style={{
                          color: "#105D50",
                          width: 36,
                          height: 36,
                        }}
                      />
                      {faculty.facultyName}
                    </MenuItem>
                  ))}
                </TextField>) : 
                (<TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 45,
                    width: 575,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                }}
                label="Faculty Name"
                variant="outlined"
                fullWidth
                name="facultyName"
                type="text"
                select
                SelectProps={{
                  multiple: true, // Allow multiple selections
                  renderValue: (selected) => selected.join(", "),
                  // Display selected options as comma-separated values
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "160px", // Set the maximum height for the dropdown
                      },
                    },
                  },
                }}
                value={selectFaculty.map(s => s.name) || []}
                // onChange={
                //   (e) =>
                //     setGetFaculty({
                //       ...getFaculty,
                //       facultyname: e.target.value,
                //     }) // Call the course change handler
                // }
                required
              >
                {faculties.map((faculty) => (
                  faculty.status === 1 ? 
                  <MenuItem
                    key={faculty._id}
                    value={faculty.facultyName}
                    style={{
                      fontSize: "14px",
                      height: "40px",
                      color: "#105d50",
                    }}
                  >
                    <Checkbox
                      checked={selectFaculty.some(s => s.id ===
                        faculty.facultyId
                      )}
                      onChange={(e) => handleCheckboxChange(e, faculty)}
                      style={{
                        color: "#105D50",
                        width: 36,
                        height: 36,
                      }}
                    />
                    {faculty.facultyName}
                  </MenuItem> : 
                  null
                ))}
              </TextField>)
              }
            
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-4 pb-2">
              {!editMode && (
                <button
                  type="button"
                  onClick={handleReset}
                  className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Reset
                </button>
              )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default SubjectForm;
