import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import camera from "../Images/camera.png";
import { IconButton, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../App.css";
//import Chip from '@mui/material/Chip';
import Checkbox from "@mui/material/Checkbox";
import moment from 'moment';

const apiUrl = process.env.REACT_APP_API_URL;
const ImageUrl = process.env.REACT_APP_IMAGE_URL;

const FacultyForm = () => {
  const LMS_ROLE = localStorage.getItem("lms_role");
  const [editMode, setEditMode] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  // const [profile, setProfile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [dob, setDob] = useState(null);
  const [yoj, setYoj] = useState(null);
  const [facultyImage, setFacultyImage] = useState(null);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedSubjectNames, setSelectedSubjectNames] = useState([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [subjects, setSubjects] = useState([]); // Replace this with your actual subject data
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");

  const countries = {
    India: {
      states: [
        {
          name: "Andaman and Nicobar Islands",
          districts: ["Nicobar", "North and Middle Andaman", "South Andaman"],
        },
        {
          name: "Andhra Pradesh",
          districts: [
            "Anantapur",
            "Chittoor",
            "East Godavari",
            "Guntur",
            "Krishna",
            "Kurnool",
            "Prakasam",
            "Srikakulam",
            "Visakhapatnam",
            "Vizianagaram",
            "West Godavari",
            "Y.S.R.",
          ],
        },
        {
          name: "Arunachal Pradesh",
          districts: [
            "Anjaw",
            "Changlang",
            "Dibang Valley",
            "East Kameng",
            "East Siang",
            "Kamle",
            "Kra Daadi",
            "Kurung Kumey",
            "Lepa Rada",
            "Lohit",
            "Longding",
            "Lower Dibang Valley",
            "Lower Siang",
            "Lower Subansiri",
            "Namsai",
            "Pakke-Kessang",
            "Papum Pare",
            "Shi Yomi",
            "Siang",
            "Tawang",
            "Tirap",
            "Upper Siang",
            "Upper Subansiri",
            "West Kameng",
            "West Siang",
          ],
        },
        {
          name: "Assam",
          districts: [
            "Baksa",
            "Barpeta",
            "Biswanath",
            "Bongaigaon",
            "Cachar",
            "Charaideo",
            "Chirang",
            "Darrang",
            "Dhemaji",
            "Dhubri",
            "Dibrugarh",
            "Dima Hasao",
            "Goalpara",
            "Golaghat",
            "Hailakandi",
            "Hojai",
            "Jorhat",
            "Kamrup",
            "Kamrup Metropolitan",
            "Karbi Anglong",
            "Karimganj",
            "Kokrajhar",
            "Lakhimpur",
            "Majuli",
            "Morigaon",
            "Nagaon",
            "Nalbari",
            "Sivasagar",
            "Sonitpur",
            "South Salmara-Mankachar",
            "Tinsukia",
            "Udalguri",
            "West Karbi Anglong",
          ],
        },
        {
          name: "Bihar",
          districts: [
            "Araria",
            "Arwal",
            "Aurangabad",
            "Banka",
            "Begusarai",
            "Bhagalpur",
            "Bhojpur",
            "Buxar",
            "Darbhanga",
            "East Champaran",
            "Gaya",
            "Gopalganj",
            "Jamui",
            "Jehanabad",
            "Kaimur",
            "Katihar",
            "Khagaria",
            "Kishanganj",
            "Lakhisarai",
            "Madhepura",
            "Madhubani",
            "Munger",
            "Muzaffarpur",
            "Nalanda",
            "Nawada",
            "Patna",
            "Purnia",
            "Rohtas",
            "Saharsa",
            "Samastipur",
            "Saran",
            "Sheikhpura",
            "Sheohar",
            "Sitamarhi",
            "Siwan",
            "Supaul",
            "Vaishali",
            "West Champaran",
          ],
        },
        {
          name: "Chandigarh",
          districts: ["Chandigarh"],
        },
        {
          name: "Chhattisgarh",
          districts: [
            "Balod",
            "Baloda Bazar",
            "Balrampur",
            "Bastar",
            "Bemetara",
            "Bijapur",
            "Bilaspur",
            "Dantewada",
            "Dhamtari",
            "Durg",
            "Gariaband",
            "Janjgir-Champa",
            "Jashpur",
            "Kanker",
            "Kabirdham",
            "Kondagaon",
            "Korba",
            "Koriya",
            "Mahasamund",
            "Mungeli",
            "Narayanpur",
            "Raigarh",
            "Raipur",
            "Rajnandgaon",
            "Sukma",
            "Surajpur",
            "Surguja",
          ],
        },
        {
          name: "Dadra and Nagar Haveli and Daman and Diu",
          districts: ["Dadra and Nagar Haveli", "Daman", "Diu"],
        },
        {
          name: "Delhi",
          districts: [
            "Central Delhi",
            "East Delhi",
            "New Delhi",
            "North Delhi",
            "North East Delhi",
            "North West Delhi",
            "Shahdara",
            "South Delhi",
            "South East Delhi",
            "South West Delhi",
            "West Delhi",
          ],
        },
        {
          name: "Goa",
          districts: ["North Goa", "South Goa"],
        },
        {
          name: "Gujarat",
          districts: [
            "Ahmedabad",
            "Amreli",
            "Anand",
            "Aravalli",
            "Banaskantha",
            "Bharuch",
            "Bhavnagar",
            "Botad",
            "Chhota Udaipur",
            "Dahod",
            "Dang",
            "Devbhoomi Dwarka",
            "Gandhinagar",
            "Gir Somnath",
            "Jamnagar",
            "Junagadh",
            "Kheda",
            "Kutch",
            "Mahisagar",
            "Mehsana",
            "Morbi",
            "Narmada",
            "Navsari",
            "Panchmahal",
            "Patan",
            "Porbandar",
            "Rajkot",
            "Sabarkantha",
            "Surat",
            "Surendranagar",
            "Tapi",
            "Vadodara",
            "Valsad",
          ],
        },
        {
          name: "Haryana",
          districts: [
            "Ambala",
            "Bhiwani",
            "Charkhi Dadri",
            "Faridabad",
            "Fatehabad",
            "Gurugram",
            "Hisar",
            "Jhajjar",
            "Jind",
            "Kaithal",
            "Karnal",
            "Kurukshetra",
            "Mahendragarh",
            "Nuh",
            "Palwal",
            "Panchkula",
            "Panipat",
            "Rewari",
            "Rohtak",
            "Sirsa",
            "Sonipat",
            "Yamunanagar",
          ],
        },
        {
          name: "Himachal Pradesh",
          districts: [
            "Bilaspur",
            "Chamba",
            "Hamirpur",
            "Kangra",
            "Kinnaur",
            "Kullu",
            "Lahaul and Spiti",
            "Mandi",
            "Shimla",
            "Sirmaur",
            "Solan",
            "Una",
          ],
        },
        {
          name: "Jharkhand",
          districts: [
            "Bokaro",
            "Chatra",
            "Deoghar",
            "Dhanbad",
            "Dumka",
            "East Singhbhum",
            "Garhwa",
            "Giridih",
            "Godda",
            "Gumla",
            "Hazaribagh",
            "Jamtara",
            "Khunti",
            "Koderma",
            "Latehar",
            "Lohardaga",
            "Pakur",
            "Palamu",
            "Ramgarh",
            "Ranchi",
            "Sahibganj",
            "Seraikela-Kharsawan",
            "Simdega",
            "West Singhbhum",
          ],
        },
        {
          name: "Karnataka",
          districts: [
            "Bagalkot",
            "Ballari",
            "Belagavi",
            "Bengaluru Rural",
            "Bengaluru Urban",
            "Bidar",
            "Chamarajanagar",
            "Chikballapur",
            "Chikmagalur",
            "Chitradurga",
            "Dakshina Kannada",
            "Davanagere",
            "Dharwad",
            "Gadag",
            "Hassan",
            "Haveri",
            "Kalaburagi",
            "Kodagu",
            "Kolar",
            "Koppal",
            "Mandya",
            "Mysuru",
            "Raichur",
            "Ramanagara",
            "Shivamogga",
            "Tumakuru",
            "Udupi",
            "Uttara Kannada",
            "Vijayapura",
            "Yadgir",
          ],
        },
        {
          name: "Kerala",
          districts: [
            "Alappuzha",
            "Ernakulam",
            "Idukki",
            "Kannur",
            "Kasaragod",
            "Kollam",
            "Kottayam",
            "Kozhikode",
            "Malappuram",
            "Palakkad",
            "Pathanamthitta",
            "Thiruvananthapuram",
            "Thrissur",
            "Wayanad",
          ],
        },
        {
          name: "Ladakh",
          districts: ["Kargil", "Leh"],
        },
        {
          name: "Lakshadweep",
          districts: ["Lakshadweep"],
        },
        {
          name: "Madhya Pradesh",
          districts: [
            "Agar Malwa",
            "Alirajpur",
            "Anuppur",
            "Ashoknagar",
            "Balaghat",
            "Barwani",
            "Betul",
            "Bhind",
            "Bhopal",
            "Burhanpur",
            "Chhatarpur",
            "Chhindwara",
            "Damoh",
            "Datia",
            "Dewas",
            "Dhar",
            "Dindori",
            "Guna",
            "Gwalior",
            "Harda",
            "Hoshangabad",
            "Indore",
            "Jabalpur",
            "Jhabua",
            "Katni",
            "Khandwa",
            "Khargone",
            "Mandla",
            "Mandsaur",
            "Morena",
            "Narsinghpur",
            "Neemuch",
            "Panna",
            "Raisen",
            "Rajgarh",
            "Ratlam",
            "Rewa",
            "Sagar",
            "Satna",
            "Sehore",
            "Seoni",
            "Shahdol",
            "Shajapur",
            "Sheopur",
            "Shivpuri",
            "Sidhi",
            "Singrauli",
            "Tikamgarh",
            "Ujjain",
            "Umaria",
            "Vidisha",
          ],
        },
        {
          name: "Maharashtra",
          districts: [
            "Ahmednagar",
            "Akola",
            "Amravati",
            "Aurangabad",
            "Beed",
            "Bhandara",
            "Buldhana",
            "Chandrapur",
            "Dhule",
            "Gadchiroli",
            "Gondia",
            "Hingoli",
            "Jalgaon",
            "Jalna",
            "Kolhapur",
            "Latur",
            "Mumbai City",
            "Mumbai Suburban",
            "Nagpur",
            "Nanded",
            "Nandurbar",
            "Nashik",
            "Osmanabad",
            "Palghar",
            "Parbhani",
            "Pune",
            "Raigad",
            "Ratnagiri",
            "Sangli",
            "Satara",
            "Sindhudurg",
            "Solapur",
            "Thane",
            "Wardha",
            "Washim",
            "Yavatmal",
          ],
        },
        {
          name: "Manipur",
          districts: [
            "Bishnupur",
            "Chandel",
            "Churachandpur",
            "Imphal East",
            "Imphal West",
            "Jiribam",
            "Kakching",
            "Kamjong",
            "Kangpokpi",
            "Noney",
            "Pherzawl",
            "Senapati",
            "Tamenglong",
            "Tengnoupal",
            "Thoubal",
            "Ukhrul",
          ],
        },
        {
          name: "Meghalaya",
          districts: [
            "East Garo Hills",
            "East Jaintia Hills",
            "East Khasi Hills",
            "North Garo Hills",
            "Ri Bhoi",
            "South Garo Hills",
            "South West Garo Hills",
            "South West Khasi Hills",
            "West Garo Hills",
            "West Jaintia Hills",
            "West Khasi Hills",
          ],
        },
        {
          name: "Mizoram",
          districts: [
            "Aizawl",
            "Champhai",
            "Hnahthial",
            "Khawzawl",
            "Kolasib",
            "Lawngtlai",
            "Lunglei",
            "Mamit",
            "Saiha",
            "Saitual",
            "Serchhip",
          ],
        },
        {
          name: "Nagaland",
          districts: [
            "Dimapur",
            "Kiphire",
            "Kohima",
            "Longleng",
            "Mokokchung",
            "Mon",
            "Peren",
            "Phek",
            "Tuensang",
            "Wokha",
            "Zunheboto",
          ],
        },
        {
          name: "Odisha",
          districts: [
            "Angul",
            "Balangir",
            "Balasore",
            "Bargarh",
            "Bhadrak",
            "Boudh",
            "Cuttack",
            "Deogarh",
            "Dhenkanal",
            "Gajapati",
            "Ganjam",
            "Jagatsinghpur",
            "Jajpur",
            "Jharsuguda",
            "Kalahandi",
            "Kandhamal",
            "Kendrapara",
            "Kendujhar",
            "Khordha",
            "Koraput",
            "Malkangiri",
            "Mayurbhanj",
            "Nabarangpur",
            "Nayagarh",
            "Nuapada",
            "Puri",
            "Rayagada",
            "Sambalpur",
            "Subarnapur",
            "Sundargarh",
          ],
        },
        {
          name: "Puducherry",
          districts: ["Karaikal", "Mahe", "Puducherry", "Yanam"],
        },
        {
          name: "Punjab",
          districts: [
            "Amritsar",
            "Barnala",
            "Bathinda",
            "Faridkot",
            "Fatehgarh Sahib",
            "Fazilka",
            "Ferozepur",
            "Gurdaspur",
            "Hoshiarpur",
            "Jalandhar",
            "Kapurthala",
            "Ludhiana",
            "Mansa",
            "Moga",
            "Pathankot",
            "Patiala",
            "Rupnagar",
            "Sangrur",
            "Shaheed Bhagat Singh Nagar",
            "Sri Muktsar Sahib",
            "Tarn Taran",
          ],
        },
        {
          name: "Rajasthan",
          districts: [
            "Ajmer",
            "Alwar",
            "Banswara",
            "Baran",
            "Barmer",
            "Bharatpur",
            "Bhilwara",
            "Bikaner",
            "Bundi",
            "Chittorgarh",
            "Churu",
            "Dausa",
            "Dholpur",
            "Dungarpur",
            "Hanumangarh",
            "Jaipur",
            "Jaisalmer",
            "Jalore",
            "Jhalawar",
            "Jhunjhunu",
            "Jodhpur",
            "Karauli",
            "Kota",
            "Nagaur",
            "Pali",
            "Pratapgarh",
            "Rajsamand",
            "Sawai Madhopur",
            "Sikar",
            "Sirohi",
            "Sri Ganganagar",
            "Tonk",
            "Udaipur",
          ],
        },
        {
          name: "Sikkim",
          districts: [
            "East Sikkim",
            "North Sikkim",
            "South Sikkim",
            "West Sikkim",
          ],
        },
        {
          name: "Tamil Nadu",
          districts: [
            "Ariyalur",
            "Chengalpattu",
            "Chennai",
            "Coimbatore",
            "Cuddalore",
            "Dharmapuri",
            "Dindigul",
            "Erode",
            "Kallakurichi",
            "Kanchipuram",
            "Kanyakumari",
            "Karur",
            "Krishnagiri",
            "Madurai",
            "Nagapattinam",
            "Namakkal",
            "Nilgiris",
            "Perambalur",
            "Pudukkottai",
            "Ramanathapuram",
            "Ranipet",
            "Salem",
            "Sivaganga",
            "Tenkasi",
            "Thanjavur",
            "Theni",
            "Thoothukudi",
            "Tiruchirapalli",
            "Tirunelveli",
            "Tirupathur",
            "Tiruppur",
            "Tiruvallur",
            "Tiruvannamalai",
            "Vellore",
            "Viluppuram",
            "Virudhunagar",
          ],
        },
        {
          name: "Telangana",
          districts: [
            "Bhadradri Kothagudem",
            "Hyderabad",
            "Jagtial",
            "Jangaon",
            "Jayashankar Bhupalpally",
            "Jogulamba Gadwal",
            "Kamareddy",
            "Karimnagar",
            "Khammam",
            "Komaram Bheem Asifabad",
            "Mahabubabad",
            "Mahabubnagar",
            "Mancherial",
            "Medak",
            "Medchal-Malkajgiri",
            "Nagarkurnool",
            "Nalgonda",
            "Nirmal",
            "Nizamabad",
            "Peddapalli",
            "Rajanna Sircilla",
            "Rangareddy",
            "Sangareddy",
            "Siddipet",
            "Suryapet",
            "Vikarabad",
            "Wanaparthy",
            "Warangal",
            "Yadadri Bhuvanagiri",
          ],
        },
        {
          name: "Tripura",
          districts: [
            "Dhalai",
            "Gomati",
            "Khowai",
            "North Tripura",
            "Sepahijala",
            "South Tripura",
            "Unakoti",
            "West Tripura",
          ],
        },
        {
          name: "Uttar Pradesh",
          districts: [
            "Agra",
            "Aligarh",
            "Ambedkar Nagar",
            "Amethi",
            "Amroha",
            "Auraiya",
            "Ayodhya",
            "Azamgarh",
            "Baghpat",
            "Bahraich",
            "Ballia",
            "Balrampur",
            "Banda",
            "Barabanki",
            "Bareilly",
            "Basti",
            "Bhadohi",
            "Bijnor",
            "Budaun",
            "Bulandshahr",
            "Chandauli",
            "Chitrakoot",
            "Deoria",
            "Etah",
            "Etawah",
            "Farrukhabad",
            "Fatehpur",
            "Firozabad",
            "Gautam Buddh Nagar",
            "Ghaziabad",
            "Ghazipur",
            "Gonda",
            "Gorakhpur",
            "Hamirpur",
            "Hapur",
            "Hardoi",
            "Hathras",
            "Jalaun",
            "Jaunpur",
            "Jhansi",
            "Kannauj",
            "Kanpur Dehat",
            "Kanpur Nagar",
            "Kasganj",
            "Kaushambi",
            "Kushinagar",
            "Lakhimpur Kheri",
            "Lalitpur",
            "Lucknow",
            "Mahoba",
            "Maharajganj",
            "Mainpuri",
            "Mathura",
            "Mau",
            "Meerut",
            "Mirzapur",
            "Moradabad",
            "Muzaffarnagar",
            "Pilibhit",
            "Pratapgarh",
            "Prayagraj",
            "Raebareli",
            "Rampur",
            "Saharanpur",
            "Sambhal",
            "Sant Kabir Nagar",
            "Shahjahanpur",
            "Shamli",
            "Shravasti",
            "Siddharthnagar",
            "Sitapur",
            "Sonbhadra",
            "Sultanpur",
            "Unnao",
            "Varanasi",
          ],
        },
        {
          name: "Uttarakhand",
          districts: [
            "Almora",
            "Bageshwar",
            "Chamoli",
            "Champawat",
            "Dehradun",
            "Haridwar",
            "Nainital",
            "Pauri Garhwal",
            "Pithoragarh",
            "Rudraprayag",
            "Tehri Garhwal",
            "Udham Singh Nagar",
            "Uttarkashi",
          ],
        },
        {
          name: "West Bengal",
          districts: [
            "Alipurduar",
            "Bankura",
            "Birbhum",
            "Cooch Behar",
            "Dakshin Dinajpur",
            "Darjeeling",
            "Hooghly",
            "Howrah",
            "Jalpaiguri",
            "Jhargram",
            "Kalimpong",
            "Kolkata",
            "Malda",
            "Murshidabad",
            "Nadia",
            "North 24 Parganas",
            "Paschim Bardhaman",
            "Purba Bardhaman",
            "Purba Medinipur",
            "Purulia",
            "South 24 Parganas",
            "Uttar Dinajpur",
          ],
        },
      ],
    },
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState("");
    setSelectedDistrict("");
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedDistrict("");
  };

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  const countryOptions = Object.keys(countries).map((country) => (
    <MenuItem key={country} value={country}>
      {country}
    </MenuItem>
  ));

  const stateOptions = selectedCountry
    ? countries[selectedCountry].states.map((state) => (
        <MenuItem key={state.name} value={state.name}>
          {state.name}
        </MenuItem>
      ))
    : [];

  const districtOptions = selectedState
    ? countries[selectedCountry].states
        .find((state) => state.name === selectedState)
        .districts.map((district) => (
          <MenuItem key={district} value={district}>
            {district}
          </MenuItem>
        ))
    : [];

  const initialFormData = {
    uploadProfile: null,
    facultyName: "", // Define these variables
    facultyId: "",
    employeecode: "",
    handlingSubjects: "",
    qualification: "",
    gender: "",
    emailId: "",
    mobileNumber: "",
    addressLine1: "",
    addressLine2: "",
    area: "",
    country: "",
    state: "",
    district: "",
    pincode: "",
    dob: "null",
    yoj: "null",
    description: "",
    password: "",
    bloodGroup: "",
    UniqueId: localStorage.getItem("Unique_id"),
    instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        : localStorage.getItem("institute_Unique_Id"),
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleOnChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setInputValue(e.target.value);
  };

  console.log(formData.uploadProfile, facultyImage);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formAppendData = new FormData();
    formAppendData.append("uploadProfile", facultyImage);
    formAppendData.append("facultyId", formData.facultyId);
    formAppendData.append("facultyName", formData.facultyName);
    formAppendData.append("employeecode", formData.employeecode);
    formAppendData.append("handlingSubjects", formData.handlingSubjects);
    formAppendData.append("qualification", formData.qualification);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("emailId", formData.emailId.toLowerCase());
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("addressLine2", formData.addressLine2);
    formAppendData.append("area", formData.area);
    formAppendData.append("pincode", formData.pincode);
    formAppendData.append("country",selectedCountry);
    formAppendData.append("state", selectedState);
    formAppendData.append("district", selectedDistrict);
    formAppendData.append("gender", formData.gender);
    formAppendData.append("dob", formData.dob);
    formAppendData.append("yoj", formData.yoj);
    formAppendData.append("description", formData.description);
    formAppendData.append("password", formData.password);
    formAppendData.append("bloodGroup", formData.bloodGroup);
    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
    );

    // Create the Axios config object with the headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (formData.uploadProfile === null && facultyImage === null) {
      toast.warn("Please upload your profile");
    } else if (facultyImage !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegefaculty/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/faculty");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegefaculty`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/faculty");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    } else if (formData.uploadProfile !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegefaculty/${id}`, formData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/faculty");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegefaculty`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              navigate("/homepage/faculty");
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };
  console.log( LMS_ROLE === "3" || LMS_ROLE === "5"
  ? localStorage.getItem("Unique_id")
  :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id"));

  const handleSubmitandAdd = async (event) => {
    event.preventDefault();

    const formAppendData = new FormData();
    formAppendData.append("uploadProfile", facultyImage);
    formAppendData.append("facultyId", formData.facultyId);
    formAppendData.append("facultyName", formData.facultyName);
    formAppendData.append("employeecode", formData.employeecode);
    formAppendData.append("handlingSubjects", formData.handlingSubjects);
    formAppendData.append("qualification", formData.qualification);
    formAppendData.append("mobileNumber", formData.mobileNumber);
    formAppendData.append("emailId", formData.emailId.toLowerCase());
    formAppendData.append("gender", formData.gender);
    formAppendData.append("dob", formData.dob);
    formAppendData.append("yoj", formData.yoj);
    formAppendData.append("description", formData.description);
    formAppendData.append("password", formData.password);
    formAppendData.append("bloodGroup", formData.bloodGroup);
    formAppendData.append("addressLine1", formData.addressLine1);
    formAppendData.append("area", formData.area);
    formAppendData.append("pincode", formData.pincode);
    formAppendData.append("country",selectedCountry);
    formAppendData.append("state", selectedState);
    formAppendData.append("district", selectedDistrict);
    formAppendData.append("UniqueId", localStorage.getItem("Unique_id"));
    formAppendData.append(
      "instituteUniqueId",
      LMS_ROLE === "3" || LMS_ROLE === "5"
        ? localStorage.getItem("Unique_id")
        :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")
    );

    const formSendData = {
      uploadProfile: formData.uploadProfile,
      facultyId: formData.facultyId,
      facultyName: formData.facultyName,
      employeecode: formData.employeecode,
      handlingSubjects: formData.handlingSubjects,
      qualification:formData.qualification,
      mobileNumber: formData.mobileNumber,
      gender:formData.gender,
      emailId: formData.emailId.toLowerCase(),
      addressLine1: formData.addressLine1,
      area: formData.area,
      dob:formData.dob,
      yoj:formData.yoj,
      pincode: formData.pincode,
      country: selectedCountry,
      state: selectedState,
      district: selectedDistrict,
      password: formData.password,
      UniqueId: localStorage.getItem("Unique_id"),
      instituteUniqueId:
      LMS_ROLE === "3" || LMS_ROLE === "5"
      ? localStorage.getItem("Unique_id")
      :  LMS_ROLE === "6" ? localStorage.getItem("branch_Unique_Id") : localStorage.getItem("institute_Unique_Id")

    };

    // Create the Axios config object with the headers
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (formData.uploadProfile === null && facultyImage === null) {
      toast.warn("Please upload your profile");
    } else if (facultyImage !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegefaculty/${id}`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
                setDob(null);
        setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
            
          });
      } else {
        axios
          .post(`${apiUrl}/collegefaculty`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setDob(null);
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
            
          });
      }
    } else if (formData.uploadProfile !== null) {
      if (editMode) {
        axios
          .put(`${apiUrl}/collegefaculty/${id}`, formSendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setDob(null);
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      } else {
        axios
          .post(`${apiUrl}/collegefaculty`, formAppendData, config)
          .then((res) => {
            if (res.data.status === 301) {
              toast.warn(res.data.message);
            } else if (res.status === 200) {
              toast.success(res.data.message);
              setFormData(initialFormData);
              setDob(null);
              setYoj(null);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setFormData({ ...formData });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/collegefaculty/${id}`);

    console.log(formData.data.data);

    if (formData.status === 200) {
      setFormData({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  const handleImageUpload = (e) => {
    const uploadProfile = e.target.files[0];

    // Check if a file was selected
    if (uploadProfile) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // Set the image URL to the reader's result, which is the base64 data URL of the uploaded image
        setImageUrl(e.target.result);
        console.log(uploadProfile);
      };

      reader.readAsDataURL(uploadProfile);
      setFacultyImage(uploadProfile);
    }
  };

  const handleReset = () => {

    setFormData(initialFormData);
  setImageUrl("");
  setSelectedCountry("");
  setSelectedState("");
  setSelectedDistrict("");
  setDob(null); // Reset Date of Birth
    setYoj(null); 
  };


  const handleDobChange = (date) => {
    setDob(date);
    setFormData({ ...formData, dob: date ? date.format("DD/MM/YYYY") : "" });
  };
  const handleDojChange = (date) => {
    setYoj(date);
    setFormData({ ...formData, yoj: date ? date.format("DD/MM/YYYY") : "" });
  };

  
  // const handleOnChange = (event) => {
  //   setInputValue(event.target.value);
  // };

  //  const handleKeyDown = (event) => {
  //   if (event.key === 'Enter' && inputValue.trim() !== '') {
  //     // Update formData with the entered tag
  //     setTags([...tags, inputValue.trim()]);
  //     setFormData((prev) => ({
  //       ...prev,
  //       handlingSubjects: JSON.stringify([...tags, inputValue.trim()]),
  //     }));
  //     setInputValue('');
  //   }
  // };

  // const handleDelete = (tagToDelete) => () => {
  //   setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  // };
  useEffect(() => {
    if (LMS_ROLE === "3" || LMS_ROLE === "5") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyinstitutquniqueid/${localStorage.getItem(
            "Unique_id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          // if (error.response.data.status === 404) {
          //   toast.info("Course name not found");
          // }
        });
    } else if (LMS_ROLE === "6") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyinstitutquniqueid/${localStorage.getItem(
            "branch_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          // if (error.response.data.status === 404) {
          //   toast.info("Course name not found");
          // }
        });
    } else if (LMS_ROLE === "4") {
      axios
        .get(
          `${apiUrl}/collegesubjectbyinstitutquniqueid/${localStorage.getItem(
            "institute_Unique_Id"
          )}`
        )
        .then((response) => {
          if (response.status === 200 && Array.isArray(response.data.data)) {
            setSubjects(response.data.data);
          } else {
            console.log("Failed to fetch courses.");
          }
        })
        .catch((error) => {
          console.log(error.response.data);
          // if (error.response.data.status === 404) {
          //   toast.info("Course name not found");
          // }
        });
    }
  }, []);


  const handleSubjectChange = (e) => {
    const selectedSubjectNames = e.target.value || [];
  
    // Filter the subjects based on selected Subject Names
    const selectedSubjects = subjects.filter((subject) =>
      selectedSubjectNames.includes(subject.subjectName)
    );
  
    // Map the selected subjects to their IDs
    const selectedSubjectIds = selectedSubjects.map((subject) => subject.subjectId);
  
    // Join the IDs as a comma-separated string
    const subjectIdsString = selectedSubjectIds.join(", ");
  
    // Update the state with the selected Subject IDs
    setSelectedSubjectIds(subjectIdsString);
  };


  const handleCheckboxChange = (e, subjectId, subjectName) => {
    if (e.target.checked) {
      setSelectedSubjectNames((prevNames) => [...prevNames, subjectName]);
      setSelectedSubjectIds((prevIds) => [...prevIds, subjectId]);
    } else {
      setSelectedSubjectNames((prevNames) =>
        prevNames.filter((name) => name !== subjectName)
      );
      setSelectedSubjectIds((prevIds) =>{
        if (Array.isArray(prevIds)) {
        prevIds.filter((id) => id !== subjectId)
        }
        else {
          console.error("prevIds is not an array");
          return prevIds; // return as is
        }
      }
      );
    }
  };

  return (
    <div className="w-full flex justify-between items-center pb-10">
      <div className="w-full h-[990px] bg-white px-16 pb-16 rounded-lg drop-shadow border ">
        {/* Add Faculty Form */}
        <form onSubmit={handleSubmit}>
          <div className="text-center py-6">
            <input
              accept="image/*"
              name="uploadProfile"
              id="image-upload"
              type="file"
              onChange={handleImageUpload}
              style={{ display: "none", backgroundColor: "white" }}
            />
            <label htmlFor="image-upload">
              <IconButton
                color="#f5f5f5"
                aria-label="upload picture"
                component="span"
              >
                {/* <Avatar sx={{ width: 150, height: 150 }}> */}

                <div className="w-[140px] h-[140px] flex flex-col justify-center items-center border-2 border-primary rounded-full bg-white text-primary">
                  {imageUrl ? (
                    <img
                      alt="uploaded"
                      src={imageUrl}
                      className="w-36 h-36 rounded-full object-contain"
                    />
                  ) : (
                    // If no image is uploaded, show the upload icon and text
                    <>
                      {editMode ? (
                        <>
                          {" "}
                          <img
                            alt=""
                            src={`${ImageUrl}/CollegeFacultyUploads/${formData.uploadProfile}`}
                            className="w-36 h-36 rounded-full object-contain"
                          />
                        </>
                      ) : (
                        <>
                          {""}
                          <img
                            alt="uploadcamera"
                            src={camera}
                            className="w-7 h-7 text-primary"
                          />
                          <span className="text-[15px] font-normal">
                            Upload Profile
                          </span>
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* </Avatar> */}
              </IconButton>
            </label>
          </div>

          <Stack
            sx={{
              margin: "auto",
              width: "100%",
              display: "flex",
              alignItems: "center",
              "& > :not(style)": {},
              "& .MuiInputLabel-root": {
                // Reduce label font size
                fontSize: "13px",
                color: "#105D50",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1.3px solid #105D50", // Set the default outline color to black
                },
                "&:hover fieldset": {
                  border: "1.3px solid #105D50",
                  // Change the border color on hover
                },

                "&.Mui-focused fieldset": {
                  border: "1.34px solid #105D50",
                  fontSize: "13px", // Set the outline color to yellow on focus
                },
              },
            }}
          >
            <Stack
              direction="row"
              justifyContent="center"
              // alignItems="center"
              sx={{ marginBottom: "20px", width: "100%" }}
              spacing={2}
            >
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                      fontSize: "14px",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Faculty Name"
                  variant="outlined"
                  fullWidth
                  name="facultyName"
                  type="text"
                  value={formData.facultyName}
                  onChange={handleOnChange}
                  required
                />
                
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Employee Code"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="employeecode"
                  value={formData.employeecode}
                  onChange={handleOnChange}
                />
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                    }}
                    label="Handling Subjects"
                    variant="outlined"
                    fullWidth
                    name="handlingSubjects"
                    type="text"
                    select
                    SelectProps={{
                      multiple: true,
                      renderValue: (selected) => selected.join(", "),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: "160px",
                          },
                        },
                      },
                    }}
                    value={formData.handlingSubjects || []}
                    onChange={(e) => {
                      handleOnChange(e);
                      handleSubjectChange(e);
                    }}

                  >
                    {subjects.map((subject) => (
                      subject.status === 1 ?
                      <MenuItem key={subject._id} value={subject.subjectName}>
                        <Checkbox
                          checked={formData.handlingSubjects.includes(subject.subjectName)}
                          onChange={(e) =>
                            handleCheckboxChange(e, subject.subjectName)
                          }
                          style={{
                            color: "#105D50",
                            width: 36,
                            height: 36
                          }}
                        />
                        {subject.subjectName}
                      </MenuItem> : null
                    ))}
                  </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Email ID"
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="emailId"
                  value={formData.emailId}
                  onChange={handleOnChange}
                  autoComplete="off"
                  required
                />
              
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleOnChange}
                  required
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="Date Of Joining"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    type="date"
                    name="yoj"
                    fullWidth
                    //value={formData.yoj}
                    onChange={handleDojChange}

                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Address Line"
                  variant="outlined"
                  fullWidth
                  type="Text"
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleOnChange}
                  required
                />
<TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  fullWidth
                  label="Country"
                  variant="outlined"
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {countryOptions}
                </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      backgroundColor: "#EEEDED",
                    },
                  }}
                  select
                  label="District"
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {districtOptions}
                </TextField>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                width={"280px"}
                spacing={2}
              >
               
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                    }}
                    label="Faculty ID"
                    variant="outlined"
                    fullWidth
                    name="facultyId"
                    type="text"
                    value={formData.facultyId}
                    onChange={handleOnChange}
                    required
                  />
                {/* )} */}

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Qualification"
                  variant="outlined"
                  fullWidth
                  type="text"
                  name="qualification"
                  value={formData.qualification}
                  onChange={handleOnChange}
                />

                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },

                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Gender"
                  variant="outlined"
                  fullWidth
                  select
                  type="text"
                  name="gender"
                  value={formData.gender}
                  onChange={(e) =>
                    setFormData({ ...formData, gender: e.target.value })
                  }
                  required
                >
                  <MenuItem value="male" style={{ fontSize: "12px" }}>
                    Male
                  </MenuItem>
                  <MenuItem value="female" style={{ fontSize: "12px" }}>
                    Female
                  </MenuItem>
                  <MenuItem value="other" style={{ fontSize: "12px" }}>
                    Other
                  </MenuItem>
                </TextField>
                {editMode ? (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    //value={formData.password}
                    onChange={handleOnChange}
                    autoComplete="new-password"
                    //required
                  />
                ) : (
                  <TextField
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },
                      "& .MuiInputBase-root": {
                        height: 45,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        backgroundColor: "#EEEDED",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&:hover fieldset": {
                          border: "1.3px solid #105D50",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1.34px solid #105D50",
                        },
                      },
                    }}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleOnChange}
                    autoComplete="new-password"
                    required
                  />
                )}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{
                      backgroundColor: "#F5F5F5",
                      color: "#105D50",
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "#105D50",
                      },

                      "& .MuiInputBase-root": {
                        height: 45,
                        width: 280,
                        fontSize: "14px",
                      },
                      "&:hover": {
                        // Apply styles when hovering over the TextField
                        backgroundColor: "#EEEDED", // Change background color on hover
                      },
                    }}
                    label="D.O.B *"
                    format="DD/MM/YYYY"
                    variant="outlined"
                    name="dob"
                    fullWidth
                    type="date"
                    //value={formData.dob}
                    onChange={handleDobChange}
                    required
                    //defaultValue={dayjs('')}
                  />
                </LocalizationProvider>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#105D50",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                  }}
                  label="Blood Group"
                  variant="outlined"
                  fullWidth
                  name="bloodGroup"
                  type="text"
                  value={formData.bloodGroup}
                  onChange={handleOnChange}
                />
               <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                      fontSize: "14px",
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Area"
                  variant="outlined"
                  fullWidth
                  type="Text"
                  name="area"
                  value={formData.area}
                  onChange={handleOnChange}
                  required
                />
                      <TextField
                       sx={{
                        backgroundColor: "#F5F5F5",
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#105D50",
                        },
                        "& .MuiInputBase-root": {
                          height: 45,
                          fontSize: "14px",
                        },
                        "&:hover": {
                          backgroundColor: "#EEEDED",
                        },
                      }}
                      select
                      label="State"
                      variant="outlined"
                      fullWidth
                      name="state"
                      value={selectedState}
                      onChange={handleStateChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {stateOptions}
                    </TextField>
                <TextField
                  sx={{
                    backgroundColor: "#F5F5F5",
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#105D50",
                    },
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                    "&:hover": {
                      // Apply styles when hovering over the TextField
                      backgroundColor: "#EEEDED", // Change background color on hover
                    },
                  }}
                  label="Pincode"
                  variant="outlined"
                  fullWidth
                  type="Number"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleOnChange}
                  required
                />
              </Stack>{" "}
            </Stack>

            <Stack>
              <TextField
                sx={{
                  backgroundColor: "#F5F5F5",
                  color: "#105D50",
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "#105D50",
                  },

                  "& .MuiInputBase-root": {
                    height: 120,
                    width: 580,
                    fontSize: "14px",
                  },
                  "&:hover": {
                    // Apply styles when hovering over the TextField
                    backgroundColor: "#EEEDED", // Change background color on hover
                  },
                }}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                type="text"
                name="description"
                value={formData.description}
                onChange={handleOnChange}
                //defaultValue="Default Value"
              />
            </Stack>

            <div className="flex justify-center items-center space-x-8 pt-8">
              {!editMode && (
                <button
                  type="button"
                  onClick={handleReset}
                  className=" text-[12px] font-normal  px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Reset
                </button>
              )}
              <button
                type="submit"
                className="text-[12px] font-normal   px-10 py-[10px] bg-primary text-white rounded-md hover:bg-secondary duration-300"
              >
                {editMode ? "Update" : "Submit"}
              </button>
              {!editMode && (
                <button
                  type="button"
                  onClick={(e) => {
                    handleSubmitandAdd(e);
                  }}
                  className=" text-[12px] font-normal px-[20px] py-[10px]  bg-primary text-white rounded-md hover:bg-secondary duration-300"
                >
                  Submit & Add
                </button>
              )}
            </div>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default FacultyForm;
